import { useTheme } from '@emotion/react';
import CheckIcon from 'assets/ibanera/Icon_Tick.png';
import Button from 'components/button/Button';
import {
    CryptoDropdownSelect,
    mapAvailableAssetsToDropdownSelectOptions,
} from 'components/cryptoDropdownSelect/CryptoDropdownSelect';
import { MODAL_PORTAL_ID } from 'components/modal/Modal';
import {
    NotificationIdentifier,
    selectUIUpdate,
} from 'components/notifications/notificationUIUpdateReducer';
import { Spinner } from 'components/spinner/Spinner';
import { generateBase64Src } from 'helpers/generateBase64Src';
import { Toast, ToastMessageReason } from 'helpers/toast';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAvailableAssets } from 'reducers/availableAssets';
import { closeModal, DepositModalData, selectModalState } from 'reducers/modal';
import { CryptoOption } from 'types/shared';
import { DepositCryptoModalApi } from './DepositCryptoModalApi';
import walletIcon from 'assets/bitline-ui-redesign/deposit crypto icon.svg';

// If the currency only has one transaction type, we use SegWit to represent that.
enum TransactionType {
    SEGWIT = 1,
    LEGACY,
}

type DepositCryptoModalProps = {};

const DepositCryptoModal: React.FC<DepositCryptoModalProps> = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { availableAssets: assets } = useSelector(selectAvailableAssets);
    const availableCryptoDropdownOptions = assets
        ? mapAvailableAssetsToDropdownSelectOptions(assets)
        : [];
    const { data } = useSelector(selectModalState);

    const [isLoading, setIsLoading] = useState(false);
    const [selectedCrypto, setSelectedCrypto] = useState<CryptoOption | null>(() => {
        const depositModalData = data as DepositModalData;
        if (depositModalData?.fireblocksAssetId && availableCryptoDropdownOptions) {
            const preSelectedAvailableCrypto = availableCryptoDropdownOptions.find(
                (option) => option.fireblocksId === depositModalData.fireblocksAssetId
            );
            return preSelectedAvailableCrypto ?? null;
        }
        return null;
    });
    const [walletAddress, setWalletAddress] = useState<string | null>(null);
    const [QRCode, setQRCode] = useState<string | null>(null);
    const [legacyWalletAddress, setLegacyWalletAddress] = useState<string | null>(null);
    const [legacyQRCode, setLegacyQRCode] = useState<string | null>(null);
    const [transactionType, setTransactionType] = useState<TransactionType>(TransactionType.SEGWIT);
    const portalRef = useRef<HTMLDivElement | null>(null);
    const update = useSelector(selectUIUpdate);

    const handleCopy = () => {
        const addressToCopy =
            transactionType === TransactionType.SEGWIT ? walletAddress : legacyWalletAddress;
        if (addressToCopy) {
            Toast.openToastMessage('Copied', ToastMessageReason.VALID);
            window.navigator.clipboard.writeText(addressToCopy);
        }
    };

    useEffect(() => {
        if (update?.pushType === NotificationIdentifier.DEPOSIT_TRANSACTION_CREATED) {
            if (
                update.data.destinationWalletAddress === walletAddress ||
                update.data.destinationWalletAddress === legacyWalletAddress
            ) {
                dispatch(closeModal());
            }
        }
    }, [update, legacyWalletAddress, walletAddress]);

    useEffect(() => {
        if (selectedCrypto) {
            const selectedAvailableCrypto = assets
                ? assets.find((crypto) => crypto.fireblocksAssetId === selectedCrypto.fireblocksId)
                : null;
            if (selectedAvailableCrypto) {
                setIsLoading(true);
                setTransactionType(TransactionType.SEGWIT);
                setWalletAddress(null);
                setLegacyWalletAddress(null);
                setQRCode(null);
                setLegacyQRCode(null);
                DepositCryptoModalApi.getAddress(selectedAvailableCrypto)
                    .then((response) => {
                        if (response.data.status === '1') {
                            const { address, legacyAddress, qrCode, legacyQRCode } =
                                response.data.details;
                            setWalletAddress(address);
                            setQRCode(qrCode);
                            setLegacyWalletAddress(legacyAddress);
                            setLegacyQRCode(legacyQRCode);
                            setIsLoading(false);
                        }
                    })
                    .catch(() => {
                        setIsLoading(false);
                        Toast.openGenericErrorToast();
                    });
            }
        }
    }, [selectedCrypto, assets, dispatch]);

    // Believe React is resolving all contents of the modal before attaching to the
    // DOM meaning getElementById is returning null if we directly include selector
    // in comoponent body. Effects are run after the modal content is attached so
    // we include the selector here.
    const [shouldRerender, setShouldRerender] = useState(false);

    useEffect(() => {
        const portal = document.getElementById(MODAL_PORTAL_ID) as HTMLDivElement;
        if (portal) {
            portalRef.current = portal;
            setShouldRerender(true);
        }
    }, []);

    useEffect(() => {
        if (shouldRerender) {
            setShouldRerender(false);
        }
    }, [shouldRerender]);

    return (
        <div className="DepositCryptoModal">
            <h1 className="Title">Deposit Crypto</h1>

            <img src={walletIcon} alt="blue wallet icon" className="WalletIcon" />

            <div className="SelectContainer">
                {portalRef.current && availableCryptoDropdownOptions && (
                    <>
                        <span className="FormLabel">Select a Currency</span>
                        <CryptoDropdownSelect
                            options={availableCryptoDropdownOptions}
                            portalTarget={portalRef.current}
                            selectedCrypto={selectedCrypto}
                            setSelectedCrypto={setSelectedCrypto}
                        />
                    </>
                )}
            </div>
            {isLoading ? (
                <div className="LoaderContainer">
                    <Spinner />
                </div>
            ) : walletAddress ||
              QRCode ||
              (walletAddress && QRCode && legacyWalletAddress && legacyQRCode) ? (
                <div className="DepositCryptoDetailsWrapper">
                    {walletAddress && (
                        <div className="WalletAddressContainer">
                            <p className="NoMargin WalletAddress">
                                {transactionType === TransactionType.SEGWIT
                                    ? walletAddress
                                    : legacyWalletAddress}
                            </p>{' '}
                            <Button className="CopyButton" onClick={handleCopy}>
                                Copy
                            </Button>
                        </div>
                    )}
                    {QRCode && (
                        <div className="QRContainer">
                            <img
                                src={generateBase64Src(
                                    transactionType === TransactionType.SEGWIT
                                        ? QRCode
                                        : legacyQRCode ?? ''
                                )}
                                className="QRCode"
                            />
                        </div>
                    )}{' '}
                    {walletAddress && QRCode && legacyWalletAddress && legacyQRCode && (
                        <div className="RadioContainer">
                            <div
                                className={`Option Left ${
                                    transactionType === TransactionType.SEGWIT ? 'Selected' : ''
                                }`}
                                style={{ color: theme.colors.first }}
                                onClick={() => setTransactionType(TransactionType.SEGWIT)}
                            >
                                <div
                                    className={`Checkbox ${
                                        transactionType === TransactionType.SEGWIT
                                    } ? 'Selected' : ''`}
                                    style={{
                                        backgroundColor:
                                            transactionType === TransactionType.SEGWIT
                                                ? theme.colors.first
                                                : undefined,
                                        borderColor:
                                            transactionType === TransactionType.SEGWIT
                                                ? theme.colors.first
                                                : undefined,
                                    }}
                                >
                                    {transactionType === TransactionType.SEGWIT && (
                                        <img src={CheckIcon} />
                                    )}
                                </div>
                                SegWit
                            </div>
                            {
                                <div
                                    className={`Option Right ${
                                        transactionType === TransactionType.LEGACY ? 'Selected' : ''
                                    }`}
                                    style={{ color: theme.colors.first }}
                                    onClick={() => setTransactionType(TransactionType.LEGACY)}
                                >
                                    <div
                                        className="Checkbox"
                                        style={{
                                            backgroundColor:
                                                transactionType === TransactionType.LEGACY
                                                    ? theme.colors.first
                                                    : undefined,
                                            borderColor:
                                                transactionType === TransactionType.LEGACY
                                                    ? theme.colors.first
                                                    : undefined,
                                        }}
                                    >
                                        {transactionType === TransactionType.LEGACY && (
                                            <img src={CheckIcon} />
                                        )}
                                    </div>
                                    Legacy
                                </div>
                            }
                        </div>
                    )}
                </div>
            ) : null}
        </div>
    );
};

export { DepositCryptoModal };
