import instance, { ApiResponse, BaseApi } from 'api';
import { endpoints } from 'endpoints.config';
import { TFAType } from 'types/shared';

type GenerateQRCodeResponse = {
    activationCode: string;
    activationCodeExpiryTimeSeconds: number;
    activationQRCode: string;
};

export class ActivateGuaranteeModalApi extends BaseApi {
    public static generateQRCode(tfaCode: string, tfaType: TFAType, guaranteesId: number) {
        return ActivateGuaranteeModalApi.makeRequest<GenerateQRCodeResponse>({
            url: endpoints.guaranteemodule.getActivateGuaranteeQRCode,
            data: { tfaCode, tfaType, guaranteesId },
            method: 'POST',
        });
    }
}
