import { css, useTheme } from '@emotion/react';
import { Link } from '@reach/router';
import React from 'react';
/** @jsxImportSource @emotion/react */

interface CommonProps {
    className?: string;
    children: React.ReactNode;
}

type ThemedLinkProps = ({ to: string; onClick?: never } | { to?: never; onClick: () => void }) &
    CommonProps;

export const ThemedLink: React.FC<ThemedLinkProps> = ({
    className,
    to,
    onClick,
    children,
}: ThemedLinkProps) => {
    const theme = useTheme();

    const linkStyle = css`
        border-bottom: 1px solid ${theme.colors.first};
        color: ${theme.colors.first};
        cursor: pointer;
        font-size: 12px;
        text-decoration: none;
    `;

    if (to) {
        return (
            <Link to={to} css={linkStyle} className={className ? className : ''}>
                {children}
            </Link>
        );
    }

    // Use onClick prop for elements that are styled as links, but don't route to new path.
    if (onClick) {
        return (
            <span css={linkStyle} className={className ? className : ''} onClick={onClick}>
                {children}
            </span>
        );
    }

    return null;
};
