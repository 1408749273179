import instance, { ApiResponse } from 'api';
import { endpoints } from 'endpoints.config';
import { TableData } from 'helpers/createUseTable';
import { ActionsObservable, combineEpics, ofType } from 'redux-observable';
import { empty, from } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { Store } from './rootReducer';

export type AvailableAsset = {
    id: number;
    name: string;
    displayCode: string;
    fireblocksAssetId: string;
};

export type AvailableVenue = {
    id: number;
    name: string;
    location: string;
    supportedFireblocksAssetIds: string[];
    venueAssetsCode: string;
};

type AvailableVenueListItem = {
    venues__Id: number;
    venues__Name: string;
    venues__Location: string;
    venueFiatAssets__AssetsId: number;
    assets__Code: string;
    assets__Symbol: string;
    venues__SupportedFireblocksAssetIdsCdl: string;
};

const parseAvailableVenueListItem = (listItem: AvailableVenueListItem): AvailableVenue => ({
    id: listItem.venues__Id,
    name: listItem.venues__Name,
    location: listItem.venues__Location,
    supportedFireblocksAssetIds: listItem.venues__SupportedFireblocksAssetIdsCdl.split(','),
    venueAssetsCode: listItem.assets__Code,
});

type GetAvailableAssetsResponse = ApiResponse<{
    availableAssets: AvailableAsset[];
    fiatCurrency: string;
}>;

type GetAvailableVenuesResponse = TableData<AvailableVenueListItem>;

type AvailableAssetsState = {
    availableAssets: null | AvailableAsset[];
    fiatCurrency: null | string;
    availableVenues: null | AvailableVenue[];
};

const initialState: AvailableAssetsState = {
    availableAssets: null,
    fiatCurrency: null,
    availableVenues: null,
};

enum AvailableAssetsActionType {
    GET_AVAILABLE_ASSETS = 'app/availableAssets/GET_AVAILABLE_ASSETS',
    SET_AVAILABLE_ASSETS = 'app/availableAssets/SET_AVAILABLE_ASSETS',
    SET_PAIRED_CURRENCY = 'app/availableAssets/SET_PAIRED_CURRENCY',
    GET_AVAILABLE_VENUES = 'app/availableAssets/GET_AVAILABLE_VENUES',
    SET_AVAILABLE_VENUES = 'app/availableAssets/SET_AVAILABLE_VENUES',
}

type GetAvailableAssetsAction = {
    type: AvailableAssetsActionType.GET_AVAILABLE_ASSETS;
};
type SetAvailableAssetsAction = {
    type: AvailableAssetsActionType.SET_AVAILABLE_ASSETS;
    payload: AvailableAssetsState['availableAssets'];
};
type SetPairedCurrencyAction = {
    type: AvailableAssetsActionType.SET_PAIRED_CURRENCY;
    payload: AvailableAssetsState['fiatCurrency'];
};
type GetAvailableVenuesAction = {
    type: AvailableAssetsActionType.GET_AVAILABLE_VENUES;
};
type SetAvailableVenuesAction = {
    type: AvailableAssetsActionType.SET_AVAILABLE_VENUES;
    payload: AvailableAssetsState['availableVenues'];
};

type AvailableAssetsAction =
    | SetAvailableAssetsAction
    | GetAvailableAssetsAction
    | SetAvailableVenuesAction
    | GetAvailableVenuesAction
    | SetPairedCurrencyAction;

const Reducer = (state = initialState, action: AvailableAssetsAction): AvailableAssetsState => {
    switch (action.type) {
        case AvailableAssetsActionType.SET_AVAILABLE_ASSETS:
            return { ...state, availableAssets: action.payload };
        case AvailableAssetsActionType.SET_AVAILABLE_VENUES:
            return { ...state, availableVenues: action.payload };
        case AvailableAssetsActionType.SET_PAIRED_CURRENCY:
            return { ...state, fiatCurrency: action.payload };
        default:
            return state;
    }
};

const fetchAvailableAssets = () => {
    return from(
        instance.get<GetAvailableAssetsResponse>(endpoints.cryptosmodule.getAvailableAssets)
    );
};

const getAvailableAssetsEpic = (action$: ActionsObservable<GetAvailableAssetsAction>) =>
    action$.pipe(
        ofType(AvailableAssetsActionType.GET_AVAILABLE_ASSETS),
        switchMap(() => {
            return fetchAvailableAssets().pipe(
                filter((response) => response.data.status === '1'),
                mergeMap((response) => {
                    return [
                        setAvailableAssets(response.data.details.availableAssets),
                        setPairedCurrency(response.data.details.fiatCurrency),
                    ];
                }),
                catchError(() => {
                    //Toast.openGenericErrorToast(); //TODO add real error message
                    return empty();
                })
            );
        })
    );

const fetchAvailableVenues = () => {
    return from(
        instance.get<GetAvailableVenuesResponse>(endpoints.guaranteemodule.listAvailableVenues)
    );
};

const getAvailableVenuesEpic = (action$: ActionsObservable<GetAvailableVenuesAction>) =>
    action$.ofType(AvailableAssetsActionType.GET_AVAILABLE_VENUES).pipe(
        switchMap(() =>
            fetchAvailableVenues().pipe(
                filter((response) => response.data.status === '1'),
                map((response) =>
                    setAvailableVenues(
                        response.data.details.listData.map((listItem) =>
                            parseAvailableVenueListItem(listItem)
                        )
                    )
                ),
                catchError(() => {
                    //Toast.openGenericErrorToast(); //TODO add real error message
                    return empty();
                })
            )
        )
    );

export const availableAssetsEpic = combineEpics(getAvailableAssetsEpic, getAvailableVenuesEpic);

export default Reducer;

// Action creators
const setAvailableAssets = (
    assets: AvailableAssetsState['availableAssets']
): SetAvailableAssetsAction => ({
    type: AvailableAssetsActionType.SET_AVAILABLE_ASSETS,
    payload: assets,
});
const setAvailableVenues = (
    assets: AvailableAssetsState['availableVenues']
): SetAvailableVenuesAction => ({
    type: AvailableAssetsActionType.SET_AVAILABLE_VENUES,
    payload: assets,
});
const setPairedCurrency = (currency: string): SetPairedCurrencyAction => ({
    type: AvailableAssetsActionType.SET_PAIRED_CURRENCY,
    payload: currency,
});

export const getAvailableAssets = (): GetAvailableAssetsAction => ({
    type: AvailableAssetsActionType.GET_AVAILABLE_ASSETS,
});
export const getAvailableVenues = (): GetAvailableVenuesAction => ({
    type: AvailableAssetsActionType.GET_AVAILABLE_VENUES,
});

// Selectors
export const selectAvailableAssets = (store: Store) => store.availableAssets;
export const selectFiatCurrency = (store: Store) => store.availableAssets.fiatCurrency;
