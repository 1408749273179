import React, { createContext, useContext } from 'react';
import { CountryCode, UserProfileDetails } from './models';

export const profileContext = createContext<ProfileContext | null>(null);
type ProfileContext = {
    profileDetails: UserProfileDetails | null;
    countryCodes: CountryCode[];
};

export const useProfileContext = () => {
    const context = useContext(profileContext);
    if (!context) {
        throw new Error('profile context not used within provider');
    }
    return context;
};
