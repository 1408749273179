import instance, { ApiResponse } from 'api';
import { endpoints } from 'endpoints.config';
import { AvailableAsset } from 'reducers/availableAssets';

type AddressResponse = {
    address: string;
    qrCode: string;
    legacyAddress: string;
    legacyQRCode: string;
};

export class DepositCryptoModalApi {
    public static getAddress(asset: AvailableAsset) {
        return instance.get<ApiResponse<AddressResponse>>(endpoints.cryptosmodule.getAddress, {
            params: { currency: asset.fireblocksAssetId },
        });
    }
}
