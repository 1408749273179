import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectAuthStatus } from 'reducers/auth';
import { selectComponentResources } from 'reducers/componentResources';
import { selectVerificationDetails } from 'reducers/jumio';

export const useZoho = () => {
    const zoho = (window as any).$zoho;
    const { userInfo } = useSelector(selectComponentResources);
    const authStatus = useSelector(selectAuthStatus);
    const { stage } = useSelector(selectVerificationDetails);

    useEffect(() => {
        if (!userInfo || !zoho) return;
        zoho.salesiq.visitor.name(`${userInfo.firstName} ${userInfo.lastName}`);
        zoho.salesiq.visitor.email(userInfo.emailAddress ?? '');
        zoho.salesiq.visitor.info({ Site: 'Bitline' });
        zoho.salesiq.visitor.info({ 'Signed in?': authStatus === 'signed_in' ? 'Yes' : 'No' });
        zoho.salesiq.visitor.info({ 'Verification Status': stage });
    }, [userInfo, zoho, authStatus, stage]);
};
