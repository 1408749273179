import { useTable } from 'api';
import GuaranteesLogo from 'assets/ibanera/Icon_Big_Guarantee.png';
import {
    GuaranteesTable,
    GUARANTEES_TABLE_ID_COLUMN,
} from 'components/guaranteesTable/GuaranteesTable';
import { LoadingScreenObject } from 'components/loadingScreen/LoadingScreen';
import Pagination from 'components/pagination/Pagination';
import { TableSearchInput } from 'components/tableSearchInput/TableSearchInput';
import { endpoints } from 'endpoints.config';
import { useDebouncedState } from 'helpers/useDebouncedState';
import React, { createContext, SetStateAction, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllCryptoPrices } from 'reducers/cryptoPrices';
import { getUsersCurrentHoldings, selectCurrentHoldings } from 'reducers/currentHoldings';
import { BalanceTiles } from './BalanceTiles';

export type SelectedCryptoData = {
    open: boolean;
    data: CryptoLiveData | null;
};

export type CryptoLiveData = {
    pair: string;
    bestAsk: number | null;
    name: string;
    ticker: string;
    close: number | null;
    low24hrs: number | null;
    high24hrs: number | null;
    openPrice: number | null;
};

export type CryptoContextType = {
    searchString: string;
    setSearchString: React.Dispatch<SetStateAction<string>>;
};

const defaultContext: CryptoContextType = {
    searchString: '',
    setSearchString: () => {},
};

const HomeContext = createContext<CryptoContextType>(defaultContext);

export const HomePage: React.FC = () => {
    const dispatch = useDispatch();

    const table = useTable<any, any>({
        url: endpoints.dashboardmodule.transactionList,
        filters: `guarantees__Status INSTRINGARRAY ([(Creating), (Pending), (Locked), (Releasing)])`,
    });

    const [searchString, setSearchString, localSearchString] = useDebouncedState('');
    const localSearchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(event.currentTarget.value);
    };

    useEffect(() => {
        table.data?.actions.changeSearch(searchString);
    }, [searchString]);

    useEffect(() => {
        dispatch(getUsersCurrentHoldings());
    }, [dispatch]);
    const currentHoldings = useSelector(selectCurrentHoldings);
    const livePriceData = useSelector(selectAllCryptoPrices);

    return (
        <HomeContext.Provider value={{ searchString, setSearchString }}>
            <div className="HomePage">
                {!currentHoldings && <LoadingScreenObject fullscreen />}
                <BalanceTiles />
                <div className="HomeLiveGuarantees">
                    <div className="LiveGuaranteesHeader">
                        <h2 className="HomeTableHeader">Live Transactions</h2>
                        <TableSearchInput
                            name="search"
                            placeholder="Search"
                            className="EditBox"
                            value={localSearchString}
                            onChange={localSearchHandler}
                        />
                    </div>
                    <GuaranteesTable
                        isLoading={table.loading}
                        table={table}
                        idColumn={GUARANTEES_TABLE_ID_COLUMN}
                        liveData={livePriceData}
                        isHomePageTable
                        isNewBitLineDesign
                    />
                    <Pagination table={table} />
                </div>
            </div>
        </HomeContext.Provider>
    );
};
