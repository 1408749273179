/**
 * Reducer paired with the notifications system. 'ApplicationDataPush' message
 * updates the value in this reducer. Components subscribe to the value in this
 * reducer and if the value indicates the component should update, it does so.
 * If your application doesn't use the notifications system to send messages
 * other than stack notifications there is no need to include this in your app.
 */
import { GuaranteeTableRow } from 'components/guaranteesTable/GuaranteesTable';
import { VerificationStatusResponse } from 'components/jumio/jumioModels';
import { Store } from '../../reducers/rootReducer';
import { TickerPrice } from 'reducers/cryptoPrices';

// Identifiers can be found in Stoplight project under dummy notifications endpoint.
export enum NotificationIdentifier {
    UPDATE_BALANCE = 'UPDATE_BALANCE',
    ACTIVATE_PARTIAL_RELEASE = 'ACTIVATE_PARTIAL_RELEASE',
    ACTIVATE_TOP_UP = 'ACTIVATE_TOP_UP',
    DEACTIVATE_PARTIAL_RELEASE = 'DEACTIVATE_PARTIAL_RELEASE',
    DEACTIVATE_TOP_UP = 'DEACTIVATE_TOP_UP',
    RELOAD_GUARANTEES_TABLE = 'RELOAD_GUARANTEES_TABLE',
    RELOAD_TRANSACTIONS_TABLE = 'RELOAD_TRANSACTIONS_TABLE',
    UPDATE_GUARANTEE_STATUS = 'UPDATE_GUARANTEE_STATUS',
    SET_CRYPTO_PRICES = 'SET_CRYPTO_PRICES',
    DEPOSIT_TRANSACTION_CREATED = 'DEPOSIT_TRANSACTION_CREATED',
    ACTIVATION_CODE_SCANNED = 'ACTIVATION_CODE_SCANNED',
    UNLOCK_CODE_SCANNED = 'UNLOCK_CODE_SCANNED',
    UPDATE_VERIFICATION_COMPONENTS = 'UPDATE_VERIFICATION_COMPONENTS',
}

export type NotificationData =
    | {
          pushType: NotificationIdentifier.UPDATE_BALANCE;
          data: {
              balance: number;
              lockedBalance: number;
              unlockedBalance: number;
              fireblocksAssetId: string;
          };
      }
    | {
          pushType: NotificationIdentifier.ACTIVATE_PARTIAL_RELEASE;
          data: {
              guaranteesId: number;
          };
      }
    | {
          pushType: NotificationIdentifier.ACTIVATE_TOP_UP;
          data: {
              guaranteesId: number;
          };
      }
    | {
          pushType: NotificationIdentifier.DEACTIVATE_PARTIAL_RELEASE;
          data: {
              guaranteesId: number;
          };
      }
    | {
          pushType: NotificationIdentifier.DEACTIVATE_TOP_UP;
          data: {
              guaranteesId: number;
          };
      }
    | {
          pushType: NotificationIdentifier.RELOAD_GUARANTEES_TABLE;
          data: {};
      }
    | {
          pushType: NotificationIdentifier.RELOAD_TRANSACTIONS_TABLE;
          data: {};
      }
    | {
          pushType: NotificationIdentifier.UPDATE_GUARANTEE_STATUS;
          data: {
              guaranteesId: number;
              status: GuaranteeTableRow['guarantees__Status'];
          };
      }
    | {
          pushType: NotificationIdentifier.SET_CRYPTO_PRICES;
          data: {
              prices: TickerPrice[];
          };
      }
    | {
          pushType: NotificationIdentifier.DEPOSIT_TRANSACTION_CREATED;
          data: {
              destinationWalletAddress: string;
          };
      }
    | {
          pushType: NotificationIdentifier.ACTIVATION_CODE_SCANNED;
          data: {
              activationCode: string;
          };
      }
    | {
          pushType: NotificationIdentifier.UPDATE_VERIFICATION_COMPONENTS;
          data: VerificationStatusResponse;
      }
    | {
          pushType: NotificationIdentifier.UNLOCK_CODE_SCANNED;
          data: {
              unlockCode: string;
          };
      };

/**
 * Only a single update is stored which means that if it is not consumed immediately
 * it could get overwritten by a future update. This is fine because if the update
 * is not consumed immediately it means the target component isn't rendered and when
 * it does get rerendered, the latest data will be fetched anyway. This approach
 * means that any data sent in a SignalR message must also be reflected in the REST
 * API call when it gets made.
 */
type NotificationUIUpdateState = {
    update: NotificationData | null;
};

const initialState: NotificationUIUpdateState = { update: null };

enum NotificationUIUpdateActionType {
    ADD_UI_UPDATE = 'ADD_UI_UPDATE',
    COMPLETE_UI_UPDATE = 'COMPLETE_UI_UPDATE',
}

type NotificationUIUpdateAction =
    | {
          type: NotificationUIUpdateActionType.ADD_UI_UPDATE;
          payload: NotificationData;
      }
    | { type: NotificationUIUpdateActionType.COMPLETE_UI_UPDATE };

const Reducer = (
    state = initialState,
    action: NotificationUIUpdateAction
): NotificationUIUpdateState => {
    switch (action.type) {
        case NotificationUIUpdateActionType.ADD_UI_UPDATE:
            return { update: action.payload };
        case NotificationUIUpdateActionType.COMPLETE_UI_UPDATE:
            return { update: null };
        default:
            return state;
    }
};

export default Reducer;

// Action creators

export const setUIUpdate = (payload: NotificationData): NotificationUIUpdateAction => {
    return {
        type: NotificationUIUpdateActionType.ADD_UI_UPDATE,
        payload: payload,
    };
};
export const completeUIUpdate = (): NotificationUIUpdateAction => ({
    type: NotificationUIUpdateActionType.COMPLETE_UI_UPDATE,
});

// Selectors
export const selectUIUpdate = (store: Store) => store.notificationUIUpdate.update;
