import React from 'react';
import classNames from 'classnames';
import BitLineLogo from 'assets/bitline-ui-redesign/bitline logo.svg';

type Props = {
    context?: 'SignInOrRegisterSplashPage';
};

export const SplashPage: React.FC<Props> = ({ children, context }) => {
    const isSignInOrRegisterPage = context === 'SignInOrRegisterSplashPage';

    return (
        <div
            className={classNames('SplashPage', {
                WithMobBackground: isSignInOrRegisterPage,
                WithMobInfoSection: isSignInOrRegisterPage,
                FullScreen: isSignInOrRegisterPage,
            })}
        >
            <div className="SplashPageInfoSection">
                {isSignInOrRegisterPage ? (
                    <img src={BitLineLogo} alt="BitLine Logo" className="BitLineLogo" />
                ) : null}
                <h1>Powerful, Smart, Ready!</h1>
                <h2>Casino transactions via crypto assets</h2>
                <div className="SubTextWrapper">
                    <p>
                        <span className="SplashPageImportantText">Access cash or chips</span> within
                        minutes at casinos worldwide, 24/7!
                    </p>
                </div>
            </div>
            {children}
        </div>
    );
};
