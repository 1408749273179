// https://stackoverflow.com/a/23059703/14015703
const FloatRegex = /^(?=.)([+-]?([0-9]*)(\.([0-9]+))?)$/;

export const getNumberFromString = (numString: string): number | null => {
    const removedCommas = numString.replaceAll(',', '');
    if (FloatRegex.test(removedCommas) && parseFloat(removedCommas)) {
        return parseFloat(removedCommas);
    }
    return null;
};
