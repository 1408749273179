import { Store } from './rootReducer';
import { Theme } from '@emotion/react';
import { initialTheme } from '../theme';

/* STATE */
type State = {
    theme: Theme;
    companyName: string;
    imagesPath: string;
};

const initialState: State = {
    theme: initialTheme,
    companyName: '',
    imagesPath: '',
};

/* ACTIONS */
const SET_BRANDING = 'app/ui/SET_BRANDING';

type Action = {
    type: typeof SET_BRANDING;
    payload: { theme: Theme; brandName: string; imagesPath: string };
};

/* REDUCER */
export default function reducer(state = initialState, action: Action): State {
    switch (action.type) {
        case SET_BRANDING:
            return {
                ...state,
                theme: action.payload.theme,
                companyName: action.payload.brandName,
                imagesPath: action.payload.imagesPath,
            };
        default:
            return state;
    }
}

/* ACTION CREATORS */
export const setBranding = (payload: any): Action => {
    return {
        type: SET_BRANDING,
        payload,
    };
};

/* SELECTORS */
export const selectTheme = (app: Store): Theme => app.ui.theme;

export const selectImagePath = (app: Store): string => app.ui.imagesPath;
