import { DateRangeSelector } from 'components/dateRangeSelector/DateRangeSelector';
import { TableSearchInput } from 'components/tableSearchInput/TableSearchInput';
import React from 'react';

type TableFilteringAndSearchProps = {
    searchValue: string;
    handleSearchChange: (value: string) => void;
    filteringLabel?: string;
    startDate: Date | null;
    setStartDate: (date: Date) => void;
    endDate: Date | null;
    setEndDate: (date: Date) => void;
};

export const TableFilteringAndSearch: React.FC<TableFilteringAndSearchProps> = ({
    handleSearchChange,
    searchValue,
    filteringLabel,
    endDate,
    setEndDate,
    startDate,
    setStartDate,
}) => {
    const localSearchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleSearchChange(event.currentTarget.value);
    };

    return (
        <div className="TableFilteringAndSearch">
            <div className="DateFilters">
                {filteringLabel && <p className="FilteringLabel">{filteringLabel}</p>}
                <DateRangeSelector
                    endDate={endDate}
                    startDate={startDate}
                    setEndDate={setEndDate}
                    setStartDate={setStartDate}
                />
            </div>
            <div className="SearchBox">
                <TableSearchInput
                    name="search"
                    placeholder="Search"
                    className="EditBox"
                    value={searchValue}
                    onChange={localSearchHandler}
                />
            </div>
        </div>
    );
};
