import instance, { ApiResponse } from 'api';
import Button from 'components/button/Button';
import { endpoints } from 'endpoints.config';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectVerificationDetails,
    setVerificationStage,
    updateAdditionalData,
} from 'reducers/jumio';
import { JumioStage, VerificationStatusResponse } from './jumioModels';

//icons
import WarningIcon from 'assets/bitline-ui-redesign/error icon.svg';

export const JumioFailure = ({ bManualAvailable }: { bManualAvailable: boolean }) => {
    const [submitting, setSubmitting] = useState(false);
    const dispatch = useDispatch();
    const { stage } = useSelector(selectVerificationDetails);

    const handleManualInterventionRequest = () => {
        setSubmitting(true);
        instance
            .post<ApiResponse<VerificationStatusResponse>>(
                endpoints.jumiomodule.requestManualVerification
            )
            // This endpoint just updates the additional data, it won't
            .then((res) => {
                dispatch(updateAdditionalData(res.data.details.additionalData));
                Toast.openToastMessage(
                    'Our support team has been alerted, one of them will contact you by email soon.',
                    ToastMessageReason.VALID
                );
            })
            .finally(() => setSubmitting(false));
    };

    const handleRetry = async () => {
        setSubmitting(true);
        try {
            const res = await instance.get<ApiResponse<VerificationStatusResponse>>(
                endpoints.jumiomodule.verificationStatus,
                {
                    params: { retry: true },
                }
            );
            dispatch(
                setVerificationStage({
                    currentStage: res.data.details.status,
                    additionalData: res.data.details.additionalData,
                })
            );
        } catch (err) {
            Toast.openGenericErrorToast();
            setSubmitting(false);
        }
    };
    const zoho = (window as any).$zoho.salesiq;
    const handleZoho = () => {
        const question =
            stage === JumioStage.AddressFrameFailed
                ? 'I need some help verifying my address'
                : 'I need some help verifying my ID';
        zoho.visitor.question(question);
        zoho.chat.start();
    };
    return (
        <div className="VerificationError">
            <img className="ErrorIcon" src={WarningIcon} alt="error" />
            <h3>An error has occured</h3>
            <p>
                Sorry, there was an error during verification of your{' '}
                {stage === JumioStage.AddressFrameFailed ? 'address' : 'ID'}, please try again.
            </p>
            <div className="JumioErrorButtons">
                <Button minWidth disabled={submitting} onClick={handleRetry}>
                    Retry
                </Button>

                {zoho && (
                    <Button minWidth priority="secondary" onClick={handleZoho}>
                        Live chat
                    </Button>
                )}

                {bManualAvailable && (
                    <Button
                        disabled={submitting}
                        className="ManualInterventionBtn"
                        onClick={handleManualInterventionRequest}
                        priority="secondary"
                        minWidth
                    >
                        Request help
                    </Button>
                )}
            </div>
        </div>
    );
};
