import { FormSingleSelectField } from '@avamae/formbuilder';
import instance, { ApiResponse } from 'api';
import Button from 'components/button/Button';
import { VerifyIdentityOptions } from 'components/jumio/JumioVerification';
import { VerificationStatusResponse } from 'components/jumio/jumioModels';
import { Spinner } from 'components/spinner/Spinner';
import { endpoints } from 'endpoints.config';
import { Formik, Form, FormikHelpers } from 'formik';
import { Toast, ToastMessageReason } from 'helpers/toast';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modal';
import AddressIcon from 'assets/address_blue_icon.svg';

type Props = {};

const initialValues = {
    countryCode: '',
    typeCode: '',
};

const steps = {
    loading: 'LOADING',
    textData: 'TEXTDATA',
    iframe: 'IFRAME',
    success: 'SUCCESS',
} as const;
type Step = (typeof steps)[keyof typeof steps];

export const UpdateAddressModal = (props: Props) => {
    const [options, setOptions] = useState<VerifyIdentityOptions | null>(null);
    const [iframeUrl, setIframeUrl] = useState<string | null>(null);
    const [step, setStep] = useState<Step>(steps.success);
    const dispatch = useDispatch();

    const handleClose = () => dispatch(closeModal());

    useEffect(() => {
        instance
            .get<ApiResponse<VerifyIdentityOptions>>(endpoints.jumiomodule.verifyIdentity)
            .then((res) => {
                setOptions(res.data.details);
                setStep(steps.textData);
            })
            .catch((err) => {
                Toast.openToastMessage(
                    "We can't process this request at the moment, please try again later",
                    ToastMessageReason.ERROR
                );
            });
    }, []);

    const handleSubmit = (
        values: typeof initialValues,
        formikHelpers: FormikHelpers<typeof initialValues>
    ) => {
        instance
            .post<ApiResponse<VerificationStatusResponse>>(
                endpoints.jumiomodule.verifyIdentity,
                values
            )
            .then((res) => {
                if (!res.data.details.additionalData?.iframeUrl) throw new Error();
                setIframeUrl(res.data.details.additionalData?.iframeUrl ?? null);
                setStep(steps.iframe);
            })
            .catch((err) => {
                formikHelpers.setSubmitting(false);
                Toast.openGenericErrorToast();
            });
    };

    let stepContent: React.ReactNode;
    if (!options || step === steps.loading)
        stepContent = (
            <>
                <h1 className="Title">Edit Address</h1>

                <img src={AddressIcon} alt="address icon" className="AddressIcon" />
                <Spinner className="UpdateAddressSpinner" />

                <Button
                    priority="secondary"
                    type="button"
                    className="Secondary"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
            </>
        );
    else if (step === steps.iframe)
        stepContent = (
            <JumioAddressIframe
                url={iframeUrl ?? ''}
                handleComplete={() => {
                    setStep(steps.success);
                }}
                handleClose={handleClose}
            />
        );
    else if (step === steps.success)
        stepContent = <AddressUpdateSuccessMessage handleClose={handleClose} />;
    else
        stepContent = (
            <>
                <h1 className="Title">Edit Address</h1>

                <img src={AddressIcon} alt="address icon" className="AddressIcon" />
                <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                    <Form className="UpdateAddressForm">
                        <p>You'll need to upload a new proof of address document</p>
                        <FormSingleSelectField
                            label="Document type"
                            fieldName="typeCode"
                            options={options.documentOptions.map((option) => ({
                                value: option.typeCode,
                                label: option.name,
                            }))}
                            dropdownProps={{
                                menuPortalTarget: document.body,
                                styles: {
                                    menuPortal: (provided: any) => ({
                                        ...provided,
                                        zIndex: 500,
                                    }),
                                },
                            }}
                        />
                        <FormSingleSelectField
                            label="Origin country"
                            fieldName="countryCode"
                            options={options.countryOptions.map((option) => ({
                                value: option.isoCode,
                                label: option.name,
                            }))}
                            dropdownProps={{
                                menuPortalTarget: document.body,
                                styles: {
                                    menuPortal: (provided: any) => ({
                                        ...provided,
                                        zIndex: 500,
                                    }),
                                },
                            }}
                        />
                        <div className="UpdateAddressButtons">
                            <Button minWidth color="second">
                                Next
                            </Button>

                            <Button
                                minWidth
                                priority="secondary"
                                type="button"
                                className="Secondary"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </div>
                    </Form>
                </Formik>
            </>
        );

    return <div className="UpdateAddressModal">{stepContent}</div>;
};

const JumioAddressIframe = ({
    url,
    handleComplete,
    handleClose,
}: {
    url: string;
    handleComplete(): void;
    handleClose(): void;
}) => {
    const iframeOrigin = 'https://netverify.com';

    const handleMessage = useCallback(
        (e: MessageEvent<any>) => {
            if (
                e.origin.toLowerCase() === iframeOrigin ||
                e.origin.toLowerCase() === 'https://bitline.netverify.com'
            ) {
                // temporarily allow both so that this can be deployed separately from change to url
                if (typeof e.data === 'string') {
                    const data = JSON.parse(e.data);
                    if (data.payload.value === 'success') handleComplete();
                } else if (e.data.payload.value === 'success') handleComplete();
            }
        },
        [handleComplete, iframeOrigin]
    );

    useEffect(() => {
        window.addEventListener('message', handleMessage);
        return () => window.removeEventListener('message', handleMessage);
    }, [handleMessage]);

    return (
        <>
            <iframe
                title="Jumio ID Iframe"
                src={url}
                className="JumioIframe"
                allow="camera;fullscreen;accelerometer;gyroscope;magnetometer"
                allowFullScreen
            ></iframe>
            <Button priority="secondary" type="button" className="Secondary" onClick={handleClose}>
                Cancel
            </Button>
        </>
    );
};

const AddressUpdateSuccessMessage = ({ handleClose }: { handleClose(): void }) => {
    return (
        <>
            <div className="AddressUpdateSuccess">
                Thanks, we'll verify your document and update your address
            </div>
            <Button priority="secondary" type="button" className="Secondary" onClick={handleClose}>
                Cancel
            </Button>
        </>
    );
};
