import Button from 'components/button/Button';
import React from 'react';
import { useDispatch } from 'react-redux';
import { openModal, ModalType } from 'reducers/modal';

type NavMenuButtonsProps = {};

export const NavMenuButtons: React.FC<NavMenuButtonsProps> = () => {
    const dispatch = useDispatch();

    const handleNavButtons = (modalType: ModalType) => () => {
        dispatch(openModal({ modalType, data: null }));
    };

    return (
        <div className="SideMenuButtons">
            <Button onClick={handleNavButtons(ModalType.DEPOSIT_CRYPTO)}>Deposit Crypto</Button>
            <Button priority="secondary" onClick={handleNavButtons(ModalType.CREATE_GUARANTEE)}>
                Create Transaction
            </Button>
        </div>
    );
};
