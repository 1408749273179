import { Spinner } from 'components/spinner/Spinner';
import { Toast } from 'helpers/toast';
import React, { useCallback, useEffect, useState } from 'react';
import { NotificationChannel, NotificationStream, NotificationsUserPreferences } from './models';
import { NotificationsApi } from './NotificationsApi';

export const NotificationsConfig: React.FC = () => {
    const [notificationPreferences, setNotificationPreferences] =
        useState<NotificationsUserPreferences | null>(null);

    useEffect(() => {
        (async () => {
            const response = await NotificationsApi.getNotificationsConfig();
            if (NotificationsApi.isSuccessData(response)) {
                setNotificationPreferences(response.data);
            } else {
                Toast.openGenericErrorToast();
            }
        })();
    }, []);

    const findPreference = useCallback(
        (channelId: NotificationChannel['id'], streamId: NotificationStream['id']) => {
            if (notificationPreferences) {
                return notificationPreferences.userPreferences.find(
                    (preference) =>
                        preference.channelsId === channelId && preference.streamsId === streamId
                );
            }
        },
        [notificationPreferences]
    );

    console.log('notificationPreferences', notificationPreferences);

    const findIsChecked = (
        channelId: NotificationChannel['id'],
        streamId: NotificationStream['id']
    ) => {
        const preference = findPreference(channelId, streamId);
        return !!preference?.bEnabled;
    };

    const handleChange = (
        channelId: NotificationChannel['id'],
        streamId: NotificationStream['id'],
        value: boolean
    ) => {
        const preference = findPreference(channelId, streamId);
        if (preference && preference.bEnabled !== value) {
            const indexToUpdate = notificationPreferences!.userPreferences.findIndex(
                (preference) =>
                    preference.channelsId === channelId && preference.streamsId === streamId
            );
            if (indexToUpdate !== -1) {
                // const newValue = !preference.bEnabled;
                const newValue = value;

                console.log('newValue', newValue);
                NotificationsApi.updateSingleConfig({
                    channelsId: channelId,
                    streamsId: streamId,
                    bEnabled: newValue,
                });
                const newPreferences = [...notificationPreferences!.userPreferences];
                newPreferences[indexToUpdate] = { ...preference, bEnabled: !preference.bEnabled };
                setNotificationPreferences((prev) => ({
                    ...prev!,
                    userPreferences: newPreferences,
                }));
            }
        }
    };

    // This is BitLine specific implementation. Can be modified / removed on other
    // projects.
    // Each stream is required to be active in at least one channel. Therefore
    // we need to grey out certain checkboxes in the UI if they can't be disabled.
    const checkCheckboxCanBeChanged = (
        channelId: NotificationChannel['id'],
        streamId: NotificationStream['id']
    ) => {
        if (notificationPreferences) {
            const preference = findPreference(channelId, streamId);
            const row = notificationPreferences.userPreferences.filter(
                (preference) => preference.streamsId === streamId
            );
            const numCheckedInRow = row.filter((preference) => preference.bEnabled).length;
            const isPreferenceOnlyCheckedInRow = preference?.bEnabled && numCheckedInRow === 1;
            const stream = notificationPreferences.streams.find((stream) => stream.id === streamId);
            const isStreamRequired = stream!.bRequired;
            const channel = notificationPreferences.channels.find(
                (channel) => channel.id === channelId
            );
            const isChannelAvailable = channel!.bAvailable;
            return isChannelAvailable && !(isStreamRequired && isPreferenceOnlyCheckedInRow);
        }
        return true;
    };

    const generateId = (
        channelId: NotificationChannel['id'],
        streamId: NotificationStream['id']
    ) => {
        return `s${streamId}-c${channelId}`;
    };

    return (
        <div className="NotificationsConfig">
            {notificationPreferences ? (
                <div className="PreferencesTable">
                    {notificationPreferences.streams.map((stream, i) => (
                        <Stream
                            key={i}
                            stream={stream}
                            channels={notificationPreferences.channels}
                            generateId={generateId}
                            findIsChecked={findIsChecked}
                            checkCheckboxCanBeChanged={checkCheckboxCanBeChanged}
                            handleChange={handleChange}
                        />
                    ))}
                </div>
            ) : (
                <Spinner />
            )}
        </div>
    );
};

type StreamProps = {
    stream: NotificationStream;
    channels: NotificationChannel[];
} & Omit<ChannelProps, 'channel' | 'streamId'>;

const Stream: React.FC<StreamProps> = ({ stream, channels, ...props }) => (
    <div className="NotificationStream">
        <div className="NotificationType">{stream.description}</div>
        {channels.map((channel, i) => (
            <Channel key={i} channel={channel} streamId={stream.id} {...props} />
        ))}
    </div>
);

type ChannelProps = {
    streamId: number;
    channel: NotificationChannel;
    checkCheckboxCanBeChanged: (
        channelId: NotificationChannel['id'],
        streamId: NotificationStream['id']
    ) => boolean;
    findIsChecked: (
        channelId: NotificationChannel['id'],
        streamId: NotificationStream['id']
    ) => boolean;
    generateId: (
        channelId: NotificationChannel['id'],
        streamId: NotificationStream['id']
    ) => string;
    handleChange: (
        channelId: NotificationChannel['id'],
        streamId: NotificationStream['id'],
        value: boolean
    ) => void;
};

const Channel: React.FC<ChannelProps> = ({
    streamId,
    channel,
    findIsChecked,
    checkCheckboxCanBeChanged,
    generateId,
    handleChange,
}) => {
    const canBeChanged = checkCheckboxCanBeChanged(channel.id, streamId);

    return (
        <div className="Channel">
            <label className="ChannelLabel">{channel.displayName} notification</label>

            <div className={`RadioButtons ${canBeChanged ? '' : 'Disabled'}`}>
                <div
                    className="Radio"
                    role="checkbox"
                    aria-checked={findIsChecked(channel.id, streamId)}
                    aria-disabled={!canBeChanged}
                    onClick={() => handleChange(channel.id, streamId, true)}
                >
                    <input
                        id={channel.displayName + '-on'}
                        disabled={!canBeChanged}
                        type="radio"
                        name={generateId(channel.id, streamId)}
                        value={'true'}
                        checked={findIsChecked(channel.id, streamId)}
                        onChange={() => {}} // Prevent console error about providing a checked attribute but no onChange prop. The onChange is the onClick on the div that wraps this.
                    />
                    <label className="CustomInput" htmlFor={channel.displayName + '-yes'}>
                        <svg
                            className="Icon"
                            width="42.607"
                            height="46.896"
                            viewBox="0 0 42.607 46.896"
                        >
                            <g>
                                <path
                                    id="Trazado_30526"
                                    data-name="Trazado 30526"
                                    d="M20.8,46.9a16.479,16.479,0,0,1-2.02-.492A7.3,7.3,0,0,1,14,40.9c-.019-.081-.035-.163-.058-.242a1.669,1.669,0,0,0-.079-.175h-.577c-3.078,0-6.156.008-9.234,0A3.86,3.86,0,0,1,.1,37.4a3.762,3.762,0,0,1,1.441-3.942c.571-.458,1.168-.882,1.751-1.324A3.11,3.11,0,0,0,4.538,30.1c.7-4.2,1.448-8.388,2.067-12.6a14.881,14.881,0,0,1,7.008-11.07,19.315,19.315,0,0,1,2.874-1.362c.392-.162.553-.307.558-.757a4.253,4.253,0,1,1,8.506-.053c.012.489.18.653.612.819a14.539,14.539,0,0,1,9.713,11.837c.747,4.392,1.457,8.789,2.185,13.184a3.08,3.08,0,0,0,1.207,2c.527.405,1.063.8,1.594,1.2A3.922,3.922,0,0,1,38.5,40.477q-4.592.009-9.184,0h-.6c-.094.374-.165.714-.264,1.046a7.449,7.449,0,0,1-6.316,5.285,2.3,2.3,0,0,0-.33.086Zm.5-8.565c0-.005,0-.011,0-.016q3.245,0,6.489,0,5.391.006,10.782.012a1.75,1.75,0,0,0,1.8-1.216,1.777,1.777,0,0,0-.731-2.055c-.592-.458-1.2-.89-1.788-1.358a5.067,5.067,0,0,1-1.879-3.141c-.283-1.587-.535-3.18-.8-4.771-.516-3.116-.952-6.248-1.562-9.345a12.46,12.46,0,0,0-15.233-9.7A11.851,11.851,0,0,0,9.06,16.211c-.948,4.364-1.562,8.8-2.22,13.227a6.491,6.491,0,0,1-2.932,4.909,9.781,9.781,0,0,0-1.151.876,1.744,1.744,0,0,0-.16,2.536,1.979,1.979,0,0,0,1.584.577q8.561-.011,17.121,0M16.12,40.506a5.282,5.282,0,0,0,10.346,0ZM19.213,4.3H23.4a2.068,2.068,0,0,0-2.16-2.175A2.043,2.043,0,0,0,19.213,4.3"
                                    transform="translate(0 0)"
                                />
                            </g>
                        </svg>
                        On
                    </label>
                </div>
                <div
                    className="Radio"
                    role="checkbox"
                    aria-checked={!findIsChecked(channel.id, streamId)}
                    aria-disabled={!canBeChanged}
                    onClick={() => handleChange(channel.id, streamId, false)}
                >
                    <input
                        id={channel.displayName + '-off'}
                        disabled={!canBeChanged}
                        type="radio"
                        name={generateId(channel.id, streamId)}
                        value={'false'}
                        checked={!findIsChecked(channel.id, streamId)}
                        onChange={() => {}} // Prevent console error about providing a checked attribute but no onChange prop. The onChange is the onClick on the div that wraps this.
                    />
                    <label className="CustomInput" htmlFor={channel.displayName + '-no'}>
                        <svg
                            className="Icon"
                            width="152.85"
                            height="152.838"
                            viewBox="0 0 152.85 152.838"
                        >
                            <g id="Grupo_9755" data-name="Grupo 9755" clip-path="url(#clip-path)">
                                <path
                                    id="Trazado_30528"
                                    data-name="Trazado 30528"
                                    d="M120.14,38.209c-2.914,3.042-5.519,5.67-7.983,8.422a2.6,2.6,0,0,0-.263,2.205c2.462,6.757,2.629,13.786,2.89,20.86.3,8.243.781,16.5,1.689,24.7.715,6.455,3.723,11.747,9.755,15.035,2.856,1.557,3.835,8.955,1.543,11.2a5.821,5.821,0,0,1-3.7,1.345q-13.1.172-26.2.026c-1.9-.02-2.765.405-3.136,2.457a18.659,18.659,0,0,1-36.548.229c-.4-2.057-1.136-2.746-3.233-2.69-5.293.142-10.6-.094-15.885.125a7.1,7.1,0,0,0-4.292,1.864c-8.628,8.45-17.117,17.04-25.652,25.583-.723.722-1.438,1.46-2.225,2.107a3.942,3.942,0,0,1-5.557.031,3.957,3.957,0,0,1-.194-5.756c1.9-2.155,4.007-4.133,6.042-6.169Q75.658,71.3,144.133,2.815c3.036-3.037,5.4-3.61,7.407-1.755,2.128,1.963,1.689,4.363-1.509,7.569-7.282,7.3-14.561,14.6-21.927,21.819-1.527,1.5-1.952,2.646-.962,4.774a33.075,33.075,0,0,1,2.327,21.739c-.543,2.395-1.979,3.528-4.4,3.169-2.3-.34-3.378-2.119-2.818-5.146a28.027,28.027,0,0,0-1.1-14.543c-.178-.513-.445-1-1.006-2.233M63.81,95.671h44.959c-.354-3.293-.827-6.314-.977-9.351-.442-8.905-.7-17.82-1.162-26.724a38.585,38.585,0,0,0-1.073-5.58L63.81,95.671M44.636,113.586l.611.614H118.6c-2.3-3.128-4.533-6.283-6.927-9.306a3.584,3.584,0,0,0-2.474-1.179q-26.2-.1-52.409-.022a3.724,3.724,0,0,0-2.318.6c-3.336,3.033-6.567,6.184-9.832,9.3m21.359,8.639c.242,5.534,5.267,9.748,11.089,9.479,5.259-.243,9.892-4.755,9.679-9.479Z"
                                    transform="translate(0 0)"
                                />
                                <path
                                    id="Trazado_30529"
                                    data-name="Trazado 30529"
                                    d="M62.391,113c3.437-11.137,3.223-22.3,3.36-33.419.078-6.321.336-12.613,2.388-18.693a32.166,32.166,0,0,1,17.466-19.56c1.9-.89,2.775-1.793,2.969-4.008.668-7.636,7.549-13.45,15.522-13.446,7.909,0,14.782,5.933,15.42,13.586a4.071,4.071,0,0,0,2.818,3.849c2.39,1.1,4.6,2.6,7.591,4.346-1.961,1.752-3.334,3.123-4.872,4.272a2.554,2.554,0,0,1-2.138.163c-2.729-1.14-5.342-2.567-8.093-3.643-2.273-.89-3-2.342-2.959-4.709a14.7,14.7,0,0,0-.894-6.165c-1.579-3.466-5.525-4.879-9.223-3.861A7.963,7.963,0,0,0,96,39.393a11.724,11.724,0,0,0,.134,1.942c.448,2.881-.791,4.517-3.552,5.366C85.733,48.808,80.363,52.788,77,59.281c-2.95,5.7-3.47,11.855-3.694,18.11-.282,7.877-.64,15.753-1.119,23.62a6.058,6.058,0,0,1-1.562,3.346c-2.651,2.965-5.479,5.774-8.238,8.642"
                                    transform="translate(-27.594 -10.559)"
                                />
                                <path
                                    id="Trazado_30530"
                                    data-name="Trazado 30530"
                                    d="M40.207,64.449c.687-15.3,7.1-26.014,20.163-32.478,2.936-1.453,5.119-.855,6.144,1.49.933,2.132-.064,3.978-2.926,5.424C51.993,44.741,46.6,55.035,48.345,67.952c.112.827.506,1.867.163,2.451-.769,1.305-1.707,2.921-2.969,3.428a3.038,3.038,0,0,1-4.094-2.315c-.659-2.589-.938-5.275-1.238-7.068"
                                    transform="translate(-17.782 -13.792)"
                                />
                                <path
                                    id="Trazado_30531"
                                    data-name="Trazado 30531"
                                    d="M192.186,40.238c-1.2-.6-2.555-1.211-3.843-1.938-1.967-1.108-2.724-2.8-1.839-4.928.838-2.018,2.972-2.749,5.157-1.759,2.078.941,4.069,2.073,6.295,3.223l-5.77,5.4"
                                    transform="translate(-82.323 -13.788)"
                                />
                            </g>
                        </svg>{' '}
                        Off
                    </label>
                </div>
            </div>
        </div>
    );
};
