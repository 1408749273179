import { RouteComponentProps } from '@reach/router';
import { JumioVerification } from 'components/jumio/JumioVerification';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectisVerified } from 'reducers/jumio';
import Page from '../../components/page/Page';

const Dashboard: React.FC<RouteComponentProps> = ({ children }) => {
    const isVerified = useSelector(selectisVerified);
    return (
        <Page isPublic={false} className="Dashboard">
            {isVerified ? children : <JumioVerification />}
        </Page>
    );
};

export default Dashboard;
