import instance, { ApiResponse, ErrorMessage, GENERIC_ERROR_MESSAGE } from 'api';
import { AxiosError, AxiosResponse } from 'axios';
import { endpoints } from 'endpoints.config';
import {
    AccountDetails,
    EmailVerificationCodePayload,
    RegisterCustomerUserPayload,
    RegisterValidateStepOne,
    SMSTFASetup,
    TFAVerificationPayload,
} from './models';

export type ApiReturn<T = any> = {
    response: T | null;
    errors: ErrorMessage[] | null;
    id?: number;
};

const registerUrl = endpoints.registration.customerUser;
export const postRegisterCustomerUser = async (
    payload: RegisterCustomerUserPayload
): Promise<ApiReturn> => {
    try {
        const res = await instance.post<RegisterCustomerUserPayload, AxiosResponse<ApiResponse>>(
            registerUrl,
            {
                ...payload,
            }
        );
        if (res.data.status === '1') {
            return {
                response: res.data,
                errors: null,
            };
        } else {
            return {
                response: res,
                errors: res.data.errors,
            };
        }
    } catch (error: any) {
        return {
            response: null,
            errors: error.response?.data?.errors
                ? error.response.data.errors
                : [GENERIC_ERROR_MESSAGE],
        };
    }
};

const validateUrl = endpoints.registration.validateStepOne;
export const postValidateStepOne = async (payload: RegisterValidateStepOne): Promise<ApiReturn> => {
    try {
        const res = await instance.post<ApiResponse>(validateUrl, payload);
        if (res.data.status === '1') {
            return {
                response: res.data,
                errors: null,
            };
        } else {
            return {
                response: res,
                errors: res.data.errors,
            };
        }
    } catch (error: any) {
        return {
            response: null,
            errors: error.response?.data?.errors
                ? error.response.data.errors
                : [GENERIC_ERROR_MESSAGE],
        };
    }
};

const emailVerificationCodeUrl = endpoints.auth.verifyEmailAddress;
export const postEmailVerificationCode = async (
    payload: EmailVerificationCodePayload
): Promise<ApiReturn> => {
    try {
        const res = await instance.post(emailVerificationCodeUrl, payload);
        if (res.data.status === '1') {
            return {
                response: res.data,
                errors: null,
            };
        } else {
            return {
                response: res,
                errors: res.data.errors,
            };
        }
    } catch (error: any) {
        return {
            response: null,
            errors: error.response?.data?.errors
                ? error.response.data.errors
                : [GENERIC_ERROR_MESSAGE],
        };
    }
};

const smsTfaSetupUrl = endpoints.auth.requestSmsTfaCode;
export const postSmsTFASetup = async (payload: SMSTFASetup): Promise<ApiReturn> => {
    try {
        const res = await instance.post(smsTfaSetupUrl, payload);
        if (res.data.status === '1') {
            return {
                response: res.data,
                errors: null,
            };
        } else {
            return {
                response: res,
                errors: res.data.errors,
            };
        }
    } catch (error: any) {
        return {
            response: null,
            errors: error.response?.data?.errors
                ? error.response.data.errors
                : [GENERIC_ERROR_MESSAGE],
        };
    }
};
const appTfaSetupUrl = endpoints.auth.requestTotpCode;
export const postAppTFASetup = async (payload: AccountDetails): Promise<ApiReturn> => {
    try {
        const res = await instance.post(appTfaSetupUrl, {
            username: payload.emailAddress,
            password: payload.password,
        });
        if (res.data.status === '1') {
            return {
                response: res.data,
                errors: null,
            };
        } else {
            return {
                response: res,
                errors: res.data.errors,
            };
        }
    } catch (error: any) {
        return {
            response: null,
            errors: error.response?.data?.errors
                ? error.response.data.errors
                : [GENERIC_ERROR_MESSAGE],
        };
    }
};
const enableTfaUrl = endpoints.auth.enableTfa;
export const postEnableTfa = async (payload: TFAVerificationPayload): Promise<ApiReturn> => {
    try {
        const res = await instance.post(enableTfaUrl, payload);
        if (res.data.status === '1') {
            return {
                response: res.data.details,
                errors: null,
            };
        } else {
            return {
                response: res.data.details,
                errors: res.data.errors,
            };
        }
    } catch (error: any) {
        return {
            response: null,
            errors: error.response?.data?.errors
                ? error.response.data.errors
                : [GENERIC_ERROR_MESSAGE],
        };
    }
};
