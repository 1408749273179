import Button, { ButtonPriority } from 'components/button/Button';
import React from 'react';

type TFAItemProps = {
    active: boolean;
    image: string;
    header: string;
    subHeader?: string;
    button: string | null;
    buttonPriority: ButtonPriority;
    onClick?: () => void;
    handleDisable?: (() => void) | null;
};

export const TFAItem: React.FC<TFAItemProps> = ({
    active,
    image,
    header,
    subHeader,
    button,
    buttonPriority,
    onClick,
    handleDisable,
}) => {
    return (
        <div className={`TFAItem${active ? ' Active' : ''}`}>
            <div className="Content">
                <img src={image} alt="icon" />
                <div>
                    <p className="TFAItemHeader">{header}</p>
                    <p className="TFAItemSubHeader">{subHeader}</p>
                </div>
            </div>
            {handleDisable && (
                <Button priority={'secondary'} className="DisableButton" onClick={handleDisable}>
                    Disable
                </Button>
            )}
            {button && (
                <Button
                    priority={buttonPriority}
                    type="button"
                    onClick={onClick ? onClick : () => {}}
                >
                    {button}
                </Button>
            )}
        </div>
    );
};
