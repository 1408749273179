import '@emotion/react';
import { Theme } from '@emotion/react';

export const initialTheme = {
    colors: {
        first: '#283382', //dark blue
        second: '#D71A5B',
        third: '#DA0A59',
        fourth: '#E8F5FE', //background blue
        fifth: '#2162a0', // medium blue
    },
};

export const globalStyleVariables = (theme: ThemeType): React.CSSProperties =>
    ({
        '--firstColor': theme.colors.first,
        '--secondColor': theme.colors.second,
        '--thirdColor': theme.colors.third,
        '--fourthColor': theme.colors.fourth,
        '--fifthColor': theme.colors.fifth,
    } as unknown as React.CSSProperties);

type ThemeType = typeof initialTheme;

declare module '@emotion/react' {
    export interface Theme extends ThemeType {}
}
