import Icon_Warning from 'assets/Icon_Warning.png';
import Button from 'components/button/Button';
import { TFAField } from 'components/form/TFAField';
import { Form, Formik, FormikHelpers } from 'formik';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { useTFAField } from 'helpers/useTFAField';
import { TFAValidation } from 'helpers/validationSnippets';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { signOut } from 'reducers/auth';
import { closeModal } from 'reducers/modal';
import * as Yup from 'yup';
import { IDeactivateAccount } from './models';
import { ProfileApi } from './ProfileApi';

type DeactivateAccountModalProps = {
    isSMSAuth: boolean | null;
    isAppAuth: boolean | null;
};

const validationSchema = Yup.object({
    showTfa: Yup.boolean(),
    tfaCode: Yup.string().when('showTfa', { is: true, then: TFAValidation }),
});

const DeactivateAccountModal: React.FC<DeactivateAccountModalProps> = () => {
    const [errors, setErrors] = useState<string[] | null>(null);
    const dispatch = useDispatch();
    const [showTfa, setShowTfa] = useState(false);
    const [tfaType, toggleTFAType] = useTFAField(showTfa);
    const initialValues: IDeactivateAccount = {
        showTfa: false,
        tfaCode: '',
        tfaType: tfaType,
    };
    const handleSubmit = (
        values: IDeactivateAccount,
        helpers: FormikHelpers<IDeactivateAccount>
    ) => {
        if (!values.showTfa) {
            helpers.setFieldValue('showTfa', true);
            setShowTfa(true);
            helpers.setSubmitting(false);
            helpers.setFieldTouched('tfaCode', false);
            return;
        }
        (async () => {
            const submitValues: IDeactivateAccount = { ...values, tfaType: tfaType };
            const result = await ProfileApi.DeactivateAccount(submitValues);
            if (ProfileApi.isSuccessData(result)) {
                dispatch(signOut());
                Toast.openToastMessage('Account deactivated', ToastMessageReason.VALID);
            } else if (ProfileApi.isErrorData(result)) {
                setErrors(result.errors);
                helpers.setSubmitting(false);
            } else {
                dispatch(closeModal());
                Toast.openGenericErrorToast();
                helpers.setSubmitting(false);
            }
        })();
    };
    return (
        <div className="DeactivateAccountModal FullWidth">
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {(formikProps) => (
                    <Form className="FullWidth">
                        <div className="Content">
                            <h4>Permanently deactivate</h4>
                            <p>
                                Are you sure you want to permanently deactivate your account? <br />
                                You will lose all data associated with it.
                            </p>
                            <p className="RedText">
                                <img src={Icon_Warning} alt="warning icon" />
                                This action cannot be undone
                            </p>
                            {formikProps.values.showTfa && (
                                <TFAField
                                    field={'tfaCode'}
                                    label={'Two-factor authentication code'}
                                    required
                                    tfaType={tfaType}
                                    toggleTFAType={toggleTFAType}
                                    value={formikProps.values.tfaCode}
                                    autoFocus
                                    holdFocus
                                />
                            )}
                            {errors && (
                                <>
                                    {errors.map((error) => (
                                        <p className="ErrorText NoMargin">{error}</p>
                                    ))}
                                </>
                            )}
                        </div>
                        <div className="ModalNavigation">
                            <Button
                                priority="secondary"
                                onClick={() => {
                                    dispatch(closeModal());
                                }}
                                type="button"
                                color="grey"
                            >
                                Cancel
                            </Button>
                            <Button
                                variety="red"
                                type="submit"
                                disabled={formikProps.isSubmitting || !formikProps.isValid}
                            >
                                Deactivate
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export { DeactivateAccountModal };
