import React, { useMemo } from 'react';

export type ProgressBarSteps<T> = Readonly<
    {
        stepNumber: number;
        label: string;
        internalIdentifier: T;
    }[]
>;
type Props<T = any> = {
    steps: ProgressBarSteps<T>;
    currentStepNumber: number;
    className?: string;
};

export const SegmentedProgressBar = <T,>({ steps, currentStepNumber, className }: Props<T>) => {
    return (
        <div className={`SegmentedProgressBar ${className}`}>
            <div className="Bar">
                {steps.map((step, i) => (
                    <div
                        className={`Segment ${
                            step.stepNumber < currentStepNumber
                                ? 'Passed'
                                : step.stepNumber === currentStepNumber
                                ? 'Active'
                                : ''
                        }`}
                        key={step.stepNumber}
                    >
                        {i > 0 && <div className="ConnectingLine"></div>}
                        <div className="Ball">{step.stepNumber}</div>
                        <div className="SegmentLabel">
                            {step.stepNumber === currentStepNumber ? (
                                <b>{step.label}</b>
                            ) : (
                                step.label
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {/* <div className="SegmentLabels">
                {steps.map(
                    (step, i) => (
                        <div key={step.stepNumber}>
                            {step.stepNumber === currentStepNumber ? (
                                <b>{step.label}</b>
                            ) : (
                                step.label
                            )}
                        </div>
                    )
                    //IMPLEMENTATION HERE
                )}
            </div> */}
        </div>
    );
};
