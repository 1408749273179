import { Link, useNavigate } from '@reach/router';
import { AppPath } from 'appConstants';
import Icon_Form_01 from 'assets/ibanera/Icon_Form_01.png';
import Button from 'components/button/Button';
import FormTextField from 'components/form/FormTextField';
import { PasswordHelper } from 'components/passwordHelper/PasswordHelper';
import { PasswordStrengthMeter } from 'components/passwordStrengthMeter/PasswordStrengthMeter';
import { Spinner } from 'components/spinner/Spinner';
import { Form, Formik, FormikHelpers, useField, useFormikContext } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { RegisterFormSubForm } from './models';
import { postRegisterCustomerUser } from './RegisterApi';
import { handleRegisterFormErrors } from './RegistrationErrorHandling';
import BackgroundImage from 'assets/ibanera/Bg_Register.jpg';
import { ERROR_CODES, getErrorMessage } from 'errors';
import PhoneInput from 'react-phone-number-input';
import { ErrorM } from 'components/form/ErrorM';
import { toCamelCase } from 'helpers/formatFormFieldNames';
import { useSelector } from 'react-redux';
import { selectCultureCode } from 'reducers/language';
import { SplashPage } from 'pages/SplashPage';
import { nameCombiner } from 'helpers/nameCombiner';
import { CheckboxInput } from 'components/form/CheckboxInput';
import { FormPhoneNumberField } from './InputPersonalDetails';
import { RegistrationFormValues } from './RegistrationForm';

export const InputAccountDetails: React.FC<RegisterFormSubForm> = ({
    translations,
    setErrorMessage,
    errorMessage,
}) => {
    const [showPasswordHelper, setShowPasswordHelper] = React.useState(false);
    const [passwordVisibility, setPasswordVisibility] = React.useState(false);
    const cultureCode = useSelector(selectCultureCode);
    const navigate = useNavigate();

    const handlePasswordVisibility = () => {
        setPasswordVisibility(!passwordVisibility);
    };

    const { values, isSubmitting } = useFormikContext<RegistrationFormValues>();

    const handleResetError = () => {
        setErrorMessage('');
    };

    if (!translations) return null;

    return (
        <Form onChange={handleResetError}>
            <div className="RegisterFormWidth">
                <FormTextField
                    field={'emailAddress'}
                    label={translations.email_address}
                    required={true}
                    maxLength={200}
                    inputMode="email"
                />
                <FormPhoneNumberField
                    field={'phoneNumber'}
                    label={'Mobile Phone Number'} //TODO replace with translation
                />
                <div className="FormBox PasswordField">
                    <FormTextField
                        field={'password'}
                        label={translations.password}
                        required={true}
                        maxLength={20}
                        type={passwordVisibility ? 'text' : 'password'}
                        showIcon={true}
                        icon={Icon_Form_01}
                        onClickIcon={handlePasswordVisibility}
                        placeholder="Minimum 8 characters"
                    >
                        <PasswordStrengthMeter
                            fieldName="password"
                            onInfoClick={() => setShowPasswordHelper((s) => !s)}
                        />
                        {showPasswordHelper && <PasswordHelper fieldName="password" />}
                    </FormTextField>
                </div>
                <FormTextField
                    field={'confirmPassword'}
                    label={'Confirm password'}
                    required={true}
                    maxLength={20}
                    type={passwordVisibility ? 'text' : 'password'}
                    placeholder="Minimum 8 characters"
                />
                {errorMessage && <div className="ErrorMessage">{errorMessage}</div>}
                <Button
                    priority="primary"
                    variety="full"
                    type="submit"
                    disabled={isSubmitting}
                    color="second"
                >
                    {isSubmitting ? <Spinner color={'#fff'} /> : translations.continue_button}
                </Button>
                <Button
                    priority="secondary"
                    variety="full"
                    type="button"
                    disabled={isSubmitting}
                    color="first"
                    style={{ marginTop: 10 }}
                    onClick={() => navigate('/')}
                >
                    Cancel
                </Button>
            </div>
        </Form>
    );
};
