import Footer from 'components/footer/Footer';
import Header from 'components/header/Header';
import { MobileNavigation } from 'components/mobileNavigation/MobileNavigation';
import SideMenu from 'components/sideMenu/SideMenu';
import { useGetComponentResources } from 'helpers/useGetComponentResources';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectSignedIn } from 'reducers/auth';
import { selectComponentResources } from 'reducers/componentResources';

export interface UserInformation {
    firstName: string;
    lastName: string;
    bAccountSwitchAvailable: boolean;
    bMainAccountHolder: boolean;
    emailAddress?: string;
    dateOfBirth?: string;
    nationality?: string;
}

export const Navigation: React.FC = ({ children }) => {
    const isSignedIn = useSelector(selectSignedIn);
    // Need to use this hook here as moving it up a level to App causes entire application
    // rerenders on culture code change which is causing problems with the culture code setting
    // logic.
    useGetComponentResources();
    const { footerLinks, customerMenuLinks, customerMenuIcons } =
        useSelector(selectComponentResources);
    const leftMenuLinks = customerMenuLinks?.filter((item) => item.position === 'LeftMenu');

    return isSignedIn ? (
        <div className={'PartialWidth'}>
            <Header />
            {children}
            <SideMenu links={leftMenuLinks ? leftMenuLinks : null} icons={customerMenuIcons} />
            {footerLinks && (
                <>
                    <MobileNavigation
                        moreMenuFooterLinks={footerLinks}
                        sideMenuLinks={leftMenuLinks ? leftMenuLinks : null}
                        sideMenuIcons={customerMenuIcons}
                    />
                    <Footer links={footerLinks} />
                </>
            )}
        </div>
    ) : (
        <div className={'FullWidth'}>
            <Header />
            {children}
            {footerLinks && (
                <>
                    <MobileNavigation moreMenuFooterLinks={footerLinks} />
                    <Footer links={footerLinks} />
                </>
            )}
        </div>
    );
};
