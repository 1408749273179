import { navigate, RouteComponentProps } from '@reach/router';
import instance, { ApiResponse } from 'api';
import { AppPath } from 'appConstants';
import Icon_Form_01 from 'assets/ibanera/Icon_Form_01.png';
import Button from 'components/button/Button';
import FormTextField from 'components/form/FormTextField';
import Page from 'components/page/Page';
import { PasswordHelper } from 'components/passwordHelper/PasswordHelper';
import { PasswordStrengthMeter } from 'components/passwordStrengthMeter/PasswordStrengthMeter';
import { Spinner } from 'components/spinner/Spinner';
import { endpoints } from 'endpoints.config';
import { Form, Formik, FormikHelpers } from 'formik';
import { parseQueryParamsToObject } from 'helpers/parseQueryParamsToObject';
import React, { useRef, useState } from 'react';
import * as Yup from 'yup';

interface ResetPasswordQueryParams {
    token: string;
}

interface ResetPasswordFormFields {
    NewPassword: string;
    ConfirmNewPassword: string;
}

const initialValues: ResetPasswordFormFields = {
    NewPassword: '',
    ConfirmNewPassword: '',
};

const validationSchema = Yup.object({
    NewPassword: Yup.string().required('Please enter a new password'),
    ConfirmNewPassword: Yup.mixed().test('match', 'Passwords do not match', function (value) {
        return value === this.parent.NewPassword;
    }),
});

export const ResetPassword: React.FC<RouteComponentProps> = () => {
    const [formIsComplete, setFormIsComplete] = useState(false);
    const tokenRef = useRef<string | null>(null);
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [showPasswordHelper, setShowPasswordHelper] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const params = parseQueryParamsToObject<ResetPasswordQueryParams>(
        window.location.search.slice(1)
    );

    // Reach router is stripping query params from the url, not 100% sure why.
    // Storing the token in ref before it gets stripped.
    if (params) {
        tokenRef.current = params.token;
    }

    const handlePasswordVisibility = () => {
        setPasswordVisibility(!passwordVisibility);
    };

    const handleSubmit = (
        values: ResetPasswordFormFields,
        actions: FormikHelpers<ResetPasswordFormFields>
    ) => {
        instance
            .post<ApiResponse>(endpoints.forgottenpasswordmodule.resetpassword, {
                ...values,
                PasswordResetToken: tokenRef.current,
            })
            .then((response) => {
                if (response.data.status === '1') {
                    setFormIsComplete(true);
                } else {
                    actions.setSubmitting(false);
                    setErrorMessage(response.data.errors[0].messageCode);
                }
            })
            .catch((response) => {
                actions.setSubmitting(false);
                setErrorMessage('Please try again');
            });
    };

    return (
        <Page isPublic={true}>
            <div className="ForgottenPasswordPage WidthContent">
                {!formIsComplete ? (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        {({ isSubmitting }) => (
                            <Form className="ForgottenPasswordForm">
                                <h1>Password Reset</h1>
                                <p>Enter your new password</p>
                                <div className="FormBox PasswordField">
                                    <FormTextField
                                        field={'NewPassword'}
                                        label={'New Password'}
                                        required={true}
                                        maxLength={20}
                                        type={passwordVisibility ? 'text' : 'password'}
                                        showIcon={true}
                                        icon={Icon_Form_01}
                                        onClickIcon={handlePasswordVisibility}
                                    >
                                        <PasswordStrengthMeter
                                            fieldName="NewPassword"
                                            onInfoClick={() => setShowPasswordHelper((s) => !s)}
                                        />
                                        {showPasswordHelper && (
                                            <PasswordHelper fieldName="NewPassword" />
                                        )}
                                    </FormTextField>
                                </div>
                                <FormTextField
                                    field={'ConfirmNewPassword'}
                                    label={'Confirm New Password'}
                                    required={true}
                                    maxLength={200}
                                    type="password"
                                />
                                {errorMessage && <p>{errorMessage}</p>}
                                <Button
                                    variety="full"
                                    priority="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? <Spinner color="white" /> : 'Reset password'}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                ) : (
                    <div className="SuccessMessage">
                        <h1>Thank you</h1>
                        <p>Your password has been reset</p>
                        <Button
                            variety="full"
                            priority="primary"
                            onClick={() => navigate(AppPath.SIGN_IN)}
                        >
                            Go to Sign In
                        </Button>
                    </div>
                )}
            </div>
        </Page>
    );
};
