import Button from 'components/button/Button';
import FormTextField from 'components/form/FormTextField';
import { TFAField } from 'components/form/TFAField';
import { ValidationCodeField } from 'components/form/ValidationCodeField';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { useTFAField } from 'helpers/useTFAField';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modal';
import * as Yup from 'yup';
import { IUpdateTFA, ProfileParser } from './models';
import { ProfileApi } from './ProfileApi';
import { SecurityContext } from './Security';

type EnableSmsTFAModalProps = {
    isSMSAuth: boolean | null;
    isAppAuth: boolean | null;
    phoneNumber: string | null;
};

const EnableSmsTFAModal: React.FC<EnableSmsTFAModalProps> = ({
    isAppAuth,
    isSMSAuth,
    phoneNumber,
}) => {
    const [isVerifyStep, setIsVerifyStep] = useState(false);
    const [errors, setErrors] = useState<string[] | null>(null);
    const { reloadSecurityData } = useContext(SecurityContext);
    const dispatch = useDispatch();

    // Toggle should always be null as this modal should only be show if the
    // user only has App TFA enabled.
    const [tfaType, toggleTFAType] = useTFAField();

    const validationSchema = Yup.object({
        tfaCode: isVerifyStep
            ? Yup.string()
            : Yup.string().required('Please enter your two-factor authentication code'),
        tfaCodeConfirm: isVerifyStep
            ? Yup.string().required('Please enter your two-factor authentication code')
            : Yup.string(),
        phoneNumber: Yup.string().required('Please enter your phone number'),
        tfaType: Yup.string(),
        changeTfaType: Yup.string(),
        totpSharedSecret: Yup.string(),
    });

    const initialValues: IUpdateTFA = {
        tfaCode: '',
        tfaCodeConfirm: '',
        phoneNumber: phoneNumber ?? '',
        tfaType: isSMSAuth ? 'SMS' : 'AuthenticatorApp',
        changeTfaType: 'SMS',
        totpSharedSecret: '',
    };

    const handleResendCode = (helpers: FormikHelpers<IUpdateTFA>) => {
        helpers.setFieldValue('tfaCode', '');
        Toast.openToastMessage(
            'Please reverify your two-factor authentication',
            ToastMessageReason.PENDING
        );
        setIsVerifyStep(false);
    };

    const handleSubmit = (values: IUpdateTFA) => {
        if (!isVerifyStep) {
            const submitValues: IUpdateTFA = { ...values, tfaType: tfaType };
            (async () => {
                const result = await ProfileApi.EnableSmsTFA(submitValues);
                if (ProfileApi.isSuccessData(result)) {
                    setErrors(null);
                    setIsVerifyStep(true);
                } else if (ProfileApi.isErrorData(result)) {
                    setErrors(result.errors);
                } else {
                    Toast.openGenericErrorToast();
                }
            })();
        } else {
            (async () => {
                const result = await ProfileApi.UpdateTFAConfirm(
                    ProfileParser.parseConfirmEnableSmsTFA(values)
                );
                if (ProfileApi.isSuccessData(result)) {
                    reloadSecurityData();
                    dispatch(closeModal());
                    Toast.openToastMessage(
                        'You have successfully enabled SMS two-factor authentication',
                        ToastMessageReason.VALID
                    );
                } else if (ProfileApi.isErrorData(result)) {
                    setErrors(result.errors);
                } else {
                    Toast.openGenericErrorToast();
                }
            })();
        }
    };

    return (
        <div className="EnableSmsTFAModal">
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                enableReinitialize
            >
                {(formikProps: FormikProps<IUpdateTFA>) => (
                    <Form>
                        <>
                            {!isVerifyStep ? (
                                <>
                                    {/* We only render the phone number field if the user doesn't already have 
                                    a phone number associated with their account. Adding it here will associate 
                                    it with the account on the backend. If we have a phone number for the user
                                    we still include it in the post but don't render the field and allow the user to
                                    edit it. We include it just for validation purposes. */}
                                    {!phoneNumber && (
                                        <FormTextField
                                            field={'phoneNumber'}
                                            label={'Phone number'}
                                            autoComplete={'off'}
                                            required
                                            autoFocus
                                        />
                                    )}
                                    <TFAField
                                        field={'tfaCode'}
                                        label={'Enter the code from your app authenticator'}
                                        autoComplete={'off'}
                                        value={formikProps.values.tfaCode}
                                        toggleTFAType={toggleTFAType}
                                        tfaType={tfaType}
                                        required
                                        autoFocus={!!phoneNumber}
                                        holdFocus={!!phoneNumber}
                                    />
                                </>
                            ) : (
                                <>
                                    <ValidationCodeField
                                        field={'tfaCodeConfirm'}
                                        label={'SMS Verification code'}
                                        autoComplete={'one-time-code'}
                                        required
                                        value={formikProps.values.tfaCodeConfirm}
                                        holdFocus
                                        autoFocus
                                    />
                                    <Button
                                        priority="tertiary"
                                        onClick={() => handleResendCode(formikProps)}
                                        className="RegisterLink"
                                        type="button"
                                    >
                                        Resend Code
                                    </Button>
                                </>
                            )}
                            {errors && (
                                <>
                                    {errors.map((error) => (
                                        <p className="ErrorText NoMargin">{error}</p>
                                    ))}
                                </>
                            )}
                            <div className="ModalNavigation">
                                <Button
                                    priority="secondary"
                                    onClick={() => {
                                        dispatch(closeModal());
                                    }}
                                    type="button"
                                    color="grey"
                                >
                                    Cancel
                                </Button>
                                <Button priority="primary" type="submit">
                                    Update
                                </Button>
                            </div>
                        </>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export { EnableSmsTFAModal };
