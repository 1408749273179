import { TFAType } from 'types/shared';

export interface INotifications {
    deposit: boolean;
    withdrawal: boolean;
    newIPSignIn: boolean;
    signIn: boolean;
}

export interface ITfa {
    tfaCode: string;
    tfaType: TFAType;
    showTfa?: boolean;
}
interface ITfaDto {
    TfaCode: string;
    TfaType: string;
}

export interface IUpdatePassword extends ITfa {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
}
export interface IUpdatePasswordDto extends ITfaDto {
    OldPassword: string;
    NewPassword: string;
    ConfirmNewPassword: string;
}

export interface IUpdatePhoneNumber extends ITfa {
    phoneNumber: string;
}

export interface IVerifyPhoneNumber {
    phoneNumber: string;
    phoneNumberVerificationCode: string;
}

export interface IUpdatePhoneNumberDto extends ITfaDto {
    PhoneNumber: string;
}

export interface IUpdateEmail extends ITfa {
    emailAddress: string;
    emailVerificationCode: string;
    bResendCode: boolean;
}
export interface IUpdateEmailAddress extends ITfa {
    emailAddress: string;
}

export interface IUpdateEmailAddressDto extends ITfaDto {
    EmailAddress: string;
}
export interface IUpdateEmailAddressConfirmDto {
    EmailAddress: string;
    EmailVerificationCode: string;
    bResendCode: boolean;
}
export interface IResendEmailVerificationCode {
    EmailAddress: string;
    bResendCode: true;
}

export interface IDeactivateAccount extends ITfa {}
export interface IDeactivateAccountDto extends ITfaDto {}

export interface IUpdateTFA extends ITfa {
    phoneNumber?: string;
    tfaCodeConfirm: string;
    changeTfaType: string;
    totpSharedSecret: string;
}

export interface IUpdateTFAConfirmApp extends ITfa {
    changeTfaType: string;
    totpSharedSecret: string;
}

export interface IUpdateTFAConfirmSMS extends ITfa {
    changeTfaType: string;
    phoneNumber: string;
}

export type UpdateAppTFAState = {
    sharedSecret: string;
    qrCode: string;
};

export interface DisableTFAType extends ITfa {
    changeTfaType: TFAType;
}

export type UserProfileDetails = {
    firstName: string;
    lastName: string;
    dateOfBirth: string | null;
    nationality: string | null;
    countryISOCode: string | null;
    countryName: string | null;
    addressLine1: string | null;
    addressLine2: string | null;
    townCity: string | null;
    stateProvinceName: string | null;
    postcode: string | null;
    emailAddress?: string;
    phoneNumber?: string;
    secureProfilePictureDownloadUri?: string;
};

export type CountryCode = {
    countryISOCode: string;
    countryName: string;
};

export type GetUserProfileResponse = UserProfileDetails & { countries: CountryCode[] };

export type EditableUserProfileDetails = Pick<
    UserProfileDetails,
    | 'firstName'
    | 'lastName'
    | 'countryISOCode'
    | 'addressLine1'
    | 'addressLine2'
    | 'postcode'
    | 'townCity'
    | 'stateProvinceName'
>;

export type StateProvince = { ansiCode: string; name: string };

export class ProfileParser {
    public static parseUpdatePassword = (updatePassword: IUpdatePassword): IUpdatePasswordDto => ({
        OldPassword: updatePassword.oldPassword,
        NewPassword: updatePassword.newPassword,
        ConfirmNewPassword: updatePassword.confirmNewPassword,
        TfaCode: updatePassword.tfaCode,
        TfaType: updatePassword.tfaType,
    });
    public static parseUpdatePhoneNumber = (update: IUpdatePhoneNumber): IUpdatePhoneNumberDto => ({
        PhoneNumber: update.phoneNumber,
        TfaCode: update.tfaCode,
        TfaType: update.tfaType,
    });
    public static parseUpdateEmailAddress = (update: IUpdateEmail): IUpdateEmailAddressDto => ({
        EmailAddress: update.emailAddress,
        TfaCode: update.tfaCode,
        TfaType: update.tfaType,
    });
    public static parseUpdateEmailAddressConfirm = (
        update: IUpdateEmail
    ): IUpdateEmailAddressConfirmDto => ({
        EmailAddress: update.emailAddress,
        EmailVerificationCode: update.emailVerificationCode,
        bResendCode: update.bResendCode,
    });
    public static parseResendEmailAddressVerification = (
        update: IUpdateEmail
    ): IResendEmailVerificationCode => ({
        EmailAddress: update.emailAddress,
        bResendCode: true,
    });
    public static parseDeactivateAccount = (update: IDeactivateAccount): IDeactivateAccountDto => ({
        TfaCode: update.tfaCode,
        TfaType: update.tfaType,
    });
    public static parseEnableSmsTFA = (update: IUpdateTFA): IUpdatePhoneNumber => ({
        tfaCode: update.tfaCode,
        tfaType: update.tfaType,
        phoneNumber: update.phoneNumber!,
    });
    public static parseConfirmEnableSmsTFA = (update: IUpdateTFA): IUpdateTFAConfirmSMS => ({
        tfaCode: update.tfaCodeConfirm,
        tfaType: 'SMS',
        changeTfaType: update.changeTfaType,
        phoneNumber: update.phoneNumber!,
    });
    public static parseConfirmUpdateAppTFA = (update: IUpdateTFA): IUpdateTFAConfirmApp => ({
        tfaCode: update.tfaCodeConfirm,
        tfaType: 'AuthenticatorApp',
        changeTfaType: update.changeTfaType,
        totpSharedSecret: update.totpSharedSecret,
    });
    public static parseUpdateAppTFA = (update: IUpdateTFA): ITfa => ({
        tfaType: update.tfaType,
        tfaCode: update.tfaCode,
    });
}
