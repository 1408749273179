import EmptyList from 'assets/ibanera/Img_List_Empty.png';
import NoLiveTransactions from 'assets/bitline-ui-redesign/no-live-transaction-image.png';
import Button from 'components/button/Button';
import { FlexTable } from 'components/flexTable';
import { CellOptions } from 'components/flexTable/Cell';
import {
    DateCell,
    DateCountdownCell,
    EmptyCell,
    NameInitialsCell,
    NumberAndTickerCell,
    StatusCell,
    TLTVALTCCell,
} from 'components/flexTable/CustomCells';
import { TableProps } from 'components/flexTable/FlexTable';
import {
    completeUIUpdate,
    NotificationIdentifier,
    selectUIUpdate,
} from 'components/notifications/notificationUIUpdateReducer';
import { notUndef } from 'helpers/notUndef';
import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PricesMap, selectAllCryptoPrices } from 'reducers/cryptoPrices';
import { ModalType, openModal } from 'reducers/modal';
import { Dispatch } from 'redux';
import { BitLineTable } from 'components/bitLineTable/BitLineTable';

export const GUARANTEES_TABLE_ID_COLUMN = 'guarantees__Id';

enum AdjustmentButton {
    PARTIAL_RELEASE = 'PARTIAL_RELEASE',
    TOP_UP = 'TOP_UP',
}

export type GuaranteeTableRow = {
    guarantees__Id: number;
    guarantees__GuaranteesCode: string;
    venues__Name: string;
    venues__Location: string;
    guarantees__AddDate: string;
    guarantees__LockDate: string | null;
    guarantees__UnlockDate: string | null;
    guarantees__Cost: number;
    assets__Code: string;
    guarantees__GuaranteeValue: number;
    guarantees__TLTV: number;
    guarantees__ALTV: number;
    fiatAssets__Code: string;
    // Corresponds to the safe (blue) boundaries for ALTV.
    // Below, cell turns green and user can partial release.
    // Above, the user needs to top up.
    guarantees__LowerALTVLimit: number;
    guarantees__UpperALTVLimit: number;
    // guarantees__TLTVALTV is used to render information combined from the
    // above rows. The other are hidden using bVisible.
    guarantees__TLTVALTV: null;
    guarantees__Fee: number;
    guarantees__Status: 'Creating' | 'Locked' | 'Pending' | 'Released' | 'Deleted' | 'Releasing';
    assets__FireblocksAssetId: string;
    guarantees__AdjustmentButton: AdjustmentButton | null;
};

export function isGuaranteeTableRow(data: any): data is GuaranteeTableRow {
    return !!data && !!(data as GuaranteeTableRow)?.guarantees__Id;
}

export type GuaranteeTableLiveDataPoint = number;

export type GuaranteeTableLiveData = {
    [key: string]: GuaranteeTableLiveDataPoint;
};

const rowFormatter =
    (isLoading: boolean, liveData?: PricesMap) =>
    (row: GuaranteeTableRow): { [K in keyof typeof row]: React.ReactNode } => {
        let formattedRow: { [K in keyof typeof row]: React.ReactNode } = { ...row };

        if (isLoading) {
            const formattedRowKeys = Object.keys(formattedRow) as (keyof GuaranteeTableRow)[];
            formattedRowKeys.forEach((key) => (formattedRow[key] = <EmptyCell />));
            return formattedRow;
        }
        const rowCryptoPrice =
            liveData?.[row.fiatAssets__Code]?.[row.assets__FireblocksAssetId] ?? null;

        if (row.venues__Name && row.venues__Location) {
            formattedRow = {
                ...formattedRow,
                venues__Name: (
                    <NameInitialsCell
                        name={row.venues__Name}
                        subName={row.venues__Location}
                        additionalText={row.guarantees__GuaranteesCode ?? ''}
                    />
                ),
            };
        }

        if (notUndef(row.guarantees__AddDate)) {
            formattedRow = {
                ...formattedRow,
                guarantees__AddDate: (
                    <DateCell date={row.guarantees__AddDate} formatString={'MM/dd/yyyy'} />
                ),
            };
        }

        if (notUndef(row.guarantees__LockDate)) {
            formattedRow = {
                ...formattedRow,
                guarantees__LockDate: (
                    <DateCountdownCell
                        issueDate={row.guarantees__LockDate}
                        endDate={row.guarantees__UnlockDate}
                    />
                ),
            };
        }

        if (row.guarantees__Cost && row.assets__Code) {
            formattedRow = {
                ...formattedRow,
                guarantees__Cost: (
                    <NumberAndTickerCell number={row.guarantees__Cost} ticker={row.assets__Code} />
                ),
            };
        }

        if (row.guarantees__GuaranteeValue) {
            formattedRow = {
                ...formattedRow,
                guarantees__GuaranteeValue: (
                    <NumberAndTickerCell
                        number={row.guarantees__GuaranteeValue}
                        ticker={row.fiatAssets__Code ?? 'USD'}
                        isFiat
                    />
                ),
            };
        }

        if (
            notUndef(row.guarantees__TLTV) &&
            notUndef(row.guarantees__LowerALTVLimit) &&
            notUndef(row.guarantees__UpperALTVLimit)
        ) {
            formattedRow = {
                ...formattedRow,
                guarantees__TLTVALTV: (
                    <TLTVALTCCell
                        tltv={row.guarantees__TLTV}
                        cryptoGuaranteeAmount={row.guarantees__Cost}
                        fiatGuaranteeAmount={row.guarantees__GuaranteeValue}
                        currentCryptoPrice={rowCryptoPrice}
                        altvMin={row.guarantees__LowerALTVLimit}
                        altvMax={row.guarantees__UpperALTVLimit}
                        isClosed={
                            row.guarantees__Status === 'Released' ||
                            row.guarantees__Status === 'Deleted'
                        }
                    />
                ),
            };
        }

        if (row.guarantees__Fee && row.guarantees__GuaranteeValue) {
            formattedRow = {
                ...formattedRow,
                guarantees__Fee: (
                    <NumberAndTickerCell
                        number={row.guarantees__Fee}
                        ticker={row.fiatAssets__Code ?? 'USD'}
                        isFiat
                    />
                ),
            };
        }

        if (row.guarantees__Status) {
            formattedRow = {
                ...formattedRow,
                guarantees__Status: <StatusCell status={row.guarantees__Status} />,
            };
        }

        return formattedRow;
    };

export const GuaranteesTable: React.FC<
    TableProps<GuaranteeTableRow> & {
        liveData?: PricesMap;
        isLoading: boolean;
        isHomePageTable?: boolean;
        isNewBitLineDesign?: boolean; // TODO - feature flagging for now, remove when complete
    }
> = (props) => {
    const dispatch = useDispatch();
    const allCryptoPrices = useSelector(selectAllCryptoPrices);
    const update = useSelector(selectUIUpdate);

    const formatter = rowFormatter(props.isLoading, allCryptoPrices);

    const columnOptions: { [key in keyof GuaranteeTableRow]?: CellOptions } = useMemo(
        () => ({
            venues__Name: { width: 'SemiLarge' },
            guarantees__AddDate: { width: 'SemiLarge' },
            guarantees__LockDate: { width: 'SemiLarge' },
            guarantees__Fee: { width: 'SemiSmall' },
            guarantees__Cost: { width: 'SemiLarge' },
            guarantees__GuaranteeValue: { width: 'SemiLarge' },
            guarantees__TLTVALTV: { width: 'SemiLarge' },
        }),
        []
    );

    // Effect that handles UI updating from using notifications system.
    useEffect(() => {
        if (update) {
            const list = props.table?.data?.details.listData as GuaranteeTableRow[] | undefined;
            const isInListData =
                list?.findIndex((existingRow) => existingRow.guarantees__Id) !== -1;
            let newList: GuaranteeTableRow[] | null = null;
            if (
                update.pushType === NotificationIdentifier.ACTIVATE_PARTIAL_RELEASE ||
                update.pushType === NotificationIdentifier.ACTIVATE_TOP_UP ||
                update.pushType === NotificationIdentifier.DEACTIVATE_PARTIAL_RELEASE ||
                update.pushType === NotificationIdentifier.DEACTIVATE_TOP_UP
            ) {
                const updateTableAdjustmentButton = (button: AdjustmentButton | null) => {
                    if (isInListData && list) {
                        newList = list.map((row) => ({
                            ...row,
                            guarantees__AdjustmentButton:
                                row.guarantees__Id === update.data.guaranteesId
                                    ? button
                                    : row.guarantees__AdjustmentButton,
                        }));
                    }
                };
                switch (update.pushType) {
                    case NotificationIdentifier.ACTIVATE_PARTIAL_RELEASE:
                        updateTableAdjustmentButton(AdjustmentButton.PARTIAL_RELEASE);
                        break;
                    case NotificationIdentifier.ACTIVATE_TOP_UP:
                        updateTableAdjustmentButton(AdjustmentButton.TOP_UP);
                        break;
                    case NotificationIdentifier.DEACTIVATE_PARTIAL_RELEASE:
                        updateTableAdjustmentButton(null);
                        break;
                    case NotificationIdentifier.DEACTIVATE_TOP_UP:
                        updateTableAdjustmentButton(null);
                        break;
                }
                if (newList && list) {
                    props.table?.data?.actions.updateLocalTable(props.idColumn, newList, list);
                }
                dispatch(completeUIUpdate());
            } else if (update.pushType === NotificationIdentifier.RELOAD_GUARANTEES_TABLE) {
                props.table?.reload();
                dispatch(completeUIUpdate());
            } else if (update.pushType === NotificationIdentifier.UPDATE_GUARANTEE_STATUS) {
                if (isInListData && list) {
                    newList = list.map((row) => ({
                        ...row,
                        guarantees__Status:
                            row.guarantees__Id === update.data.guaranteesId
                                ? update.data.status
                                : row.guarantees__Status,
                    }));
                    props.table?.data?.actions.updateLocalTable(props.idColumn, newList, list);
                }
                dispatch(completeUIUpdate());
            }
        }
    }, [update, props.idColumn, props.table, dispatch]);

    return (
        <div className="GuaranteesTable">
            {props.isNewBitLineDesign ? (
                <BitLineTable
                    {...props}
                    headerColumns={['venues__Name', 'guarantees__AddDate', 'guarantees__LockDate']}
                    rowFormatter={formatter}
                    rowButton={
                        props.isLoading
                            ? undefined
                            : (data: GuaranteeTableRow) =>
                                  createButtons(data, dispatch, props.isLoading)
                    }
                    emptyListComponent={
                        props.isHomePageTable
                            ? EmptyTableNotifierHomePage
                            : EmptyTableNotifierGuaranteesPage
                    }
                />
            ) : (
                <FlexTable
                    {...props}
                    rowFormatter={formatter}
                    rowButton={
                        props.isLoading
                            ? undefined
                            : (data: GuaranteeTableRow) =>
                                  createButtons(data, dispatch, props.isLoading)
                    }
                    buttonInMiddleTable
                    emptyListComponent={
                        props.isHomePageTable
                            ? EmptyTableNotifierHomePage
                            : EmptyTableNotifierGuaranteesPage
                    }
                    splitFromLeft={1}
                    // rowCellOptions={{ venues__Name: { width: 'Large' } }}
                    rowCellOptions={columnOptions as any}
                    noRowHover
                    isTallRow={(row: GuaranteeTableRow) => {
                        return (
                            row.guarantees__AdjustmentButton === AdjustmentButton.PARTIAL_RELEASE
                        );
                    }}
                />
            )}
        </div>
    );
};

const createButtons = (data: GuaranteeTableRow, dispatch: Dispatch, isLoading: boolean) => {
    if (isLoading) {
        return <EmptyCell />;
    }

    if (data.guarantees__Status !== 'Pending' && data.guarantees__Status !== 'Locked') {
        return <div className="GuaranteesTableButtonsCell"></div>;
    }

    const handleTopUp = () => {
        dispatch(openModal({ modalType: ModalType.TOP_UP, data }));
    };
    const handleGuaranteeUnlock = () => {
        dispatch(openModal({ modalType: ModalType.UNLOCK_GUARANTEE, data }));
    };

    const handlePartialRelease = () => {
        dispatch(openModal({ modalType: ModalType.PARTIAL_RELEASE, data }));
    };

    if (data.guarantees__Status === 'Pending') {
        const handleActivate = () => {
            dispatch(openModal({ modalType: ModalType.ACTIVATE_GUARANTEE, data: data }));
        };
        const handleGuaranteeDelete = () => {
            dispatch(openModal({ modalType: ModalType.DELETE_GUARANTEE, data }));
        };
        return (
            <div className="GuaranteesTableButtonsCell">
                <Button
                    priority="secondary"
                    className="TableButton DeleteButton"
                    onClick={handleGuaranteeDelete}
                >
                    Delete
                </Button>
                {data.guarantees__AdjustmentButton === AdjustmentButton.TOP_UP ? (
                    <Button priority="primary" className="TableButton Bad" onClick={handleTopUp}>
                        Top up
                    </Button>
                ) : (
                    <Button priority="primary" className="TableButton" onClick={handleActivate}>
                        Activate
                    </Button>
                )}
                {data.guarantees__AdjustmentButton === AdjustmentButton.PARTIAL_RELEASE && (
                    <Button
                        priority="primary"
                        className="TableButton Good"
                        onClick={handlePartialRelease}
                    >
                        Partial Release
                    </Button>
                )}
            </div>
        );
    }

    return (
        <div className="GuaranteesTableButtonsCell">
            {data.guarantees__AdjustmentButton === AdjustmentButton.PARTIAL_RELEASE ? (
                <>
                    <Button
                        priority="primary"
                        className="TableButton Good"
                        onClick={handlePartialRelease}
                    >
                        Partial Release
                    </Button>
                    <Button
                        priority="primary"
                        className="TableButton"
                        onClick={handleGuaranteeUnlock}
                    >
                        Unlock
                    </Button>
                </>
            ) : data.guarantees__AdjustmentButton === AdjustmentButton.TOP_UP ? (
                <>
                    <Button priority="primary" className="TableButton Bad" onClick={handleTopUp}>
                        Top up
                    </Button>
                    <Button
                        priority="primary"
                        className="TableButton"
                        onClick={handleGuaranteeUnlock}
                    >
                        Unlock
                    </Button>
                </>
            ) : (
                <>
                    <Button
                        priority="primary"
                        className="TableButton"
                        onClick={handleGuaranteeUnlock}
                    >
                        Unlock
                    </Button>
                </>
            )}
        </div>
    );
};

const EmptyTableNotifierHomePage: React.FC = () => {
    const dispatch = useDispatch();

    const handleCreateGuarantee = () => {
        dispatch(openModal({ modalType: ModalType.CREATE_GUARANTEE }));
    };
    return (
        <div className="EmptyTableNotifier">
            <img alt="Empty table" src={NoLiveTransactions} />
            <h3>You have no live transactions</h3>
            <Button
                onClick={handleCreateGuarantee}
                priority="primary"
                color="second"
                minWidth
                className="EmptyTableButton"
            >
                Create a transaction
            </Button>
        </div>
    );
};

const EmptyTableNotifierGuaranteesPage: React.FC = () => {
    const dispatch = useDispatch();

    const handleCreateGuarantee = () => {
        dispatch(openModal({ modalType: ModalType.CREATE_GUARANTEE }));
    };
    return (
        <div className="EmptyTableNotifier">
            <img alt="Empty table" src={NoLiveTransactions} />
            <h3>You have no live transactions</h3>
            <Button
                onClick={handleCreateGuarantee}
                priority="primary"
                color="second"
                minWidth
                className="EmptyTableButton"
            >
                Create a transaction
            </Button>
        </div>
    );
};
