import { navigate, RouteComponentProps, useNavigate } from '@reach/router';
import { Spinner } from 'components/spinner/Spinner';
import { Formik, useFormikContext } from 'formik';
import { usePageTranslations } from 'helpers/usePageTranslations';
import { RegisterInitialState } from 'pages/register/Register';
import { postEmailVerificationCode } from 'pages/register/RegisterApi';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { AppPath, PageResourceKey } from '../../appConstants';
import Page from '../../components/page/Page';
import {
    selectAuthStatus,
    selectTFAType,
    setAuthStatus,
    signIn,
    SignInAttempt,
    tfaSignIn,
} from '../../reducers/auth';
import { selectCultureCode } from '../../reducers/language';
import SignInForm from './SignInForm';
import { SignInTranslation, TFAFormDetails } from './signInTypes';
import TFAForm, { parseTfaForm } from './TFAForm';
import BackgroundImage from 'assets/ibanera/Bg_Register.jpg';
import { SplashPage } from 'pages/SplashPage';

type RouteState = { state: { reset: boolean } };

type Props = RouteComponentProps<{ location: RouteState }>;

const SignIn: React.FC<Props> = ({ location }) => {
    const authStatus = useSelector(selectAuthStatus);
    const cultureCode = useSelector(selectCultureCode);
    const TFAType = useSelector(selectTFAType);
    const dispatch = useDispatch();

    if (authStatus === 'signed_in') {
        navigate(`/${cultureCode}${AppPath.CHECKING_DETAILS}`);
    }

    // Useful if user wants to return to the sign-in screen from the tfa screen.
    // Can use the sign-in button in the header which will route with this state.
    useEffect(() => {
        if (location?.state?.reset) {
            // May need this to reset some other parts of state in the auth reducer
            // if this functionality starts causing problems.
            dispatch(setAuthStatus('signed_out'));
        }
    }, [location?.state?.reset, dispatch]);

    const translations = usePageTranslations<SignInTranslation>(PageResourceKey.SIGN_IN);

    const initialValues: TFAFormDetails = {
        username: '',
        password: '',
        rememberMe: false,
        tfaCode: '',
        tfaType: TFAType ? TFAType : '',
        useSMS: false,
        emailVerificationCode: '',
        bResendCode: false,
    };

    const parseSignIn = (values: TFAFormDetails): SignInAttempt => ({
        username: values.username,
        rememberMe: values.rememberMe,
        password: values.password,
    });

    const handleSubmit = (values: TFAFormDetails) => {
        if (authStatus === 'signed_out') {
            dispatch(signIn(parseSignIn(values)));
        } else if (authStatus === 'need_tfa') {
            dispatch(
                tfaSignIn(
                    parseTfaForm({ ...values, tfaType: values.useSMS ? 'SMS' : 'AuthenticatorApp' })
                )
            );
        } else if (authStatus === 'need_email_verified') {
            (async () => {
                const result = await postEmailVerificationCode(values);
                if (result.response) {
                    dispatch(signIn(parseSignIn(values)));
                }
            })();
        }
    };

    const validationSchema = Yup.object({
        username: Yup.string()
            .required('Email address is required')
            .email('Email address is invalid'),
        password: Yup.string()
            .required('Password is required')
            .max(20, 'Field character count is invalid')
            .min(8, 'Field character count is invalid'),
        rememberMe: Yup.boolean(),
        // tfaCode:
        //     authStatus === 'need_tfa'
        //         ? Yup.string().required('Please enter your 6-digit code').max(6)
        //         : Yup.string(),
        useSMS: Yup.boolean(),
    });

    return (
        <Page isPublic={true}>
            <SplashPage>
                <div className="SignInFormPanel">
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        {() => (
                            <>
                                <RegistrationRedirectWrapper />
                                {(authStatus === 'signed_out' || authStatus === 'pending') && (
                                    <SignInForm translations={translations} />
                                )}
                                {authStatus === 'need_tfa' || authStatus === 'tfa_pending' ? (
                                    <TFAForm translations={translations} />
                                ) : null}
                                {authStatus === 'signed_in' && <Spinner />}
                            </>
                        )}
                    </Formik>
                </div>
            </SplashPage>
        </Page>
    );
};

const RegistrationRedirectWrapper: React.FC = () => {
    const dispatch = useDispatch();
    const authStatus = useSelector(selectAuthStatus);
    const cultureCode = useSelector(selectCultureCode);
    const { values } = useFormikContext<TFAFormDetails>();
    const navigate = useNavigate();

    if (authStatus === 'need_email_verified') {
        dispatch(setAuthStatus('signed_out'));
        navigate(`/${cultureCode}${AppPath.REGISTER}`, {
            state: {
                stage: 'emailVerification',
                email: values.username,
                password: values.password,
            } as RegisterInitialState,
        });
    } else if (authStatus === 'needs_to_add_account_tfa') {
        dispatch(setAuthStatus('signed_out'));
        navigate(`/${cultureCode}${AppPath.REGISTER}`, {
            state: {
                stage: 'tfa',
                email: values.username,
                password: values.password,
            } as RegisterInitialState,
        });
    }

    return null;
};

export default SignIn;
