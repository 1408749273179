import { EitherOrSelection } from 'components/eitherOrSelection/EitherOrSelection';
import React from 'react';
import AppAnimation from 'assets/bitline-ui-redesign/authenticator app animation.json';
import SMSAnimation from 'assets/bitline-ui-redesign/sms verification.json';
import Button from '../../components/button/Button';
import { AuthType, SelectAuthTypeProps } from './models';
import { useNavigate } from '@reach/router';

export const SelectAuthType: React.FC<SelectAuthTypeProps> = ({
    translations,
    onComplete,
    authType,
    setAuthType,
}) => {
    const handleSelection = (authType: AuthType) => () => {
        setAuthType(authType);
    };
    const navigate = useNavigate();

    return (
        <>
            {translations && (
                <>
                    <div className="RegisterFormWidth SelectAuthHeader">
                        <h1>Add Extra Security</h1>
                        <p>Secure your auth by adding two step verification</p>
                    </div>
                    <div className="SelectAccountType">
                        <EitherOrSelection
                            title="Authenticator App"
                            selected={authType === AuthType.APP}
                            onClick={handleSelection(AuthType.APP)}
                            value={AuthType.APP}
                            animation={AppAnimation}
                            recommended
                            className="AppSelection"
                        />
                        <EitherOrSelection
                            title="SMS"
                            selected={authType === AuthType.SMS}
                            onClick={handleSelection(AuthType.SMS)}
                            value={AuthType.SMS}
                            animation={SMSAnimation}
                        />
                    </div>
                    <div className="RegisterFormWidth BtnContainer">
                        <Button
                            priority="primary"
                            variety="full"
                            className="RegisterFormButton"
                            onClick={onComplete}
                            color="second"
                        >
                            {translations.continue_button}
                        </Button>
                        <Button
                            priority="secondary"
                            variety="full"
                            type="button"
                            color="first"
                            style={{ marginTop: 10 }}
                            onClick={() => navigate('/')}
                        >
                            Cancel
                        </Button>
                    </div>
                </>
            )}
        </>
    );
};
