import { padString } from './padString';

export const generateRandomColorHex = () => {
    return Math.floor(Math.random() * 16777215).toString(16);
};

// https://stackoverflow.com/a/8134122/14015703
export const generateSeededRandomColorHex = (seed: number | string) => {
    if (typeof seed === 'string') {
        seed = getHash(seed);
    }

    // This is sometimes only outputting 5 chars.
    const color = Math.floor(Math.abs(Math.sin(seed) * 1677721) % 1677721).toString(16);
    return padString(color, 6, '0');
};

// https://stackoverflow.com/a/40958826/14015703
function getHash(input: string) {
    var hash = 0,
        len = input.length;
    for (var i = 0; i < len; i++) {
        hash = (hash << 5) - hash + input.charCodeAt(i);
        hash |= 0; // to 32bit integer
    }
    return hash;
}
