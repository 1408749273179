import { DASHBOARD_MENU_PAGE_SUB_PAGE_PLACEHOLDER } from 'App';
import { AppPath, DashboardPath } from 'appConstants';
import { FooterLinks } from 'components/footer/types';
import { NavMenuButtons } from 'components/navMenuButtons/NavMenuButtons';
import { SideMenuLink } from 'components/sideMenu/SideMenu';
import React from 'react';
import { useSelector } from 'react-redux';
import { Icon } from 'reducers/componentResources';
import chevron from '../../assets/ibanera/Icon_Dropdown_01.png';
import { MobileNavigationButton, StaticIcon } from './MobileNavigationButton';

type SignedInMoreMenuProps = {
    linksFromFooter: FooterLinks | null;
    pageLinks: SideMenuLink[] | null;
    pageLinkIcons: Icon[] | null;
    navigateWithCultureCode: (path: string) => void;
};

export const SignedInMoreMenu: React.FC<SignedInMoreMenuProps> = ({
    linksFromFooter,
    pageLinks,
    pageLinkIcons,
    navigateWithCultureCode,
}) => {
    const accountTypePathItem = AppPath.PERSONAL;
    return (
        <div className="MoreMenu">
            <div className="PageLinks">
                <NavMenuButtons />
                {pageLinks &&
                    pageLinkIcons &&
                    pageLinks.map((link, index) => {
                        const linkHasChildren = !!link.childElements;
                        // See App.tsx Dashboard routing for explanation on the need for
                        // this placeholder.
                        const finalUrlSegment = `/${linkHasChildren
                                ? `${link.childElements[0].path}`
                                : DASHBOARD_MENU_PAGE_SUB_PAGE_PLACEHOLDER
                            }`;
                        return (
                            <div
                                onClick={() =>
                                    navigateWithCultureCode(
                                        `${accountTypePathItem}/${link.path}${finalUrlSegment}`
                                    )
                                }
                                key={link.id}
                                className="MoreMenuPageLink"
                            >
                                {
                                    <img
                                        src={pageLinkIcons[index].mobileInactive}
                                        alt="Icon"
                                        className="Icon"
                                    />
                                }
                                <span className="LinkLabel">{link.displayName}</span>
                                {<img src={chevron} alt="chevron" className="Chevron" />}
                            </div>
                        );
                    })}
            </div>
            <div className="FooterLinks">
                {linksFromFooter &&
                    linksFromFooter.map((link) => (
                        <div
                            onClick={() =>
                                navigateWithCultureCode(
                                    `${accountTypePathItem}${DashboardPath.INFO}${link.url}`
                                )
                            }
                            className="FooterLink"
                        >
                            {link.title}
                        </div>
                    ))}
            </div>
        </div>
    );
};

type PublicMoreMenuProps = {
    linksFromFooter: FooterLinks | null;
    navigateWithCultureCode: (path: string) => void;
};

export const PublicMoreMenu: React.FC<PublicMoreMenuProps> = ({
    linksFromFooter,
    navigateWithCultureCode,
}) => {
    return (
        <div className="MoreMenu">
            <div className="PageLinks">
                <MobileNavigationButton
                    onClick={() => navigateWithCultureCode(`${AppPath.SIGN_IN}`)}
                    label="Sign in"
                    icon={StaticIcon.SIGN_IN}
                    className="MoreMenuPageLink"
                    isActive={false}
                />
                <MobileNavigationButton
                    onClick={() => navigateWithCultureCode(`${AppPath.REGISTER}`)}
                    label="Register"
                    icon={StaticIcon.REGISTER}
                    className="MoreMenuPageLink"
                    isActive={false}
                />
            </div>
            <div className="FooterLinks">
                {linksFromFooter &&
                    linksFromFooter.map((link) => {
                        return (
                            <div
                                onClick={() =>
                                    navigateWithCultureCode(`${AppPath.INFO}${link.url}`)
                                }
                                className="FooterLink"
                            >
                                {link.title}
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};
