import { Spinner } from 'components/spinner/Spinner';
import { lowerCase, upperFirst } from 'lodash';
import React from 'react';
import { useProfileContext } from './useProfileContext';
import EmailIcon from 'assets/email_icon.svg';
import MobileIcon from 'assets/mobile_icon.svg';
import CountryIcon from 'assets/country_icon.svg';
import AddressIcon from 'assets/address_icon.svg';
import editIcon from 'assets/edit_icon.svg';

export function generateAddressString(...addressItem: (string | null)[]) {
    const args = Array.prototype.slice.call(arguments);
    const filtered = args.filter((arg) => !!arg);
    return filtered.join(', ');
}

export const ProfileDetails: React.FC = () => {
    const { profileDetails } = useProfileContext();

    return (
        <div className="Profile">
            <div className="Content">
                {profileDetails ? (
                    <>
                        <UserDetail
                            value={profileDetails.emailAddress ?? '-'}
                            data={'Email'}
                            icon={EmailIcon}
                        />
                        <UserDetail
                            value={profileDetails.phoneNumber ?? '-'}
                            data={'Mobile'}
                            icon={MobileIcon}
                        />
                        <UserDetail
                            value={profileDetails.countryName}
                            data={'Country of Residence'}
                            icon={CountryIcon}
                        />
                        <UserDetail
                            value={generateAddressString(
                                profileDetails.addressLine1,
                                profileDetails.addressLine2,
                                profileDetails.townCity,
                                profileDetails.stateProvinceName,
                                profileDetails.postcode
                            )}
                            data={'Address'}
                            icon={AddressIcon}
                        />
                    </>
                ) : (
                    <Spinner />
                )}
            </div>
        </div>
    );
};

type UserDetailProps = {
    data: string;
    value: string | null;
    icon: string;
    onEdit?: () => void;
};

export const UserDetail: React.FC<UserDetailProps> = ({ data, value, icon, onEdit }) => {
    return (
        <div className="UserDetail">
            {icon && <img src={icon} alt="" />}
            <div className="Detail">
                <p className="Detail Label">{upperFirst(lowerCase(data))}</p>
                <p className="Detail Value">{!!value ? value : '-'}</p>
            </div>
            {onEdit && (
                <button className="EditBtn" onClick={onEdit}>
                    <img src={editIcon} alt="edit" />
                </button>
            )}
        </div>
    );
};
