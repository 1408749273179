import Button from 'components/button/Button';
import { useDispatch } from 'react-redux';
import { signOut } from 'reducers/auth';

export const JumioManualVerificationRequired = () => {
    const dispatch = useDispatch();
    return (
        <div className="VerificationComplete">
            <h3>Your details have been passed on to our team</h3>
            <p>One of our associates will be in touch to check your details manually</p>
            <Button variety="full" onClick={() => dispatch(signOut())}>
                Sign out
            </Button>
        </div>
    );
};
