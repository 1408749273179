import { RouteComponentProps } from '@reach/router';
import { AppPath, DashboardPath } from 'appConstants';
import { SideMenuLink } from 'components/sideMenu/SideMenu';
import { SubNavigationMenu } from 'components/subNavigationMenu/SubNavigationMenu';
import { endpoints } from 'endpoints.config';
import { removeLeadingSlash } from 'helpers/removeLeadingSlash';
import { GuaranteesPage } from 'pages/guarantees/GuaranteesPage';
import { HomePage } from 'pages/home/HomePage';
import { ProfilePage, ProfileSubPage } from 'pages/profile/ProfilePage';
import { TransactionsPage } from 'pages/transactions/TransactionsPage';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectComponentResources } from 'reducers/componentResources';
import { selectCultureCode } from 'reducers/language';

interface DashboardPageContentProps extends RouteComponentProps {
    page?: string;
    sub_page?: string;
}

export const DashboardContentPage: React.FC<DashboardPageContentProps> = ({ page, sub_page }) => {
    const cultureCode = useSelector(selectCultureCode);

    const { customerMenuLinks } = useSelector(selectComponentResources);
    const navigationItem = customerMenuLinks?.find((item) => item.path === page);
    const pageHasSubNavigation = !!navigationItem?.childElements;
    // If the item has no children then we render it's own content. Else we render the
    // selected sub_page.
    const itemToRender = pageHasSubNavigation
        ? navigationItem?.childElements.find((item) => item.path === sub_page)
        : navigationItem;

    const contentContainerClass = `DashboardPageContent LocalContent ${
        pageHasSubNavigation ? 'WithSubNavigation' : ''
    } ${navigationItem?.path === removeLeadingSlash(DashboardPath.PROFILE) ? 'NoPadding' : ''}`;

    const cryptoEndpoints = useMemo(
        () => ({
            history: endpoints.cryptosmodule.historyList,
            market: endpoints.cryptosmodule.marketList,
            portfolio: endpoints.cryptosmodule.portfolioList,
        }),
        []
    );

    return (
        <div className="DashboardPage">
            {pageHasSubNavigation && (
                <SubNavigationMenu
                    items={navigationItem?.childElements as SideMenuLink[]}
                    basePath={`/${cultureCode}${AppPath.PERSONAL}/${page}`}
                />
            )}
            <div className={contentContainerClass}>
                {navigationItem ? (
                    navigationItem.path === removeLeadingSlash(DashboardPath.PROFILE) ? (
                        <ProfilePage subPage={sub_page as ProfileSubPage} />
                    ) : navigationItem.path === removeLeadingSlash(DashboardPath.TRANSACTIONS) ? (
                        <TransactionsPage />
                    ) : navigationItem.path === removeLeadingSlash(DashboardPath.GUARANTEES) ? (
                        <GuaranteesPage />
                    ) : (
                        <h1>{itemToRender?.displayName ?? 'Home'}</h1>
                    )
                ) : (
                    <HomePage />
                )}
            </div>
        </div>
    );
};
