import { FormSingleSelectField } from '@avamae/formbuilder';
import instance, { ApiResponse } from 'api';
import Button from 'components/button/Button';
import { endpoints } from 'endpoints.config';
import { Form, Formik, FormikHelpers } from 'formik';
import { useDispatch } from 'react-redux';
import { setVerificationStage } from 'reducers/jumio';
import { VerificationStatusResponse } from './jumioModels';
import { VerifyIdentityOptions } from './JumioVerification';

const initialValues = {
    countryCode: '',
    typeCode: '',
};
export const JumioTextualData = ({ options }: { options: VerifyIdentityOptions }) => {
    const dispatch = useDispatch();
    const handleSubmit = (
        values: typeof initialValues,
        formikHelpers: FormikHelpers<typeof initialValues>
    ) => {
        instance
            .post<ApiResponse<VerificationStatusResponse>>(
                endpoints.jumiomodule.verifyIdentity,
                values
            )
            .then((res) =>
                dispatch(
                    setVerificationStage({
                        currentStage: res.data.details.status,
                        additionalData: res.data.details.additionalData,
                    })
                )
            )
            .catch((err) => {
                formikHelpers.setSubmitting(false);
            });
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <Form>
                <h3>We need some proof of your address, please choose from the options below:</h3>
                <FormSingleSelectField
                    label="Document type"
                    fieldName="typeCode"
                    options={options.documentOptions.map((option) => ({
                        value: option.typeCode,
                        label: option.name,
                    }))}
                    dropdownProps={{
                        menuPortalTarget: document.body,
                        styles: {
                            menuPortal: (provided: any) => ({
                                ...provided,
                                zIndex: 500,
                            }),
                        },
                    }}
                />
                <FormSingleSelectField
                    label="Origin country"
                    fieldName="countryCode"
                    options={options.countryOptions.map((option) => ({
                        value: option.isoCode,
                        label: option.name,
                    }))}
                    dropdownProps={{
                        menuPortalTarget: document.body,
                        styles: {
                            menuPortal: (provided: any) => ({
                                ...provided,
                                zIndex: 500,
                            }),
                        },
                    }}
                />
                <Button variety="full">Next</Button>
            </Form>
        </Formik>
    );
};
