import CountdownIcon from 'assets/ibanera/Icon_Countdown.png';
import React, { useEffect, useState } from 'react';

type CountdownTimerProps = {
    countdownTime: number | null;
    onTimerEnd?: () => void;
    className?: string;
};

export const CountdownTimer: React.FC<CountdownTimerProps> = ({
    countdownTime,
    onTimerEnd,
    className = '',
}) => {
    const [timeRemaining, setTimeRemaining] = useState<number | null>(countdownTime);
    const [countdownInterval, setCountdownInterval] = useState<number | null>(null);

    useEffect(() => {
        if (timeRemaining && !countdownInterval) {
            const interval = window.setInterval(() => {
                setTimeRemaining((oldVal) => (oldVal === null ? null : Math.max(oldVal - 1, 0)));
            }, 1000);
            setCountdownInterval(interval);
        }

        if (timeRemaining === 0 && countdownInterval) {
            window.clearInterval(countdownInterval);
            setCountdownInterval(null);
            if (onTimerEnd) {
                onTimerEnd();
            }
        }
    }, [onTimerEnd, countdownInterval, timeRemaining]);

    return (
        <div className={`CountdownTimer ${className}`}>
            <img src={CountdownIcon} className="CountdownIcon" />
            <span>{timeRemaining ? `${timeRemaining} Sec` : '-'}</span>
        </div>
    );
};
