import signInActive from 'assets/ibanera/Icon_Sign_In_Active.png';
import signInInactive from 'assets/ibanera/Icon_Sign_In_Inactive.png';
import RegisterActive from 'assets/ibanera/Icon_Register_Active.png';
import RegisterInactive from 'assets/ibanera/Icon_Register_Inactive.png';
import MoreActive from 'assets/ibanera/Icon_More_Active.png';
import MoreInactive from 'assets/ibanera/Icon_More_Inactive.png';
import HomeActive from 'assets/ibanera/Icon_Home_Active.png';
import HomeInactive from 'assets/ibanera/Icon_Home_Inactive.png';

import React from 'react';
import { selectTheme } from 'reducers/ui';
import { useSelector } from 'react-redux';
import { Icon } from 'reducers/componentResources';

export enum StaticIcon {
    SIGN_IN,
    REGISTER,
    MORE,
    HOME,
}

const ICON_MAP: { [key in StaticIcon]: Icon } = {
    [StaticIcon.SIGN_IN]: {
        active: signInActive,
        mobileInactive: signInInactive,
    },
    [StaticIcon.REGISTER]: {
        active: RegisterActive,
        mobileInactive: RegisterInactive,
    },
    [StaticIcon.MORE]: {
        active: MoreActive,
        mobileInactive: MoreInactive,
    },
    [StaticIcon.HOME]: {
        active: HomeActive,
        mobileInactive: HomeInactive,
    },
};

type MobileNavigationButtonProps = {
    onClick: () => void;
    label: string;
    icon: StaticIcon | Icon;
    className?: string;
    isActive?: boolean;
    isInMoreMenu?: boolean;
};

// As StaticIcon is an enum, it would have typeof number.
const checkIfLoadedIcon = (icon: StaticIcon | Icon): icon is StaticIcon => {
    return typeof icon === 'object';
};

export const MobileNavigationButton: React.FC<MobileNavigationButtonProps> = ({
    onClick,
    label,
    icon,
    className,
    isActive,
    isInMoreMenu,
}) => {
    const isIconLoaded = checkIfLoadedIcon(icon);
    const iconSources: Icon = isIconLoaded ? (icon as Icon) : ICON_MAP[icon as StaticIcon];
    const theme = useSelector(selectTheme);

    return (
        <button
            className={`MobileNavigationButton ${className ? className : ''} ${
                isActive ? 'Active' : 'Inactive'
            }`}
            onClick={onClick}
        >
            <img
                src={iconSources.mobileInactive}
                alt="Icon"
                className={`Icon ${isActive ? 'Hidden' : ''}`}
            />
            {!isInMoreMenu && (
                <img
                    src={iconSources.active}
                    alt="Icon"
                    className={`Icon ${isActive ? '' : 'Hidden'}`}
                />
            )}
            <span
                className="Label"
                style={isActive && !isInMoreMenu ? { color: theme.colors.first } : {}}
            >
                {label}
            </span>
        </button>
    );
};
