import { useTable } from 'api';
import Pagination from 'components/pagination/Pagination';
import { TableFilteringAndSearch } from 'components/tableFilteringAndSearch/TableFilteringAndSearch';
import {
    TransactionsTable,
    TRANSACTIONS_TABLE_ID_COLUMN,
} from 'components/transactionsTable/TransactionsTable';
import { endpoints } from 'endpoints.config';
import { addDayToDate } from 'helpers/addDayToDate';
import { useDebouncedState } from 'helpers/useDebouncedState';
import React, { useEffect, useState } from 'react';

export const TransactionsPage = () => {
    const table = useTable<any, any>({
        url: endpoints.transfersmodule.list,
        bClearFilters: true,
        bClearSearch: true,
    });

    const [searchValue, setSearchValue, localSearchValue] = useDebouncedState<string>('');
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    useEffect(() => {
        table.data?.actions.changeSearch(searchValue);
    }, [searchValue]);

    useEffect(() => {
        if (startDate || endDate) {
            table.data?.actions.setFilters([
                {
                    columnKey: 'customerAssetAccountsTransactions__Date',
                    operator: 'BETWEEN',
                    value: `${startDate ? startDate.toISOString() : null}TO${
                        endDate ? addDayToDate(endDate).toISOString() : null
                    }`,
                },
            ]);
        }
    }, [startDate, endDate]);

    const handleSearch = (value: string) => {
        setSearchValue(value);
    };

    return (
        <div className="TransactionsPage">
            <TableFilteringAndSearch
                searchValue={localSearchValue}
                handleSearchChange={handleSearch}
                filteringLabel="Date:"
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
            />
            <TransactionsTable
                idColumn={TRANSACTIONS_TABLE_ID_COLUMN}
                table={table}
                splitFromLeft={1}
                isNewBitLineDesign={true}
            />
            <Pagination table={table} />
        </div>
    );
};
