import instance, { ApiResponse } from 'api';
import Img_Register_01 from 'assets/Img_Register_01.png';
import Img_Register_02 from 'assets/Img_Register_02.png';
import Button from 'components/button/Button';
import { Modal } from 'components/modal/Modal';
import { Spinner } from 'components/spinner/Spinner';
import { format } from 'date-fns';
import { endpoints } from 'endpoints.config';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserTFASetting } from 'reducers/auth';
import { ModalType, openModal, selectModalState } from 'reducers/modal';
import { DeactivateAccountModal } from './DeactivateAccountModal';
import { DisableTFAModal } from './DisableTFAModal';
import { EnableSmsTFAModal } from './EnableSmsTFAModal';
import { TFAItem } from './TFAItem';
import { UpdateAppTFAModal } from './UpdateAppTFAModal';
import { UpdateEmailAddressModal } from './UpdateEmailAddressModal';
import { UpdatePasswordModal } from './UpdatePasswordModal';
import { UpdatePhoneNumberModal } from './UpdatePhoneNumberModal';
import { UserDetail, generateAddressString } from './ProfileDetails';
import passwordIcon from 'assets/password_icon.svg';
import mobileIcon from 'assets/mobile_icon.svg';
import addressIcon from 'assets/address_icon.svg';
import accountActivationIcon from 'assets/account_activation_icon.svg';
import { useProfileContext } from './useProfileContext';
import { UpdateAddressModal } from './UpdateAddressModal';

interface UserSecurityData {
    phoneNumber: string | null;
    emailAddress: string;
    bTwoFactorAppAuthEnabled: boolean;
    bTwoFactorSMSAuthEnabled: boolean;
    accountActivationDate: string;
    loginList:
        | {
              browser: string;
              dateTime: string;
              ip: string;
              locationInfo: string;
          }[]
        | null;
}

export const SecurityContext = createContext<{ reloadSecurityData: () => void }>({
    reloadSecurityData: () => {},
});

export const Security = () => {
    const { profileDetails } = useProfileContext();
    const [securityData, setSecurityData] = useState<UserSecurityData | null>(null);
    const [reloadSecurityData, setReloadSecurityData] = useState<boolean>(false);
    const dispatch = useDispatch();
    const modal = useSelector(selectModalState);
    const handleOpenModal = (modalType: ModalType) => () => {
        dispatch(openModal({ modalType: modalType }));
    };

    const getAndSetSecurityData = useCallback(() => {
        instance
            .get<ApiResponse<UserSecurityData>>(endpoints.profilemodule.usersecurityinfo)
            .then((response) => {
                if (response.data.status === '1') {
                    setSecurityData(response.data.details);
                    const { bTwoFactorAppAuthEnabled, bTwoFactorSMSAuthEnabled } =
                        response.data.details;
                    dispatch(
                        setUserTFASetting({
                            bTwoFactorAppAuthEnabled,
                            bTwoFactorSMSAuthEnabled,
                        })
                    );
                }
            });
    }, [dispatch]);

    useEffect(() => {
        getAndSetSecurityData();
    }, [getAndSetSecurityData]);

    useEffect(() => {
        if (reloadSecurityData) {
            setReloadSecurityData(false);
            getAndSetSecurityData();
        }
    }, [getAndSetSecurityData, reloadSecurityData]);

    const isSMSAuth =
        securityData &&
        securityData.bTwoFactorSMSAuthEnabled &&
        securityData.bTwoFactorSMSAuthEnabled;
    const isAppAuth =
        securityData &&
        securityData.bTwoFactorAppAuthEnabled &&
        securityData.bTwoFactorAppAuthEnabled;

    return (
        <>
            <SecurityContext.Provider
                value={{ reloadSecurityData: () => setReloadSecurityData(true) }}
            >
                <div className="SecurityPage">
                    {securityData && profileDetails ? (
                        <>
                            <div className="SecurityPageSection">
                                <div className="Content">
                                    <div className="SecurityHeader">
                                        <div className="Label">Security</div>
                                        {/* TODO - Needs implementing */}
                                        {/* <button className="TfaLink">
                                            Two factor authentication
                                        </button> */}
                                    </div>
                                    <UserDetail
                                        data={'Password'}
                                        value={'******'}
                                        icon={passwordIcon}
                                        onEdit={handleOpenModal(ModalType.PROFILE_UPDATE_PASSWORD)}
                                    />
                                    <UserDetail
                                        data={'Mobile'}
                                        value={profileDetails?.phoneNumber ?? '-'}
                                        icon={mobileIcon}
                                        onEdit={handleOpenModal(
                                            ModalType.PROFILE_UPDATE_PHONE_NUMBER
                                        )}
                                    />
                                    <UserDetail
                                        data={'Address'}
                                        value={generateAddressString(
                                            profileDetails.addressLine1,
                                            profileDetails.addressLine2,
                                            profileDetails.townCity,
                                            profileDetails.stateProvinceName,
                                            profileDetails.postcode
                                        )}
                                        icon={addressIcon}
                                        onEdit={handleOpenModal(ModalType.UPDATE_ADDRESS)}
                                    />
                                    <div className="UserDetail">
                                        <img src={accountActivationIcon} alt="" />
                                        <div className="Detail">
                                            <p className="Detail Label">Account Activation</p>
                                            <p className="Detail Value">
                                                {format(
                                                    new Date(securityData.accountActivationDate),
                                                    'dd/MM/yyyy'
                                                )}
                                            </p>
                                            <Button
                                                className="Deactivate"
                                                onClick={handleOpenModal(
                                                    ModalType.PROFILE_DEACTIVATE_ACCOUNT
                                                )}
                                                color="second"
                                            >
                                                Deactivate Account
                                            </Button>
                                        </div>
                                    </div>
                                    {modal.modalType === ModalType.PROFILE_UPDATE_PASSWORD ? (
                                        <Modal removeCloseButton={true} filledHeader={true}>
                                            <UpdatePasswordModal
                                                isSMSAuth={isSMSAuth}
                                                isAppAuth={isAppAuth}
                                            />
                                        </Modal>
                                    ) : null}
                                    {modal.modalType === ModalType.PROFILE_UPDATE_PHONE_NUMBER ? (
                                        <Modal removeCloseButton={true} filledHeader={true}>
                                            <UpdatePhoneNumberModal
                                                isSMSAuth={isSMSAuth}
                                                isAppAuth={isAppAuth}
                                            />
                                        </Modal>
                                    ) : null}
                                    {modal.modalType === ModalType.PROFILE_UPDATE_EMAIL ? (
                                        <Modal removeCloseButton={true} filledHeader={true}>
                                            <UpdateEmailAddressModal
                                                isSMSAuth={isSMSAuth}
                                                isAppAuth={isAppAuth}
                                                reloadData={setReloadSecurityData}
                                            />
                                        </Modal>
                                    ) : null}
                                    {modal.modalType === ModalType.UPDATE_ADDRESS ? (
                                        <Modal removeCloseButton={true} filledHeader={true}>
                                            <UpdateAddressModal />
                                        </Modal>
                                    ) : null}
                                    {modal.modalType === ModalType.PROFILE_DEACTIVATE_ACCOUNT ? (
                                        <Modal title="Deactivate account">
                                            <DeactivateAccountModal
                                                isSMSAuth={isSMSAuth}
                                                isAppAuth={isAppAuth}
                                            />
                                        </Modal>
                                    ) : null}
                                </div>
                            </div>
                            <div className="SecurityPageSection">
                                <div className="Content MFA">
                                    <h2>Two-factor authentication</h2>
                                    <TFAItem
                                        active={!!isAppAuth}
                                        image={Img_Register_01}
                                        header={'Authenticator App'}
                                        button={
                                            isAppAuth ? 'Update App' : 'Enable App Authentication'
                                        }
                                        buttonPriority={isAppAuth ? 'primary' : 'quarternary'}
                                        onClick={handleOpenModal(ModalType.PROFILE_UPDATE_APP_TFA)}
                                        handleDisable={
                                            !!isAppAuth && !!isSMSAuth
                                                ? () =>
                                                      dispatch(
                                                          openModal({
                                                              modalType:
                                                                  ModalType.PROFILE_DISABLE_TFA_TYPE,
                                                              data: 'AuthenticatorApp',
                                                          })
                                                      )
                                                : null
                                        }
                                    />
                                    <TFAItem
                                        active={!!isSMSAuth}
                                        image={Img_Register_02}
                                        header={'SMS'}
                                        subHeader={
                                            securityData && securityData.phoneNumber
                                                ? `Phone number : ${securityData.phoneNumber}`
                                                : ''
                                        }
                                        button={isSMSAuth ? null : 'Enable SMS Authentication'}
                                        buttonPriority={isSMSAuth ? 'primary' : 'quarternary'}
                                        onClick={handleOpenModal(
                                            isSMSAuth
                                                ? ModalType.PROFILE_UPDATE_PHONE_NUMBER
                                                : ModalType.PROFILE_ENABLE_SMS_TFA
                                        )}
                                        handleDisable={
                                            !!isAppAuth && !!isSMSAuth
                                                ? () =>
                                                      dispatch(
                                                          openModal({
                                                              modalType:
                                                                  ModalType.PROFILE_DISABLE_TFA_TYPE,
                                                              data: 'SMS',
                                                          })
                                                      )
                                                : null
                                        }
                                    />
                                </div>
                                {modal.modalType === ModalType.PROFILE_UPDATE_APP_TFA ? (
                                    <Modal title="Update App Authentication">
                                        <UpdateAppTFAModal
                                            isSMSAuth={isSMSAuth}
                                            isAppAuth={isAppAuth}
                                        />
                                    </Modal>
                                ) : null}
                                {modal.modalType === ModalType.PROFILE_ENABLE_SMS_TFA ? (
                                    <Modal title="Enable SMS Authentication">
                                        <EnableSmsTFAModal
                                            isSMSAuth={isSMSAuth}
                                            isAppAuth={isAppAuth}
                                            phoneNumber={securityData?.phoneNumber}
                                        />
                                    </Modal>
                                ) : null}
                                {modal.modalType === ModalType.PROFILE_DISABLE_TFA_TYPE ? (
                                    <Modal title="Disable TFA Type">
                                        <DisableTFAModal />
                                    </Modal>
                                ) : null}
                            </div>
                            <div className="SecurityPageSection">
                                <h2>Last Sessions</h2>
                                <div className="TableContainer">
                                    <table className="LoginTable">
                                        <thead>
                                            <tr>
                                                <th>Signed in</th>
                                                <th>Browser</th>
                                                <th>IP address</th>
                                                <th>Location</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {securityData.loginList?.map((item, i) => (
                                                <tr key={`row-${i + 1}`}>
                                                    <td>
                                                        {format(
                                                            new Date(item.dateTime),
                                                            'dd/MM/yy hh:mm aa'
                                                        )}
                                                    </td>
                                                    <td className="Bold">{item.browser}</td>
                                                    <td className="Bold">{item.ip}</td>
                                                    <td>{item.locationInfo}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                    ) : (
                        <Spinner />
                    )}
                </div>
            </SecurityContext.Provider>
        </>
    );
};
