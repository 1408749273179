export enum AppPath {
    FORGOTTEN_PASSWORD = '/forgotten-password',
    SIGN_IN = '/sign-in',
    DASHBOARD = '/dashboard',
    PERSONAL = '/personal',
    REGISTER = '/register',
    RESET_PASSWORD = '/reset-password',
    INFO = '/info',
    CHECKING_DETAILS = '/checking-details',
}

// All pages that include the navigation menu should exist under the Dashboard
// path. This way we avoid unncessarily rerendering the navigation components when
// changing between these pages.
export enum DashboardPath {
    HOME = '/home',
    ACCOUNTS = '/accounts',
    PAYMENTS = '/payments',
    TRANSFERS = '/transfers',
    CARDS = '/cards',
    INFO = '/info',
    CRYPTO = '/crypto',
    PROFILE = '/view-profile',
    STABLECOIN = '/stablecoin',
    GUARANTEES = '/transactions',
    TRANSACTIONS = '/transfers',
}

export enum ProfilePath {
    PROFILE = '/view-profile',
    SERVICES = '/services',
    AFFLIATE = '/affliate',
    MESSAGES = '/messages',
    NOTFICATIONS = '/notfications',
    SECURITY = '/security',
}

// Contains keys passed to the languageresources endpoint as query params to get
// page translations.
export enum PageResourceKey {
    SIGN_IN = 'sign_in',
}

export enum ComponentResourceKey {
    FOOTER_LINKS = 'footer_links',
    SIDE_MENU_LINKS = 'side_menu_links',
    CUSTOMER_MENU_LINKS = 'customer_menu_links',
    LANGUAGE_LIST = 'language_list',
    USER_INFORMATION = 'user_information',
}

// This can be moved into global redux store if it needs to by dynamic.
const DOLLAR_FORMATTER = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const fiatCurrencyFormatter = (value: number, currency: string) => {
    if (currency === 'USD') return DOLLAR_FORMATTER.format(value);
    return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(value);
};

export const GLOBAL_CRYPTO_PRECISION = 9;

export const PORTAL_ID = 'portal';

export const DEFAULT_COLOR = '#283382';

export const FORM_REQUIRED_FIELD_TEXT = 'Fields denoted with * are required';
