import React from 'react';
import { ColumnDetail } from '@avamae/table';
import { Cell, CellOptions } from './Cell';
import { useDetectOverflow } from 'helpers/useDetectOverflow';
import { toCamelCase } from 'helpers/formatFormFieldNames';

import { RowConfig, RowProps, TableRow } from './FlexTableRow';

const SCROLLBAR_WIDTH = 17;

type MiddleTableProps<T = any> = {
    idColumn: string;
    columns: ColumnDetail<any>[];
    listData: T[] | undefined;
    rowHovered: number | null;
    handleMouseEnterRow(e: any, i: number): void;
    onRowClick(id: number): void;
    rowButton?(data: any): React.ReactNode;
    hiddenColumns?: string[];
    formatPrimitives(value: unknown): any;
    rowFormatter?(r: T): { [K in keyof T]: React.ReactNode };
    noRowHover?: boolean;
    selectedIds?: (string | number)[];
    rowCellOptions?: { [K in keyof T]: CellOptions };
    toggleColumnSort?: (key: keyof T, queryParams?: { [k: string]: any }) => void;
    queryParams?: { [k: string]: any };
    sortBy: { [k: string]: 'ASC' | 'DESC' };
    type?: string;
    rowConfig?: RowConfig;
    isTallRow?: (r: T) => boolean;
};

const MiddleTable: React.FC<MiddleTableProps> = ({
    idColumn,
    columns,
    listData,
    rowHovered,
    handleMouseEnterRow,
    onRowClick,
    rowButton,
    hiddenColumns = [],
    formatPrimitives,
    rowFormatter,
    noRowHover,
    selectedIds,
    rowCellOptions = {},
    toggleColumnSort,
    sortBy,
    type,
    queryParams,
    rowConfig,
    isTallRow,
}) => {
    const { ref, hasOverflow } = useDetectOverflow();

    const onClickHeaderCell = React.useCallback(
        (bSortable: boolean, columnKey: string | number | symbol) => () =>
            bSortable && toggleColumnSort && toggleColumnSort(String(columnKey), queryParams),
        [toggleColumnSort, queryParams]
    );

    const rowButtonComponent = React.useCallback(
        (d: any) => (rowButton ? () => rowButton(d) : undefined),
        [rowButton]
    );

    const allRowProps: Partial<RowProps<any>> = React.useMemo(
        () => ({
            columns,
            formatPrimitives,
            hiddenColumns,
            rowHovered,
            handleMouseEnterRow,
            formatRow: rowFormatter,
            noRowHover,
            rowCellOptions,
            idColumn,
            rowConfig,
            isTallRow,
        }),
        [
            columns,
            formatPrimitives,
            hiddenColumns,
            rowHovered,
            handleMouseEnterRow,
            rowFormatter,
            noRowHover,
            rowCellOptions,
            idColumn,
            rowConfig,
            isTallRow,
        ]
    );

    const handleScroll = (e: any) => {
        const leftTable = document.getElementById('FlexTable_LeftTable');
        const scrolledAmount = e.currentTarget.scrollHeight - e.currentTarget.scrollTop;
        const clientHeight = e.currentTarget.clientHeight;
        const fromBottom = Math.abs(scrolledAmount - clientHeight);

        if (fromBottom <= SCROLLBAR_WIDTH) {
            const leftTableAdjustment = SCROLLBAR_WIDTH - fromBottom;
            if (leftTable) {
                leftTable.style.marginBottom = `${leftTableAdjustment}px`; //width of horizontal scrollbar on middle table. Causing left table to misalign when scrolled to bottom
            }
        } else {
            if (leftTable && leftTable.style.marginBottom !== '0px') {
                leftTable.style.marginBottom = '0px';
            }
        }
    };

    return (
        <div
            id="MiddleTable_OverflowEnabled"
            ref={ref}
            className={hasOverflow ? 'MiddleTable Overflowing' : 'MiddleTable'}
            style={!listData || listData.length === 0 ? { overflow: 'hidden' } : {}}
        >
            <div className="Head">
                {columns
                    .filter((c) => !hiddenColumns?.includes(c.columnKey.toString().toLowerCase()))
                    .map((c, i) => {
                        const sortByValue = sortBy[String(c.columnKey)] ?? 'NONE';
                        const isSorting = sortByValue === 'ASC' || sortByValue === 'DESC';
                        const { bSortable } = c;
                        return (
                            <Cell
                                key={i}
                                sortable={bSortable}
                                onClick={onClickHeaderCell(bSortable, c.columnKey)}
                                options={
                                    rowCellOptions[toCamelCase(c.columnKey as string) as string]
                                }
                            >
                                <span
                                    className={bSortable && isSorting ? 'Sorting' : ''}
                                    title={c.labelValue}
                                >
                                    {c.labelValue}
                                </span>
                                {bSortable && (
                                    <i
                                        className={
                                            sortByValue === 'ASC'
                                                ? 'SortIcon ASC'
                                                : sortByValue === 'DESC'
                                                ? 'SortIcon DESC'
                                                : 'SortIcon'
                                        }
                                    />
                                )}
                            </Cell>
                        );
                    })}
                {rowButton && <Cell {...ROW_BUTTON_OPTIONS} />}
            </div>
            {listData && listData.length > 0 && (
                <div className="TableBody" onScroll={handleScroll}>
                    {listData?.map((d, i) => {
                        const rowProps: any = {
                            id: d[idColumn].toString(),
                            r: d,
                            index: i,
                            onRowClick,
                            rowButton: rowButtonComponent(d),
                            isSelected: selectedIds
                                ?.map((i) => i.toString())
                                .includes(d[idColumn].toString()),
                        };
                        return <TableRow key={i} {...allRowProps} {...rowProps} />;
                    })}
                </div>
            )}
        </div>
    );
};

export { MiddleTable };

const NO_SORT_ICON = { noSortIcon: true };

const ROW_BUTTON_OPTIONS = { options: { ...NO_SORT_ICON } };
