import React, { useCallback, useEffect, useState } from 'react';
import { Notifications } from './Notifications';
import { ProfileDetails } from './ProfileDetails';
import { Security } from './Security';
import { useDispatch } from 'react-redux';
import { signOutWithBackendCall } from 'reducers/auth';
import { format } from 'date-fns';
import { useTheme } from '@emotion/react';
import { ProfileApi } from './ProfileApi';
import { CountryCode, UserProfileDetails } from './models';
import { Toast } from 'helpers/toast';
import { profileContext } from './useProfileContext';

export enum ProfileSubPage {
    CREATE_BUSINESS_ACCOUNT = 'create-business-account',
    CREATE_PERSONAL_ACCOUNT = 'create-personal-account',
    SECURITY = 'security',
    PROFILE = 'profile',
    NOTIFICATIONS = 'notifications',
}

interface ProfilePageProps {
    subPage: ProfileSubPage;
}

const handleProfilePage = (subPage: ProfileSubPage) => {
    switch (subPage) {
        case ProfileSubPage.PROFILE:
            return <ProfileDetails />;
        case ProfileSubPage.SECURITY:
            return <Security />;
        case ProfileSubPage.NOTIFICATIONS:
            return <Notifications />;
        default:
            return <div>An error has occurred. Please contact support.</div>;
    }
};

export const ProfilePage: React.FC<ProfilePageProps> = ({ subPage }) => {
    const [profile, setProfile] = useState<UserProfileDetails | null>(null);
    const [countryCodes, setCountryCodes] = useState<CountryCode[] | null>(null);

    const getProfile = useCallback(async () => {
        const response = await ProfileApi.GetUserDetails();
        if (ProfileApi.isSuccessData(response)) {
            const { countries, ...rest } = response.data;
            setProfile(rest);
            setCountryCodes(countries);
        } else {
            Toast.openGenericErrorToast();
        }
    }, []);

    useEffect(() => {
        getProfile();
    }, [getProfile]);
    return (
        <profileContext.Provider
            value={{ profileDetails: profile, countryCodes: countryCodes ?? [] }}
        >
            <ProfileHeader profileDetails={profile} />
            <div className="ProfilePage MinusHeader">{handleProfilePage(subPage)}</div>
        </profileContext.Provider>
    );
};

const ProfileHeader = ({ profileDetails }: { profileDetails: UserProfileDetails | null }) => {
    const dispatch = useDispatch();
    const [imgFallback, setImgFallback] = useState(false);

    const theme = useTheme();

    return (
        <div className="ProfileHeader" style={{ backgroundColor: theme.colors.fifth }}>
            <button className="LogoutLink" onClick={() => dispatch(signOutWithBackendCall())}>
                Logout
            </button>

            <h3>
                {profileDetails?.firstName} {profileDetails?.lastName}
            </h3>
            <h4>
                {profileDetails?.nationality}
                {profileDetails?.nationality && profileDetails.dateOfBirth && ' - '}
                {profileDetails?.dateOfBirth && format(new Date(profileDetails.dateOfBirth), 'P')}
            </h4>
        </div>
    );
};
