import EmptyList from 'assets/ibanera/Img_List_Empty.png';
import { BitLineTable } from 'components/bitLineTable/BitLineTable';
import Button from 'components/button/Button';
import { FlexTable } from 'components/flexTable';
import { CellOptions } from 'components/flexTable/Cell';
import {
    CryptoReferenceCell,
    DateCell,
    EmptyCell,
    NumberAndTickerCell,
    StatusCell,
    StringCell,
} from 'components/flexTable/CustomCells';
import { TableProps } from 'components/flexTable/FlexTable';
import {
    completeUIUpdate,
    NotificationIdentifier,
    selectUIUpdate,
} from 'components/notifications/notificationUIUpdateReducer';
import { notUndef } from 'helpers/notUndef';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectFiatCurrency } from 'reducers/availableAssets';
import { PricesMap, selectAllCryptoPrices } from 'reducers/cryptoPrices';
import { ModalType, openModal } from 'reducers/modal';

export type TransactionsRow = {
    customerAssetAccountsTransactions__Id: number;
    assets__Name: string;
    assets__Code: string;
    customerAssetAccountsTransactions__TransactionsCode: string;
    customerAssetAccountsTransactions__Date: string;
    transactionTypes__TypeDescription: string;
    customerAssetAccountsTransactions__Amount: number;
    feeTransactions__Amount: number;
    customerAssetAccountsTransactions__bPending: boolean;
    customerAssetAccountsTransactions__bVoid: boolean;
    customerAssetAccountsTransactions__Status: 'Complete' | 'Pending' | 'Void' | 'Frozen';
    guarantees__GuaranteesCode?: string;
    guarantees__GuaranteeAmount?: number;
    guarantees__Fee?: number;
    guaranteeFiatAssets__Code?: string;
};

const rowFormatter =
    (isLoading: boolean, liveData: PricesMap, fiatCurrencyCode: string) =>
    (row: TransactionsRow): { [K in keyof typeof row]: React.ReactNode } => {
        let formattedRow: { [K in keyof typeof row]: React.ReactNode } = { ...row };

        if (isLoading) {
            const formattedRowKeys = Object.keys(formattedRow);
            formattedRowKeys.forEach(
                (key) => (formattedRow[key as keyof typeof row] = <EmptyCell />)
            );
            return formattedRow;
        }

        if (
            row.customerAssetAccountsTransactions__TransactionsCode &&
            row.assets__Name &&
            row.assets__Code
        ) {
            formattedRow = {
                ...formattedRow,
                customerAssetAccountsTransactions__TransactionsCode: (
                    <CryptoReferenceCell
                        name={row.assets__Name}
                        ticker={row.assets__Code}
                        reference={row.customerAssetAccountsTransactions__TransactionsCode}
                    />
                ),
            };
        }

        if (row.customerAssetAccountsTransactions__Date) {
            formattedRow = {
                ...formattedRow,
                customerAssetAccountsTransactions__Date: (
                    <DateCell date={row.customerAssetAccountsTransactions__Date} />
                ),
            };
        }

        if (notUndef(row.transactionTypes__TypeDescription)) {
            formattedRow = {
                ...formattedRow,
                transactionTypes__TypeDescription: (
                    <StringCell
                        value={`${row.transactionTypes__TypeDescription!}${
                            row.guarantees__GuaranteesCode
                                ? ` - ${row.guarantees__GuaranteesCode}`
                                : ''
                        }`}
                    />
                ),
            };
        }

        if (
            row.customerAssetAccountsTransactions__Amount ||
            row.customerAssetAccountsTransactions__Amount === 0
        ) {
            formattedRow = {
                ...formattedRow,
                customerAssetAccountsTransactions__Amount:
                    row.assets__Code &&
                    typeof notUndef(row.customerAssetAccountsTransactions__Amount) ? (
                        <NumberAndTickerCell
                            number={row.customerAssetAccountsTransactions__Amount}
                            ticker={row.assets__Code}
                        />
                    ) : (
                        row.customerAssetAccountsTransactions__Amount
                    ),
            };
        }

        formattedRow.feeTransactions__Amount =
            notUndef(row.feeTransactions__Amount) && row.assets__Code ? (
                <NumberAndTickerCell
                    number={row.feeTransactions__Amount}
                    ticker={row.assets__Code}
                />
            ) : (
                row.feeTransactions__Amount
            );

        if (row.customerAssetAccountsTransactions__Status) {
            formattedRow = {
                ...formattedRow,
                customerAssetAccountsTransactions__Status: (
                    <StatusCell status={row.customerAssetAccountsTransactions__Status} />
                ),
            };
        }
        if (!row.guarantees__GuaranteeAmount && liveData && liveData[fiatCurrencyCode]) {
            formattedRow = {
                ...formattedRow,
                guarantees__GuaranteeAmount: (
                    <NumberAndTickerCell
                        number={
                            row.customerAssetAccountsTransactions__Amount *
                            liveData[fiatCurrencyCode]?.[row.assets__Code]
                        }
                        ticker={fiatCurrencyCode}
                        isFiat
                    />
                ),
            };
        }
        if (row.guarantees__GuaranteeAmount) {
            formattedRow = {
                ...formattedRow,
                guarantees__GuaranteeAmount: (
                    <NumberAndTickerCell
                        number={row.guarantees__GuaranteeAmount}
                        ticker={row.guaranteeFiatAssets__Code ?? 'USD'}
                        isFiat
                    />
                ),
            };
        }
        console.log({ formattedRow });
        return formattedRow;
    };

export const TRANSACTIONS_TABLE_ID_COLUMN = 'customerAssetAccountsTransactions__Id';

export const TransactionsTable: React.FC<
    TableProps<TransactionsRow> & {
        isNewBitLineDesign?: boolean; // TODO - feature flagging for now, remove when complete
    }
> = (props) => {
    const fiatCurrencyCode = useSelector(selectFiatCurrency);
    const allCryptoPrices = useSelector(selectAllCryptoPrices);
    const dispatch = useDispatch();
    const update = useSelector(selectUIUpdate);
    const formatter = rowFormatter(
        !!props.table?.loading,
        allCryptoPrices,
        fiatCurrencyCode ?? 'USD'
    );
    const columnOptions: { [key in keyof TransactionsRow]?: CellOptions } = useMemo(
        () => ({
            customerAssetAccountsTransactions__TransactionsCode: { width: 'Large' },
            customerAssetAccountsTransactions__Date: { width: 'SemiLarge' },
            transactionTypes__TypeDescription: { width: 'Large' },
            customerAssetAccountsTransactions__Amount: { width: 'SemiLarge' },
        }),
        []
    );

    // Listens for notifications UI messages.
    useEffect(() => {
        if (update) {
            switch (update.pushType) {
                case NotificationIdentifier.RELOAD_TRANSACTIONS_TABLE:
                    props.table?.reload();
                    dispatch(completeUIUpdate());
            }
        }
    }, [update, props.table, dispatch]);

    return (
        <div className="GuaranteesTable TransactionsTable">
            {props.isNewBitLineDesign ? (
                <BitLineTable
                    {...props}
                    headerColumns={[
                        'customerAssetAccountsTransactions__TransactionsCode',
                        'customerAssetAccountsTransactions__Date',
                        'customerAssetAccountsTransactions__Amount',
                    ]}
                    rowFormatter={formatter}
                    emptyListComponent={EmptyTableNotifier}
                    isNotCollapsible={true}
                />
            ) : (
                <FlexTable
                    {...props}
                    rowFormatter={formatter}
                    emptyListComponent={EmptyTableNotifier}
                    // Casting to any as typing don't seem to allow the optional values
                    // used in
                    rowCellOptions={columnOptions as any}
                    noRowHover
                />
            )}
        </div>
    );
};

const EmptyTableNotifier: React.FC = () => {
    const dispatch = useDispatch();

    const handleDeposit = () => {
        dispatch(openModal({ modalType: ModalType.DEPOSIT_CRYPTO }));
    };

    return (
        <div className="EmptyTableNotifier">
            <img src={EmptyList} alt="Table is empty" />
            <h3>You haven't made any transactions</h3>
            <Button onClick={handleDeposit} priority="secondary-2" className="EmptyTableButton">
                Deposit Crypto
            </Button>
        </div>
    );
};
