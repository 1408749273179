import { useFocus } from 'helpers/useFocus';
import React, { Children } from 'react';
import FormTextField, { FormTextFieldProps } from './FormTextField';
import { TFAInputMask } from './TFAField';
import { useField, useFormikContext } from 'formik';

type ValidationCodeFieldProps = FormTextFieldProps & {
    value: string;
    backgroundColor?: 'white' | 'grey';
    holdFocus?: boolean;
};

export const ValidationCodeField: React.FC<ValidationCodeFieldProps> = ({
    value,
    backgroundColor = 'grey',
    className = '',
    holdFocus,
    children,
    ...props
}) => {
    const [inputRef, setFocus] = useFocus<HTMLInputElement>();
    const handleBlur = () => {
        holdFocus && setFocus();
    };
    const [field, meta, helpers] = useField(props.field);
    console.log({ meta });
    return (
        <div className="TFAInputContainer">
            {children}
            <FormTextField
                maxLength={6}
                inputMode="numeric"
                {...props}
                className={`TFAHiddenField ${className}`}
                ref={inputRef}
                onBlur={handleBlur}
            />
            <TFAInputMask
                value={value}
                className={`InputMask ${backgroundColor} ${meta.error ? 'ErrorInput' : ''}`}
            />
        </div>
    );
};
