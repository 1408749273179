import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './styles/Main.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { createEpicMiddleware } from 'redux-observable';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { rootEpic, rootReducer } from './reducers/rootReducer';

const epicMiddleware = createEpicMiddleware();

const configureStore = () => {
    const store = createStore(
        rootReducer,
        composeWithDevTools(
            applyMiddleware(epicMiddleware, (store) => (next) => (action) => {
                // https://stackoverflow.com/a/53309408/14015703
                // Including this custom middleware to prevent errors in observable
                // streams bubbling up to the root epic, which can cause it to
                // terminate.
                try {
                    next(action);
                } catch (e) {
                    // As a very last resort we can call window.location.reload
                    // if any uncaught errors make their way up to the root epic.
                    // For now we just log so we can see what's throwing the error.
                    console.error(e);
                    setTimeout(() => {
                        throw e;
                    });
                }
            })
        )
    );
    epicMiddleware.run(rootEpic);
    return store;
};

export const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
