import React from 'react';
import { ColumnDetail } from '@avamae/table';
import { Cell, CellOptions, CellType } from './Cell';
import { toCamelCase } from 'helpers/formatFormFieldNames';
import { RowConfig, RowProps, TableRow } from './FlexTableRow';

type RightTableProps<T = any> = {
    idColumn: string;
    columns: ColumnDetail<any>[];
    listData: T[] | undefined;
    rowHovered: number | null;
    handleMouseEnterRow(e: any, i: number): void;
    onRowClick(id: string | number): void;
    rowButton?(data: any): React.ReactNode;
    rowOptionComponent?(data: any): React.ReactNode;
    hiddenColumns?: string[];
    toggleColumnSort?: (key: keyof T, queryParams?: { [k: string]: any }) => void;
    queryParams?: { [k: string]: any };
    formatPrimitives(value: unknown): any;
    rowFormatter?(r: T): { [K in keyof T]: React.ReactNode };
    noRowHover?: boolean;
    columnCount: number;
    buttonInMiddleTable?: boolean;
    rowCellOptions?: { [K in keyof T]: CellOptions };
    sortBy: { [k in keyof T]: 'ASC' | 'DESC' };
    rowConfig?: RowConfig;
    selectedIds: (string | number)[] | undefined;
    isTallRow?: (r: T) => boolean;
};

const RightTable: React.FC<RightTableProps> = ({
    idColumn,
    columns,
    listData,
    rowHovered,
    handleMouseEnterRow,
    onRowClick,
    rowButton,
    rowOptionComponent,
    hiddenColumns = [],
    toggleColumnSort,
    formatPrimitives,
    rowFormatter,
    noRowHover,
    columnCount,
    buttonInMiddleTable,
    rowCellOptions = {},
    sortBy,
    queryParams,
    rowConfig,
    selectedIds,
    isTallRow,
}) => {
    const onClickCell = React.useCallback(
        (bSortable: boolean, columnKey: string | number | symbol) => () =>
            bSortable && toggleColumnSort && toggleColumnSort(String(columnKey), queryParams),
        [toggleColumnSort, queryParams]
    );

    const rowButtonData = rowButton && listData ? listData.map((data) => rowButton(data)) : [];
    const rowButtonComponent = React.useCallback(
        (i: number) => (rowButton ? () => rowButtonData[i] : undefined),
        [rowButton, rowButtonData]
    );

    const rowOptionData =
        rowOptionComponent && listData ? listData.map((data) => rowOptionComponent(data)) : [];

    const rowOption = React.useCallback(
        (i: number) => (rowOptionComponent ? () => rowOptionData[i] : undefined),
        [rowOptionData, rowOptionComponent]
    );

    if (
        (columnCount <= 0 && !rowButton && !rowOptionComponent) ||
        (columnCount <= 0 && rowButton && buttonInMiddleTable)
    ) {
        return null;
    }

    const selectedColumns = columns
        .filter((c) => !hiddenColumns?.includes(c.columnKey.toString().toLowerCase()))
        .slice(columns.length - columnCount);

    const processedRowData = listData?.map((data) => {
        let rowData = {} as any;
        selectedColumns.forEach((col) => {
            rowData = {
                ...rowData,
                [toCamelCase(col.columnKey as string)]:
                    data[toCamelCase(col.columnKey as string)] ?? data[col.columnKey],
            };
        });
        const id = data[idColumn];

        if (!id) {
            throw new Error(
                `Could not find ID for row - check that the name for the id column [${idColumn}] is correct`
            );
        }
        return { id: id.toString(), ...rowData };
    });

    return (
        <div className="RightTable">
            {processedRowData && (
                <div className="Head">
                    {selectedColumns.map((c, i) => {
                        const sortByValue = sortBy[String(c.columnKey)] ?? 'NONE';

                        const isSorting = sortByValue === 'ASC' || sortByValue === 'DESC';
                        const { bSortable } = c;

                        return (
                            <Cell
                                key={i}
                                sortable={bSortable}
                                onClick={onClickCell(bSortable, c.columnKey)}
                                options={
                                    rowCellOptions[toCamelCase(c.columnKey as string) as string]
                                }
                            >
                                <span
                                    className={bSortable && isSorting ? 'Sorting' : ''}
                                    title={c.labelValue}
                                >
                                    {c.labelValue}
                                </span>
                                {bSortable && (
                                    <i
                                        className={
                                            sortByValue === 'ASC'
                                                ? 'SortIcon ASC'
                                                : sortByValue === 'DESC'
                                                ? 'SortIcon DESC'
                                                : 'SortIcon'
                                        }
                                    />
                                )}
                            </Cell>
                        );
                    })}
                    {rowButton && <Cell {...BUTTON_CELL} />}
                    {rowOptionComponent && <Cell {...ROW_OPTION_CELL} />}
                </div>
            )}
            {processedRowData && processedRowData.length > 0 && (
                <div className="TableBody">
                    {processedRowData?.map((d, i) => {
                        const rowProps: RowProps<any> = {
                            id: d.id,
                            r: d,
                            index: i,
                            idColumn,
                            columns,
                            formatPrimitives,
                            hiddenColumns: hiddenColumns,
                            rowHovered,
                            handleMouseEnterRow,
                            onRowClick,
                            rowButton: rowButtonComponent(i),
                            formatRow: rowFormatter,
                            noRowHover,
                            rowOptionComponent: rowOption(i),
                            rowCellOptions,
                            rowConfig,
                            isSelected: selectedIds
                                ?.map((i) => i.toString())
                                .includes(d.id?.toString()),
                            isTallRow,
                        };
                        return <TableRow key={i} {...rowProps} />;
                    })}
                </div>
            )}
        </div>
    );
};

export { RightTable };
const NO_SORT_ICON = { noSortIcon: true };

const BUTTON_CELL = {
    options: { ...NO_SORT_ICON, cellType: 'buttonCell' as CellType },
};
const ROW_OPTION_CELL = {
    options: { ...NO_SORT_ICON, cellType: 'rowOptionCell' as CellType },
};
