type customWindow = typeof window & { env: { BASE_URL: string } };
const envUrl = (window as customWindow).env.BASE_URL;
export const BASE_URL = envUrl;

export const STOPLIGHT_BASE_URL =
    'https://stoplight.io/mocks/avamae/fintech-cloud-web-bitline-cutsomer-v1';

export const endpoints = {
    auth: {
        login: `${BASE_URL}/api/v1/publicrole/authmodule/login`,
        tfaLogin: `${BASE_URL}/api/v1/publicrole/authmodule/tfalogin`,
        requestSmsTfaCode: `${BASE_URL}/api/v1/publicrole/authmodule/requestsmstfacode`,
        requestTotpCode: `${BASE_URL}/api/v1/publicrole/authmodule/requesttotpqrcode`,
        verifyEmailAddress: `${BASE_URL}/api/v1/publicrole/authmodule/verifyemailaddress`,
        enableTfa: `${BASE_URL}/api/v1/publicrole/authmodule/enabletfa`,
        refreshAccessToken: `${BASE_URL}/api/v1/publicrole/authmodule/refresh`,
    },
    licenseesmodule: {
        licenseesbranding: `${BASE_URL}/api/v1/publicrole/licenseesmodule/licenseesbranding`,
    },
    languagemodule: {
        languages: `${BASE_URL}/api/v1/publicrole/languagesmodule/languages`,
        languageresources: `${BASE_URL}/api/v1/publicrole/languagesmodule/languageresouces`,
        setlanguage: `${BASE_URL}/api/v1/publicrole/languagesmodule/setlanguage`,
    },
    navigationmodule: {
        footerlinks: `${BASE_URL}/api/v1/publicrole/navigationmodule/footerlinks`,
        customerNavigationLinks: `${BASE_URL}/api/v1/customerrole/navigationmodule/customernavigationlinks`,
    },
    registration: {
        customerUser: `${BASE_URL}/api/v1/publicrole/registrationmodule/registercustomeruser`,
        validateStepOne: `${BASE_URL}/api/v1/publicrole/registrationmodule/validatebusinesscustomerregistrationstep1`,
    },
    profilemodule: {
        updatePassword: `${BASE_URL}/api/v1/customerrole/profilemodule/updatepassword`,
        updatePhoneNumber: `${BASE_URL}/api/v1/customerrole/profilemodule/updatephonenumber`,
        verifyPhoneNumber: `${BASE_URL}/api/v1/customerrole/profilemodule/updatephonenumberconfirm`,
        updatePhoneNumberConfirm: `${BASE_URL}/api/v1/customerrole/profilemodule/updatephonenumberconfirm`,
        updateEmailAddress: `${BASE_URL}/api/v1/customerrole/profilemodule/updateemailaddress`,
        updateEmailAddressConfirm: `${BASE_URL}/api/v1/customerrole/profilemodule/updateemailaddressconfirm`,
        requestSmsTfaCode: `${BASE_URL}/api/v1/customerrole/profilemodule/requestsmstfacode`,
        logout: `${BASE_URL}/api/v1/customerrole/profilemodule/logout`,
        enableSmsTfa: `${BASE_URL}/api/v1/customerrole/profilemodule/enablesmstfa`,
        updateAppTfa: `${BASE_URL}/api/v1/customerrole/profilemodule/updateapptfa`,
        disableTfa: `${BASE_URL}/api/v1/customerrole/profilemodule/disabletfa`,
        updateTfaConfirm: `${BASE_URL}/api/v1/customerrole/profilemodule/updatetfaconfirm`,
        userinfo: `${BASE_URL}/api/v1/customerrole/profilemodule/userinfo`,
        switchcustomeraccounttype: `${BASE_URL}/api/v1/customerrole/profilemodule/switchcustomeraccounttype`,
        signout: `${BASE_URL}/api/v1/customerrole/profilemodule/logout`,
        registerlinkedcustomer: `${BASE_URL}/api/v1/customerrole/profilemodule/registerlinkedcustomer`,
        usersecurityinfo: `${BASE_URL}/api/v1/customerrole/profilemodule/usersecurityinfo`,
        disableAccount: `${BASE_URL}/api/v1/customerrole/profilemodule/disableaccount`,
        userProfileDetails: `${BASE_URL}/api/v1/customerrole/profilemodule/userprofiledetails`,
        getStateProvinceOptions: `${BASE_URL}/api/v1/customerrole/profilemodule/stateprovincelist`,
    },
    jumiomodule: {
        verifyIdentity: `${BASE_URL}/api/v1/customerrole/jumiomodule/verifyidentity`,
        verificationStatus: `${BASE_URL}/api/v1/customerrole/jumiomodule/verificationstatus`,
        idIframeComplete: `${BASE_URL}/api/v1/customerrole/jumiomodule/idsubmit`,
        documentIframeComplete: `${BASE_URL}/api/v1/customerrole/jumiomodule/addressdocumentsubmit`,
        requestManualVerification: `${BASE_URL}/api/v1/customerrole/jumiomodule/requestmanualverification`,
        reverifyAddress: `${BASE_URL}/api/v1/customerrole/jumiomodule/requestmanualverification`,
    },
    cmsmodule: {
        pagecontent: `${BASE_URL}/api/v1/publicrole/cmsmodule/pagecontent`,
    },
    forgottenpasswordmodule: {
        resetpasswordrequest: `${BASE_URL}/api/v1/publicrole/forgottenpasswordmodule/requestpasswordreset`,
        resetpassword: `${BASE_URL}/api/v1/publicrole/forgottenpasswordmodule/resetpassword`,
    },
    cryptosmodule: {
        marketList: `${BASE_URL}/api/v1/customerrole/cryptomodule/marketlist`,
        portfolioList: `${BASE_URL}/api/v1/customerrole/cryptomodule/portfoliolist`,
        historyList: `${BASE_URL}/api/v1/customerrole/cryptomodule/historylist`,
        assetPairs: `${BASE_URL}/api/v1/customerrole/cryptomodule/getassetpairs`,
        getAddress: `${BASE_URL}/api/v1/customerrole/cryptomodule/getdepositaddress`,
        getAvailableAssets: `${BASE_URL}/api/v1/customerrole/cryptomodule/getavailableassets`,
        getUsersCurrentHoldings: `${BASE_URL}/api/v1/customerrole/cryptomodule/getassetbalancelist`,
        getCurrentPrices: `${BASE_URL}/api/v1/customerrole/cryptomodule/getcurrentprices`,
        withdraw: `${BASE_URL}/api/v1/customerrole/cryptomodule/withdraw`,
        estimateWithdrawFee: `${BASE_URL}/api/v1/customerrole/cryptomodule/estimatewithdrawfee`,
    },
    stablecoinmodule: {
        portfolioList: `${BASE_URL}/api/v1/customerrole/stablecoinmodule/portfoliolist`,
        historyList: `${BASE_URL}/api/v1/customerrole/stablecoinmodule/historylist`,
        marketList: `${BASE_URL}/api/v1/customerrole/stablecoinmodule/marketlist`,
    },
    exchangemodule: {
        marketList: `${BASE_URL}/api/v1/customerrole/exchangemodule/marketlist`,
        portfolioList: `${BASE_URL}/api/v1/customerrole/exchangemodule/portfoliolist`,
        historyList: `${BASE_URL}/api/v1/customerrole/exchangemodule/historylist`,
    },
    transfersmodule: {
        list: `${BASE_URL}/api/v1/customerrole/transfersmodule/list`,
    },
    guaranteemodule: {
        createGuarantee: `${BASE_URL}/api/v1/customerrole/transactionsmodule/create`,
        topUp: `${BASE_URL}/api/v1/customerrole/transactionsmodule/topup`,
        partialRelease: `${BASE_URL}/api/v1/customerrole/transactionsmodule/partialrelease`,
        deleteGuarantee: `${BASE_URL}/api/v1/customerrole/transactionsmodule/delete`,
        getPartialReleaseAmount: `${BASE_URL}/api/v1/customerrole/transactionsmodule/getpartialreleaseamount`,
        getTopUpAmount: `${BASE_URL}/api/v1/customerrole/transactionsmodule/gettopupamount`,
        getActivateGuaranteeQRCode: `${BASE_URL}/api/v1/customerrole/transactionsmodule/getactivationcode`,
        listAvailableVenues: `${BASE_URL}/api/v1/customerrole/transactionsmodule/listavailablevenues`,
        colatteralUnlock: `${BASE_URL}/api/v1/customerrole/transactionsmodule/collateralunlock`,
        unlockGuarantee: `${BASE_URL}/api/v1/customerrole/transactionsmodule/getunlockcode`,
        getTerms: `${BASE_URL}/api/v1/customerrole/transactionsmodule/getterms`,
        list: `${BASE_URL}/api/v1/customerrole/transactionsmodule/list`,
        getAvailableVenues: `${BASE_URL}/api/v1/customerrole/transactionsmodule/getavailablevenues`, // deprecated
    },
    notificationsmodule: {
        userStack: `${BASE_URL}/api/notificationsmodule/notifications/userstack`,
        acknowledgeSingle: `${BASE_URL}/api/notificationsmodule/notifications/acknowledgesingle`,
        getUserPreferences: `${BASE_URL}/api/v1/customerrole/notificationsauxmodule/getforuser`,
        updateSinglePreference: `${BASE_URL}/api/notificationsmodule/userpreferences/updatesingle`,
        signalR: `${BASE_URL}/nm-hub`,
    },
    dashboardmodule: {
        transactionList: `${BASE_URL}/api/v1/customerrole/dashboardmodule/transactionslist`,
    },
};
