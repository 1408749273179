import instance, { ApiResponse } from 'api';
import { endpoints } from 'endpoints.config';

export type TopUpAmountResponse = {
    amount: number;
    ltvLiquidationPercent: number;
    tltv: number;
};

export class TopUpModalApi {
    public static getTopUpAmount(guaranteeId: number) {
        return instance.get<ApiResponse<TopUpAmountResponse>>(
            endpoints.guaranteemodule.getTopUpAmount,
            { params: { guaranteesId: guaranteeId }, withCredentials: false }
        );
    }

    public static postTopUpRequest(guaranteeId: number, amount: number) {
        return instance.post<ApiResponse<{}>>(endpoints.guaranteemodule.topUp, {
            guaranteesId: guaranteeId,
            cost: amount,
        });
    }
}
