import { Options } from 'react-lottie';

export const defaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};
export type AnimationJson = Options['animationData'];
