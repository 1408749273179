import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCryptoPrice } from 'reducers/cryptoPrices';

export const useCryptoPriceCacher = (
    assetId: string | null,
    fiatCurrencyCode: string = 'USD',
    cacheTimeMs: number = 50000
) => {
    const price = useSelector(selectCryptoPrice(assetId, fiatCurrencyCode));
    // We store the price in a ref, which allows us to escape the React rerender cycle
    // when price changes, and only update state only when the interval completes.
    const priceRef = useRef<number | null>(price);
    const [updateCachedValue, setUpdateCachedValue] = useState(false);
    const [cachedPriceInterval, setCachedPriceInterval] = useState<number | null>(null);
    // Have to initialize to null and then set value once price is set.
    const [cachedPrice, setCachedPrice] = useState<null | number>(null);
    const [secondsRemaining, setSecondsRemaining] = useState<number | null>(null);
    const [secondsRemainingInterval, setSecondsRemainingInterval] = useState<number | null>(null);

    if (!cachedPrice && price) {
        setCachedPrice(price);
    }

    useEffect(() => {
        if (updateCachedValue) {
            setCachedPrice(priceRef.current);
            setUpdateCachedValue(false);
        }
    }, [updateCachedValue]);

    useEffect(() => {
        if (!cachedPriceInterval && !secondsRemainingInterval) {
            setSecondsRemaining(cacheTimeMs / 1000);
            const priceInterval = window.setInterval(() => {
                priceRef.current = price;
                setUpdateCachedValue(true);
                setSecondsRemaining(cacheTimeMs / 1000);
            }, cacheTimeMs);

            const secondsInterval = window.setInterval(() => {
                setSecondsRemaining((prevValue) => {
                    if (prevValue !== null) {
                        return Math.max(0, prevValue - 1);
                    }
                    return null;
                });
            }, 1000);

            setCachedPriceInterval(priceInterval);
            setSecondsRemainingInterval(secondsInterval);
        }
    }, [cacheTimeMs, cachedPriceInterval, price, secondsRemainingInterval]);

    // TODO: Would be nice to have the timer reset here also.
    const getLatestPrice = useCallback(() => {
        setUpdateCachedValue(true);
    }, []);

    useEffect(() => {
        return () => {
            if (cachedPriceInterval) {
                window.clearInterval(cachedPriceInterval);
                setCachedPriceInterval(null);
            }
            if (secondsRemainingInterval) {
                window.clearInterval(secondsRemainingInterval);
                setSecondsRemainingInterval(null);
            }
        };
    }, [cachedPriceInterval, secondsRemainingInterval]);

    return [cachedPrice, secondsRemaining, getLatestPrice] as const;
};
