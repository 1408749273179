import instance, { ApiResponse, BaseApi } from 'api';
import { endpoints } from 'endpoints.config';

export type WithdrawRequest = {
    amount: number;
    tfaCode: string;
    tfaType: 'SMS' | 'AuthenticatorApp';
    walletAddress: string;
    fireblocksAssetId: string;
};

export type FeeEstimateRequest = {
    fireblocksAssetId: string;
    amount?: number;
    walletAddress?: string;
};

type FeeEstimateResponse = {
    feeEstimate: number;
};

export class WithdrawModalApi extends BaseApi {
    public static submitWithdrawRequest = (request: WithdrawRequest) => {
        return instance.post<ApiResponse<{}>>(endpoints.cryptosmodule.withdraw, request);
    };

    public static getFeeEstimate = (request: FeeEstimateRequest) => {
        return WithdrawModalApi.makeRequest<FeeEstimateResponse>({
            url: endpoints.cryptosmodule.estimateWithdrawFee,
            method: 'GET',
            params: request,
        });
    };
}
