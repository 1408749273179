import instance, { ApiResponse, BaseApi } from 'api';
import { endpoints } from 'endpoints.config';
import { MinMax } from 'types/shared';

type AvailableBalance = { ticker: string; amount: number };
type AvailableBalances = AvailableBalance[];

export type GuaranteeTerms = {
    guaranteeLimits: MinMax;
    termDays: number;
    ltvPercent: number;
    feePercent: number;
    ltvLiquidationPercent: number;
    additionalTerms: {
        feeRebates:
            | {
                  closeWithinDays: number;
                  rebatePercent: number;
              }[]
            | null;
        extraFees:
            | {
                  type: 'Early Settlement Threshold';
                  amount: '5% of transaction';
              }[]
            | null;
    };
};

export type CreateGuaranteeRequest = {
    assetsId: number;
    venueId: number;
    startDate: string;
    amount: number;
    cost: number;
};

export type CreateGuaranteeSuccessResponseData = {
    amount: number;
    amountAssetsCode: string;
    cost: number;
    costAssetsCode: string;
    guaranteesId: number;
    guaranteesCode: string;
    venueName: string;
};

export class CreateGuaranteeApi extends BaseApi {
    public static getGuaranteeTerms(currency: string, venueId: number) {
        return instance.get<ApiResponse<GuaranteeTerms>>(endpoints.guaranteemodule.getTerms, {
            params: { currency, venue: venueId },
        });
    }

    public static createGuarantee(request: CreateGuaranteeRequest) {
        return this.makeRequest<CreateGuaranteeSuccessResponseData>({
            url: endpoints.guaranteemodule.createGuarantee,
            data: request,
            method: 'POST',
        });
    }
}
