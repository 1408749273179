import { Navigation } from 'components/navigation/Navigation';
import { useCreateThemeStyle } from 'helpers/useCreateThemeStyles';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectSignedIn } from '../../reducers/auth';
import Private from '../private/Private';
/** @jsxImportSource @emotion/react */

type Props = {
    isPublic?: boolean;
    className?: string;
};

const Page: React.FC<Props> = ({ children, className = '', isPublic = false }) => {
    const isSignedIn = useSelector(selectSignedIn);

    if (isPublic) {
        return (
            <div className={`Page ${className}`}>
                <Navigation>{children}</Navigation>
            </div>
        );
    }
    return (
        <Private isSignedIn={isSignedIn}>
            <div className={`Page ${className}`}>
                <Navigation>{children}</Navigation>
            </div>
        </Private>
    );
};

export default Page;
