import SearchIcon from 'assets/ibanera/search-icon.svg';

type TableSearchInputProps = React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
>;

export const TableSearchInput: React.FC<TableSearchInputProps> = (props) => (
    <div className="MarketTableInput">
        <div className="FormBox FormSideBySide">
            <div className="FormLabel">
                <label></label>
            </div>
            <div className="FormField">
                <input {...props} className="EditBox" />
            </div>
            <img src={SearchIcon} alt="icon" />
        </div>
    </div>
);
