import React from 'react';
import classNames from 'classnames';
import { css, useTheme } from '@emotion/react';
/** @jsxImportSource @emotion/react */

type ButtonVariety = 'full' | 'small' | 'blue' | 'slim' | 'red' | '';
// primary = background color and white text
// secondary = transparent background with border
// tertiary = no border and no background, just text
export type ButtonPriority = 'primary' | 'secondary' | 'secondary-2' | 'tertiary' | 'quarternary';

type ButtonType = JSX.IntrinsicElements['button'] & {
    variety?: ButtonVariety;
    disabled?: boolean;
    onClick?: () => void;
    type?: string;
    iconClassName?: string;
    priority?: ButtonPriority;
    icon?: string;
    color?: 'first' | 'second' | 'grey';
    minWidth?: boolean;
};

function varietyToClassname(v: ButtonVariety): string {
    switch (v) {
        case 'full':
            return 'BtnFull';
        case 'small':
            return 'BtnSmall';
        case 'slim':
            return 'BtnSlim';
        case 'red':
            return 'BtnRed';
        default:
            return '';
    }
}

const Button: React.FC<ButtonType> = ({
    variety = '',
    priority = 'primary',
    className,
    iconClassName,
    icon,
    children,
    color = 'first',
    minWidth = false,
    ...props
}) => {
    const theme = useTheme();

    const buttonColor =
        color === 'first'
            ? theme.colors.first
            : color === 'second'
            ? theme.colors.second
            : '#D5D5D5';

    const TextColor =
        color === 'first'
            ? theme.colors.first
            : color === 'second'
            ? theme.colors.second
            : '#333333';

    const buttonCSS =
        priority === 'primary'
            ? css`
                  background: ${buttonColor};
                  border: 1px solid ${buttonColor};
              `
            : priority === 'secondary'
            ? css`
                  background-color: transparent;
                  border: 1px solid ${buttonColor};
                  color: ${TextColor};
              `
            : priority === 'secondary-2'
            ? css`
                  background-color: ${buttonColor}40;
                  border-color: transparent;
                  color: ${TextColor};
              `
            : priority === 'tertiary'
            ? css`
                  background-color: transparent;
                  border: 0;
                  color: ${TextColor};
              `
            : priority === 'quarternary'
            ? css`
                  background-color: #dfe1ec;
                  border: 0;
                  color: ${TextColor};
              `
            : null;

    return (
        <button
            css={buttonCSS}
            className={classNames(
                'Btn',
                varietyToClassname(variety),
                className,
                minWidth ? 'MinWidth' : ''
            )}
            {...props}
        >
            {children}
            {icon ? (
                <img src={icon} alt="icon" className={iconClassName ? iconClassName : ''} />
            ) : null}
        </button>
    );
};

export default Button;
