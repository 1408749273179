import * as Yup from 'yup';
import { stringOnlyContainsNumbers } from './stringOnlyContainsNumbers';

export const TFAValidation = Yup.string().test(
    'is valid tfa',
    'Please enter your TFA code',
    (value: string | undefined) => {
        if (value && value.length === 6 && stringOnlyContainsNumbers(value)) {
            return true;
        }
        return false;
    }
);

export const VerificationCodeValidation = Yup.string().test(
    'is valid verification',
    'Please enter your verifiction code',
    (value: string | undefined) => {
        if (value && value.length === 6 && stringOnlyContainsNumbers(value)) {
            return true;
        }
        return false;
    }
);
