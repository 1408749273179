import { combineReducers } from 'redux';
import { ActionsObservable, combineEpics } from 'redux-observable';
import ui from './ui';
import auth, { authEpic } from './auth';
import language, { languageEpic } from './language';
import modal from 'reducers/modal';
import componentResources, { componentResourcesEpic } from './componentResources';
import cryptoPrices, { cryptoPricesEpic } from './cryptoPrices';
import availableAssets, { availableAssetsEpic } from './availableAssets';
import currentHoldings, { currentHoldingsEpic } from './currentHoldings';
import notificationUIUpdate from '../components/notifications/notificationUIUpdateReducer';
import jumio, { verificationEpic } from './jumio';

export const rootReducer = combineReducers({
    ui,
    auth,
    language,
    modal,
    componentResources,
    cryptoPrices,
    availableAssets,
    currentHoldings,
    notificationUIUpdate,
    jumio,
});

/**
 * https://stackoverflow.com/a/39499895/14015703
 * At some point in the future we need to switch from just logging the errors
 * bubbled up to the root observable, to trying to restart the epics if they fail.
 * Alternatively we just force an app refresh which is much simpler. For now we'll leave the errors
 * being logged so we can track them down and improve error handling within certain
 * epics.
 * TODO(HC): Address any typings here.
 */
export const rootEpic = (action$: ActionsObservable<any>, store: any) =>
    combineEpics(
        authEpic,
        componentResourcesEpic,
        cryptoPricesEpic,
        availableAssetsEpic,
        currentHoldingsEpic,
        verificationEpic,
        languageEpic
    )(action$, store);

export type Store = ReturnType<typeof rootReducer>;

// Global selectors (selecting data from multiple reducers)
export const selectIsPublicResourcesFetched = (store: Store) => {
    return !!store.componentResources.footerLinks && !!store.componentResources.languageList;
};
export const selectIsCustomerResourcesFetched = (store: Store) => {
    return (
        !!store.componentResources.customerMenuLinks &&
        !!store.componentResources.userInfo &&
        !!store.componentResources.customerMenuIcons &&
        !!store.jumio.currentStage &&
        !store.auth.accessTokenNeedsRefresh
    );
};
