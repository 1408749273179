import React from 'react';
import { FieldAttributes, useField } from 'formik';

const Input = React.forwardRef<HTMLInputElement, FieldAttributes<any>>(
    ({ className = '', ...props }, ref) => {
        const [field, meta] = useField(props);
        const showError = meta.touched && meta.error;
        const onBlur = props.onBlur ?? field.onBlur;
        if (props.type === 'textarea') {
            return (
                <textarea
                    rows={8}
                    maxLength={1000}
                    {...(field as any)}
                    className={`EditBox${showError ? ' ErrorInput' : ''} ${className}`}
                    {...props}
                    onBlur={onBlur}
                    ref={ref}
                ></textarea>
            );
        }
        return (
            <input
                {...(field as any)}
                className={`EditBox${showError ? ' ErrorInput' : ''} ${className}`}
                disabled={props.disabled === true}
                {...props}
                onBlur={onBlur}
                ref={ref}
            />
        );
    }
);

export { Input };
