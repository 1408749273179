import React from 'react';
import { ErrorM } from './ErrorM';
import { Input } from './Input';

export interface FormTextFieldProps extends React.HTMLProps<HTMLInputElement> {
    field: string;
    label: string;
    required: boolean;
    type?: string;
    hint?: string;
    optionalTag?: string;
    disabled?: boolean;
    placeholder?: string;
    sideBySide?: boolean;
    isVisible?: boolean;
    maxLength?: number;
    icon?: string;
    showIcon?: boolean;
    onClickIcon?: () => void;
    touched?: () => void;
    autoFocus?: boolean;
    className?: string;
    asterisk?: boolean;
    inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
    onBlur?: (e: React.FocusEvent) => void;
    children?: React.ReactNode;
    errorMessageWarningIcon?: boolean;
}

const FormTextField = React.forwardRef<HTMLInputElement, FormTextFieldProps>(
    (
        {
            field,
            label,
            required,
            type,
            hint,
            optionalTag,
            disabled,
            placeholder,
            sideBySide,
            isVisible = true,
            maxLength,
            icon,
            onClickIcon,
            showIcon = false,
            touched,
            autoFocus = false,
            inputMode,
            className = '',
            // Ideally shouldn't need this prop because the asterisk depends on whether the field
            // is required. However, there are plenty of forms in this project where the field are
            // required but it wouldn't make sense to show the asterisk and message.
            asterisk = false,
            onBlur,
            children,
            errorMessageWarningIcon = false,
            ...props
        },
        ref
    ) => {
        return (
            <div className={`FormBox ${sideBySide && 'SideBySide'} ${isVisible ? '' : 'Hide'}`}>
                <div className="FormLabel">
                    <label>
                        {label}
                        {asterisk && <span>*</span>}
                    </label>
                    {hint && <div className="FormHint">{hint}</div>}
                </div>
                {children}
                <div className="FormField">
                    <Input
                        name={field}
                        type={type ? type : 'text'}
                        disabled={disabled}
                        placeholder={placeholder}
                        maxLength={maxLength}
                        touched={touched}
                        autoFocus={autoFocus}
                        className={className}
                        inputMode={inputMode || undefined}
                        onBlur={onBlur}
                        ref={ref as any}
                        {...props}
                    />
                    {showIcon && icon && <img src={icon} alt="" onClick={onClickIcon} />}
                    <ErrorM name={field} warningIcon={errorMessageWarningIcon} />
                </div>
            </div>
        );
    }
);

export default FormTextField;
