export const ERROR_CODES = {
    TfaCode_Invalid: 'TFA code not valid',
    Generic: 'There was a problem. Please try again later.',
    Required: 'Required',
    Invalid: 'Invalid',
    Already_In_Use: 'Already in use',
    Already_In_Use_For_Personal_Account: 'Already in use',
    Already_In_Use_For_Business_Account: 'Already in use',
    Password_No_Match: 'Passwords do not match',
    Password_Policy_No_Match: 'Password is not strong enough',
    Invalid_Password_Length: 'Minimum password length is 8 characters',
    Expired: 'Expired',
    Username_Or_Password_Incorrect: 'Email address or password incorrect',
    Account_Locked: 'Account locked',
    Account_Suppressed: 'Account suppressed',
    EmailAddress_Already_Verified: 'Email address is already verified',
    Insufficient_Funds: 'Insufficient funds - deposits can take some time to be confirmed',
    StartDate_Invalid: 'Invalid start date',
    Crypto_Price_Updated: 'Price outdated. Please try again.',
    Guarantee_Transactions_Pending:
        'This transaction has other transactions in progress, please try again later',
    Unable_To_Submit_Transaction:
        "We can't create this transaction at the moment, please try again later",
    Too_Many_Requests:
        "You've reached our limit on sms codes, please come back and try again later",
    //Verification:
    Verification_Error: 'No response from our verification provider, please try again later',
    Storage_Error: 'Uploading the file failed, please try again',
    Image_Invalid: 'Image is invalid or poor quality, please take a new photo',
    Document_Invalid: 'Document is invalid or expired, please try a different one',
    Cross_Reference_Failed: "We couldn't match your photo to your ID, please take new photos",

    CountryCode_Invalid: 'The selected country is not supported',
    StateProvince_Invalid: 'Please enter a valid state/province',
    TownCity_Invalid: 'Please enter a valid town/city',
    PostalCode_Invalid: 'Please enter a valid postal code',
    AddressLine1_Invalid: 'Please enter a valid address',
    FirstName_Invalid: 'Please enter a valid name',
    NationalID_Invalid: 'Please enter a valid ID number',
    LastName_Invalid: 'Please enter a valid name',
    PhoneNumber_Invalid: 'Please enter a valid phone number',
    //registration:
    Invalid_PhoneNumber: 'Please enter a valid phone number',
};

export const getErrorMessage = (code: keyof typeof ERROR_CODES | string) => {
    return ERROR_CODES[code as keyof typeof ERROR_CODES]
        ? ERROR_CODES[code as keyof typeof ERROR_CODES]
        : ERROR_CODES['Generic'];
};
