import Button from 'components/button/Button';
import FormTextField from 'components/form/FormTextField';
import { TFAField } from 'components/form/TFAField';
import { ValidationCodeField } from 'components/form/ValidationCodeField';
import { ThemedLink } from 'components/themedLink/ThemedLink';
import { Form, Formik, FormikHelpers } from 'formik';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { useTFAField } from 'helpers/useTFAField';
import { TFAValidation, VerificationCodeValidation } from 'helpers/validationSnippets';
import React, { SetStateAction, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modal';
import * as Yup from 'yup';
import { IUpdateEmail } from './models';
import { ProfileApi } from './ProfileApi';
import { SecurityContext } from './Security';
import { useTheme } from '@emotion/react';
import WarningIcon from 'assets/bitline-ui-redesign/error icon.svg';

type UpdateEmailAddressModalProps = {
    isSMSAuth: boolean | null;
    isAppAuth: boolean | null;
    reloadData: React.Dispatch<SetStateAction<boolean>>;
};

const emailValidationSchema = Yup.object({
    showTfa: Yup.boolean(),
    emailAddress: Yup.string().required('Please enter your new email address'),
    tfaCode: Yup.string().when('showTfa', { is: true, then: TFAValidation }),
});

const verificationValidationSchema = Yup.object({
    emailVerificationCode: VerificationCodeValidation,
});

const UpdateEmailAddressModal: React.FC<UpdateEmailAddressModalProps> = ({ reloadData }) => {
    const [isVerifyStep, setIsVerifyStep] = useState<boolean>(false);
    const [errors, setErrors] = useState<string[] | null>(null);
    const theme = useTheme();
    const dispatch = useDispatch();
    const { reloadSecurityData } = useContext(SecurityContext);
    const [showTfa, setShowTfa] = useState(false);
    const [tfaType, toggleTFAType] = useTFAField(showTfa);
    const initialValues: IUpdateEmail = {
        showTfa: false,
        emailAddress: '',
        emailVerificationCode: '',
        bResendCode: false,
        tfaCode: '',
        tfaType: tfaType,
    };

    const handleEmailSubmit = async (
        values: IUpdateEmail,
        helpers: FormikHelpers<IUpdateEmail>
    ) => {
        if (!values.showTfa) {
            helpers.setFieldValue('showTfa', true);
            setShowTfa(true);
            helpers.setSubmitting(false);
            helpers.setFieldTouched('tfaCode', false);
            return;
        }
        const result = await ProfileApi.UpdateEmailAddress({ ...values, tfaType });
        if (ProfileApi.isSuccessData(result)) {
            setErrors(null);
            setIsVerifyStep(true);
            helpers.setSubmitting(false);
        } else if (ProfileApi.isErrorData(result)) {
            setErrors(result.errors);
            helpers.setSubmitting(false);
        } else {
            dispatch(closeModal());
            Toast.openGenericErrorToast();
        }
    };

    const handleVerifySubmit = async (
        values: IUpdateEmail,
        helpers: FormikHelpers<IUpdateEmail>
    ) => {
        const result = await ProfileApi.UpdateEmailAddressConfirm(values);
        if (ProfileApi.isSuccessData(result)) {
            reloadSecurityData();
            setErrors(null);
            dispatch(closeModal());
            Toast.openToastMessage('Email updated successfully', ToastMessageReason.VALID);
        } else if (ProfileApi.isErrorData(result)) {
            setErrors(result.errors);
            helpers.setSubmitting(false);
        } else {
            Toast.openGenericErrorToast();
        }
    };

    const handleResendCode = async (values: IUpdateEmail, helpers: FormikHelpers<IUpdateEmail>) => {
        setIsVerifyStep(false);
        helpers.setFieldValue('tfaCode', '');
    };

    const currentSubmitHandler = isVerifyStep ? handleVerifySubmit : handleEmailSubmit;
    const currentValidationSchema = isVerifyStep
        ? verificationValidationSchema
        : emailValidationSchema;

    return (
        <div className="UpdateEmailAddressModal">
            <h1 className="Title">Update Email Address</h1>

            <Formik
                initialValues={initialValues}
                onSubmit={currentSubmitHandler}
                validationSchema={currentValidationSchema}
            >
                {(formikProps) => (
                    <Form className="Form">
                        <>
                            {isVerifyStep ? (
                                <>
                                    <ValidationCodeField
                                        field="emailVerificationCode"
                                        label="Email verification code"
                                        value={formikProps.values.emailVerificationCode}
                                        autoComplete={'off'}
                                        required
                                        autoFocus
                                        holdFocus
                                    >
                                        <ThemedLink
                                            onClick={() => {
                                                handleResendCode(formikProps.values, formikProps);
                                            }}
                                            className="ResendCodeLink"
                                        >
                                            Resend code
                                        </ThemedLink>
                                    </ValidationCodeField>
                                    {/* <div className="ResendCodeButtonContainer">
                                        
                                    </div> */}
                                </>
                            ) : (
                                <>
                                    <FormTextField
                                        field="emailAddress"
                                        label="Email address"
                                        required
                                        inputMode="email"
                                    />
                                    {formikProps.values.showTfa && (
                                        <TFAField
                                            field={'tfaCode'}
                                            label={'Two-factor authentication code'}
                                            autoComplete={'one-time-code'}
                                            required
                                            tfaType={tfaType}
                                            toggleTFAType={toggleTFAType}
                                            value={formikProps.values.tfaCode}
                                            autoFocus
                                        />
                                    )}
                                </>
                            )}
                            {errors && (
                                <>
                                    {errors.map((error) => (
                                        <div className="ErrorLabel EmailErrorMessages">
                                            <img
                                                src={WarningIcon}
                                                alt="Warning icon"
                                                className="WarningIcon"
                                            />
                                            <span className="ErrorText">{error}</span>
                                        </div>
                                    ))}
                                </>
                            )}

                            <Button
                                type="submit"
                                priority="primary"
                                style={{
                                    backgroundColor: theme.colors.second,
                                    borderColor: theme.colors.second,
                                }}
                                disabled={formikProps.isSubmitting || !formikProps.isValid}
                            >
                                Update
                            </Button>
                            <Button
                                type="button"
                                priority="secondary"
                                className="Secondary"
                                disabled={formikProps.isSubmitting}
                                onClick={() => {
                                    dispatch(closeModal());
                                }}
                            >
                                Cancel
                            </Button>
                        </>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export { UpdateEmailAddressModal };
