import Button from 'components/button/Button';
import { useField } from 'formik';
import { useFocus } from 'helpers/useFocus';
import React from 'react';
import { TFAType } from 'types/shared';
import FormTextField, { FormTextFieldProps } from './FormTextField';

type TFAFieldProps = FormTextFieldProps & {
    value: string;
    toggleTFAType: (() => void) | null;
    tfaType: TFAType;
    backgroundColor?: 'white' | 'grey';
    holdFocus?: boolean;
};

export const TFAField: React.FC<TFAFieldProps> = ({
    value,
    toggleTFAType,
    tfaType,
    backgroundColor = 'grey',
    className = '',
    holdFocus,
    ...props
}) => {
    const [inputRef, setFocus] = useFocus<HTMLInputElement>();
    const [fieldProps] = useField(props.field);
    const handleBlur = (e: React.FocusEvent) => {
        fieldProps.onBlur(e);
        holdFocus && setFocus();
    };
    return (
        <div className="TFAInputContainer">
            {toggleTFAType && (
                <div className="SwitchTFATypeContainer">
                    <Button
                        priority="tertiary"
                        className="SwitchTFATypeButton"
                        onClick={() => {
                            setFocus();
                            toggleTFAType();
                        }}
                        type="button"
                    >
                        {tfaType === 'AuthenticatorApp' ? 'Use SMS instead' : 'Use app instead'}
                    </Button>
                </div>
            )}
            <FormTextField
                maxLength={6}
                autoComplete={'one-time-code'}
                inputMode="numeric"
                {...props}
                className={`TFAHiddenField ${className}`}
                ref={inputRef}
                onBlur={handleBlur}
            />
            <TFAInputMask value={value} className={`InputMask ${backgroundColor}`} />
        </div>
    );
};

type TFAInputMaskProps = {
    value: string;
    className?: string;
};

const TFA_INPUT_LENGTH = 6;

export const TFAInputMask: React.FC<TFAInputMaskProps> = ({ value, className }) => {
    const convertValueToMask = () =>
        Array(TFA_INPUT_LENGTH)
            .fill('')
            .map((_, index) => {
                return (
                    <div key={index} className={`Point ${value[index] ? 'Filled' : ''}`}>
                        {value[index] ?? '•'}
                    </div>
                );
            });
    return <label className={`InputMask ${className}`}>{convertValueToMask()}</label>;
};
