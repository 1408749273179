import { navigate, RouteComponentProps } from '@reach/router';
import { AppPath } from 'appConstants';
import Button from 'components/button/Button';
import FormTextField from 'components/form/FormTextField';
import Page from 'components/page/Page';
import { ThemedLink } from 'components/themedLink/ThemedLink';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import axiosInstance, { ApiResponse } from 'api';
import { endpoints } from 'endpoints.config';
import * as Yup from 'yup';
import { Spinner } from 'components/spinner/Spinner';

interface ForgottenPasswordRequest {
    EmailAddress: string;
}

const initialValues: ForgottenPasswordRequest = {
    EmailAddress: '',
};

const validationSchema = Yup.object({
    EmailAddress: Yup.string()
        .required('Email address is required')
        .email('Email address is invalid'),
});

export const ForgottenPassword: React.FC<RouteComponentProps> = () => {
    const [formIsComplete, setFormIsComplete] = useState(false);

    const handleSubmit = (
        values: ForgottenPasswordRequest,
        actions: FormikHelpers<ForgottenPasswordRequest>
    ) => {
        axiosInstance
            .post<ApiResponse>(endpoints.forgottenpasswordmodule.resetpasswordrequest, null, {
                params: { ...values },
            })
            .then((response) => {
                if (response.data.status === '1') {
                    setFormIsComplete(true);
                } else {
                    actions.setSubmitting(false);
                }
            });
    };

    return (
        <Page isPublic={true}>
            <div className="ForgottenPasswordPage WidthContent">
                {!formIsComplete ? (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        {({ isSubmitting }) => (
                            <Form className="ForgottenPasswordForm">
                                <h1>Password Reset</h1>
                                <FormTextField
                                    field={'EmailAddress'}
                                    label={'Email address'}
                                    required={true}
                                    maxLength={200}
                                />
                                <Button
                                    variety="full"
                                    priority="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? <Spinner color="white" /> : 'Send Password'}
                                </Button>
                                <p className="Redirect">
                                    <ThemedLink to={AppPath.SIGN_IN}>Retry Sign In?</ThemedLink>
                                </p>
                            </Form>
                        )}
                    </Formik>
                ) : (
                    <div className="SuccessMessage">
                        <h1>Thank you</h1>
                        <p>
                            If that email address is one we recognise, we’ve just sent you
                            instructions for resetting your password.
                        </p>
                        <Button
                            variety="full"
                            priority="primary"
                            onClick={() => navigate(AppPath.SIGN_IN)}
                        >
                            Go to Sign In
                        </Button>
                    </div>
                )}
            </div>
        </Page>
    );
};
