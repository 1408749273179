import { navigate, NavigateOptions, useLocation } from '@reach/router';
import { DASHBOARD_MENU_PAGE_SUB_PAGE_PLACEHOLDER } from 'App';
import { AppPath, DashboardPath } from 'appConstants';
import { FooterLinks } from 'components/footer/types';
import { SideMenuLink } from 'components/sideMenu/SideMenu';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSignedIn } from 'reducers/auth';
import { Icon } from 'reducers/componentResources';
import { selectCultureCode } from 'reducers/language';
import { MobileNavigationButton, StaticIcon } from './MobileNavigationButton';
import { PublicMoreMenu, SignedInMoreMenu } from './MoreMenu';

const NUM_SIDEMENU_LINKS_IN_NAV_BAR = 3;

// Links that are in the footer on desktop are moved into the 'More' menu.
type MobileNavigationProps = {
    moreMenuFooterLinks: FooterLinks | null;
    sideMenuLinks?: SideMenuLink[] | null;
    sideMenuIcons?: Icon[] | null;
};

export const MobileNavigation: React.FC<MobileNavigationProps> = ({
    moreMenuFooterLinks,
    sideMenuLinks,
    sideMenuIcons,
}) => {
    const isSignedIn = useSelector(selectSignedIn);
    const cultureCode = useSelector(selectCultureCode);
    const { pathname } = useLocation();
    const accountTypePathItem = AppPath.PERSONAL;

    const [moreMenuIsOpen, setMoreMenuIsOpen] = useState(false);

    const navigateWithCultureCode = (
        path: AppPath | string,
        options?: NavigateOptions<{ reset: true }>
    ) => {
        setMoreMenuIsOpen(false);
        navigate(`/${cultureCode}${path}`, options);
    };

    const isPathActive = (path: AppPath | string) => {
        // Don't let another path show as active when moreMenu is open.
        return pathname === `/${cultureCode}${path}` && !moreMenuIsOpen;
    };

    const openMoreMenu = () => {
        setMoreMenuIsOpen(true);
    };

    useEffect(() => {
        document.body.classList.toggle('OverflowHidden', moreMenuIsOpen);
    }, [moreMenuIsOpen]);

    return (
        <>
            {/* Rendering 'More' menu ontop of page rather than having a separate route 
            allows us to keep the routing structure for the mobile and desktop apps identical. */}

            <div className="MobileNavigation">
                {/* TODO(HC): Get signed-in buttons from shared state with SideMenu */}
                {isSignedIn ? (
                    sideMenuLinks &&
                    sideMenuIcons && (
                        <>
                            {moreMenuIsOpen && sideMenuLinks && sideMenuIcons && (
                                <SignedInMoreMenu
                                    linksFromFooter={moreMenuFooterLinks}
                                    pageLinks={sideMenuLinks.slice(
                                        NUM_SIDEMENU_LINKS_IN_NAV_BAR,
                                        sideMenuLinks.length
                                    )}
                                    pageLinkIcons={sideMenuIcons.slice(
                                        NUM_SIDEMENU_LINKS_IN_NAV_BAR,
                                        sideMenuLinks.length
                                    )}
                                    navigateWithCultureCode={navigateWithCultureCode}
                                />
                            )}
                            <MobileNavigationButton
                                onClick={() => {
                                    return navigateWithCultureCode(
                                        `${accountTypePathItem}${DashboardPath.HOME}/${DASHBOARD_MENU_PAGE_SUB_PAGE_PLACEHOLDER}`
                                    );
                                }}
                                label="Home"
                                icon={StaticIcon.HOME}
                                isActive={isPathActive(
                                    `${accountTypePathItem}${DashboardPath.HOME}/${DASHBOARD_MENU_PAGE_SUB_PAGE_PLACEHOLDER}`
                                )}
                            />
                            {sideMenuLinks
                                .slice(0, NUM_SIDEMENU_LINKS_IN_NAV_BAR)
                                .map((link, index) => {
                                    const linkHasChildren = !!link.childElements;
                                    // See App.tsx Dashboard routing for explanation on the need for
                                    // this placeholder.
                                    const finalUrlSegment = `/${
                                        linkHasChildren
                                            ? `${link.childElements[0].path}`
                                            : DASHBOARD_MENU_PAGE_SUB_PAGE_PLACEHOLDER
                                    }`;
                                    const path = `${accountTypePathItem}/${link.path}${finalUrlSegment}`;
                                    return (
                                        <React.Fragment key={link.id}>
                                            <MobileNavigationButton
                                                onClick={() => {
                                                    navigateWithCultureCode(path);
                                                }}
                                                label={link.displayName}
                                                icon={sideMenuIcons[index]}
                                                isActive={isPathActive(path)}
                                            />
                                        </React.Fragment>
                                    );
                                })}
                            <MobileNavigationButton
                                onClick={openMoreMenu}
                                label="More"
                                icon={StaticIcon.MORE}
                                isActive={moreMenuIsOpen}
                            />
                        </>
                    )
                ) : (
                    <>
                        {moreMenuIsOpen && (
                            <PublicMoreMenu
                                linksFromFooter={moreMenuFooterLinks}
                                navigateWithCultureCode={navigateWithCultureCode}
                            />
                        )}
                        <MobileNavigationButton
                            onClick={() => {
                                navigateWithCultureCode(AppPath.SIGN_IN, {
                                    state: { reset: true },
                                });
                            }}
                            label="Sign in"
                            icon={StaticIcon.SIGN_IN}
                            isActive={isPathActive(AppPath.SIGN_IN)}
                        />
                        <MobileNavigationButton
                            onClick={() => {
                                navigateWithCultureCode(AppPath.REGISTER);
                            }}
                            label="Register"
                            icon={StaticIcon.REGISTER}
                            isActive={isPathActive(AppPath.REGISTER)}
                        />
                        <MobileNavigationButton
                            onClick={openMoreMenu}
                            label="More"
                            icon={StaticIcon.MORE}
                            isActive={moreMenuIsOpen}
                        />
                    </>
                )}
            </div>
        </>
    );
};
