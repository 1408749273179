import Button from 'components/button/Button';
import { ValidationCodeField } from 'components/form/ValidationCodeField';
import { Spinner } from 'components/spinner/Spinner';
import { ThemedLink } from 'components/themedLink/ThemedLink';
import { Form, useFormikContext } from 'formik';
import { Toast, ToastMessageReason } from 'helpers/toast';
import React from 'react';
import { RegisterFormSubForm } from './models';
import { postEmailVerificationCode } from './RegisterApi';
import { handleTFAError } from './RegistrationErrorHandling';
import { RegistrationFormValues } from './RegistrationForm';
import { useNavigate } from '@reach/router';

export const EmailVerification: React.FC<RegisterFormSubForm> = ({
    errorMessage,
    setErrorMessage,
    translations,
}) => {
    const { values, isSubmitting, setSubmitting } = useFormikContext<RegistrationFormValues>();
    const navigate = useNavigate();

    const resendEmailCode = (values: RegistrationFormValues) => () => {
        setSubmitting(true);
        (async () => {
            const result = await postEmailVerificationCode({
                username: values.emailAddress,
                password: values.password,
                bResendCode: true,
                emailVerificationCode: '',
            });
            if (result.response) {
                setSubmitting(false);
                Toast.openToastMessage('Verification email re-sent', ToastMessageReason.PENDING);
            }
            if (result.errors && result.errors.length > 0) {
                setErrorMessage(handleTFAError(result.errors[0]));
                setSubmitting(false);
            }
        })();
    };

    const handleResetError = () => {
        setErrorMessage('');
    };

    return (
        <Form onChange={handleResetError} className="EmailVerificationForm">
            <div className="RegisterFormWidth">
                <p style={{ fontWeight: 800, fontSize: '1rem' }}>
                    Enter the code sent to your email address
                </p>
                <ValidationCodeField
                    field={'emailVerificationCode'}
                    label={'Your verification code'}
                    value={values.emailVerificationCode}
                    required={true}
                    maxLength={6}
                    autoFocus
                    autoComplete={'off'}
                    holdFocus
                >
                    <ThemedLink onClick={resendEmailCode(values)} className="RegisterLink">
                        Resend code
                    </ThemedLink>
                </ValidationCodeField>

                {errorMessage && <div className="ErrorMessage">{errorMessage}</div>}
                <Button
                    priority="primary"
                    variety="full"
                    type="submit"
                    disabled={isSubmitting}
                    color="second"
                >
                    {isSubmitting ? <Spinner color={'#fff'} /> : 'Continue'}
                </Button>
                <Button
                    priority="secondary"
                    variety="full"
                    type="button"
                    disabled={isSubmitting}
                    color="first"
                    style={{ marginTop: 10 }}
                    onClick={() => navigate('/')}
                >
                    Cancel
                </Button>
            </div>
        </Form>
    );
};
