import { useTheme } from '@emotion/react';
import { fiatCurrencyFormatter } from 'appConstants';
import Button from 'components/button/Button';
import { GuaranteeTableRow, isGuaranteeTableRow } from 'components/guaranteesTable/GuaranteesTable';
import { Spinner } from 'components/spinner/Spinner';
import { Toast, ToastMessageReason } from 'helpers/toast';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, selectModalState } from 'reducers/modal';
import { PartialReleaseModalApi } from './partialReleaseModalApi';

export const PartialReleaseModal: React.FC = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { data } = useSelector(selectModalState);
    const [releaseAmount, setReleaseAmount] = useState<number | null>(null);
    const [newALTV, setNewALTV] = useState<number | null>(null);

    if (!isGuaranteeTableRow(data)) {
        dispatch(closeModal());
    }
    const topUpModalData = data as GuaranteeTableRow;

    useEffect(() => {
        PartialReleaseModalApi.getReleaseAmount(topUpModalData.guarantees__Id)
            .then((response) => {
                setReleaseAmount(response.data.details.amount);
                setNewALTV(response.data.details.newALTV);
            })
            .catch(() => {
                dispatch(closeModal());
                Toast.openGenericErrorToast();
            });
    }, [topUpModalData.guarantees__Id, dispatch]);

    const handleRelease = () => {
        if (releaseAmount) {
            PartialReleaseModalApi.postReleaseRequest(topUpModalData.guarantees__Id, releaseAmount)
                .then(() => {
                    dispatch(closeModal());
                    Toast.openToastMessage('Release sucessful', ToastMessageReason.VALID);
                })
                .catch(() => {
                    Toast.openGenericErrorToast();
                });
        }
    };

    const handleClose = () => {
        dispatch(closeModal());
    };

    return (
        <div className="PartialReleaseModal">
            {' '}
            {releaseAmount && newALTV ? (
                <div>
                    <p>
                        <b>
                            Your Actual Value has risen above our safe threshold needed to fully cover
                            your transaction for{' '}
                            {fiatCurrencyFormatter(
                                topUpModalData.guarantees__GuaranteeValue,
                                topUpModalData.fiatAssets__Code
                            )}{' '}
                            and so you may release some of your {topUpModalData.assets__Code}.
                        </b>
                    </p>
                    <p>
                        To bring your Actual Value back to {newALTV}% we can release {releaseAmount}{' '}
                        {topUpModalData.assets__Code}.
                    </p>
                    <div className="ModalFooter">
                        <Button priority="secondary" className="Secondary" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button
                            onClick={handleRelease}
                            priority="primary"
                            style={{
                                backgroundColor: theme.colors.second,
                                borderColor: theme.colors.second,
                            }}
                            type="button"
                        >
                            Release {topUpModalData.assets__Code}
                        </Button>
                    </div>
                </div>
            ) : (
                <div className="LoaderContainer">
                    <Spinner />
                </div>
            )}
        </div>
    );
};
