import React from 'react';
import { Redirect } from '@reach/router';
import { selectCultureCode } from '../../reducers/language';
import { useSelector } from 'react-redux';
import { AppPath } from '../../appConstants';

type PrivateProps = {
    isSignedIn: boolean;
};

const Private: React.FC<PrivateProps> = ({ isSignedIn, children }) => {
    const cultureCode = useSelector(selectCultureCode);

    if (!isSignedIn) {
        // TODO(Hari Chauhan): Should store culture code in local storage so that if it has not been
        // determined when the user tries to navigate, we have something to default it to.
        const redirectPath = cultureCode
            ? `/${cultureCode}${AppPath.SIGN_IN}`
            : `/en-US${AppPath.SIGN_IN}`;
        return <Redirect noThrow to={redirectPath} />;
    }

    return <React.Fragment>{children}</React.Fragment>;
};

export default Private;
