import React from "react";
import { GoSearch } from "react-icons/go";
export interface NoResultsProps {
    message?: string;
}
const NoResults: React.FC<NoResultsProps> = ({ message }) => {
    return (
        <div className="NoResults">
            <GoSearch className="Icon" />
            <h4 className="Message">{message ?? "No results found"}</h4>
        </div>
    );
};
export { NoResults };