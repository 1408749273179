import instance, { ApiResponse } from 'api';
import { endpoints } from 'endpoints.config';

type TopUpAmountResponse = {
    amount: number;
    newALTV: number;
};

export class PartialReleaseModalApi {
    public static getReleaseAmount(guaranteesId: number) {
        return instance.get<ApiResponse<TopUpAmountResponse>>(
            endpoints.guaranteemodule.getPartialReleaseAmount,
            { params: { guaranteesId } }
        );
    }

    public static postReleaseRequest(guaranteesId: number, amount: number) {
        return instance.post<ApiResponse<{}>>(endpoints.guaranteemodule.partialRelease, {
            guaranteesId,
            amount,
        });
    }
}
