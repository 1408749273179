import { Link } from '@reach/router';
import { DASHBOARD_MENU_PAGE_SUB_PAGE_PLACEHOLDER } from 'App';
import { UserDropdown } from 'components/userDropdown/UserDropdown';
import React from 'react';
import { useSelector } from 'react-redux';
import { AppPath, DashboardPath, ProfilePath } from '../../appConstants';
import MessageIcon from '../../assets/ibanera/Icon_Message.png';
import { selectSignedIn } from '../../reducers/auth';
import { selectCultureCode } from '../../reducers/language';
import { selectImagePath } from '../../reducers/ui';
import Button from '../button/Button';
import LanguageDropdown from '../languageDropdown/LanguageDropdown';

const PublicHeaderItems: React.FC<{ cultureCode: string | null }> = ({ cultureCode }) => {
    return (
        <div className="Actions">
            <div className="Buttons">
                <Link to={`/${cultureCode}${AppPath.SIGN_IN}`} state={{ reset: true }}>
                    <Button variety="full" priority="secondary" className="BtnWhite">
                        Sign in
                    </Button>
                </Link>
                <Link to={`/${cultureCode}${AppPath.REGISTER}`}>
                    <Button variety="full" priority="primary" className="BtnBlue">
                        Register
                    </Button>
                </Link>
            </div>
        </div>
    );
};

const LoggedInHeaderItems: React.FC<{ cultureCode: string | null }> = ({ cultureCode }) => {
    const accountTypePathItem = AppPath.PERSONAL;
    const messagesPath = `/${cultureCode}${accountTypePathItem}${DashboardPath.PROFILE}${ProfilePath.MESSAGES}`;

    return (
        <div className="Actions">
            <UserDropdown />
        </div>
    );
};

const Header = () => {
    const isSignedIn = useSelector(selectSignedIn);
    const imagePath = useSelector(selectImagePath);
    const cultureCode = useSelector(selectCultureCode);

    const logoPath = isSignedIn
        ? `/${cultureCode}${AppPath.PERSONAL}${DashboardPath.HOME}/${DASHBOARD_MENU_PAGE_SUB_PAGE_PLACEHOLDER}`
        : `/${cultureCode}`;

    return (
        <div className="Header">
            <div className="InnerHeader">
                <div className="Logo">
                    <Link to={logoPath}>
                        <img src={imagePath + '/Logo.png'} alt="Logo" />
                    </Link>
                </div>
                {isSignedIn ? (
                    <LoggedInHeaderItems cultureCode={cultureCode} />
                ) : (
                    <PublicHeaderItems cultureCode={cultureCode} />
                )}
            </div>
        </div>
    );
};

export default Header;
