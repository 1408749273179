import { useReducer, useState, useEffect, useMemo, useCallback, Reducer, useRef } from 'react';
import { AxiosInstance, AxiosRequestConfig } from 'axios';

type State<T, E> =
    | {
          loading: true;
          error: undefined;
          data: undefined;
      }
    | { loading: boolean; error: E; data: undefined }
    | { loading: boolean; error: undefined; data: T };

type Action<T, E = Error> =
    | { type: 'loading' }
    | { type: 'error'; payload: E }
    | { type: 'data'; payload: T }
    | { type: 'reset' };

function initialState<T, E>(initialData?: T): State<T, E> {
    return initialData == null
        ? { loading: true, error: undefined, data: undefined }
        : { loading: false, error: undefined, data: initialData };
}

function usePrevious<T>(value: T) {
    const ref = useRef<T | null>(null);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export function createUseFetch(
    api: AxiosInstance,
    preventReRequest?: (
        prevConfig: AxiosRequestConfig | null | undefined,
        config: AxiosRequestConfig | null | undefined
    ) => boolean
) {
    return function <T = any, E = Error>(
        url: string,
        config?: AxiosRequestConfig,
        initialData?: T,
        preventRequest?: boolean,
        additionalAxiosConfig?: AxiosRequestConfig
    ) {
        const [store, dispatch] = useReducer<Reducer<State<T, E>, Action<T, E>>, T | undefined>(
            reducer,
            initialData,
            initialState
        );

        const { loading } = store;

        const [flag, setFlag] = useState(0);

        const reload = useCallback(() => {
            if (!loading) {
                setFlag((prev) => prev + 1);
            }
        }, [loading]);

        const prevConfig = usePrevious(config);
        const prevFlag = usePrevious(flag);
        const prevUrl = usePrevious(url);

        useEffect(() => {
            let cancelled = false;

            if (
                prevFlag === flag &&
                prevUrl === url &&
                ((preventReRequest && preventReRequest(prevConfig, config)) || preventRequest)
            ) {
                return;
            }

            if (
                prevFlag !== flag ||
                prevUrl !== url ||
                JSON.stringify(prevConfig) !== JSON.stringify(config)
            ) {
                dispatch({ type: 'loading' });
                api.get(url, { ...config, ...additionalAxiosConfig })
                    .then((res) => {
                        if (!cancelled) {
                            dispatch({ type: 'data', payload: res.data });
                        }
                    })
                    .catch((err) => {
                        if (!cancelled) {
                            dispatch({ type: 'error', payload: err });
                        }
                    });
            }
            return () => {
                cancelled = true;
            };
        }, [url, config, flag]);

        const { loading: storeLoading, error: storeError, data: storeData } = store;

        return useMemo(
            () => ({
                loading: storeLoading,
                error: storeError,
                data: storeData,
                reload,
            }),
            [storeLoading, storeError, storeData, reload]
        ) as { reload: typeof reload } & (
            | { loading: true; error: undefined; data: undefined }
            | { loading: boolean; error: E; data: undefined }
            | { loading: boolean; error: undefined; data: T }
        );
    };

    function reducer<T, E>(prevState: State<T, E>, action: Action<T, E>): State<T, E> {
        switch (action.type) {
            case 'loading':
                return { ...prevState, loading: true };
            case 'error':
                return { loading: false, data: undefined, error: action.payload };
            case 'data':
                return { loading: false, data: action.payload, error: undefined };
            case 'reset':
                return initialState();
            default:
                throw new Error(`Impossible reducer state from following action: ${action}`);
        }
    }
}
