import instance, { ApiResponse } from 'api';
import { endpoints } from 'endpoints.config';
import { TFAType } from 'types/shared';
import { UnlockOption } from './UnlockGuaranteeModal';

type UnlockGuaranteeResponse = { unlockCode: string; unlockQRCode: string };

export class UnlockGuaranteeModalApi {
    public static unlockGuarantee(
        guaranteesId: number,
        tfaCode: string,
        tfaType: TFAType,
        unlockMethod: UnlockOption
    ) {
        const url =
            unlockMethod === UnlockOption.COLLATERAL
                ? endpoints.guaranteemodule.colatteralUnlock
                : endpoints.guaranteemodule.unlockGuarantee;
        return instance.post<ApiResponse<UnlockGuaranteeResponse>>(url, {
            guaranteesId,
            tfaCode,
            tfaType,
            unlockMethod,
        });
    }
}
