import { Link, navigate, RouteComponentProps } from '@reach/router';
import { useDefaultRoute } from 'helpers/useDefaultRoute';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppPath } from '../../appConstants';
import Page from '../../components/page/Page';
import { selectAuthStatus, setAuthStatus } from '../../reducers/auth';
import { selectCultureCode } from '../../reducers/language';
import { SplashPage } from 'pages/SplashPage';
import Button from 'components/button/Button';
import { useTheme } from '@emotion/react';

type RouteState = { state: { reset: boolean } };

type Props = RouteComponentProps<{ location: RouteState }>;

const SignInOrRegister: React.FC<Props> = ({ location }) => {
    const authStatus = useSelector(selectAuthStatus);
    const cultureCode = useSelector(selectCultureCode);
    const dispatch = useDispatch();
    const theme = useTheme();

    if (authStatus === 'signed_in') {
        navigate(`/${cultureCode}${AppPath.CHECKING_DETAILS}`);
    }

    // Useful if user wants to return to the sign-in screen from the tfa screen.
    // Can use the sign-in button in the header which will route with this state.
    useEffect(() => {
        if (location?.state?.reset) {
            // May need this to reset some other parts of state in the auth reducer
            // if this functionality starts causing problems.
            dispatch(setAuthStatus('signed_out'));
        }
    }, [location?.state?.reset, dispatch]);

    useDefaultRoute(`/${cultureCode}`);

    return (
        <Page isPublic={true}>
            <SplashPage context={'SignInOrRegisterSplashPage'}>
                <div className="SignInOrRegisterFormPanel">
                    <Link to={`/${cultureCode}${AppPath.REGISTER}`}>
                        <Button
                            type="button"
                            priority="primary"
                            style={{
                                backgroundColor: theme.colors.second,
                                borderColor: theme.colors.second,
                            }}
                        >
                            Join BitLine
                        </Button>
                    </Link>
                    <Link to={`/${cultureCode}${AppPath.SIGN_IN}`} state={{ reset: true }}>
                        <Button type="button" priority="secondary" className="Secondary">
                            Sign In
                        </Button>
                    </Link>
                </div>
            </SplashPage>
        </Page>
    );
};

export default SignInOrRegister;
