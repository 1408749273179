import instance, { ApiResponse } from 'api';
import { endpoints } from 'endpoints.config';
import { Toast } from 'helpers/toast';
import { ActionsObservable, combineEpics, ofType } from 'redux-observable';
import { empty, from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AvailableAsset } from './availableAssets';
import { Store } from './rootReducer';

export type CurrentHolding = {
    balance: number | null;
    lockedBalance: number | null;
    unlockedBalance: number | null;
    displayCode: string;
    fireblocksAssetId: string;
    name: string;
    lockedBalanceHasPending: boolean;
    unlockedBalanceHasPending: boolean;
};

type CurrentHoldingsState = CurrentHolding[] | null;

const initialState: CurrentHoldingsState = null;

enum CurrentHoldingsActionType {
    GET_CURRENT_HOLDINGS = 'app/currentHoldings/GET_CURRENT_HOLDINGS',
    SET_CURRENT_HOLDINGS = 'app/currentHoldings/SET_CURRENT_HOLDINGS',
    UPDATE_CURRENT_HOLDING_BALANCE = 'app/currentHoldings/UPDATE_CURRENT_HOLDING_BALANCE',
    DELETE_CURRENT_HOLDINGS = 'app/currentHoldings/DELETE_CURRENT_HOLDINGS',
}

type GetCurrentHoldingsAction = {
    type: CurrentHoldingsActionType.GET_CURRENT_HOLDINGS;
};

type SetCurrentHoldingsAction =
    | {
          type: CurrentHoldingsActionType.SET_CURRENT_HOLDINGS;
          payload: CurrentHolding[];
      }
    | {
          type: CurrentHoldingsActionType.UPDATE_CURRENT_HOLDING_BALANCE;
          payload: { messageData: Partial<CurrentHolding>; availableAsset: AvailableAsset };
      };

type CurrentHoldingsAction =
    | GetCurrentHoldingsAction
    | SetCurrentHoldingsAction
    | { type: CurrentHoldingsActionType.DELETE_CURRENT_HOLDINGS };

const Reducer = (
    state: CurrentHoldingsState = initialState,
    action: CurrentHoldingsAction
): CurrentHoldingsState => {
    switch (action.type) {
        /* case CurrentHoldingsActionType.GET_CURRENT_HOLDINGS:
            return state
                ? state.map((currentHolding) => ({
                      ...currentHolding,
                      balance: null,
                      lockedBalance: null,
                      unlockedBalance: null,
                  }))
                : state; */
        case CurrentHoldingsActionType.SET_CURRENT_HOLDINGS:
            return action.payload;
        case CurrentHoldingsActionType.UPDATE_CURRENT_HOLDING_BALANCE:
            // If user already has some current holdings.
            const updatedHolding = {
                ...action.payload.availableAsset,
                ...action.payload.messageData,
            } as CurrentHolding;
            if (state) {
                const stateCopy = [...state];
                const indexToUpdate = stateCopy.findIndex(
                    (holding) =>
                        holding.fireblocksAssetId === action.payload.messageData.fireblocksAssetId
                );
                // If user already has holding of the particular crypto being updated.
                if (indexToUpdate !== -1) {
                    stateCopy[indexToUpdate] = updatedHolding;
                } else {
                    stateCopy.push(updatedHolding);
                }
                return stateCopy;
            } else {
                return [updatedHolding];
            }
        case CurrentHoldingsActionType.DELETE_CURRENT_HOLDINGS:
            return initialState;
        default:
            return state;
    }
};

const getUsersCurrentHoldingsEpic = (action$: ActionsObservable<GetCurrentHoldingsAction>) =>
    action$.pipe(
        ofType(CurrentHoldingsActionType.GET_CURRENT_HOLDINGS),
        switchMap(() =>
            from(
                instance.get<ApiResponse<CurrentHolding[]>>(
                    endpoints.cryptosmodule.getUsersCurrentHoldings
                )
            ).pipe(
                map((response) => setCurrentHoldings(response.data.details)),
                catchError(() => {
                    //Toast.openGenericErrorToast(); //TODO add real error messages
                    return empty();
                })
            )
        )
    );

export const currentHoldingsEpic = combineEpics(getUsersCurrentHoldingsEpic);

export default Reducer;

// Action creators
const setCurrentHoldings = (holdings: CurrentHolding[]): SetCurrentHoldingsAction => ({
    type: CurrentHoldingsActionType.SET_CURRENT_HOLDINGS,
    payload: holdings,
});

export const getUsersCurrentHoldings = (): GetCurrentHoldingsAction => ({
    type: CurrentHoldingsActionType.GET_CURRENT_HOLDINGS,
});

export const updateCurrentHolding = (
    holding: Partial<CurrentHolding>,
    availableAsset: AvailableAsset
): CurrentHoldingsAction => ({
    type: CurrentHoldingsActionType.UPDATE_CURRENT_HOLDING_BALANCE,
    payload: { messageData: holding, availableAsset },
});

export const deleteCurrentHoldings = () => ({
    type: CurrentHoldingsActionType.DELETE_CURRENT_HOLDINGS,
});

// Selectors
export const selectCurrentHoldings = (store: Store) => store.currentHoldings;
