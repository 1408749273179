import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserTFASettings, selectActiveTFAType, selectSignedIn } from 'reducers/auth';
import {
    getAvailableAssets,
    getAvailableVenues,
    selectAvailableAssets,
} from 'reducers/availableAssets';
import {
    getCustomerComponentResources,
    getPublicComponentResources,
    selectComponentResources,
} from 'reducers/componentResources';
import { getUsersCurrentHoldings, selectCurrentHoldings } from 'reducers/currentHoldings';
import {
    fetchVerificationStatus,
    selectisVerified,
    selectVerificationDetails,
    selectVerificationLoading,
} from 'reducers/jumio';
import { selectCultureCode } from 'reducers/language';

export const useGetComponentResources = () => {
    const dispatch = useDispatch();
    const cultureCode = useSelector(selectCultureCode);
    const isSignedIn = useSelector(selectSignedIn);
    const componentResources = useSelector(selectComponentResources);
    const { availableAssets, availableVenues } = useSelector(selectAvailableAssets);
    const currentHoldings = useSelector(selectCurrentHoldings);
    const isVerified = useSelector(selectisVerified);
    const verificationStatus = useSelector(selectVerificationDetails);
    const verificationLoading = useSelector(selectVerificationLoading);
    const activeTFAType = useSelector(selectActiveTFAType);

    // const [prevSignedIn, setPrevSignedIn] = useState(isSignedIn);
    const [prevCultureCode, setPrevCultureCode] = useState(cultureCode);

    // Make fetch for public component resources if not present.
    useEffect(() => {
        if (!(componentResources.footerLinks && componentResources.languageList))
            dispatch(getPublicComponentResources());
    }, [dispatch, componentResources.footerLinks, componentResources.languageList]);

    // Make fetch for customer component resources if logged-in and not present.
    useEffect(() => {
        if (isSignedIn && !(componentResources.userInfo && componentResources.customerMenuLinks)) {
            dispatch(getCustomerComponentResources());
        }
    }, [dispatch, isSignedIn, componentResources.userInfo, componentResources.customerMenuLinks]);

    useEffect(() => {
        if (isSignedIn && verificationStatus.stage === null && !verificationLoading) {
            dispatch(fetchVerificationStatus());
        }
    }, [verificationStatus, verificationLoading, isSignedIn, dispatch]);

    useEffect(() => {
        if (isSignedIn && !activeTFAType) {
            dispatch(getUserTFASettings());
        }
    }, [activeTFAType, isSignedIn]);

    useEffect(() => {
        if (isSignedIn && isVerified && !availableAssets && !availableVenues && !currentHoldings) {
            dispatch(getAvailableAssets());
            dispatch(getAvailableVenues());
        }
    }, [availableAssets, availableVenues, currentHoldings, dispatch, isSignedIn, isVerified]);

    // Update component resources if culture code is changed.
    // useEffect(() => {
    //     if (cultureCode !== prevCultureCode) {
    //         setPrevCultureCode(cultureCode);
    //         dispatch(getPublicComponentResources());
    //         if (isSignedIn) {
    //             dispatch(getCustomerComponentResources());
    //         }
    //     }
    // }, [dispatch, cultureCode, isSignedIn, prevCultureCode]);
};
