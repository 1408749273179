import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAvailableAssets } from 'reducers/availableAssets';
import { updateCurrentHolding } from 'reducers/currentHoldings';
import {
    completeUIUpdate,
    NotificationIdentifier,
    selectUIUpdate,
} from '../components/notifications/notificationUIUpdateReducer';
import { setPrices as setCryptoPrices } from '../reducers/cryptoPrices';

export const useNotificationUIUpdates = () => {
    const dispatch = useDispatch();
    const update = useSelector(selectUIUpdate);
    const { availableAssets } = useSelector(selectAvailableAssets);

    useEffect(() => {
        if (update) {
            switch (update.pushType) {
                case NotificationIdentifier.UPDATE_BALANCE:
                    const correspondingAvailableAsset = availableAssets?.find(
                        (asset) => update.data.fireblocksAssetId === asset.fireblocksAssetId
                    );
                    /**
                     * Message to update currency should always corrspond to an existing asset
                     * we have in availableAssets reducer. We include this data in the action
                     * because, messages only include a Partial of the currentHolding object.
                     * If the user doesn't already have this currency in their current holding
                     * then we need to pass the reducer data from availableAssets to populate it.
                     */
                    if (correspondingAvailableAsset) {
                        dispatch(updateCurrentHolding(update.data, correspondingAvailableAsset));
                        dispatch(completeUIUpdate());
                    }
                    break;
                case NotificationIdentifier.SET_CRYPTO_PRICES:
                    dispatch(setCryptoPrices(update.data.prices));
                    dispatch(completeUIUpdate());
                    break;
            }
        }
    }, [update, dispatch, availableAssets]);
};
