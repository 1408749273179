export const calculateALTV = (
    cryptoGuaranteeAmount: number,
    fiatGuaranteeAmount: number,
    currentCryptoPrice: number
) => {
    return (fiatGuaranteeAmount / (cryptoGuaranteeAmount * currentCryptoPrice)) * 100;
};

export const getALTVStatus = (altv: number, min: number, max: number): 'Good' | 'Mid' | 'Bad' => {
    return altv <= min ? 'Good' : altv >= max ? 'Bad' : 'Mid';
};
