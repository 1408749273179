import { ColumnDetail } from '@avamae/table';
import classNames from 'classnames';
import { toPairs } from 'ramda';
import React from 'react';
import Chevron from 'assets/bitline-ui-redesign/chevron down.svg';

export type BitLineTableRowProps<T = any> = {
    id: string;
    row: T;
    idColumn: string;
    columns: ColumnDetail<T>[];
    headerColumnsDetails: ColumnDetail<T>[];
    bodyColumnsDetails: ColumnDetail<T>[];
    handleToggleRowExpand: (rowId: string) => void;
    rowButton?: (data: T) => React.ReactNode;
    formatPrimitives: (v: unknown) => unknown;
    rowFormatter?(r: T): { [K in keyof T]: React.ReactNode };
    isExpanded?: boolean;
    isNotCollapsible: boolean;
};

export const BitLineTableRow = <T,>({
    id,
    row,
    idColumn,
    columns: c,
    headerColumnsDetails: hc,
    bodyColumnsDetails: bc,
    handleToggleRowExpand,
    rowButton,
    formatPrimitives,
    rowFormatter,
    isExpanded,
    isNotCollapsible,
}: BitLineTableRowProps<T>) => {
    const columns = React.useMemo(() => c, [c]);
    const headerColumnsDetails = React.useMemo(() => hc, [hc]);
    const bodyColumnsDetails = React.useMemo(() => bc, [bc]);

    const formattedRow = rowFormatter ? rowFormatter(row) : row;
    const headerColumnKeys = headerColumnsDetails.map((c) => c.columnKey.toString().toLowerCase());
    const bodyColumnKeys = bodyColumnsDetails.map((c) => c.columnKey.toString().toLowerCase());
    const findOrderNumber = React.useCallback(
        (key: keyof T) => {
            const col = columns.find(
                (x) => x.columnKey.toString().toLowerCase() === key.toString().toLowerCase()
            );
            if (!col) return 0;
            return col.orderNumber;
        },
        [columns]
    );
    const findColLabel = React.useCallback(
        (key: keyof T) => {
            const col = columns.find(
                (x) => x.columnKey.toString().toLowerCase() === key.toString().toLowerCase()
            );
            return col?.labelValue;
        },
        [columns]
    );

    const generatePairs = React.useCallback(
        (colKeys: string[]) =>
            toPairs(formattedRow)
                .filter(([k]) => {
                    if (colKeys.includes(k.toLowerCase())) {
                        return true;
                    }

                    return false;
                })
                .map(([k, v]) => [k, formatPrimitives(v)] as [keyof T, React.ReactNode])
                .sort((a, b) => {
                    const aOrder = findOrderNumber(a[0]);
                    const bOrder = findOrderNumber(b[0]);
                    if (aOrder === bOrder) return 0;
                    return aOrder < bOrder ? -1 : 1;
                }) as [keyof T, React.ReactNode][],
        [findOrderNumber, formatPrimitives, formattedRow]
    );

    const hasValue = React.useCallback((node: React.ReactNode): React.ReactNode => {
        if (node === null || node === undefined || node === '') {
            return '-';
        }
        return node;
    }, []);

    return (
        <div
            className={classNames('Row', {
                Expanded: isExpanded,
                NotCollapsible: isNotCollapsible,
            })}
        >
            <div className="RowHeader">
                {generatePairs(headerColumnKeys).map((pair, i) => {
                    const node = pair[1];
                    return (
                        <div key={`CellHeader_${id}_${i}`} className={'Cell'}>
                            {hasValue(node)}
                        </div>
                    );
                })}

                {isNotCollapsible ? null : (
                    <div className="ChevronColumn">
                        <img
                            src={Chevron}
                            alt="Toggle row expansion"
                            className={classNames('Chevron', { Expanded: isExpanded })}
                            onMouseDown={() => handleToggleRowExpand(id)}
                        />
                    </div>
                )}
            </div>

            {isExpanded || isNotCollapsible ? (
                <div className="RowBody">
                    {generatePairs(bodyColumnKeys).map((pair, i) => {
                        const colKey = pair[0];
                        const node = pair[1];
                        const colLabel = findColLabel(colKey);

                        return (
                            <div className={'Cell'} key={`CellBody_${id}_${i}`}>
                                <div className="CellInner">
                                    <span className="CellTitle">{colLabel}</span>
                                    {hasValue(node)}
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : null}

            {rowButton ? rowButton(row) : null}
        </div>
    );
};
