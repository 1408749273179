import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

export const useDebouncedState = <T>(
    initialValue: T,
    debounceTime: number = 500
): [T, Dispatch<SetStateAction<T>>, T] => {
    const [debouncedState, setDebouncedState] = useState<T>(initialValue);
    const [temporaryState, setTemporaryState] = useState<T>(initialValue);
    const timeout = useRef<number | null>(null);

    useEffect(() => {
        if (timeout.current) {
            window.clearTimeout(timeout.current);
        }
        const localTimeout = window.setTimeout(() => {
            setDebouncedState(temporaryState);
        }, debounceTime);
        timeout.current = localTimeout;
    }, [temporaryState, debounceTime]);

    return [debouncedState, setTemporaryState, temporaryState];
};
