import { toast, ToastOptions } from 'react-toastify';

export enum ToastMessageReason {
    ERROR = 1,
    VALID,
    PENDING,
}

(window as any).toast = toast;

export class Toast {
    public static openToastMessage(
        message: string,
        type: ToastMessageReason,
        options?: ToastOptions
    ) {
        if (type === ToastMessageReason.VALID) {
            toast.success(message, options);
        } else if (type === ToastMessageReason.PENDING) {
            toast.info(message, options);
        } else if (type === ToastMessageReason.ERROR) {
            toast.error(message, options);
        }
    }

    public static openGenericErrorToast(options?: ToastOptions) {
        toast.error('There was an error. Please try again later.', options);
    }
}
