import Button from 'components/button/Button';
import { TFAField } from 'components/form/TFAField';
import { ValidationCodeField } from 'components/form/ValidationCodeField';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { useTFAField } from 'helpers/useTFAField';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modal';
import * as Yup from 'yup';
import { IUpdateTFA, ProfileParser, UpdateAppTFAState } from './models';
import { ProfileApi } from './ProfileApi';
import { SecurityContext } from './Security';

type UpdateAppTFAModalProps = {
    isSMSAuth: boolean | null;
    isAppAuth: boolean | null;
};

enum UpdateAppTFAStep {
    CURRENT_TFA = 1,
    SHOW_NEW_DETAILS,
    CONFIRM_NEW,
}

const UpdateAppTFAModal: React.FC<UpdateAppTFAModalProps> = ({ isAppAuth, isSMSAuth }) => {
    const [currentStep, setCurrentStep] = useState<UpdateAppTFAStep>(UpdateAppTFAStep.CURRENT_TFA);
    const [updateAppTFA, setUpdateAppTFA] = useState<UpdateAppTFAState | null>(null);
    const [tfaType, toggleTFAType] = useTFAField();
    const [errors, setErrors] = useState<string[] | null>(null);
    const dispatch = useDispatch();
    const { reloadSecurityData } = useContext(SecurityContext);

    const validationSchema = Yup.object({
        tfaCode:
            currentStep === UpdateAppTFAStep.CONFIRM_NEW
                ? Yup.string()
                : Yup.string().required('Please enter your two-factor authentication code'),
        tfaCodeConfirm:
            currentStep === UpdateAppTFAStep.CONFIRM_NEW
                ? Yup.string().required('Please enter your two-factor authentication code')
                : Yup.string(),
        tfaType: Yup.string(),
        changeTfaType: Yup.string(),
        totpSharedSecret: Yup.string(),
    });

    const initialValues: IUpdateTFA = {
        tfaCode: '',
        tfaCodeConfirm: '',
        tfaType: isSMSAuth ? 'SMS' : 'AuthenticatorApp',
        changeTfaType: 'AuthenticatorApp',
        totpSharedSecret: updateAppTFA ? updateAppTFA.sharedSecret : '',
    };

    const handleSubmit = (values: IUpdateTFA, helpers: FormikHelpers<IUpdateTFA>) => {
        if (currentStep === UpdateAppTFAStep.CURRENT_TFA) {
            (async () => {
                const result = await ProfileApi.UpdateAppTFA(
                    ProfileParser.parseUpdateAppTFA({ ...values, tfaType: tfaType })
                );
                if (ProfileApi.isSuccessData(result)) {
                    setUpdateAppTFA(result.data);
                    setCurrentStep(UpdateAppTFAStep.SHOW_NEW_DETAILS);
                    setErrors([]);
                } else if (ProfileApi.isErrorData(result)) {
                    setErrors(result.errors);
                } else {
                    Toast.openGenericErrorToast();
                }
                helpers.setSubmitting(false);
            })();
        } else if (currentStep === UpdateAppTFAStep.CONFIRM_NEW) {
            (async () => {
                const result = await ProfileApi.UpdateTFAConfirm(
                    ProfileParser.parseConfirmUpdateAppTFA(values)
                );
                if (ProfileApi.isSuccessData(result)) {
                    reloadSecurityData();
                    Toast.openToastMessage(
                        'You have successfully enabled App two-factor authentication',
                        ToastMessageReason.VALID
                    );
                    setErrors([]);
                    dispatch(closeModal());
                } else if (ProfileApi.isErrorData(result)) {
                    setErrors(result.errors);
                } else {
                    Toast.openGenericErrorToast();
                }
                helpers.setSubmitting(false);
            })();
        }
    };

    return (
        <div className="UpdateAppTFAModal">
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                enableReinitialize
            >
                {(formikProps: FormikProps<IUpdateTFA>) => (
                    <Form>
                        <>
                            {currentStep === UpdateAppTFAStep.CURRENT_TFA ? (
                                <>
                                    <TFAField
                                        field={'tfaCode'}
                                        label={'TFA Code'}
                                        autoComplete={'off'}
                                        value={formikProps.values.tfaCode}
                                        toggleTFAType={toggleTFAType}
                                        tfaType={tfaType}
                                        required
                                        autoFocus
                                        holdFocus
                                    />
                                </>
                            ) : currentStep === UpdateAppTFAStep.SHOW_NEW_DETAILS ? (
                                updateAppTFA ? (
                                    <div>
                                        <img
                                            className="QRCode"
                                            src={'data:image/png;base64, ' + updateAppTFA.qrCode}
                                            alt="qrCode"
                                        />
                                        <p className="SharedSecret">{updateAppTFA.sharedSecret}</p>
                                    </div>
                                ) : null
                            ) : currentStep === UpdateAppTFAStep.CONFIRM_NEW ? (
                                <>
                                    <ValidationCodeField
                                        field={'tfaCodeConfirm'}
                                        label={'Enter the code on your App to confirm'}
                                        autoComplete={'off'}
                                        value={formikProps.values.tfaCodeConfirm}
                                        required
                                        autoFocus
                                        holdFocus
                                    />
                                </>
                            ) : null}
                            {errors && (
                                <div style={{ width: '100%' }}>
                                    {errors.map((error) => (
                                        <p className="ErrorText NoMargin">{error}</p>
                                    ))}
                                </div>
                            )}
                            <div className="ModalNavigation">
                                <Button
                                    priority="secondary"
                                    type="button"
                                    onClick={() => {
                                        dispatch(closeModal());
                                    }}
                                    color="grey"
                                >
                                    Cancel
                                </Button>
                                {currentStep === UpdateAppTFAStep.CURRENT_TFA ? (
                                    <Button
                                        priority="primary"
                                        type="submit"
                                        disabled={formikProps.isSubmitting}
                                    >
                                        Next
                                    </Button>
                                ) : currentStep === UpdateAppTFAStep.SHOW_NEW_DETAILS ? (
                                    <Button
                                        priority="primary"
                                        type="button"
                                        onClick={() => {
                                            formikProps.setErrors({});
                                            setCurrentStep(UpdateAppTFAStep.CONFIRM_NEW);
                                        }}
                                        disabled={formikProps.isSubmitting}
                                    >
                                        Next
                                    </Button>
                                ) : currentStep === UpdateAppTFAStep.CONFIRM_NEW ? (
                                    <Button
                                        priority="primary"
                                        type="submit"
                                        disabled={formikProps.isSubmitting}
                                    >
                                        Update
                                    </Button>
                                ) : null}
                            </div>
                        </>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export { UpdateAppTFAModal };
