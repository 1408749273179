import { fiatCurrencyFormatter } from 'appConstants';
import WalletIcon from 'assets/ibanera/Icon_Big_Deposit.png';
import GuaranteeLogo from 'assets/ibanera/Icon_Big_Guarantee.png';
import LockedIcon from 'assets/ibanera/Icon_Lock_01.png';
import UnlockedIcon from 'assets/ibanera/Icon_Lock_02.png';
import Button from 'components/button/Button';
import { CurrencyIcon } from 'components/currencyIcon/CurrencyIcon';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCryptoPrice } from 'reducers/cryptoPrices';
import { CurrentHolding, selectCurrentHoldings } from 'reducers/currentHoldings';
import { ModalType, openModal } from 'reducers/modal';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper/core';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import { formatPriceWithLocale } from 'helpers/formatPriceWithCommas';
import Spinner1 from 'assets/Icon_Spinner_01.png';
import { selectFiatCurrency } from 'reducers/availableAssets';

SwiperCore.use([Navigation]);

export const BalanceTiles = () => {
    const dispatch = useDispatch();

    const handleOpenCreateGuarantee = () => {
        dispatch(openModal({ modalType: ModalType.CREATE_GUARANTEE }));
    };

    const cryptoHoldings = useSelector(selectCurrentHoldings);
    return (
        <div className="BalanceTiles">
            {/* If holdings have been fetched */}
            {cryptoHoldings ? (
                // If the user has deposited crypto.
                cryptoHoldings.length > 0 ? (
                    <>
                        <div className="SwiperContainer">
                            <Swiper
                                spaceBetween={20}
                                centeredSlides={false}
                                // freeMode
                                slidesPerView={'auto'}
                                navigation
                            >
                                {cryptoHoldings.map((holding) => (
                                    <SwiperSlide key={holding.fireblocksAssetId}>
                                        <BalanceTile {...holding} layout="horizontal" />
                                    </SwiperSlide>
                                ))}
                                {cryptoHoldings.length <= 2 && (
                                    <SwiperSlide className="DummySlide One">
                                        <div className="BalanceTile DummyTile One" />
                                    </SwiperSlide>
                                )}
                                {cryptoHoldings.length <= 1 && (
                                    <SwiperSlide className="DummySlide Two">
                                        <div className="BalanceTile DummyTile Two" />
                                    </SwiperSlide>
                                )}
                            </Swiper>
                        </div>
                        <div className="CreateTileContainer">
                            {cryptoHoldings && cryptoHoldings.length > 0 && (
                                <>
                                    <div className="CreateGuaranteeTile">
                                        <div
                                            className="UnstyledButton ButtonContainer"
                                            onClick={handleOpenCreateGuarantee}
                                        >
                                            <img
                                                src={GuaranteeLogo}
                                                alt="Guarantees"
                                                className="GuaranteeLogo"
                                            />
                                            <Button /* onClick={handleOpenCreateGuarantee} */>
                                                Create Transaction
                                            </Button>
                                        </div>
                                    </div>
                                    <Button
                                        className="CreateGuaranteeBtnMobile"
                                        color="second"
                                        minWidth
                                        onClick={handleOpenCreateGuarantee}
                                    >
                                        Create Transaction
                                    </Button>
                                </>
                            )}
                        </div>
                    </>
                ) : (
                    // Else the user sees the prompt to deposit.
                    <WelcomeTile />
                )
            ) : // If data still being fetched, render nothing.
            // This data being fetched could be added as a dependecy for the
            // loading screen if so the user never sees this, if requested.
            null}
        </div>
    );
};

const WelcomeTile: React.FC = () => {
    const dispatch = useDispatch();

    const handleDepositCrypto = () => {
        dispatch(openModal({ modalType: ModalType.DEPOSIT_CRYPTO }));
    };

    return (
        <div className="WelcomeTile">
            <div className="FlexContainer">
                <div className="Left">
                    <h1 className="NoMargin Title">Welcome to Bitline</h1>
                    <h2 className="Subtitle NoMargin">
                        It’s very simple to create a transaction for any of our participating
                        casinos.
                    </h2>
                    <h3 className="Description NoMargin">
                        Get started by making your first deposit of crypto funds.
                    </h3>
                </div>
                <div className="Right">
                    <img alt="Wwallet icon" src={WalletIcon} className="" />
                    <Button priority="primary" onClick={handleDepositCrypto} className="Deposit">
                        Deposit Crypto
                    </Button>
                </div>
            </div>
        </div>
    );
};

type BalanceTileProps = CurrentHolding & { layout: 'vertical' | 'horizontal' };

const BalanceTile: React.FC<BalanceTileProps> = ({
    lockedBalance,
    displayCode,
    balance,
    unlockedBalance,
    fireblocksAssetId,
    layout = 'vertical',
    lockedBalanceHasPending,
    unlockedBalanceHasPending,
}) => {
    const fiatCurrencyCode = useSelector(selectFiatCurrency);
    const cryptoPrice = useSelector(selectCryptoPrice(fireblocksAssetId, fiatCurrencyCode));
    const unlockedFiatValue = cryptoPrice && unlockedBalance ? unlockedBalance * cryptoPrice : null;
    const lockedFiatValue = cryptoPrice && lockedBalance ? lockedBalance * cryptoPrice : null;
    const dispatch = useDispatch();

    const handleOpenDeposit = () => {
        dispatch(openModal({ modalType: ModalType.DEPOSIT_CRYPTO, data: { fireblocksAssetId } }));
    };
    const handleOpenWithdrawModal = (assetId: string) => {
        dispatch(
            openModal({
                modalType: ModalType.WITHDRAW_CRYPTO,
                data: { fireblocksAssetId: assetId },
            })
        );
    };

    return (
        <div className="BalanceTile">
            <div className="CurrencyIconContainer">
                <CurrencyIcon currency={fireblocksAssetId} className="BalanceCurrencyIcon" />
                {(unlockedBalanceHasPending || lockedBalanceHasPending) && (
                    <>
                        <img src={Spinner1} alt="" className="SpinImg" width="68px" />
                        <p>Confirming</p>
                    </>
                )}
            </div>
            <h1 className="TotalAmount">
                {balance ? formatPriceWithLocale(balance, 'en-US', 6) : '0'}
                <span className="Ticker">
                    {'   '}
                    {displayCode}
                </span>
            </h1>
            <div
                className={`TwoColumn Amounts ${
                    layout === 'horizontal' ? 'Horizontal' : 'Vertical'
                }`}
            >
                <div className="AmountSection Locked">
                    <img src={LockedIcon} alt="Locked" className="LockIcon" />
                    <div className="TextWrapper">
                        <h2 className="Amount">
                            {lockedBalance ? formatPriceWithLocale(lockedBalance, 'en-US', 6) : '0'}
                            {'   '}
                            <span className="AmountTicker">{displayCode}</span>
                        </h2>
                        <span className="FiatValue">
                            {`${fiatCurrencyFormatter(
                                lockedFiatValue ?? 0,
                                fiatCurrencyCode ?? 'USD'
                            )} ${fiatCurrencyCode ?? 'USD'}`}
                        </span>
                    </div>
                </div>
                <div className="AmountSection Unlocked">
                    <img src={UnlockedIcon} alt="Locked" className="LockIcon" />
                    <div className="TextWrapper">
                        <h2 className="Amount">
                            {unlockedBalance
                                ? formatPriceWithLocale(unlockedBalance, 'en-US', 6)
                                : '0'}
                            {'   '}
                            <span className="AmountTicker">{displayCode}</span>
                        </h2>
                        <span className="FiatValue">
                            {`${fiatCurrencyFormatter(
                                unlockedFiatValue ?? 0,
                                fiatCurrencyCode ?? 'USD'
                            )} ${fiatCurrencyCode ?? 'USD'}`}
                        </span>
                    </div>
                </div>
            </div>
            <div className="TwoColumn">
                <Button onClick={handleOpenDeposit}>Deposit</Button>
                <Button
                    priority="secondary-2"
                    onClick={() => handleOpenWithdrawModal(fireblocksAssetId)}
                >
                    Withdraw
                </Button>
            </div>
        </div>
    );
};
