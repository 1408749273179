import React from 'react';
import FirstIcon from 'assets/Icon_Btn_First.png';
import PreviousIcon from 'assets/Icon_Btn_Previous.png';
import NextIcon from 'assets/Icon_Btn_Next.png';
import LastIcon from 'assets/Icon_Btn_Last.png';

export interface IPreviousButtons {
    clearSelections: () => void;
    goToPrevPage: () => void;
    goToPage: (p: number) => void;
    pageNumber: number;
    hasPrevPage: boolean;
    mobile?: boolean;
}

export interface INextButtons {
    clearSelections: () => void;
    goToNextPage: () => void;
    goToPage: (p: number) => void;
    pageNumber: number;
    lastPage: number;
    hasNextPage: boolean;
    resultsCount: number;
}

const NextButtons: React.FC<INextButtons> = ({
    clearSelections,
    goToNextPage,
    goToPage,
    hasNextPage,
    lastPage,
    pageNumber,
    resultsCount,
}) => {
    return (
        <div className="PaginationButtonsRight">
            <button
                className="PaginatorBtn"
                onClick={() => {
                    clearSelections();
                    goToNextPage();
                }}
                disabled={!hasNextPage}
            >
                <img src={NextIcon} alt={'First'} />
            </button>
            <button
                className="PaginatorBtn Last"
                onClick={() => {
                    clearSelections();
                    goToPage(lastPage);
                }}
                disabled={pageNumber === lastPage || !resultsCount}
            >
                <img src={LastIcon} alt={'First'} />
            </button>
        </div>
    );
};

const PreviousButtons: React.FC<IPreviousButtons> = ({
    clearSelections,
    goToPage,
    goToPrevPage,
    hasPrevPage,
    pageNumber,
    mobile,
}) => {
    return (
        <div className={mobile ? 'PaginationButtonsLeft Mobile' : 'PaginationButtonsLeft'}>
            <button
                className="PaginatorBtn First"
                onClick={() => {
                    clearSelections();
                    goToPage(1);
                }}
                disabled={pageNumber === 1}
            >
                <img src={FirstIcon} alt={'First'} />
            </button>
            <button
                className="PaginatorBtn"
                onClick={() => {
                    clearSelections();
                    goToPrevPage();
                }}
                disabled={!hasPrevPage}
            >
                <img src={PreviousIcon} alt={'First'} />
            </button>
        </div>
    );
};

export { NextButtons, PreviousButtons };
