import { Link, useNavigate } from '@reach/router';
import { AppPath } from 'appConstants';
import Icon_Form_01 from 'assets/ibanera/Icon_Form_01.png';
import Button from 'components/button/Button';
import FormTextField from 'components/form/FormTextField';
import { PasswordHelper } from 'components/passwordHelper/PasswordHelper';
import { PasswordStrengthMeter } from 'components/passwordStrengthMeter/PasswordStrengthMeter';
import { Spinner } from 'components/spinner/Spinner';
import { Form, Formik, FormikHelpers, useField, useFormikContext } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { RegisterFormSubForm } from './models';
import { postRegisterCustomerUser } from './RegisterApi';
import { handleRegisterFormErrors } from './RegistrationErrorHandling';
import BackgroundImage from 'assets/ibanera/Bg_Register.jpg';
import { ERROR_CODES, getErrorMessage } from 'errors';
import PhoneInput from 'react-phone-number-input';
import { ErrorM } from 'components/form/ErrorM';
import { toCamelCase } from 'helpers/formatFormFieldNames';
import { useSelector } from 'react-redux';
import { selectCultureCode } from 'reducers/language';
import { SplashPage } from 'pages/SplashPage';
import { nameCombiner } from 'helpers/nameCombiner';
import { CheckboxInput } from 'components/form/CheckboxInput';
import { RegistrationFormValues } from './RegistrationForm';

export const InputPersonalDetails: React.FC<RegisterFormSubForm> = ({
    translations,
    errorMessage,
}) => {
    const cultureCode = useSelector(selectCultureCode);
    const navigate = useNavigate();

    const { values, isSubmitting } = useFormikContext<RegistrationFormValues>();

    if (!translations) return null;
    return (
        <Form>
            <div className="RegisterFormWidth">
                <p style={{ fontWeight: 600 }}>
                    Register for free to get started. Enter your details below as they appear on
                    your ID
                </p>
                <div className="FormSideBySide">
                    <FormTextField
                        field={'firstName'}
                        label={'First Name'}
                        required={true}
                        maxLength={200}
                        sideBySide={true}
                        placeholder="Enter your name"
                    />
                    <FormTextField
                        field={'middleNames'}
                        label={'Middle Name(s)'}
                        required={false}
                        maxLength={200}
                        sideBySide={true}
                        placeholder="Enter your name"
                    />
                    <FormTextField
                        field={'lastName'}
                        label={'Last Name'}
                        required={true}
                        maxLength={200}
                        sideBySide={true}
                        placeholder="Enter your name"
                    />
                </div>

                <div style={{ marginBottom: 20 }}>
                    <div className={`NameConfirmation ${values.nameConfirmed ? 'Checked' : ''}`}>
                        <CheckboxInput
                            name="nameConfirmed"
                            label={`I confirm that the name entered is the same as it appears on my ID`}
                        />
                    </div>
                    <ErrorM name="nameConfirmed" />
                </div>

                {errorMessage && <div className="ErrorMessage">{errorMessage}</div>}
                <Button
                    priority="primary"
                    variety="full"
                    type="submit"
                    disabled={isSubmitting}
                    color="second"
                >
                    {isSubmitting ? <Spinner color={'#fff'} /> : translations.continue_button}
                </Button>
                <Button
                    priority="secondary"
                    variety="full"
                    type="button"
                    disabled={isSubmitting}
                    color="first"
                    style={{ marginTop: 10 }}
                    onClick={() => navigate('/')}
                >
                    Cancel
                </Button>
            </div>
        </Form>
    );
};

export const FormPhoneNumberField = (props: {
    field: string;
    label?: string;
    sideBySide?: boolean;
    placeholder?: string;
}) => {
    const [field, meta, helpers] = useField(props.field);
    const showError = meta.touched && meta.error;

    return (
        <div className={`FormBox PhoneNumberBox ${props.sideBySide && 'SideBySide'}`}>
            <div className="FormLabel">
                <label>{props.label}</label>
            </div>
            <div>
                <PhoneInput
                    className="EditBox"
                    value={field.value}
                    onChange={(value: any) => {
                        value && helpers.setValue(value);
                    }}
                    defaultCountry={'US'}
                    name={props.field}
                    numberInputProps={{
                        className: `EditBox${showError ? ' ErrorInput' : ''}`,
                    }}
                    placeholder={props.placeholder}
                />
                <ErrorM name={props.field} />
            </div>
        </div>
    );
};
