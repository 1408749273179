import React from 'react';

type TFAInputMaskProps = {
    value: string;
    className?: string;
};

const TFA_INPUT_LENGTH = 6;

export const TFAInputMask: React.FC<TFAInputMaskProps> = ({ value, className }) => {
    const convertValueToMask = () =>
        Array(TFA_INPUT_LENGTH)
            .fill('')
            .map((_, index) => {
                const positionIsFilled = !!value[index];
                return (
                    <div key={index} className={`Point ${value[index] ? 'Filled' : ''}`}>
                        {value[index] ?? '•'}
                    </div>
                );
            });
    return <label className={`InputMask ${className}`}>{convertValueToMask()}</label>;
};
