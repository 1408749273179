import instance, { ApiResponse } from 'api';
import { Modal } from 'components/modal/Modal';
import {
    NotificationIdentifier,
    selectUIUpdate,
} from 'components/notifications/notificationUIUpdateReducer';
import { endpoints } from 'endpoints.config';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from 'reducers/auth';
import { JumioIframe } from './JumioIdIframe';
import { JumioTextualData } from './JumioTextualData';
import { JumioVerificationComplete } from './JumioVerificationComplete';
import { JumioManualVerificationRequired } from './JumioManualVerificationRequired';
import {
    fetchVerificationStatus,
    selectVerificationDetails,
    setVerificationStage,
} from 'reducers/jumio';
import { JumioStage } from './jumioModels';
import { Spinner } from 'components/spinner/Spinner';
import { JumioFailure } from './JumioFailure';
import { JumioCooldown } from './JumioCooldown';

export type VerifyIdentityOptions = {
    countryOptions: { isoCode: string; name: string }[];
    documentOptions: { typeCode: string; name: string }[];
};

export const JumioVerification = () => {
    const dispatch = useDispatch();
    const update = useSelector(selectUIUpdate);
    const [verifyIdentityOptions, setVerifyIdentityOptions] =
        useState<VerifyIdentityOptions | null>(null);
    const { stage, additionalData } = useSelector(selectVerificationDetails);

    useEffect(() => {
        if (update?.pushType !== NotificationIdentifier.UPDATE_VERIFICATION_COMPONENTS) return;
        dispatch(
            setVerificationStage({
                currentStage: update.data.status,
                additionalData: update.data.additionalData,
            })
        );
    }, [update, dispatch]);

    useEffect(() => {
        if (
            (stage === JumioStage.AddressFrameForm || stage === JumioStage.IdFrameSubmitted) &&
            !verifyIdentityOptions
        ) {
            instance
                .get<ApiResponse<VerifyIdentityOptions>>(endpoints.jumiomodule.verifyIdentity)
                .then((res) => {
                    setVerifyIdentityOptions(res.data.details);
                });
        }
    });

    useEffect(() => {
        const timeout = setTimeout(() => dispatch(fetchVerificationStatus()), 20000);
        return clearTimeout(timeout);
    });

    const chooseStage = () => {
        switch (stage) {
            case JumioStage.IdFrame:
                return (
                    additionalData?.iframeUrl && (
                        <JumioIframe url={additionalData?.iframeUrl} stage={stage} />
                    )
                );
            case JumioStage.IdFrameSubmitted:
                return <PendingScreen />;
            case JumioStage.IdFrameFailed:
                return <JumioFailure bManualAvailable={!!additionalData?.bManualAvailable} />;
            case JumioStage.AddressFrameForm:
                return (
                    verifyIdentityOptions && <JumioTextualData options={verifyIdentityOptions} />
                );
            case JumioStage.AddressFrame:
                return (
                    additionalData?.iframeUrl && (
                        <JumioIframe url={additionalData?.iframeUrl} stage={stage} />
                    )
                );
            case JumioStage.AddressFrameSubmitted:
                return <PendingScreen />;
            case JumioStage.AddressFrameFailed:
                return <JumioFailure bManualAvailable={!!additionalData?.bManualAvailable} />;
            case JumioStage.AwaitingManualVerification:
                return <JumioManualVerificationRequired />;
            case JumioStage.FrameCooldown:
                return <JumioCooldown bManualAvailable={!!additionalData?.bManualAvailable} />;
            case JumioStage.Success:
                return <JumioVerificationComplete />;
        }
    };

    return (
        <Modal
            title="Customer verification"
            customCloseButton={
                <span className="SignOutLink" onClick={() => dispatch(signOut())}>
                    Sign out
                </span>
            }
            className="JumioModal"
        >
            {chooseStage()}
        </Modal>
    );
};

const PendingScreen = () => {
    return (
        <div className="Waiting">
            <Spinner />
            <h3>Please wait, we are checking your details</h3>
            <p>This may take a few minutes, we'll email you when it's done.</p>
        </div>
    );
};
