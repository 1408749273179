/**
 * Takes a Date object with associated timezone information and converts to a Date
 * with the same year, month, day, time etc, but in UTC.
 */

export const createUTCDate = (date: Date) => {
    const newDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        0,
        -date.getTimezoneOffset()
    );
    return new Date(newDate);
};
