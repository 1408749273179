import instance, { ApiResponse } from 'api';
import {
    AdditionalData,
    JumioStage,
    VerificationStatusResponse,
} from 'components/jumio/jumioModels';
import { endpoints } from 'endpoints.config';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { ActionsObservable, combineEpics, ofType } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { signOut, SIGN_IN_SUCCESS } from './auth';
import { Store } from './rootReducer';

interface State {
    isVerified: boolean;
    currentStage: JumioStage | null;
    checking: boolean;
    additionalData?: AdditionalData;
}

const initialState: State = {
    isVerified: false,
    currentStage: null,
    checking: false,
    additionalData: null,
};

enum ActionType {
    FETCH_VERIFICATION_STATUS = 'FETCH_VERIFICATION_STATUS',
    SET_VERIFICATION_STATUS = 'SET_VERIFICATION_STATUS',
    DELETE_VERIFICATION_STATUS = 'DELETE_VERIFICATION_STATUS',
    UPDATE_VERIFICATION_STATUS = 'UPDATE_VERIFICATION_STATUS',
}

type Action =
    | { type: ActionType.FETCH_VERIFICATION_STATUS }
    | { type: ActionType.SET_VERIFICATION_STATUS; payload: State }
    | { type: ActionType.UPDATE_VERIFICATION_STATUS; payload: Partial<State> }
    | { type: ActionType.DELETE_VERIFICATION_STATUS };

const reducer = (state: State = initialState, action: Action): State => {
    switch (action.type) {
        case ActionType.SET_VERIFICATION_STATUS:
            return action.payload as State;
        case ActionType.UPDATE_VERIFICATION_STATUS:
            return { ...state, ...action.payload };
        case ActionType.DELETE_VERIFICATION_STATUS:
            return initialState;
        case ActionType.FETCH_VERIFICATION_STATUS:
            return {
                ...state,
                checking: true,
            };
        default:
            return state;
    }
};

const fetchStatus = () => {
    return from(
        instance.get<ApiResponse<VerificationStatusResponse>>(
            endpoints.jumiomodule.verificationStatus
        )
    );
};

const fetchVerificationStatusEpic = (
    action$: ActionsObservable<{
        type: ActionType.FETCH_VERIFICATION_STATUS | typeof SIGN_IN_SUCCESS;
    }>
) => {
    return action$.pipe(
        ofType(ActionType.FETCH_VERIFICATION_STATUS, SIGN_IN_SUCCESS),
        switchMap(() =>
            fetchStatus().pipe(
                filter((response) => response.data.status === '1'),
                map((response) => response.data.details),
                map((details) => {
                    return setVerificationStatus({
                        checking: false,
                        isVerified: details.status === JumioStage.Verified,
                        currentStage: details.status,
                        additionalData: details.additionalData,
                    });
                }),
                catchError(() => {
                    Toast.openToastMessage(
                        'We are currently experiencing problems with our KYC provider. Please try again later.',
                        ToastMessageReason.ERROR
                    );
                    return [signOut()];
                })
            )
        )
    );
};
export default reducer;
export const verificationEpic = combineEpics(fetchVerificationStatusEpic);
//action creators
const setVerificationStatus = (payload: State): Action => {
    return { type: ActionType.SET_VERIFICATION_STATUS, payload: payload };
};
export const deleteVerificationStatus = (): Action => ({
    type: ActionType.DELETE_VERIFICATION_STATUS,
});

export const fetchVerificationStatus = () => ({
    type: ActionType.FETCH_VERIFICATION_STATUS,
});

export const setVerificationStage = (payload: Omit<State, 'isVerified' | 'checking'>): Action => {
    return {
        type: ActionType.SET_VERIFICATION_STATUS,
        payload: { checking: false, isVerified: false, ...payload },
    };
};

export const updateAdditionalData = (additionalData: AdditionalData): Action => {
    return {
        type: ActionType.UPDATE_VERIFICATION_STATUS,
        payload: { additionalData },
    };
};

// Selectors
export const selectVerificationLoading = (store: Store) => store.jumio.checking;
export const selectisVerified = (store: Store) => store.jumio.isVerified;
export const selectVerificationDetails = (store: Store) => ({
    stage: store.jumio.currentStage,
    additionalData: store.jumio.additionalData,
});
