import { isErrorHandled, retrieveErrorMessages } from 'api';
import Button from 'components/button/Button';
import { TFAField } from 'components/form/TFAField';
import { ValidationCodeField } from 'components/form/ValidationCodeField';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { useTFAField } from 'helpers/useTFAField';
import { TFAValidation, VerificationCodeValidation } from 'helpers/validationSnippets';
import { FormPhoneNumberField } from 'pages/register/InputPersonalDetails';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modal';
import * as Yup from 'yup';
import { IUpdatePhoneNumber, IVerifyPhoneNumber } from './models';
import { ProfileApi } from './ProfileApi';
import { SecurityContext } from './Security';
import mobileIcon from 'assets/bitline-ui-redesign/blue mobile icon.svg';
import { useTheme } from '@emotion/react';
import WarningIcon from 'assets/bitline-ui-redesign/error icon.svg';
import { useProfileContext } from './useProfileContext';

type UpdatePhoneNumberModalProps = {
    isSMSAuth: boolean | null;
    isAppAuth: boolean | null;
};

const addNumberValidationSchema = Yup.object({
    showTfa: Yup.boolean(),
    phoneNumber: Yup.string().required('Please enter a phone number'),
    tfaCode: Yup.string().when('showTfa', { is: true, then: TFAValidation }),
});

const verifyValidationSchema = Yup.object({
    phoneNumberVerificationCode: VerificationCodeValidation,
});

enum UpdatePhoneNumberStep {
    ADD_NUMBER = 1,
    VERIFY,
}

const UpdatePhoneNumberModal: React.FC<UpdatePhoneNumberModalProps> = ({
    isAppAuth,
    isSMSAuth,
}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [showTfa, setShowTfa] = useState(false);
    const [tfaType, toggleTFAType] = useTFAField(showTfa);

    const { profileDetails } = useProfileContext();

    const addNumberInitialValues: IUpdatePhoneNumber = {
        showTfa: false,
        phoneNumber: profileDetails?.phoneNumber ?? '',
        tfaCode: '',
        tfaType: isSMSAuth ? 'SMS' : 'AuthenticatorApp',
    };
    const { reloadSecurityData } = useContext(SecurityContext);

    const verifyInitialValues: IVerifyPhoneNumber = {
        phoneNumberVerificationCode: '',
        phoneNumber: '',
    };

    const [errors, setErrors] = useState<string[] | null>(null);
    const [currentStep, setCurrentStep] = useState<UpdatePhoneNumberStep>(
        UpdatePhoneNumberStep.ADD_NUMBER
    );
    const [storedPhoneNumber, setStoredPhoneNumber] = useState<string | null>(null);

    const handleAddNumberSubmit = async (
        values: IUpdatePhoneNumber,
        helpers: FormikHelpers<IUpdatePhoneNumber>
    ) => {
        if (!values.showTfa) {
            helpers.setFieldValue('showTfa', true);
            setShowTfa(true);
            helpers.setSubmitting(false);
            helpers.setFieldTouched('tfaCode', false);
            return;
        }
        try {
            await ProfileApi.UpdatePhoneNumber({ ...values, tfaType: tfaType });
            setStoredPhoneNumber(values.phoneNumber);
            setCurrentStep(UpdatePhoneNumberStep.VERIFY);
            setErrors(null);
        } catch (error: any) {
            if (isErrorHandled(error)) {
                const responseErrors = retrieveErrorMessages(error.response.data.errors);
                setErrors(responseErrors);
            } else {
                dispatch(closeModal());
                Toast.openGenericErrorToast();
            }
        }
        helpers.setSubmitting(false);
    };

    const handleVerifySubmit = async (
        values: IVerifyPhoneNumber,
        helpers: FormikHelpers<IVerifyPhoneNumber>
    ) => {
        if (storedPhoneNumber) {
            try {
                await ProfileApi.VerifyPhoneNumber({ ...values, phoneNumber: storedPhoneNumber });
                reloadSecurityData();
                dispatch(closeModal());
                Toast.openToastMessage(
                    'Phone number changed successfully',
                    ToastMessageReason.VALID
                );
            } catch (error: any) {
                if (isErrorHandled(error)) {
                    const responseErrors = retrieveErrorMessages(error.response.data.errors);
                    setErrors(responseErrors);
                    helpers.setSubmitting(false);
                } else {
                    dispatch(closeModal());
                    Toast.openGenericErrorToast();
                }
            }
        } else {
            dispatch(closeModal());
            Toast.openGenericErrorToast();
        }
        helpers.setSubmitting(false);
    };

    // TODO(HC): Refactor into a single Formik context.
    return (
        <div className="UpdatePhoneNumberModal">
            <h1 className="Title">Update Phone Number</h1>

            <img src={mobileIcon} alt="blue mobile icon" className="MobileIcon" />

            <Formik
                initialValues={addNumberInitialValues}
                onSubmit={handleAddNumberSubmit}
                validationSchema={addNumberValidationSchema}
                enableReinitialize
            >
                {(formikProps: FormikProps<IUpdatePhoneNumber>) => (
                    <Form
                        style={{
                            display:
                                currentStep === UpdatePhoneNumberStep.ADD_NUMBER ? 'flex' : 'none',
                        }}
                        className={'Form'}
                    >
                        <>
                            <FormPhoneNumberField field="phoneNumber" label="New phone number" />
                            {formikProps.values.showTfa && (
                                <>
                                    <TFAField
                                        field={'tfaCode'}
                                        label={'Two-factor authentication code'}
                                        autoComplete={'off'}
                                        value={formikProps.values.tfaCode}
                                        tfaType={tfaType}
                                        toggleTFAType={toggleTFAType}
                                        required
                                        autoFocus
                                    />
                                </>
                            )}
                            {errors && (
                                <>
                                    {errors.map((error) => (
                                        <div className="ErrorLabel PhoneNumberErrorMessages">
                                            <img
                                                src={WarningIcon}
                                                alt="Warning icon"
                                                className="WarningIcon"
                                            />
                                            <span className="ErrorText">{error}</span>
                                        </div>
                                    ))}
                                </>
                            )}

                            <Button
                                priority="primary"
                                type="submit"
                                style={{
                                    backgroundColor: theme.colors.second,
                                    borderColor: theme.colors.second,
                                }}
                                disabled={formikProps.isSubmitting || !formikProps.isValid}
                            >
                                Save Changes
                            </Button>
                            <Button
                                priority="secondary"
                                type="button"
                                className="Secondary"
                                onClick={() => {
                                    dispatch(closeModal());
                                }}
                            >
                                Cancel
                            </Button>
                        </>
                    </Form>
                )}
            </Formik>
            <Formik
                initialValues={verifyInitialValues}
                validationSchema={verifyValidationSchema}
                // Calling submit manually in onClick as Formik seems to have trouble
                // with mulitle context rendered at once.
                onSubmit={() => {}}
                validateOnMount
                enableReinitialize
            >
                {(helpers) => (
                    <Form
                        style={{
                            display: currentStep === UpdatePhoneNumberStep.VERIFY ? 'flex' : 'none',
                        }}
                        className={'Form'}
                    >
                        <>
                            <ValidationCodeField
                                required
                                field="phoneNumberVerificationCode"
                                label="Verification Code"
                                autoComplete={'one-time-code'}
                                value={helpers.values.phoneNumberVerificationCode}
                                autoFocus
                                holdFocus
                            />
                            {errors && (
                                <>
                                    {errors.map((error) => (
                                        <div className="ErrorLabel PhoneNumberErrorMessages">
                                            <img
                                                src={WarningIcon}
                                                alt="Warning icon"
                                                className="WarningIcon"
                                            />
                                            <span className="ErrorText">{error}</span>
                                        </div>
                                    ))}
                                </>
                            )}

                            <Button
                                priority="primary"
                                type="submit"
                                style={{
                                    backgroundColor: theme.colors.second,
                                    borderColor: theme.colors.second,
                                }}
                                disabled={!helpers.isValid}
                                // Calling submit manually as Formik seems to have trouble with
                                // mulitle context rendered at once.
                                onClick={() => handleVerifySubmit(helpers.values, helpers)}
                            >
                                Save Changes
                            </Button>
                            <Button
                                priority="secondary"
                                type="button"
                                className="Secondary"
                                onClick={() => {
                                    dispatch(closeModal());
                                }}
                            >
                                Cancel
                            </Button>
                        </>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export { UpdatePhoneNumberModal };
