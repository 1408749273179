import { navigate, RouteComponentProps } from '@reach/router';
import { DASHBOARD_MENU_PAGE_SUB_PAGE_PLACEHOLDER } from 'App';
import { AppPath, DashboardPath } from 'appConstants';
import Page from 'components/page/Page';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectComponentResources } from 'reducers/componentResources';
import { selectCultureCode } from 'reducers/language';

export const CheckingDetails: React.FC<RouteComponentProps> = () => {
    const { userInfo } = useSelector(selectComponentResources);
    const cultureCode = useSelector(selectCultureCode);

    if (userInfo) {
        navigate(
            `/${cultureCode}${AppPath.PERSONAL}${DashboardPath.HOME}/${DASHBOARD_MENU_PAGE_SUB_PAGE_PLACEHOLDER}`
        );
    }

    // We return an empty page component here so that the <Navigation /> component is
    // rendered, which uses the useGetComponentResources hook, hence if the user manually
    // refreshes on this page, the component resources will be refetched.
    return <Page />;
};
