import { GuaranteeTableRow } from 'components/guaranteesTable/GuaranteesTable';
import { TFAType } from 'types/shared';
import { Store } from './rootReducer';

export type Nullable<T> = T | null;

//Types
export enum ModalType {
    RESEND_CODE = 'RESEND_CODE',
    CRYPTO_CREATE_ALERT = 'CRYPTO_CREATE_ALERT',
    CRYPTO_CURRENCY_SELECT = 'CRYPTO_CURRENCY_SELECT',
    DEPOSIT_CRYPTO = 'DEPOSIT_CRYPTO',
    CREATE_GUARANTEE = 'CREATE_GUARANTEE',
    PROFILE_UPDATE_PASSWORD = 'PROFILE_UPDATE_PASSWORD',
    PROFILE_UPDATE_PHONE_NUMBER = 'PROFILE_UPDATE_PHONE_NUMBER',
    PROFILE_UPDATE_PHONE_NUMBER_CONFIRM = 'PROFILE_UPDATE_PHONE_NUMBER_CONFIRM',
    PROFILE_UPDATE_EMAIL = 'PROFILE_UPDATE_EMAIL',
    PROFILE_UPDATE_EMAIL_CONFIRM = 'PROFILE_UPDATE_EMAIL_CONFIRM',
    PROFILE_DEACTIVATE_ACCOUNT = 'PROFILE_DEACTIVATE_ACCOUNT',
    PROFILE_ENABLE_SMS_TFA = 'PROFILE_ENABLE_SMS_TFA',
    PROFILE_UPDATE_APP_TFA = 'PROFILE_UPDATE_APP_TFA',
    PROFILE_DISABLE_TFA_TYPE = 'PROFILE_DISABLE_TFA_TYPE',
    WITHDRAW_CRYPTO = 'WITHDRAW_CRYPTO',
    ACTIVATE_GUARANTEE = 'ACTIVATE_GUARANTEE',
    UNLOCK_GUARANTEE = 'UNLOCK_GUARANTEE',
    TOP_UP = 'TOP_UP',
    PARTIAL_RELEASE = 'PARTIAL_RELEASE',
    DELETE_GUARANTEE = 'DELETE_GUARANTEE',
    UPDATE_PROFILE_INFORMATION = 'UPDATE_PROFILE_INFORMATION',
    UPDATE_ADDRESS = 'UPDATE_ADDRESS',
}

enum ModalActionType {
    OPEN_MODAL = 'OPEN_MODAL',
    CLOSE_MODAL = 'CLOSE_MODAL',
}

export type ResendCodeModalData = {
    phoneNumber: string;
    buttons?: { primary: string; secondary: string };
};
export type WithdrawModalData = {
    fireblocksAssetId: string;
};
export type DepositModalData = { fireblocksAssetId: string } | undefined;
export type ActivateGuaranteeModalData = GuaranteeTableRow | undefined;
export type UnlockGuaranteeModalData = GuaranteeTableRow | undefined;
export type TopUpModalData = GuaranteeTableRow | undefined;
export type PartialReleaseModalData = GuaranteeTableRow | undefined;
export type DeleteGuaranteeModalData = GuaranteeTableRow | undefined;
export type DisableTFATypeModalData = TFAType | undefined;

export function isWithdrawModalData(data: ModalState['data']): data is WithdrawModalData {
    return !!data && !!(data as WithdrawModalData).fireblocksAssetId;
}

type ModalPayload = {
    modalType: ModalType;
    data?: ModalState['data'];
};

type ModalAction = { type: ModalActionType; payload: ModalPayload };

// State
type ModalState = {
    modalType: ModalType | null;
    data:
        | ResendCodeModalData
        | WithdrawModalData
        | DepositModalData
        | GuaranteeTableRow
        | PartialReleaseModalData
        | ActivateGuaranteeModalData
        | UnlockGuaranteeModalData
        | TopUpModalData
        | DeleteGuaranteeModalData
        | DisableTFATypeModalData
        | null;
};

export const initialState: ModalState = {
    modalType: null,
    data: null,
};

// Reducer
export default function modalReducer(state = initialState, action: ModalAction): ModalState {
    switch (action.type) {
        case ModalActionType.OPEN_MODAL:
            return {
                ...state,
                modalType: action.payload.modalType,
                data: action.payload.data ? action.payload.data : null,
            };
        case ModalActionType.CLOSE_MODAL:
            return {
                ...state,
                modalType: null,
                data: null,
            };
        default:
            return state;
    }
}

// Action creators
export const openModal = (payload: ModalAction['payload']) => ({
    type: ModalActionType.OPEN_MODAL,
    payload,
});

export const closeModal = () => ({ type: ModalActionType.CLOSE_MODAL });

// Selectors
export const selectModalState = (state: Store) => state.modal;
