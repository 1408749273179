import UpDown from 'assets/bitline-ui-redesign/chevron down.svg';
import { CurrencyIcon } from 'components/currencyIcon/CurrencyIcon';
import React, { MutableRefObject, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Store } from 'reducers/rootReducer';
import { CryptoOption } from 'types/shared';

// The availableAssets coming from the backend are a different shape to those being
// expected by this select component. We define functions to get one from the other.
export const mapAvailableAssetsToDropdownSelectOptions = (
    assets: NonNullable<Store['availableAssets']['availableAssets']>
) =>
    assets.length > 0 &&
    assets.map<CryptoOption>((crypto) => ({
        name: crypto.name,
        ticker: crypto.fireblocksAssetId,
        displayCode: crypto.displayCode,
        fireblocksId: crypto.fireblocksAssetId,
        id: crypto.id,
    }));

interface IOption {
    ticker: string;
    name: string;
}

type CryptoDropdownSelectProps = {
    options: CryptoOption[];
    selectedCrypto: CryptoOption | null;
    setSelectedCrypto: (crypto: CryptoOption) => void;
    amount?: number;
    portalTarget?: HTMLElement;
};

export const CryptoDropdownSelect: React.FC<CryptoDropdownSelectProps> = ({
    selectedCrypto,
    setSelectedCrypto,
    amount,
    portalTarget = undefined,
    options,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const containerRef = useRef<null | HTMLDivElement>(null);

    const handleSelect = (option: CryptoOption) => {
        setSelectedCrypto(option);
        setIsOpen(false);
    };

    const toggleOpen = () => {
        setIsOpen((prev) => !prev);
    };

    const dropdownOptions = options.filter(
        (option) => option.fireblocksId !== selectedCrypto?.fireblocksId
    );

    return (
        <div className="CryptoDropdownSelectContainer" ref={containerRef}>
            <div className="CurrentValue" onClick={toggleOpen}>
                {selectedCrypto && (
                    <CurrencyIcon currency={selectedCrypto.displayCode} className="Icon" />
                )}
                <div className="Text">
                    {selectedCrypto ? (
                        <h4 className="NoMargin Small">
                            {selectedCrypto.name}{' '}
                            {amount && (
                                <>
                                    ({amount.toPrecision(6)}{' '}
                                    <span className="Ticker">{selectedCrypto.displayCode}</span>)
                                </>
                            )}
                        </h4>
                    ) : (
                        <h4 className="NoMargin Small Placeholder">Select</h4>
                    )}
                </div>
                <img
                    src={UpDown}
                    className={isOpen ? 'UpDown Open' : 'UpDown Closed'}
                    alt="toggle select"
                />
            </div>
            {isOpen &&
                (portalTarget && containerRef.current ? (
                    <CryptoOptions
                        containerRef={containerRef as MutableRefObject<HTMLDivElement>}
                        portalTarget={portalTarget}
                        onClickGenerator={(option) => () => {
                            handleSelect(option);
                        }}
                        options={dropdownOptions}
                    />
                ) : (
                    <CryptoOptions
                        onClickGenerator={(option) => () => {
                            handleSelect(option);
                        }}
                        options={dropdownOptions}
                    />
                ))}
        </div>
    );
};

type CryptoOptionsProps = {
    options: CryptoOption[];
    onClickGenerator: (option: CryptoOption) => () => void;
    containerRef?: MutableRefObject<HTMLDivElement>;
    portalTarget?: HTMLElement;
};

const CryptoOptions: React.FC<CryptoOptionsProps> = ({
    onClickGenerator,
    options,
    // Both containerRef and usePortal must be set to use the portal.
    containerRef,
    portalTarget = null,
}) => {
    if (portalTarget && containerRef && containerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect();

        const styles: React.CSSProperties = {
            width: containerRect.width,
            top: containerRect.top + (containerRect.height + 10),
            left: containerRect.left,
        };

        return createPortal(
            <div className="SelectMenu CryptoOptions" style={styles}>
                {options.map((option) => (
                    <CryptoSelectOption
                        key={option.id}
                        name={option.name}
                        ticker={option.displayCode}
                        onClick={onClickGenerator(option)}
                    />
                ))}
            </div>,
            portalTarget
        );
    }

    return (
        <div className="SelectMenu">
            {options.map((option) => (
                <CryptoSelectOption
                    name={option.name}
                    ticker={option.displayCode}
                    onClick={onClickGenerator(option)}
                />
            ))}
        </div>
    );
};

type CryptoOptionProps = IOption & {
    onClick: (value: string) => void;
};

const CryptoSelectOption: React.FC<CryptoOptionProps> = ({ ticker, name, onClick }) => {
    return (
        <div className="CryptoOption" onClick={() => onClick(ticker)}>
            <CurrencyIcon currency={ticker} className="Icon" />
            <div className="Text">
                <h4 className="NoMargin">{name}</h4>
            </div>
        </div>
    );
};
