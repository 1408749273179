import { ThemeProvider } from '@emotion/react';
import { Router } from '@reach/router';
import GuaranteeIcon from 'assets/ibanera/Icon_Menu_06.png';
import AddressIcon from 'assets/address_icon.svg';
import { ActivateGuaranteeModal } from 'components/activateGuaranteeModal/ActivateGuaranteeModal';
import { CheckingDetails } from 'components/checkingDetails/CheckingDetails';
import { DeleteGuaranteeModal } from 'components/deleteGuaranteeModal/DeleteGuaranteeModal';
import { isGuaranteeTableRow } from 'components/guaranteesTable/GuaranteesTable';
import { InitialsIcon } from 'components/initialsIcon/InitialsIcon';
import { LoadingScreen } from 'components/loadingScreen/LoadingScreen';
import { Modal } from 'components/modal/Modal';
import { Notifications } from 'components/notifications/Notifications';
import { PartialReleaseModal } from 'components/partialReleaseModal/partialReleaseModal';
import { TopUpModal } from 'components/topUpModal/TopUpModal';
import { UnlockGuaranteeModal } from 'components/unlockGuaranteeModal/UnlockGuaranteeModal';
import { WithdrawModal } from 'components/withdrawModal/WithdrawModal';
import { useCheckSignedIn } from 'helpers/useCheckSignedIn';
import { useNotificationUIUpdates } from 'helpers/useNotificationUIUpdates';
import { useZoho } from 'helpers/useZoho';
import { DashboardContentPage } from 'pages/dashboard/DashboardContentPage';
import { DashboardInformationPage } from 'pages/dashboard/DashboardInformationPage';
import { ForgottenPassword } from 'pages/forgottenPassword/ForgottenPassword';
import { PublicInformationPage } from 'pages/publicInformationPage/PublicInformationPage';
import { ResetPassword } from 'pages/resetPassword/ResetPassword';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { selectAppVisible, selectAuthStatus } from 'reducers/auth';
import { selectAvailableAssets } from 'reducers/availableAssets';
import { getInitialCryptoPrices } from 'reducers/cryptoPrices';
import { ModalType, selectModalState } from 'reducers/modal';
import instance, { ApiResponse } from './api';
import { AppPath, DashboardPath } from './appConstants';
import { endpoints } from './endpoints.config';
import {
    addExpiryDate,
    getItemFromLocalStorage,
    storeInLocalStorage,
    hasContentExpired,
} from './helpers/cache';
import Dashboard from './pages/dashboard/Dashboard';
import SignInOrRegister from 'pages/signInOrRegister/SignInOrRegister';
import { Register } from './pages/register/Register';
import SignIn from './pages/signIn/SignIn';
import { selectTheme, setBranding } from './reducers/ui';
import { globalStyleVariables } from 'theme';
import { UpdateAddressModal } from 'pages/profile/UpdateAddressModal';

export const NOTIFICATIONS_PORTAL_ID = 'notifications-portal';

const themeUrl = endpoints.licenseesmodule.licenseesbranding;
const languageListUrl = endpoints.languagemodule.languages;

interface Branding {
    companyName: string;
    initialTheme: {
        colors: { first: string; second: string; third: string; fourth: string; fifth: string };
    };
    images: { assetsPath: string };
    expiryDate: string;
}

export const DASHBOARD_MENU_PAGE_SUB_PAGE_PLACEHOLDER = 'home';

function App() {
    const dispatch = useDispatch();
    const theme = useSelector(selectTheme);

    const cachedLanguageList = getItemFromLocalStorage('langList');
    const cachedBranding = getItemFromLocalStorage<Branding>('branding');

    useNotificationUIUpdates();
    useZoho();

    const authStatus = useSelector(selectAuthStatus);

    const availableAssets = useSelector(selectAvailableAssets);

    useEffect(() => {
        if (availableAssets.availableAssets && availableAssets.availableAssets.length > 0) {
            dispatch(getInitialCryptoPrices());
        }
    }, [dispatch, availableAssets.availableAssets]);

    // fetch app theme
    useEffect(() => {
        if (cachedBranding && !hasContentExpired(cachedBranding)) {
            dispatch(
                setBranding({
                    theme: cachedBranding.initialTheme,
                    companyName: cachedBranding.companyName,
                    imagesPath: cachedBranding.images.assetsPath,
                })
            );
            return;
        }

        instance.get<ApiResponse<Branding>>(themeUrl).then((res) => {
            if (res.status === 200) {
                if (res.data.status === '1') {
                    const branding = res.data.details;
                    const contentToCache = addExpiryDate(branding);
                    storeInLocalStorage(contentToCache, 'branding');
                    dispatch(
                        setBranding({
                            theme: { colors: { ...branding.initialTheme.colors } },
                            companyName: branding.companyName,
                            imagesPath: branding.images.assetsPath,
                        })
                    );
                }
            }
        });
    }, []);

    //const langListExpired = hasContentExpired(cachedLanguageList);

    // Get language list to populate language dropdown
    // useEffect(() => {
    //     if (!langListExpired) return;

    //     instance
    //         .get(languageListUrl)
    //         .then((res) => {
    //             if (res.status === 200) {
    //                 if (res.data.status === '1') {
    //                     const contentToCache = addExpiryDate({ list: res.data.details });
    //                     storeInLocalStorage(contentToCache, 'langList');
    //                 }
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // }, []);

    useCheckSignedIn();
    const isAppVisible = useSelector(selectAppVisible);

    const modalState = useSelector(selectModalState);

    useEffect(() => {
        if (!theme || !document) return;

        const root = document.documentElement;
        root.style.setProperty('--firstColor', theme.colors.first);
        root.style.setProperty('--secondColor', theme.colors.second);
        root.style.setProperty('--thirdColor', theme.colors.third);
        root.style.setProperty('--fourthColor', theme.colors.fourth);
        root.style.setProperty('--fifthColor', theme.colors.fifth);
    }, [theme]);

    if (!isAppVisible) {
        return null;
    }

    return (
        <ThemeProvider theme={theme}>
            <div className="Main" style={globalStyleVariables(theme)}>
                <LoadingScreen />
                <Notifications
                    activate={authStatus === 'signed_in'}
                    className="NotificationsContainer"
                    portalID={NOTIFICATIONS_PORTAL_ID}
                />
                <ToastContainer
                    position="bottom-center"
                    className="ToastMessage"
                    autoClose={5000}
                    transition={Slide}
                    hideProgressBar
                    pauseOnHover
                />
                <Router>
                    <SignInOrRegister path={':cultureCode'} default />
                    <SignIn path={`:cultureCode${AppPath.SIGN_IN}`} />
                    <Register path={`:cultureCode${AppPath.REGISTER}`} />
                    <ForgottenPassword path={`:cultureCode${AppPath.FORGOTTEN_PASSWORD}`} />
                    <ResetPassword path={`:cultureCode${AppPath.RESET_PASSWORD}`} />
                    {/* We take the user to this route whilst fetching the user information
                    which will include the account type, which will dictate whether we direct
                    to AppPath.PERSONAL or AppPath.BUSINESS dashboard route. */}
                    <CheckingDetails path={`:cultureCode${AppPath.CHECKING_DETAILS}`} />
                    <Dashboard path={`:cultureCode${AppPath.PERSONAL}`}>
                        {/* Info pages are used for CMS content pages such as those linked to in the footer. */}
                        <DashboardInformationPage path={`${DashboardPath.INFO}/:page`} />
                        {/* Some dashboard pages don't have any subpages, so we 
                        include a placeholder for sub-page for those paths so that we can match the 
                        component to this single route. Going forward we could opt to 
                        nest the path instead. */}
                        <DashboardContentPage path={`:page/:sub_page`} />
                    </Dashboard>
                    <PublicInformationPage path={`:cultureCode${AppPath.INFO}/:page`} />
                </Router>
            </div>
            {/* TODO(HC): Tidy this up into a separate component. 
                Move all modal instances into a single place */}
            {modalState.modalType === ModalType.WITHDRAW_CRYPTO ? (
                <Modal removeCloseButton={true} filledHeader={true}>
                    <WithdrawModal />
                </Modal>
            ) : modalState.modalType === ModalType.ACTIVATE_GUARANTEE ? (
                <Modal
                    title={
                        isGuaranteeTableRow(modalState.data) ? modalState.data!.venues__Name : ''
                    }
                    titleIcon={
                        isGuaranteeTableRow(modalState.data) ? (
                            <InitialsIcon name={modalState.data?.venues__Name} />
                        ) : (
                            <img src={GuaranteeIcon} />
                        )
                    }
                >
                    <ActivateGuaranteeModal />
                </Modal>
            ) : modalState.modalType === ModalType.TOP_UP ? (
                <Modal
                    title="Top up"
                    titleIcon={<img src={GuaranteeIcon} className="HeaderImgIcon" />}
                >
                    <TopUpModal />
                </Modal>
            ) : modalState.modalType === ModalType.PARTIAL_RELEASE ? (
                <Modal
                    title="Partial release"
                    titleIcon={<img src={GuaranteeIcon} className="HeaderImgIcon" />}
                >
                    <PartialReleaseModal />
                </Modal>
            ) : modalState && modalState.modalType === ModalType.UNLOCK_GUARANTEE ? (
                <Modal
                    title={
                        isGuaranteeTableRow(modalState.data) ? modalState.data!.venues__Name : ''
                    }
                    titleIcon={
                        isGuaranteeTableRow(modalState.data) ? (
                            <InitialsIcon name={modalState.data.venues__Name} />
                        ) : (
                            <img src={GuaranteeIcon} />
                        )
                    }
                >
                    <UnlockGuaranteeModal />
                </Modal>
            ) : modalState.modalType === ModalType.DELETE_GUARANTEE ? (
                <Modal
                    title={
                        isGuaranteeTableRow(modalState.data) ? modalState.data!.venues__Name : ''
                    }
                    titleIcon={
                        isGuaranteeTableRow(modalState.data) ? (
                            <InitialsIcon name={modalState.data.venues__Name} />
                        ) : (
                            <img src={GuaranteeIcon} />
                        )
                    }
                >
                    <DeleteGuaranteeModal />
                </Modal>
            ) : null}
        </ThemeProvider>
    );
}

export default App;
