import { CustomDatePicker } from 'components/customDatePicker/CustomDatePicker';
import { createUTCDate } from 'helpers/createUTCDate';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';

type DateRangeSelectorProps = {
    startDate: Date | null;
    setStartDate: (date: Date) => void;
    endDate: Date | null;
    setEndDate: (date: Date) => void;
};

export const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({
    startDate,
    endDate,
    setStartDate,
    setEndDate,
}) => {
    // Prevent user setting start date past end date. Automatically update end
    // date to equal start date if they do.
    const handleStartDateChange = (date: Date) => {
        if (endDate && endDate < date) {
            setEndDate(date);
        }
        setStartDate(date);
    };

    return (
        <div className="DateRangeSelector">
            <div className="Selector From">
                <span>From: </span>
                <CustomDatePicker
                    selected={startDate}
                    onChange={(date) => {
                        const UTCDate = createUTCDate(date as Date);
                        return handleStartDateChange(UTCDate);
                    }}
                    className={'CustomDatePicker'}
                />
            </div>
            <div className="Selector To">
                <span>To: </span>
                <CustomDatePicker
                    selected={endDate}
                    onChange={(date) => {
                        const UTCDate = createUTCDate(date as Date);
                        return setEndDate(UTCDate);
                    }}
                    minDate={startDate}
                    className={'CustomDatePicker'}
                />
            </div>
        </div>
    );
};
