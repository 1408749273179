import { ColumnDetail } from '@avamae/table';
import { indexOf } from 'lodash';
import { toPairs } from 'ramda';
import React from 'react';
import { Cell, CellOptions, CellType } from './Cell';
import { Checkbox } from './FlexTable';

export type RowData = {
    node: React.ReactNode;
    options?: CellOptions;
    cellType?: CellType;
};

export type RowConfig = {
    colourFilterHighlights?: { id: string; classNames: string }[];
};

export type RowProps<T> = {
    id: string;
    r: T;
    index: number;
    idColumn: string;
    columns: ColumnDetail<T>[];
    formatPrimitives: (v: unknown) => unknown;
    hiddenColumns: string[];
    rowHovered: number | null;
    handleMouseEnterRow: (e: any, i: number) => void;
    onRowClick: (id: string) => void;
    rowButton?: (data: T) => React.ReactNode;
    formatRow?: (r: T) => { [K in keyof T]: React.ReactNode };
    noRowHover?: boolean;
    checkbox?: Checkbox;
    isSelected?: boolean;
    rowOptionComponent?: () => React.ReactNode;
    rowCellOptions?: { [K in keyof T]: CellOptions };
    rowConfig?: RowConfig;
    isTallRow?: (r: T) => boolean;
};

const TableRowNoMemo = <T extends {}>({
    id,
    idColumn,
    r,
    index,
    columns: c,
    formatPrimitives,
    hiddenColumns,
    rowHovered,
    handleMouseEnterRow,
    isSelected,
    onRowClick,
    rowButton,
    formatRow,
    noRowHover,
    checkbox,
    rowOptionComponent,
    rowCellOptions,
    rowConfig,
    isTallRow,
}: RowProps<T>) => {
    const columns = React.useMemo(() => c, [c]);

    const tallRow = isTallRow && isTallRow(r);

    const handleRowClick = React.useCallback(
        (id: string) => () => {
            onRowClick(id);
        },
        [onRowClick]
    );
    const colourFilterHighlights = rowConfig?.colourFilterHighlights ?? [];
    const row = formatRow ? formatRow(r) : r;
    const columnKeys = columns.map((c) => c.columnKey.toString().toLowerCase());
    const findOrderNumber = (key: keyof T) => {
        const col = columns.find(
            (x) => x.columnKey.toString().toLowerCase() === key.toString().toLowerCase()
        );
        if (!col) return 0;
        return col.orderNumber;
    };
    const pairs = toPairs(row)
        .filter(([k]) => {
            if (columnKeys.includes(k.toLowerCase()) && !hiddenColumns.includes(k.toLowerCase())) {
                return true;
            }

            return false;
        })
        .map(([k, v]) => [k, formatPrimitives(v)] as [keyof T, React.ReactNode])
        .sort((a, b) => {
            const aOrder = findOrderNumber(a[0]);
            const bOrder = findOrderNumber(b[0]);
            if (aOrder === bOrder) return 0;
            return aOrder < bOrder ? -1 : 1;
        }) as [keyof T, React.ReactNode][];

    const handleSelectionChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            e.stopPropagation();
            if (checkbox) {
                const targetId = e.target.id;
                checkbox.setSelectedIds((p) => {
                    return p.includes(targetId)
                        ? p.filter((id) => id !== targetId)
                        : p.concat([targetId]);
                });
            }
        },
        [id]
    );

    // const rowData = pairs.map(p => p[1]);
    const rowData = React.useCallback((): RowData[] => {
        let data: RowData[] = rowCellOptions
            ? pairs.map((p) => ({ node: p[1], options: rowCellOptions[p[0]] ?? {} }))
            : pairs.map((p) => ({ node: p[1] }));
        if (checkbox) {
            data = [
                {
                    node: (
                        <div>
                            <input
                                id={id}
                                type="checkbox"
                                checked={checkbox.selectedIds.includes(id)}
                                onChange={handleSelectionChange}
                            />
                            <label className="CustomInput" htmlFor={id} />
                        </div>
                    ),
                    cellType: 'checkboxCell',
                },
                ...data,
            ];
        }

        if (rowButton) {
            data = [
                ...data,
                {
                    node: rowButton((r as { [k: string]: any })[idColumn]),
                    cellType: 'buttonCell',
                },
            ];
        }

        if (rowOptionComponent) {
            data = [...data, { node: rowOptionComponent(), cellType: 'rowOptionCell' }];
        }

        return data;
    }, [
        r,
        checkbox,
        idColumn,
        handleSelectionChange,
        id,
        pairs,
        rowButton,
        rowCellOptions,
        rowOptionComponent,
    ]);

    const filterHighlights = colourFilterHighlights.find((cfh) => cfh.id === id);

    const hasValue = React.useCallback((node: React.ReactNode) => {
        if (node === null || node === undefined || node === '') {
            return '-';
        }
        return node;
    }, []);

    const standardKey = `RST_Row${index}`;

    const standardRowClassNames = `Row ${noRowHover ? 'NoHover' : ''} ${
        filterHighlights ? filterHighlights.classNames : ''
    } ${isSelected ? 'Highlight' : ''}`;

    return (
        <div
            className={standardRowClassNames}
            key={standardKey}
            // onMouseOver={e => noRowHover? {} : handleMouseEnterRow(e, index)}
            onClick={handleRowClick(id)}
        >
            {rowData().map((r, i) => {
                const options = {
                    cellType: r.cellType,
                    tallCell: tallRow,
                    ...r.options,
                };
                return (
                    <Cell key={i} options={options}>
                        {hasValue(r.node)}
                    </Cell>
                );
            })}
        </div>
    );
};
export const TableRow = React.memo(TableRowNoMemo);
