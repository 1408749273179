import React, { SetStateAction, useCallback, useEffect, useState } from 'react';
import {
    AccountDetails,
    EmailVerificationProps,
    InputDetailsProps,
    RegisterStep,
    RegistrationStepProps,
    StepIdentifier,
    generateValidationSchema,
    stepIdentifiers,
} from './models';
import { InputPersonalDetails } from './InputPersonalDetails';
import { EmailVerification } from './EmailVerification';
import { ProgressBarSteps, SegmentedProgressBar } from 'components/SegmentedProgressBar';
import { InputAccountDetails } from './InputAccountDetails';
import { SplashPage } from 'pages/SplashPage';
import { Formik, FormikHelpers } from 'formik';
import { postEmailVerificationCode, postRegisterCustomerUser } from './RegisterApi';
import { toCamelCase } from 'helpers/formatFormFieldNames';
import { ERROR_CODES, getErrorMessage } from 'errors';
import { handleTFAError } from './RegistrationErrorHandling';

type Props = {
    registrationStep: RegisterStep;
    setRegistrationStep(nextStep: RegisterStep): void;
} & EmailVerificationProps &
    InputDetailsProps;

const registrationFormSteps: ProgressBarSteps<StepIdentifier> = [
    {
        label: 'Personal Details',
        stepNumber: 1,
        internalIdentifier: stepIdentifiers.personalDetails,
    },
    { label: 'Account Details', stepNumber: 2, internalIdentifier: stepIdentifiers.accountDetails },
    { label: 'Verification', stepNumber: 3, internalIdentifier: stepIdentifiers.emailVerification },
] as const;

const initialValues = {
    firstName: '',
    middleNames: '',
    lastName: '',
    emailAddress: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    nameConfirmed: false,
    emailVerificationCode: '',
};

export type RegistrationFormValues = typeof initialValues;

export const RegistrationForm = ({
    registrationStep,
    accountDetails,
    translations,
    onComplete,
    setAccountDetails,
    setRegistrationStep,
}: Props) => {
    const [currentStep, setCurrentStep] = useState(
        registrationStep === RegisterStep.EMAIL_VERIFICATION
            ? registrationFormSteps[2]
            : registrationFormSteps[0]
    );
    useEffect(() => {
        if (registrationStep === RegisterStep.EMAIL_VERIFICATION)
            setCurrentStep(registrationFormSteps[2]);
    }, [registrationStep]);

    const [errorMessage, setErrorMessage] = useState('');
    const goToNextStep = useCallback(() => {
        setCurrentStep(
            (currentStep) =>
                registrationFormSteps.find(
                    (step) => step.stepNumber === currentStep.stepNumber + 1
                ) ?? currentStep
        );
    }, []);

    const handleSubmit = (
        values: typeof initialValues,
        formikHelpers: FormikHelpers<typeof initialValues>
    ) => {
        const submitStep1 = () => {
            formikHelpers.setSubmitting(false);
            goToNextStep();
        };
        const submitStep2 = async () => {
            const result = await postRegisterCustomerUser(values);
            if (result.response) {
                setAccountDetails({
                    emailAddress: values.emailAddress,
                    password: values.password,
                    phoneNumber: values.phoneNumber,
                });
                goToNextStep();
            }
            if (result.errors) {
                console.error(result.errors);
                let shouldShowErrorMessage = true;
                result.errors.forEach((err) => {
                    const fieldName = toCamelCase(err.fieldName) as string;
                    if (Object.keys(values).includes(fieldName)) {
                        formikHelpers.setFieldError(fieldName, getErrorMessage(err.messageCode));
                        shouldShowErrorMessage = false;
                    }
                });
                if (shouldShowErrorMessage) {
                    const errorToShow = result.errors[0];
                    setErrorMessage(
                        ERROR_CODES[errorToShow.messageCode] ?? errorToShow.messageCode
                    );
                }
            }
            formikHelpers.setSubmitting(false);
        };
        const submitEmailVerification = async () => {
            const result = await postEmailVerificationCode({
                username: values.emailAddress,
                password: values.password,
                emailVerificationCode: values.emailVerificationCode,
                bResendCode: false,
            });
            if (result.response) {
                onComplete();
            }
            if (result.errors && result.errors.length > 0) {
                setErrorMessage(handleTFAError(result.errors[0]));
            }
            formikHelpers.setSubmitting(false);
        };
        switch (currentStep.internalIdentifier) {
            case stepIdentifiers.personalDetails:
                return submitStep1();
            case stepIdentifiers.accountDetails:
                return submitStep2();
            case stepIdentifiers.emailVerification:
                return submitEmailVerification();
        }
    };
    const validationSchema = generateValidationSchema(translations)[currentStep.internalIdentifier];
    return (
        <SplashPage>
            <div className="RegisterFormPanel">
                <h1>Create Your Account</h1>
                <SegmentedProgressBar
                    steps={registrationFormSteps}
                    currentStepNumber={currentStep.stepNumber}
                />
                <Formik
                    initialValues={{ ...initialValues, ...accountDetails }}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    key={registrationStep} // means that if the registrationstep is externally changed (e.g. when the auth status need_email_verification gets processed) we reload thig
                >
                    <>
                        {currentStep.internalIdentifier === stepIdentifiers.personalDetails && (
                            <InputPersonalDetails
                                translations={translations}
                                errorMessage={errorMessage}
                                setErrorMessage={setErrorMessage}
                            />
                        )}
                        {currentStep.internalIdentifier === stepIdentifiers.accountDetails && (
                            <InputAccountDetails
                                translations={translations}
                                errorMessage={errorMessage}
                                setErrorMessage={setErrorMessage}
                            />
                        )}
                        {currentStep.internalIdentifier === stepIdentifiers.emailVerification && (
                            <EmailVerification
                                translations={translations}
                                errorMessage={errorMessage}
                                setErrorMessage={setErrorMessage}
                            />
                        )}
                    </>
                </Formik>
            </div>
        </SplashPage>
    );
};
