import { useTable } from 'api';
import {
    GuaranteesTable,
    GUARANTEES_TABLE_ID_COLUMN,
} from 'components/guaranteesTable/GuaranteesTable';
import Pagination from 'components/pagination/Pagination';
import { TableFilteringAndSearch } from 'components/tableFilteringAndSearch/TableFilteringAndSearch';
import { endpoints } from 'endpoints.config';
import { addDayToDate } from 'helpers/addDayToDate';
import { useDebouncedState } from 'helpers/useDebouncedState';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAllCryptoPrices } from 'reducers/cryptoPrices';

export const GuaranteesPage = () => {
    const [searchValue, setSearchValue, localSearchValue] = useDebouncedState<string>('');
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    const handleSearch = (value: string) => {
        setSearchValue(value);
    };

    useEffect(() => {
        table.data?.actions.changeSearch(searchValue);
    }, [searchValue]);

    useEffect(() => {
        if (startDate || endDate) {
            table.data?.actions.setFilters([
                {
                    columnKey: 'Guarantees__AddDate',
                    operator: 'BETWEEN',
                    value: `${startDate ? startDate.toISOString() : null}TO${
                        endDate ? addDayToDate(endDate).toISOString() : null
                    }`,
                },
            ]);
        }
    }, [startDate, endDate]);

    const table = useTable<any, any>({
        url: endpoints.guaranteemodule.list,
        bClearFilters: true,
        bClearSearch: true,
    });
    const livePriceData = useSelector(selectAllCryptoPrices);

    return (
        <div className="GuaranteesPage">
            <TableFilteringAndSearch
                searchValue={localSearchValue}
                handleSearchChange={handleSearch}
                filteringLabel="Create Date:"
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
            />
            <GuaranteesTable
                isLoading={table.loading}
                table={table}
                idColumn={GUARANTEES_TABLE_ID_COLUMN}
                liveData={livePriceData}
                isNewBitLineDesign={true}
            />
            <Pagination table={table} />
        </div>
    );
};
