import { ErrorMessage } from 'api';

// TODO: This should be removed. All error code translating is currently handled
// by the object in errors.ts. Eventually this should be moved onto the backend
// to allow translation.
export const handleRegisterFormErrors = (error: ErrorMessage) => {
    switch (error.messageCode) {
        case 'Required':
            if (error.fieldName === 'EmailAddress') {
                return `Email address is required`;
            } else if (error.fieldName === 'CompanyName') {
                return 'Company name is required';
            }
            return 'A required field is missing';
        case 'Invalid':
            if (error.fieldName === 'EmailAddress') {
                return `Email address is invalid`;
            } else if (error.fieldName === 'CompanyName') {
                return 'Company name is invalid';
            }
            return 'There is an invalid field';
        case 'Already_In_Use':
            if (error.fieldName === 'EmailAddress') {
                return `Email address is already in use`;
            } else if (error.fieldName === 'CompanyName') {
                return 'Company name is already in use';
            }
            return 'A field is already in use';
        case 'Already_In_Use_For_Personal_Account':
            if (error.fieldName === 'EmailAddress') {
                return `Email address is already in use for a personal account`;
            } else if (error.fieldName === 'CompanyName') {
                return 'Company name is already in use for a personal account';
            }
            return 'A field is already in use';
        case 'Already_In_Use_For_Business_Account':
            if (error.fieldName === 'EmailAddress') {
                return `Email address is already in use for a business account`;
            } else if (error.fieldName === 'CompanyName') {
                return 'Company name is already in use for a business account';
            }
            return 'A field is already in use';
        case 'Password_No_Match':
            return 'Passwords do not match';
        case 'Password_Policy_No_Match':
            return 'Password must contain a letter and a number';
        default:
            return 'An error has occurred. Please try again.';
    }
};
export const handleAppQRErrors = (error: ErrorMessage) => {
    switch (error.messageCode) {
        case 'Required':
            return `An email address is required`;
        case 'Invalid':
            return `Email address is invalid`;
        case 'Expired':
            return `Oops, please try again`;
        case 'Already_In_Use':
            return `This email address is already in use`;
        case 'Username_Or_Password_Incorrect':
            return 'Your username or password is incorrect';
        case 'Account_Locked':
            return 'Too many failed attempts. Please retry in 10 minutes';
        case 'Account_Suppressed':
            return 'Account is currently inactive. Please try again later';
        case 'EmailAddress_Already_Verified':
            return 'Email address has already been verified.';
        default:
            return 'An error has occurred. Please try again.';
    }
};
export const handleSMSRequestError = (error: ErrorMessage) => {
    switch (error.messageCode) {
        case 'Required':
            return `An email address is required`;
        case 'Username_Or_Password_Incorrect':
            return 'Your username or password is incorrect';
        case 'Account_Locked':
            return 'Too many failed attempts. Please retry in 10 minutes';
        case 'Account_Suppressed':
            return 'Account is currently inactive. Please try again later';
        default:
            return 'An error has occurred. Please try again.';
    }
};

export const handleTFAError = (error: ErrorMessage) => {
    switch (error.messageCode) {
        case 'Required':
            return 'Please enter your 6-digit code';
        case 'Invalid':
            return 'The code entered is invalid';
        case 'Expired':
            return 'Your 6-digit code has expired';
        case 'Username_Or_Password_Incorrect':
            return 'Your username or password is incorrect';
        case 'Account_Locked':
            return 'Too many failed attempts. Please retry in 10 minutes';
        case 'Account_Suppressed':
            return 'Account is currently inactive. Please try again later';
        case 'EmailAddress_Already_Verified':
            return 'Email address has already been verified.';
        default:
            return 'An error has occurred. Please try again.';
    }
};
