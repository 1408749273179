import instance, { ApiResponse } from 'api';
import { endpoints } from 'endpoints.config';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { ActionsObservable, combineEpics, ofType, StateObservable } from 'redux-observable';
import { empty, from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from './rootReducer';

export type TickerPrice = { fiatAssetsCode: string; fireblocksAssetId: string; price: number };
export type PricesMap = {
    [fiatAssetsCode: string]: { [fireblocksAssetId: string]: number };
};

type CryptoPricesState = PricesMap;

const initialState: CryptoPricesState = {};

enum CryptoPriceActionType {
    GET_INITIAL_PRICES = 'app/cryptoPrices/GET_INITIAL_PRICES',
    SET_PRICES = 'app/cryptoPrices/SET_PRICES',
}

type GetInitialPricesAction = {
    type: CryptoPriceActionType.GET_INITIAL_PRICES;
};

type SetPriceAction = {
    type: CryptoPriceActionType.SET_PRICES;
    payload: TickerPrice[];
};

type CryptoPriceAction = GetInitialPricesAction | SetPriceAction;

const Reducer = (state = initialState, action: CryptoPriceAction): CryptoPricesState => {
    switch (action.type) {
        case CryptoPriceActionType.SET_PRICES:
            const stateCopy = { ...state };
            action.payload.forEach((asset) => {
                if (stateCopy[asset.fiatAssetsCode])
                    stateCopy[asset.fiatAssetsCode][asset.fireblocksAssetId] = asset.price;
                else stateCopy[asset.fiatAssetsCode] = { [asset.fireblocksAssetId]: asset.price };
            });
            return stateCopy;
        default:
            return state;
    }
};

type CurrentPricesResponse = {
    prices: TickerPrice[];
};

const getInitialPricesEpic = (
    action$: ActionsObservable<GetInitialPricesAction>,
    state$: StateObservable<Store>
) =>
    action$.pipe(
        ofType(CryptoPriceActionType.GET_INITIAL_PRICES),
        switchMap(() => {
            return from(
                instance.get<ApiResponse<CurrentPricesResponse>>(
                    endpoints.cryptosmodule.getCurrentPrices
                )
            ).pipe(
                map((response) => setPrices(response.data.details.prices)),
                catchError(() => {
                    Toast.openToastMessage(
                        'Error fetching crypto prices',
                        ToastMessageReason.ERROR
                    );
                    return empty();
                })
            );
        })
    );

export const cryptoPricesEpic = combineEpics(getInitialPricesEpic);

export default Reducer;

// Action creators
export const setPrices = (price: TickerPrice[]): SetPriceAction => ({
    type: CryptoPriceActionType.SET_PRICES,
    payload: price,
});

export const getInitialCryptoPrices = (): GetInitialPricesAction => ({
    type: CryptoPriceActionType.GET_INITIAL_PRICES,
});

// Selectors

export const selectAllCryptoPrices = (store: Store) => store.cryptoPrices;
export const selectCryptoPrice =
    (fireblocksAssetId: string | null, fiatAssetsCode: string | null) => (store: Store) => {
        if (fireblocksAssetId && fiatAssetsCode) {
            return store.cryptoPrices[fiatAssetsCode]?.[`${fireblocksAssetId}`] ?? null;
        }

        return null;
    };
