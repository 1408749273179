import { useTheme } from '@emotion/react';
import { isErrorHandled } from 'api';
import { ThreeBounce } from 'better-react-spinkit';
import Button from 'components/button/Button';
import { CurrencyIcon } from 'components/currencyIcon/CurrencyIcon';
import FormTextField from 'components/form/FormTextField';
import { TFAField } from 'components/form/TFAField';
import { ERROR_CODES, getErrorMessage } from 'errors';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { getNumberFromString } from 'helpers/getNumberFromString';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { useDebouncedState } from 'helpers/useDebouncedState';
import { useTFAField } from 'helpers/useTFAField';
import { TFAValidation } from 'helpers/validationSnippets';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCryptoPrice } from 'reducers/cryptoPrices';
import { selectCurrentHoldings } from 'reducers/currentHoldings';
import {
    closeModal,
    isWithdrawModalData,
    selectModalState,
    WithdrawModalData,
} from 'reducers/modal';
import * as Yup from 'yup';
import { WithdrawModalApi, WithdrawRequest } from './WithdrawModalApi';
import { selectFiatCurrency } from 'reducers/availableAssets';
import walletIcon from 'assets/bitline-ui-redesign/deposit crypto icon.svg';

export type WithdrawFormFormikState = {
    showTfa: boolean;
    amount: string;
    walletAddress: string;
    tfaCode: string;
};

const parseFormikStateToWithdrawPost = (
    formikValues: WithdrawFormFormikState,
    tfaType: WithdrawRequest['tfaType'],
    fireblocksAssetId: string
): WithdrawRequest | null => {
    const amount = getNumberFromString(formikValues.amount);
    if (!amount) {
        return null;
    }
    return {
        amount,
        walletAddress: formikValues.walletAddress,
        tfaCode: formikValues.tfaCode,
        fireblocksAssetId,
        tfaType,
    };
};

const createValidationSchema = (max: number, ticker: string) =>
    Yup.object({
        showTfa: Yup.boolean(),
        amount: Yup.string()
            .test('is number', 'Please enter a valid number', (value: string | undefined) => {
                if (value) {
                    if (getNumberFromString(value)) {
                        return true;
                    }
                }
                return false;
            })
            .test(
                'is below maximum',
                `The max you can withdraw is ${max} ${ticker}`,
                (value: string | undefined) => {
                    if (value && getNumberFromString(value)) {
                        return getNumberFromString(value)! <= max;
                    }
                    return false;
                }
            ),
        walletAddress: Yup.string().required('Please enter a wallet address'),
        tfaCode: Yup.string().when('showTfa', { is: true, then: TFAValidation }),
    });

export const WithdrawModal: React.FC = () => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const defaultFiatCurrency = useSelector(selectFiatCurrency);
    const [withdrawFee, setWithdrawFee] = useState<number | null>(null);

    const [error, setError] = useState<keyof typeof ERROR_CODES | null>();

    const [showTfa, setShowTfa] = useState(false);
    const [tfaType, toggleTFAType] = useTFAField(showTfa);

    const currentHoldings = useSelector(selectCurrentHoldings);

    const modalState = useSelector(selectModalState);
    if (!isWithdrawModalData(modalState.data)) {
        dispatch(closeModal());
    }
    const modalData = modalState.data as WithdrawModalData;

    // Asserting this is !undefined as we close modal if it is. Typescript can't
    // infer this.
    const selectedCurrentHolding = currentHoldings?.find(
        (holding) => holding.fireblocksAssetId === modalData.fireblocksAssetId
    )!;
    if (!selectedCurrentHolding || !selectedCurrentHolding?.unlockedBalance) {
        dispatch(closeModal());
        Toast.openGenericErrorToast();
    }

    const selectedCryptoCurrentPrice = useSelector(
        selectCryptoPrice(selectedCurrentHolding.fireblocksAssetId, defaultFiatCurrency)
    );

    const initialValues = useMemo<WithdrawFormFormikState>(
        () => ({
            showTfa: false,
            amount: String(selectedCurrentHolding.unlockedBalance),
            walletAddress: '',
            tfaCode: '',
        }),
        [selectedCurrentHolding]
    );

    const validationSchema = useMemo(
        () =>
            selectedCurrentHolding.unlockedBalance
                ? createValidationSchema(
                      selectedCurrentHolding.unlockedBalance,
                      selectedCurrentHolding.displayCode
                  )
                : {},
        [selectedCurrentHolding]
    );

    const handleSubmit = (
        values: WithdrawFormFormikState,
        helpers: FormikHelpers<WithdrawFormFormikState>
    ) => {
        setError(null);
        if (!values.showTfa) {
            helpers.setFieldValue('showTfa', true);
            setShowTfa(true);
            helpers.setSubmitting(false);
            helpers.setFieldTouched('tfaCode', false);
            return;
        }
        const parsedValues = parseFormikStateToWithdrawPost(
            values,
            tfaType,
            selectedCurrentHolding.fireblocksAssetId
        );
        if (!parsedValues) {
            Toast.openGenericErrorToast();
            return;
        }
        WithdrawModalApi.submitWithdrawRequest(parsedValues)
            .then(() => {
                Toast.openToastMessage('Withdraw initiated successfully', ToastMessageReason.VALID);
                dispatch(closeModal());
                helpers.setSubmitting(false);
            })
            .catch((error) => {
                if (isErrorHandled(error)) {
                    const { response } = error;
                    setError(response.data.errors[0].messageCode);
                    helpers.setFieldValue('tfaCode', '');
                    helpers.setFieldTouched('tfaCode', false);
                }
                helpers.setSubmitting(false);
            });
    };

    const handleClose = () => {
        dispatch(closeModal());
    };

    return (
        <div className="WithdrawModal">
            <h1 className="Title">Withdraw</h1>

            <img src={walletIcon} alt="blue wallet icon" className="WalletIcon" />

            <div className="HeadingContentWrapper">
                <CurrencyIcon
                    currency={selectedCurrentHolding.displayCode}
                    className="CurrencyIcon"
                />
                <div className="HeadingText">
                    <span className="Amount">
                        {selectedCurrentHolding.unlockedBalance}{' '}
                        <span>{selectedCurrentHolding.displayCode}</span>
                    </span>
                    <span className="CurrentPrice">
                        1 {selectedCurrentHolding.displayCode} = {selectedCryptoCurrentPrice} USD
                    </span>
                    <span className="Fee">
                        Fee ≈ {withdrawFee ?? <ThreeBounce size={2} color={theme.colors.second} />}{' '}
                        {selectedCurrentHolding.displayCode}
                    </span>
                </div>
            </div>

            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
                initialTouched={{ amount: true }}
            >
                {({ values, isValid, isSubmitting, submitForm }) => (
                    <Form className="Form">
                        <GetFeeEffectWrapper
                            setFee={setWithdrawFee}
                            fireblocksAssetId={modalData.fireblocksAssetId}
                        />
                        <FormTextField field={'amount'} label="Amount" required />
                        <FormTextField field={'walletAddress'} label="Wallet address" required />

                        {values.showTfa && (
                            <div className="TFASection">
                                <h3 className="Title">Two-Factor Authentication</h3>
                                <p className="SubTitle">
                                    {`Enter the code from ${
                                        tfaType === 'AuthenticatorApp'
                                            ? 'your authentication app'
                                            : 'the sms we sent you'
                                    }`}
                                </p>
                                <TFAField
                                    field={'tfaCode'}
                                    label={'Your verification code'}
                                    value={values.tfaCode}
                                    required
                                    tfaType={tfaType}
                                    toggleTFAType={toggleTFAType}
                                    backgroundColor="white"
                                    autoFocus
                                />
                            </div>
                        )}
                        {error && <p className="ErrorText NoMargin">{getErrorMessage(error)}</p>}

                        <Button
                            onClick={submitForm}
                            priority="primary"
                            style={{
                                backgroundColor: theme.colors.second,
                                borderColor: theme.colors.second,
                            }}
                            type="submit"
                            disabled={!isValid || isSubmitting}
                        >
                            Confirm
                        </Button>
                        <Button
                            priority="secondary"
                            className="Secondary"
                            onClick={handleClose}
                            type="button"
                        >
                            Cancel
                        </Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

type GetFeeEffectWrapperProps = {
    setFee: (fee: number | null) => void;
    fireblocksAssetId: string;
};

const GetFeeEffectWrapper: React.FC<GetFeeEffectWrapperProps> = ({ setFee, fireblocksAssetId }) => {
    const { values, errors } = useFormikContext<WithdrawFormFormikState>();

    const [debouncedState, setTemporaryState] = useDebouncedState<{
        amount: string;
        walletAddress: string;
    } | null>(null, 3000);

    useEffect(() => {
        setTemporaryState({ amount: values.amount, walletAddress: values.walletAddress });
    }, [values.amount, values.walletAddress, setTemporaryState]);

    useEffect(() => {
        if (debouncedState && !errors.amount && !errors.walletAddress) {
            (async () => {
                const amount = getNumberFromString(debouncedState.amount);
                if (amount) {
                    const response = await WithdrawModalApi.getFeeEstimate({
                        fireblocksAssetId,
                        amount,
                        walletAddress: debouncedState.walletAddress,
                    });
                    if (WithdrawModalApi.isSuccessData(response)) {
                        setFee(response.data.feeEstimate);
                    } else {
                        setFee(null);
                    }
                }
            })();
        }
    }, [debouncedState, fireblocksAssetId, setFee, errors.amount, errors.walletAddress]);

    return null;
};
