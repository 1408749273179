import Button from 'components/button/Button';
import FormTextField from 'components/form/FormTextField';
import { Spinner } from 'components/spinner/Spinner';
import { ThemedLink } from 'components/themedLink/ThemedLink';
import { Formik, Form, FormikProps } from 'formik';
import React, { useState } from 'react';
import { AppSetupDetails, AuthType, TFASetupProps } from './models';
import { postAppTFASetup } from './RegisterApi';
import { handleAppQRErrors } from './RegistrationErrorHandling';
import * as Yup from 'yup';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { ErrorM } from 'components/form/ErrorM';
import { FormPhoneNumberField } from './InputPersonalDetails';
import { parsePhoneNumber } from 'libphonenumber-js';
import IconCopy from 'assets/Icon_Copy.png';

export const TFASetup: React.FC<TFASetupProps> = ({
    translations,
    onComplete,
    authType,
    setAuthType,
    accountDetails,
    setSharedSecret,
    setPhoneNumber,
}) => {
    const [appSetupDetails, setAppSetupDetails] = useState<AppSetupDetails>({
        qrCode: '',
        sharedSecret: '',
    });
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [phoneNumberChanged, setPhoneNumberChanged] = useState(false);

    const initialValues: any = {
        phoneNumber: accountDetails.phoneNumber || '',
    };

    React.useEffect(() => {
        if (authType === AuthType.APP && accountDetails) {
            setLoading(true);
            (async () => {
                const result = await postAppTFASetup(accountDetails);
                if (result.response) {
                    setAppSetupDetails({
                        qrCode: 'data:image/png;base64,' + result.response.details.qrCode,
                        sharedSecret: result.response.details.sharedSecret,
                    });
                }
                if (result.errors && result.errors.length > 0) {
                    setError(handleAppQRErrors(result.errors[0]));
                }
                setLoading(false);
            })();
        }
    }, [authType, accountDetails]);

    const handleSubmit = (values?: any) => {
        setLoading(true);
        if (authType === AuthType.SMS) {
            setPhoneNumber(values.phoneNumber);
            onComplete();
            setLoading(false);
        } else if (authType === AuthType.APP) {
            setSharedSecret(appSetupDetails.sharedSecret);
            onComplete();
            setLoading(false);
        }
    };

    const handleAuthType = (authType: AuthType) => () => {
        setAppSetupDetails({ qrCode: '', sharedSecret: '' });
        setAuthType(authType);
    };
    const validationSchema = Yup.object({
        phoneNumber:
            authType === AuthType.SMS
                ? Yup.string()
                      .min(4, translations?.phone_number_required)
                      .required(translations?.phone_number_required)
                : Yup.string(),
    });

    return (
        <>
            {translations && (
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {(formikProps: FormikProps<typeof initialValues>) => (
                        <Form>
                            <div className="RegisterFormWidth">
                                <h1>{translations.heading2}</h1>
                                {authType === AuthType.APP && (
                                    <>
                                        <p>
                                            To setup 2FA scan the QR code below using your
                                            authenticator app
                                        </p>
                                        <div className="QrCodeContainer">
                                            {appSetupDetails.qrCode &&
                                            appSetupDetails.sharedSecret ? (
                                                <>
                                                    <img
                                                        src={appSetupDetails.qrCode}
                                                        alt="QR Code"
                                                        className="QrCode"
                                                    />

                                                    <div className="ImageLinks">
                                                        <a
                                                            href={`otpauth://totp/Bitline%20Guest%20Portal:${accountDetails.emailAddress}?secret=${appSetupDetails.sharedSecret}&issuer=Bitline%20Guest%20Portal`}
                                                        >
                                                            <p>{appSetupDetails.sharedSecret}</p>
                                                        </a>
                                                        {navigator.clipboard?.writeText && (
                                                            <button
                                                                className="ImageButton"
                                                                onClick={() =>
                                                                    navigator.clipboard.writeText(
                                                                        appSetupDetails.sharedSecret
                                                                    )
                                                                }
                                                                type="button"
                                                            >
                                                                <img src={IconCopy} alt="Copy" />
                                                            </button>
                                                        )}
                                                    </div>
                                                </>
                                            ) : (
                                                <Spinner />
                                            )}
                                        </div>

                                        {error && <div className="ErrorMessage">{error}</div>}
                                        <Button
                                            priority="primary"
                                            variety="full"
                                            type="submit"
                                            disabled={loading}
                                            color="second"
                                        >
                                            {loading ? (
                                                <Spinner color={'#fff'} />
                                            ) : (
                                                translations.continue_button
                                            )}
                                        </Button>
                                        <ThemedLink
                                            onClick={handleAuthType(AuthType.SMS)}
                                            className="RegisterLink UnderButton"
                                        >
                                            {translations.use_sms}
                                        </ThemedLink>
                                    </>
                                )}
                                {authType === AuthType.SMS && (
                                    <>
                                        <p>{translations.sub_heading3}</p>
                                        <div className={`FormBox PhoneNumberBox`}>
                                            <div className="FormLabel">
                                                <label>Phone number</label>
                                            </div>
                                            <div>
                                                <PhoneInput
                                                    className="EditBox"
                                                    value={formikProps.values['phoneNumber']}
                                                    onChange={(value: any) => {
                                                        formikProps.setFieldValue(
                                                            'phoneNumber',
                                                            value
                                                        );
                                                        setPhoneNumberChanged(true);
                                                    }}
                                                    defaultCountry={
                                                        accountDetails.phoneNumber
                                                            ? parsePhoneNumber(
                                                                  accountDetails.phoneNumber
                                                              ) ?? 'US'
                                                            : 'US'
                                                    }
                                                    name="phoneNumber"
                                                    numberInputProps={{
                                                        className: `EditBox${
                                                            formikProps.touched['phoneNumber'] &&
                                                            formikProps.errors['phoneNumber']
                                                                ? ' ErrorInput'
                                                                : ''
                                                        }`,
                                                    }}
                                                />
                                                <ErrorM name="phoneNumber" />
                                                {formikProps.values['phoneNumber'] !==
                                                    accountDetails.phoneNumber && (
                                                    <div className="ErrorLabel">
                                                        This will update the phone number you have
                                                        registered with us
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {error && <div className="ErrorMessage">{error}</div>}
                                        <Button
                                            priority="primary"
                                            variety="full"
                                            type="submit"
                                            disabled={loading}
                                        >
                                            {loading ? (
                                                <Spinner color={'#fff'} />
                                            ) : (
                                                translations.continue_button
                                            )}
                                        </Button>
                                        <ThemedLink
                                            onClick={handleAuthType(AuthType.APP)}
                                            className="RegisterLink UnderButton"
                                        >
                                            {translations.use_authy}
                                        </ThemedLink>
                                    </>
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </>
    );
};
