import { useTheme } from '@emotion/react';
import { CurrencyIcon } from 'components/currencyIcon/CurrencyIcon';
import { ProgressBar } from 'components/progressBar/ProgressBar';
import format from 'date-fns/format';
import { calculatePercentageBetween } from 'helpers/calculatePercentage';
import { formatDateToShortDate } from 'helpers/formatDateToShortDate';
import { formatPriceWithCommas } from 'helpers/formatPriceWithCommas';
import { getDaysBetweenDates } from 'helpers/getDaysBetweenDates';
import { clamp } from 'ramda';
import React, { useMemo } from 'react';
import WarningIcon from 'assets/ibanera/Icon_Warning.png';
import { calculateALTV, getALTVStatus } from 'helpers/calculateALTV';
import { InitialsIcon } from 'components/initialsIcon/InitialsIcon';
import { GuaranteeTableRow } from 'components/guaranteesTable/GuaranteesTable';
import { fiatCurrencyFormatter } from 'appConstants';
import { TransactionsRow } from 'components/transactionsTable/TransactionsTable';

type NameCellProps = {
    name: string;
    ticker: string;
};

const NameCell: React.FC<NameCellProps> = ({ name, ticker }) => {
    return (
        <div className="MarketNameCell">
            <div className="MarketImageContainer">
                <CurrencyIcon currency={ticker} />
            </div>
            <div className="MarketText">
                <span className="MarketName">{name}</span>
                <span className="MarketTicker">{ticker}</span>
            </div>
        </div>
    );
};

type NameInitialsCellProps = {
    name: string;
    subName?: string;
    additionalText?: string;
};

const NameInitialsCell: React.FC<NameInitialsCellProps> = ({ name, subName, additionalText }) => {
    return (
        <div className="NameInitialsCell">
            <div className="Left">
                <InitialsIcon name={name} />
            </div>
            <div className="Right">
                <span className="Name">{name}</span>
                <span className="SubName">{subName}</span>
                {additionalText && <span className="SubName">{additionalText}</span>}
            </div>
        </div>
    );
};

type DateCountdownCellProps = {
    issueDate: string | null;
    issueLength?: number;
    endDate?: string | null;
};

const DateCountdownCell: React.FC<DateCountdownCellProps> = ({
    issueDate,
    endDate,
    issueLength = 30,
}) => {
    const [daysElapsed, formattedIssueDate] = useMemo(() => {
        const lockDate = issueDate ? new Date(issueDate) : null;
        if (lockDate) {
            const guaranteeIsActive = !endDate;
            const toDate = guaranteeIsActive ? new Date() : new Date(endDate!);
            const formattedLockDate = formatDateToShortDate(lockDate);
            return [getDaysBetweenDates(toDate, lockDate), formattedLockDate];
        } else {
            return [null, '--/--/--'];
        }
    }, [issueDate, endDate]);

    const percentageElapsed =
        daysElapsed !== null ? clamp(0, 100, Math.floor((daysElapsed / issueLength) * 100)) : null;
    const statusClass =
        percentageElapsed !== null
            ? percentageElapsed < 20
                ? 'Good'
                : percentageElapsed < 50
                ? 'Mid'
                : 'Bad'
            : '';

    return (
        <div className={`DateCountdownCell ${statusClass}`}>
            <div className="CountdownBarContainer">
                <span className="EndNumber Left">0</span>
                <div className="CountdownBar">
                    <div className="FilledBar" style={{ width: `${percentageElapsed}%` }} />
                    {/* <div className="DayBox" style={{ left: `${percentageElapsed}%` }}>
                        <span className="Day">{daysElapsed}</span>
                    </div> */}
                </div>
                <span className="EndNumber Right">{issueLength}</span>
            </div>
            <div className="DateRow">
                <span className="Date">{formattedIssueDate}</span>
                {statusClass === 'Bad' && percentageElapsed && percentageElapsed > 90 && (
                    <img src={WarningIcon} alt="Warning" className="Warning" />
                )}
            </div>
        </div>
    );
};

type CryptoReferenceCellProps = {
    name: string;
    ticker: string;
    reference: string;
};

const CryptoReferenceCell: React.FC<CryptoReferenceCellProps> = ({ name, ticker, reference }) => {
    return (
        <div className="MarketReferenceCell">
            <div className="RowImage">
                <CurrencyIcon currency={ticker} />
            </div>
            <div className="TextSection">
                <div className="Names">
                    <h3 className="Name">{name}</h3>
                    <p className="Ticker">{ticker}</p>
                </div>
                <div className="Reference">{reference}</div>
            </div>
        </div>
    );
};

type ExchangeReferenceCellProps = {
    reference: string;
    ticker: string;
};

const ExchangeReferenceCell: React.FC<ExchangeReferenceCellProps> = ({ reference, ticker }) => (
    <div className="MarketReferenceCell Exchange">
        <div className="RowImage">
            <CurrencyIcon currency={ticker} />
        </div>
        <div>
            <span className="Reference">{reference}</span>
        </div>
    </div>
);

type PriceCellProps = {
    price: number;
    currencySymbol: string;
    currencyTicker: string;
};

const PriceCell: React.FC<PriceCellProps> = ({ currencySymbol, currencyTicker, price }) => {
    return (
        <div className="MarketPriceCell">
            <span className="Price">
                {currencySymbol}
                {formatPriceWithCommas(price, 6)}
            </span>
            <span className="Ticker">{currencyTicker}</span>
        </div>
    );
};

type ValueCellProps = {
    price: number;
    currencySymbol: string;
    currencyTicker: string;
    balance: number;
};

const ValueCell: React.FC<ValueCellProps> = ({
    currencySymbol,
    price,
    balance,
    currencyTicker,
}) => {
    return (
        <div className="MarketPriceCell">
            <span className="Price">
                {currencySymbol}
                {formatPriceWithCommas(price * balance, 6)}
            </span>
            <span className="Ticker">{currencyTicker}</span>
        </div>
    );
};

type ChangeCellProps = {
    amount: number;
};

const ChangeCell: React.FC<ChangeCellProps> = ({ amount }) => {
    const changeDirection: 'positive' | 'negative' | 'none' =
        amount > 0 ? 'positive' : amount === 0 ? 'none' : 'negative';
    const symbol = changeDirection === 'positive' ? '+' : changeDirection === 'negative' ? '-' : '';
    return (
        <span className={`MarketChangeCell ${changeDirection}`}>
            {symbol}
            {formatPriceWithCommas(Math.abs(amount), 3)}%
        </span>
    );
};

type HighLowCellProps = {
    high: number;
    low: number;
    price: number;
    currencySymbol: string;
};

const HighLowCell: React.FC<HighLowCellProps> = ({ high, low, price, currencySymbol }) => {
    return (
        <ProgressBar
            percentageComplete={calculatePercentageBetween(low, high, price)}
            start={`${currencySymbol ? currencySymbol : ''}${formatPriceWithCommas(low, 6)}`}
            end={`${currencySymbol ? currencySymbol : ''}${formatPriceWithCommas(high, 6)}`}
        />
    );
};

type DateCellProps = {
    date: string;
    formatString?: string;
};

const DateCell: React.FC<DateCellProps> = ({ date, formatString = 'MM/dd/yyyy HH:mm:ss' }) => (
    <div className="DateCell">
        <span className="Date">{format(new Date(date + 'Z'), formatString)}</span>
    </div>
);

type BuySellCellProps = {
    type: 'Buy' | 'Sell' | 'Deposit' | 'Withdraw';
};

const BuySellCell: React.FC<BuySellCellProps> = ({ type }) => {
    const theme = useTheme();
    return (
        <div className="BuySellCell">
            {type === 'Buy' || type === 'Sell' ? (
                <span
                    className={`BuyOrSell ${type}`}
                    style={{ color: type === 'Buy' ? theme.colors.first : theme.colors.second }}
                >
                    {type}
                </span>
            ) : (
                <span>{type}</span>
            )}
        </div>
    );
};

type NumberAndTickerCellProps = {
    number: number;
    ticker: string;
    isFiat?: boolean;
    dp?: number;
    precision?: number;
};

const NumberAndTickerCell: React.FC<NumberAndTickerCellProps> = ({
    number,
    ticker,
    dp = 2,
    isFiat,
}) => {
    const formattedPrice = isFiat
        ? fiatCurrencyFormatter(number, ticker)
        : formatPriceWithCommas(number);
    return (
        <div className="NumberTickerCell">
            {!!number ? (
                <>
                    <span className="Number">{formattedPrice}</span>
                    <span className="Ticker">{ticker}</span>
                </>
            ) : (
                <span>-</span>
            )}
        </div>
    );
};

const DashCell: React.FC = () => {
    return <div>-</div>;
};

type StringCellProps = {
    value: string | number;
};

const StringCell: React.FC<StringCellProps> = ({ value }) => (
    <span className="StringCell">{value}</span>
);

type PrecisionNumberCellProps = {
    number: number;
    precision?: number;
};

const PrecisionNumberCell: React.FC<PrecisionNumberCellProps> = ({ number, precision = 6 }) => {
    return <div>{number.toPrecision(precision)}</div>;
};

type TLTVALTVCellProps = {
    tltv: number;
    cryptoGuaranteeAmount: number;
    fiatGuaranteeAmount: number;
    currentCryptoPrice: number | null;
    altvMin: number;
    altvMax: number;
    isClosed?: boolean;
};

// Checks whethere ALTV is within required limits.

const TLTVALTCCell: React.FC<TLTVALTVCellProps> = ({
    tltv,
    cryptoGuaranteeAmount,
    fiatGuaranteeAmount,
    currentCryptoPrice,
    altvMax,
    altvMin,
    isClosed,
}) => {
    const altv =
        currentCryptoPrice && !isClosed
            ? calculateALTV(cryptoGuaranteeAmount, fiatGuaranteeAmount, currentCryptoPrice)
            : null;
    const roundedAltv = altv?.toFixed(0);
    const cellStatus = altv ? getALTVStatus(altv, altvMin, altvMax) : '';
    return (
        <div className={`TLTVALTVCell ${cellStatus}`}>
            <span className="TLTVALTV">{`${tltv}% / ${
                roundedAltv ? `${roundedAltv}%` : '-'
            }`}</span>
            {cellStatus === 'Bad' && (
                <img alt="Warning" src={WarningIcon} className="WarningIcon" />
            )}
        </div>
    );
};

type StatusCellprops = {
    status:
        | GuaranteeTableRow['guarantees__Status']
        | TransactionsRow['customerAssetAccountsTransactions__Status'];
};

const StatusCell: React.FC<StatusCellprops> = ({ status }) => {
    return (
        <div className={`StatusCell ${status}`}>
            <div className="StatusBackground" />
            <span className="Status">{status}</span>
        </div>
    );
};

type EmptyCellProps = {
    shimmer?: boolean;
};

const EmptyCell: React.FC<EmptyCellProps> = ({ shimmer = true }) => {
    return <div className={`EmptyCell ${shimmer ? '' : 'NoShimmer'}`} />;
};

export {
    NameCell,
    NameInitialsCell,
    DateCountdownCell,
    CryptoReferenceCell,
    ExchangeReferenceCell,
    PriceCell,
    ValueCell,
    ChangeCell,
    HighLowCell,
    DateCell,
    BuySellCell,
    NumberAndTickerCell,
    DashCell,
    StringCell,
    PrecisionNumberCell,
    TLTVALTCCell,
    StatusCell,
    EmptyCell,
};
