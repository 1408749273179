import { useTheme } from '@emotion/react';
import { GLOBAL_CRYPTO_PRECISION, fiatCurrencyFormatter } from 'appConstants';
import Button from 'components/button/Button';
import {
    CryptoDropdownSelect,
    mapAvailableAssetsToDropdownSelectOptions,
} from 'components/cryptoDropdownSelect/CryptoDropdownSelect';
import { CustomDatePicker } from 'components/customDatePicker/CustomDatePicker';
import { CheckboxInput } from 'components/form/CheckboxInput';
import FormTextField from 'components/form/FormTextField';
import { MODAL_PORTAL_ID } from 'components/modal/Modal';
import { Spinner } from 'components/spinner/Spinner';
import { VenueDropdownSelect } from 'components/venueDropdownSelect/VenueDropdownSelect';
import { ERROR_CODES } from 'errors';
import { Formik, FormikHelpers } from 'formik';
import { getNumberFromString } from 'helpers/getNumberFromString';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { useCryptoPriceCacher } from 'helpers/useCryptoPriceCacher';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAvailableAssets } from 'reducers/availableAssets';
import { selectCurrentHoldings } from 'reducers/currentHoldings';
import { closeModal, ModalType, openModal } from 'reducers/modal';
import { CryptoOption } from 'types/shared';
import * as Yup from 'yup';
import {
    CreateGuaranteeApi,
    CreateGuaranteeRequest,
    CreateGuaranteeSuccessResponseData,
    GuaranteeTerms,
} from './createGuaranteeApi';
import classNames from 'classnames';
import CongratsPlaceholder from 'assets/Congratulations_Placeholder.png';
import { ProgressBarSteps, SegmentedProgressBar } from '../SegmentedProgressBar';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../helpers/lottieHelper';
import SuccessAnimation from 'assets/bitline-ui-redesign/congratulations animation.json';

const generateDisclaimer = (tltv: number, liquidationPct: number) =>
    `As the market value of your Deposited Assets changes, so does the Actual Value of your transaction. If the Actual Value significantly increases an Early Settlement Threshold call may occur. You will then be asked to deposit Additional Assets to the transaction to bring the Actual Value more closely inline with what we call the Total Value. The Total Value of your transaction is ${tltv}%. If the Actual Value exceeds ${liquidationPct}%, a portion of your Digital Assets will automatically be sold to recover the transaction amount and fees, and any remaining Digital Assets returned to your wallet. If the Actual Value significantly decreases, you may be invited to release some of your Digital Assets back to your wallet to bring the Actual Value back to target.`;

const generateFee = (amount: string, feePercent: number, currency: string) => {
    return !getNumberFromString(amount)
        ? '-'
        : `${fiatCurrencyFormatter(
              (getNumberFromString(amount) as number) * (feePercent / 100),
              currency
          )}`;
};

const calculateCost = (
    guaranteeAmount: number,
    feePercent: number,
    LTVPercent: number,
    cryptoPrice: number
) => {
    // If using an LTV of 50%, the value of the fiat is 50% that of the crypto locked up.
    // Therefore we need to 2x the guarantee/transaction amount and then convert value to crypto
    // value.
    const LTVMultiplier = 100 / LTVPercent;
    const totalFiatCost = guaranteeAmount * LTVMultiplier;
    const totalCostInCrypto = totalFiatCost / cryptoPrice;
    return totalCostInCrypto;
};

type CreateGuaranteeStep2FormikState = {
    startDate: string;
    amount: string;
};
type CreateGuaranteeStep3FormikState = {
    disclaimer: boolean;
};

const validationSchemaStep2 = (min: number, max: number, currency: string) =>
    Yup.object({
        amount: Yup.string()
            .test('is number', 'Please enter a valid integer', (value: string | undefined) => {
                if (value) {
                    const numberOrNull = getNumberFromString(value);
                    if (numberOrNull && Number.isInteger(numberOrNull)) {
                        return true;
                    }
                }
                return false;
            })
            .test(
                'is above minimum',
                `The minimum is ${fiatCurrencyFormatter(min, currency)}`,
                (value: string | undefined) => {
                    if (value && getNumberFromString(value)) {
                        return getNumberFromString(value)! >= min;
                    }
                    return false;
                }
            )
            .test(
                'is below maximum',
                `The maximum is ${fiatCurrencyFormatter(max, currency)}`,
                (value: string | undefined) => {
                    if (value && getNumberFromString(value)) {
                        return getNumberFromString(value)! <= max;
                    }
                    return false;
                }
            ),
    });

const validationSchemaStep3 = () =>
    Yup.object({
        disclaimer: Yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
    });

const CreateGuaranteeModal: React.FC = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const currentHoldings = useSelector(selectCurrentHoldings);
    const { availableAssets: assets, availableVenues } = useSelector(selectAvailableAssets);
    const allAvailableCryptos = assets ? mapAvailableAssetsToDropdownSelectOptions(assets) : [];
    const ownedCryptos =
        allAvailableCryptos &&
        currentHoldings &&
        allAvailableCryptos.filter((crypto) => {
            const holdingIndex = currentHoldings.findIndex(
                (holding) => holding.fireblocksAssetId === crypto.fireblocksId
            );
            return holdingIndex !== -1;
        });

    const [isLoading, setIsLoading] = useState(false);
    const [selectedCryptoOption, setSelectedCryptoOption] = useState<null | CryptoOption>(null);
    const [selectedVenueId, setSelectedVenueId] = useState<null | number>(null);
    const [terms, setTerms] = useState<null | GuaranteeTerms>(null);
    const [errorMessages, setErrorMessages] = useState<string[] | null>(null);
    const [submittedStep2Values, setSubmittedStep2Values] =
        useState<CreateGuaranteeStep2FormikState | null>(null);
    const [successData, setSuccessData] = useState<CreateGuaranteeSuccessResponseData | null>(null);

    const [currentStepNumber, setCurrentStepNumber] = useState(1);
    const progressSteps: ProgressBarSteps<(typeof stepIdentifiers)[keyof typeof stepIdentifiers]> =
        [
            { stepNumber: 1, label: 'Currency / Venue', internalIdentifier: 'CurrencyVenue' },
            { stepNumber: 2, label: 'Dates / Amount', internalIdentifier: 'Dates' },
            { stepNumber: 3, label: 'Terms & Conditions', internalIdentifier: 'Terms' },
        ];
    const stepIdentifiers = {
        currencyVenue: 'CurrencyVenue',
        dates: 'Dates',
        terms: 'Terms',
    } as const;
    const nextStep = () => {
        if (currentStepNumber < progressSteps.length) {
            setCurrentStepNumber((step) => step + 1);
        }
    };

    const venueFiatAsset =
        availableVenues?.find((venue) => venue.id === selectedVenueId)?.venueAssetsCode ?? 'USD';

    const [cachedPrice, secondsRemainingTilPriceUpdate, getLatestPrice] = useCryptoPriceCacher(
        selectedCryptoOption?.fireblocksId && selectedVenueId !== null
            ? selectedCryptoOption.fireblocksId
            : null,
        venueFiatAsset,
        30000
    );

    const unlockedBalanceOfSelectedCrypto = currentHoldings?.find(
        (holding) => holding.fireblocksAssetId === selectedCryptoOption?.fireblocksId
    )?.unlockedBalance;

    useEffect(() => {
        getLatestPrice();
    }, [venueFiatAsset, getLatestPrice]);

    const venuesSupportedBySelectedCrypto = useMemo(
        () =>
            selectedCryptoOption && availableVenues
                ? availableVenues.filter((venue) =>
                      venue.supportedFireblocksAssetIds.includes(selectedCryptoOption.fireblocksId)
                  )
                : [],
        [selectedCryptoOption, availableVenues]
    );

    // If the user chooses venue X, then selectes currency B, then goes back and chooses
    // venue Y, if venue Y doesn't support B, we set selected currency to null and hide the
    // terms.
    // We avoid filtering the crypto list based on venue (as well as the other way around)
    // as the user would not be able to get to all different states once they've selected
    // both crypto and venue.
    if (venuesSupportedBySelectedCrypto && selectedVenueId) {
        const isCurrentlySelectedVenueInSupportedVenues = !!venuesSupportedBySelectedCrypto.find(
            (venue) => venue.id === selectedVenueId
        );
        if (!isCurrentlySelectedVenueInSupportedVenues) {
            setSelectedVenueId(null);
            setTerms(null);
        }
    }

    useEffect(() => {
        if (selectedCryptoOption && selectedVenueId) {
            setIsLoading(true);
            const selectedAvailableCrypto = assets
                ? assets.find(
                      (crypto) => crypto.fireblocksAssetId === selectedCryptoOption.fireblocksId
                  )
                : null;
            if (selectedAvailableCrypto) {
                CreateGuaranteeApi.getGuaranteeTerms(
                    selectedAvailableCrypto.fireblocksAssetId,
                    selectedVenueId
                )
                    .then((response) => {
                        setTerms(response.data.details);
                        setIsLoading(false);
                    })
                    .catch(() => {
                        Toast.openToastMessage('There was an error', ToastMessageReason.ERROR);
                        setIsLoading(false);
                    });
            }
        }
    }, [selectedVenueId, selectedCryptoOption, dispatch, assets]);

    const portalRef = useRef<HTMLDivElement | null>(null);
    const [shouldRererender, setShouldRerender] = useState(false);

    useEffect(() => {
        const portalElement = document.getElementById(MODAL_PORTAL_ID) as HTMLDivElement;
        if (portalElement) {
            portalRef.current = portalElement;
            setShouldRerender(true);
        }
    }, []);

    useEffect(() => {
        if (shouldRererender) {
            setShouldRerender(false);
        }
    }, [shouldRererender]);

    const initialValuesStep2: CreateGuaranteeStep2FormikState | null = terms && {
        startDate: new Date().toISOString(),
        amount: String(terms.guaranteeLimits.min),
    };

    const initialValuesStep3: CreateGuaranteeStep3FormikState = {
        disclaimer: false,
    };

    const minStartDate = useMemo(() => new Date(), []);
    const maxStartDate = useMemo(() => {
        // Add one month to min start date to get max start date. If month rolls over
        // into next year Date will automatically increment year.
        const maxDate = new Date();
        maxDate.setMonth(minStartDate.getMonth() + 1);
        return maxDate;
    }, [minStartDate]);

    const handleSubmitStep2 = (
        values: CreateGuaranteeStep2FormikState,
        helpers: FormikHelpers<CreateGuaranteeStep2FormikState>
    ) => {
        const step2Values: CreateGuaranteeStep2FormikState = {
            amount: values.amount,
            startDate: values.startDate,
        };
        setSubmittedStep2Values(step2Values);
        helpers.setSubmitting(false);
        nextStep();
    };

    const handleSubmitStep3 = async (
        values: CreateGuaranteeStep3FormikState,
        helpers: FormikHelpers<CreateGuaranteeStep3FormikState>
    ) => {
        if (submittedStep2Values != null) {
            const guaranteeValueAsNumber = getNumberFromString(submittedStep2Values.amount);
            if (
                terms &&
                cachedPrice &&
                selectedCryptoOption &&
                selectedVenueId &&
                guaranteeValueAsNumber
            ) {
                const cost = guaranteeValueAsNumber
                    ? calculateCost(
                          guaranteeValueAsNumber,
                          terms.feePercent,
                          terms.ltvPercent,
                          cachedPrice
                      )
                    : null;
                const request: CreateGuaranteeRequest | null = cost
                    ? {
                          amount: guaranteeValueAsNumber,
                          cost,
                          startDate: submittedStep2Values.startDate,
                          assetsId: selectedCryptoOption.id,
                          venueId: selectedVenueId,
                      }
                    : null;
                if (request) {
                    const response = await CreateGuaranteeApi.createGuarantee(request);
                    if (CreateGuaranteeApi.isSuccessData(response)) {
                        setSuccessData(response.data);
                        Toast.openToastMessage('Transaction created', ToastMessageReason.VALID);
                    } else if (CreateGuaranteeApi.isErrorData(response)) {
                        setErrorMessages(response.errors);
                        if (response.errors?.includes(ERROR_CODES['Crypto_Price_Updated'])) {
                            getLatestPrice();
                        }
                    } else {
                        dispatch(closeModal());
                        Toast.openGenericErrorToast();
                    }
                }
                helpers.setSubmitting(false);
            }
        }
    };

    const handleCancel = () => {
        dispatch(closeModal());
    };

    if (ownedCryptos && ownedCryptos.length === 0) {
        return (
            <div className="NoCryptosOwned">
                <h3>Please deposit some Crypto in order to create transactions.</h3>
                <Button
                    className="Btn BtnFull"
                    onClick={() => {
                        dispatch(openModal({ modalType: ModalType.DEPOSIT_CRYPTO }));
                    }}
                >
                    Deposit Crypto
                </Button>
            </div>
        );
    }

    if (successData) {
        return (
            <>
                <div className="CongratulationsScreen">
                    <Lottie
                        height={280}
                        width={280}
                        options={{ ...defaultOptions, animationData: SuccessAnimation }}
                        isClickToPauseDisabled={true}
                    />
                    <h1 className="Title">Congratulations!</h1>
                    <p className="Text">
                        Your transaction
                        <span> {successData.guaranteesCode} </span>
                        for
                        <span>
                            {' '}
                            {successData.amount.toFixed(2)} {successData.amountAssetsCode}{' '}
                        </span>
                        was created successfully.
                    </p>
                </div>
                <Button
                    minWidth
                    onClick={() => dispatch(closeModal())}
                    priority="primary"
                    style={{
                        backgroundColor: theme.colors.second,
                        borderColor: theme.colors.second,
                    }}
                >
                    Return to home page
                </Button>
            </>
        );
    }

    return (
        <div className="CreateGuaranteeModal">
            <h1 className="Title">Create a Transaction</h1>

            <SegmentedProgressBar steps={progressSteps} currentStepNumber={currentStepNumber} />

            {currentStepNumber === 1 ? (
                <>
                    <div className="DetailsSection">
                        <div className="SelectContainer">
                            {portalRef.current && ownedCryptos && (
                                <>
                                    <span className="FormLabel">Select a Currency</span>
                                    <CryptoDropdownSelect
                                        options={ownedCryptos}
                                        portalTarget={portalRef.current}
                                        selectedCrypto={selectedCryptoOption}
                                        setSelectedCrypto={setSelectedCryptoOption}
                                        amount={unlockedBalanceOfSelectedCrypto ?? undefined}
                                    />
                                </>
                            )}
                        </div>
                        <div className="SelectContainer">
                            {portalRef.current && (
                                <>
                                    <span className="FormLabel">Select a Venue</span>
                                    <VenueDropdownSelect
                                        portalTarget={portalRef.current}
                                        availableVenues={venuesSupportedBySelectedCrypto}
                                        selectedVenueId={selectedVenueId}
                                        setSelectedVenueId={setSelectedVenueId}
                                        disabled={venuesSupportedBySelectedCrypto.length === 0}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    <Button
                        onClick={nextStep}
                        priority="primary"
                        style={{
                            backgroundColor: theme.colors.second,
                            borderColor: theme.colors.second,
                        }}
                        disabled={!selectedCryptoOption || !selectedVenueId}
                    >
                        Continue
                    </Button>
                    <Button priority="secondary" className="Secondary" onClick={handleCancel}>
                        Cancel
                    </Button>
                </>
            ) : null}

            {currentStepNumber === 2 ? (
                isLoading ? (
                    <div className="LoaderContainer">
                        <Spinner />
                    </div>
                ) : (
                    terms &&
                    initialValuesStep2 && (
                        <Formik
                            initialValues={initialValuesStep2}
                            onSubmit={handleSubmitStep2}
                            validationSchema={validationSchemaStep2(
                                terms.guaranteeLimits.min,
                                terms.guaranteeLimits.max,
                                venueFiatAsset
                            )}
                            // Setting initial touched for amount field as error message
                            // only shows when field is touched, and touched is otherwise
                            // only set on blur.
                            initialTouched={{ amount: true }}
                        >
                            {({ isValid, values, setFieldValue, submitForm, isSubmitting }) => {
                                return (
                                    <>
                                        <div className="DetailsSection">
                                            <span className="FormLabel">Arrival date</span>
                                            <CustomDatePicker
                                                onChange={(date) =>
                                                    setFieldValue('startDate', date)
                                                }
                                                selected={new Date(values.startDate)}
                                                minDate={minStartDate}
                                                maxDate={maxStartDate}
                                                className={'CreateGuaranteeDatePicker'}
                                            />
                                            <FormTextField
                                                field={'amount'}
                                                label={`Transaction amount (${venueFiatAsset})`}
                                                required
                                            />
                                            {errorMessages && (
                                                <div className="ErrorMessages">
                                                    {errorMessages.map((error, i) => (
                                                        <p
                                                            className="ErrorText NoMargin"
                                                            key={`${error}-${i}`}
                                                        >
                                                            {error}
                                                        </p>
                                                    ))}
                                                </div>
                                            )}
                                        </div>

                                        {isSubmitting && <Spinner positionAbsolute />}

                                        <Button
                                            onClick={submitForm}
                                            priority="primary"
                                            style={{
                                                backgroundColor: theme.colors.second,
                                                borderColor: theme.colors.second,
                                            }}
                                            type="submit"
                                            disabled={!isValid || isSubmitting}
                                        >
                                            Continue
                                        </Button>
                                        <Button
                                            priority="secondary"
                                            className="Secondary"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </Button>
                                    </>
                                );
                            }}
                        </Formik>
                    )
                )
            ) : null}

            {currentStepNumber === 3 ? (
                isLoading ? (
                    <div className="LoaderContainer">
                        <Spinner />
                    </div>
                ) : (
                    terms &&
                    submittedStep2Values && (
                        <Formik
                            initialValues={initialValuesStep3}
                            onSubmit={handleSubmitStep3}
                            validationSchema={validationSchemaStep3()}
                        >
                            {({ isValid, errors, submitForm, isSubmitting, touched }) => {
                                const guaranteeValueAsNumber = getNumberFromString(
                                    submittedStep2Values.amount
                                );
                                const priceInCrypto =
                                    guaranteeValueAsNumber &&
                                    cachedPrice &&
                                    calculateCost(
                                        guaranteeValueAsNumber,
                                        terms.feePercent,
                                        terms.ltvPercent,
                                        cachedPrice
                                    );
                                const formattedPriceInCrypto =
                                    priceInCrypto?.toPrecision(GLOBAL_CRYPTO_PRECISION);
                                return (
                                    <>
                                        <div className="DetailsSection">
                                            <div className="Terms">
                                                <div className="Term">
                                                    <span className="Label">Term:</span>
                                                    <span>{terms.termDays} days</span>
                                                </div>
                                                <div className="Term">
                                                    <span className="Label">Total Value:</span>
                                                    <span>{terms.ltvPercent}%</span>
                                                </div>
                                                <div className="Term">
                                                    <span className="Label">
                                                        Fee ({terms.feePercent}%):
                                                    </span>
                                                    <span>
                                                        {guaranteeValueAsNumber
                                                            ? generateFee(
                                                                  submittedStep2Values.amount,
                                                                  terms.feePercent,
                                                                  venueFiatAsset
                                                              )
                                                            : '-'}
                                                    </span>
                                                </div>
                                                <div className="Term">
                                                    <span className="Label">Cost:</span>
                                                    <span>
                                                        {`${formattedPriceInCrypto} ${selectedCryptoOption?.displayCode}`}
                                                    </span>
                                                    <span className="SecondsRemaining">
                                                        {secondsRemainingTilPriceUpdate} s
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="AdditionalTerms">
                                                <div className="FeeRebates">
                                                    <p className="Title">Fee Rebates:</p>
                                                    {terms.additionalTerms.feeRebates?.map(
                                                        (rebate, i) => (
                                                            <div className="SpanContainer" key={i}>
                                                                <span className="Condition">
                                                                    Close within{' '}
                                                                    {rebate.closeWithinDays} days
                                                                </span>
                                                                <span className="Result">
                                                                    {rebate.rebatePercent}% of fee
                                                                </span>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                                <div className="ExtraFees">
                                                    <p className="Title">Extra fees:</p>
                                                    {terms.additionalTerms.extraFees?.map(
                                                        (fee, i) => (
                                                            <div className="SpanContainer" key={i}>
                                                                <span className="Condition">
                                                                    {fee.type}
                                                                </span>
                                                                <span className="Result">
                                                                    {fee.amount}%
                                                                </span>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>

                                            <div className="Disclaimer">
                                                <CheckboxInput label={''} name="disclaimer" />
                                                <div>
                                                    {generateDisclaimer(
                                                        terms.ltvPercent,
                                                        terms.ltvLiquidationPercent
                                                    )}
                                                </div>
                                            </div>
                                            {errors.disclaimer && touched.disclaimer ? (
                                                <p className="ErrorText NoMargin CreateGuaranteeDisclaimerError">
                                                    {errors.disclaimer}
                                                </p>
                                            ) : null}

                                            {errorMessages && (
                                                <div className="ErrorMessages">
                                                    {errorMessages.map((error, i) => (
                                                        <p
                                                            className="ErrorText NoMargin"
                                                            key={`${error}-${i}`}
                                                        >
                                                            {error}
                                                        </p>
                                                    ))}
                                                </div>
                                            )}
                                        </div>

                                        {isSubmitting && <Spinner positionAbsolute />}

                                        <Button
                                            onClick={submitForm}
                                            priority="primary"
                                            style={{
                                                backgroundColor: theme.colors.second,
                                                borderColor: theme.colors.second,
                                            }}
                                            type="submit"
                                            disabled={!isValid || isSubmitting}
                                        >
                                            Confirm
                                        </Button>
                                        <Button
                                            priority="secondary"
                                            className="Secondary"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </Button>
                                    </>
                                );
                            }}
                        </Formik>
                    )
                )
            ) : null}
        </div>
    );
};

export { CreateGuaranteeModal };
