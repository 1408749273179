import React from 'react';
import { ErrorMessage } from 'formik';
import WarningIcon from 'assets/bitline-ui-redesign/error icon.svg';

const ErrorM: React.FC<{ name: string; warningIcon?: boolean }> = (props) => (
    <ErrorMessage name={props.name}>
        {(msg) => (
            <div className="ErrorLabel">
                {props.warningIcon ? (
                    <img src={WarningIcon} alt="Warning icon" className="WarningIcon" />
                ) : null}
                <span>{msg}</span>
            </div>
        )}
    </ErrorMessage>
);

export { ErrorM };
