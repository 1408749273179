import React from 'react';
import TickImage from '../../assets/ibanera/Icon_Tick.png';
import Lottie from 'react-lottie';
import { AnimationJson, defaultOptions } from 'helpers/lottieHelper';

type EitherOrSelectionProps = {
    title: string;
    selected: boolean;
    onClick: () => void;
    animation: AnimationJson;
    value: string;
    recommended?: boolean;
    className?: string;
};

export const EitherOrSelection: React.FC<EitherOrSelectionProps> = ({
    title,
    selected,
    onClick,
    animation,
    value,
    recommended,
    className,
}) => {
    return (
        <label
            className={`RegistrationTile ${selected ? 'Selected' : ''} ${className ?? ''}`}
            onClick={onClick}
        >
            <div className="RegistrationTileTop">
                <span className="RegistrationTileLabel">{title}</span>

                <Lottie
                    height={150}
                    width={150}
                    options={{ ...defaultOptions, animationData: animation }}
                    isClickToPauseDisabled={true}
                />
                <input
                    type="radio"
                    name="accountType"
                    checked={selected}
                    onChange={() => {
                        selected = !selected;
                    }}
                    value={value}
                />
            </div>
            {recommended && <div className="Recommended">Recommended</div>}
            <div className="RegistrationTileBottom">
                <div className="CheckmarkContainer">
                    <div className="Checkmark CheckmarkCircle" />
                </div>
                <span>Select</span>
            </div>
        </label>
    );
};
