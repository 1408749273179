import instance, { ApiResponse } from 'api';
import { AxiosResponse } from 'axios';
import { FooterLinks } from 'components/footer/types';
import { LanguageItem } from 'components/languageDropdown/LanguageDropdown';
import { UserInformation } from 'components/navigation/Navigation';
import { SideMenuLink } from 'components/sideMenu/SideMenu';
import { endpoints } from 'endpoints.config';
import { Toast } from 'helpers/toast';
import { ActionsObservable, combineEpics, Epic, ofType } from 'redux-observable';
import { empty, forkJoin, from } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { signOut } from './auth';
import { updateLanguage } from './language';
import { Store } from './rootReducer';

export interface Icon {
    active: string;
    inactive?: string;
    mobileInactive?: string;
}

/* STATE */
type State = {
    footerLinks: FooterLinks | null;
    languageList: LanguageItem[] | null;
    customerMenuLinks: SideMenuLink[] | null;
    customerMenuIcons: Icon[] | null;
    userInfo: UserInformation | null;
};

const initialState: State = {
    footerLinks: null,
    languageList: null,
    customerMenuLinks: null,
    customerMenuIcons: null,
    userInfo: null,
};

/* ACTIONS */
const SET_COMPONENT_RESOURCES = 'app/resources/SET_COMPONENT_RESOURCES';
const DELETE_CUSTOMER_COMPONENT_RESOURCES = 'app/resources/DELETE_CUSTOMER_COMPONENT_RESOURCES';

type Action =
    | {
          type: typeof SET_COMPONENT_RESOURCES;
          payload: { resources: Partial<State> };
      }
    | { type: typeof DELETE_CUSTOMER_COMPONENT_RESOURCES };

/* REDUCER */
export default function reducer(state = initialState, action: Action): State {
    switch (action.type) {
        case SET_COMPONENT_RESOURCES:
            return { ...state, ...action.payload };
        case DELETE_CUSTOMER_COMPONENT_RESOURCES:
            return { ...state, userInfo: null, customerMenuLinks: null, customerMenuIcons: null };
        default:
            return state;
    }
}

// Epic
enum EpicActionType {
    GET_PUBLIC_COMPONENT_RESOURCES = 'app/resources/GET_PUBLIC_COMPONENT_RESOURCES',
    GET_CUSTOMER_COMPONENT_RESOURCES = 'app/resources/GET_CUSTOMER_COMPONENT_RESOURCES',
    UPDATE_USER_INFO = 'app/resources/UPDATE_USER_INFO',
    GET_ICONS = 'app/resources/GET_ICONS',
}

const fetchComponentResource = (endpoint: string) => {
    return instance.get<ApiResponse>(endpoint);
};

const checkAllResponsesAreSuccess = (responses: AxiosResponse<ApiResponse>[]) =>
    responses.reduce((acc, response) => response.data.status === '1' && acc, true);

// To add new component resources to either public or customer areas, add a new fetchComponentResource
// call to the forkJoin array and include the response in the final object passed to setComponentResources.
// Ensure the array in the second map call is descructured in the correct order.
type GetPublicComponentResourcesActionType = {
    type: EpicActionType.GET_PUBLIC_COMPONENT_RESOURCES;
};

export const getPublicComponentResourcesEpic: Epic = (
    action$: ActionsObservable<GetPublicComponentResourcesActionType>
) =>
    action$.pipe(
        ofType(EpicActionType.GET_PUBLIC_COMPONENT_RESOURCES),
        switchMap(() => {
            return forkJoin([
                fetchComponentResource(endpoints.navigationmodule.footerlinks),
                fetchComponentResource(endpoints.languagemodule.languages),
            ]).pipe(
                filter((responses) => checkAllResponsesAreSuccess(responses)),
                map((responses) => responses.map((response) => response.data.details)),
                mergeMap(([footerLinks, languageList]) => [
                    setComponentResources({ footerLinks, languageList }),
                    updateLanguage({
                        languageOptions: languageList.languageOptions,
                        cultureCode: languageList.language,
                    }),
                ]),
                catchError(() => {
                    Toast.openGenericErrorToast();
                    return empty();
                })
            );
        })
    );

type GetCustomerComponentResourcesActionType = {
    type: EpicActionType.GET_CUSTOMER_COMPONENT_RESOURCES;
};

export const getCustomerComponentResourcesEpic = (
    action$: ActionsObservable<GetCustomerComponentResourcesActionType>
) =>
    action$.pipe(
        ofType(EpicActionType.GET_CUSTOMER_COMPONENT_RESOURCES),
        switchMap(() => {
            return forkJoin([
                fetchComponentResource(endpoints.profilemodule.usersecurityinfo),
                fetchComponentResource(endpoints.profilemodule.userinfo),
                fetchComponentResource(endpoints.navigationmodule.customerNavigationLinks),
            ]).pipe(
                filter((responses) => checkAllResponsesAreSuccess(responses)),
                map((responses) => responses.map((response) => response.data.details)),
                mergeMap(([userSecurityInfo, userInfo, customerMenuLinks]) => {
                    userInfo['emailAddress'] = userSecurityInfo.emailAddress;
                    return [
                        setComponentResources({ userInfo, customerMenuLinks }),
                        getIcons(customerMenuLinks),
                    ];
                }),
                catchError(() => {
                    Toast.openGenericErrorToast();
                    return [signOut(false), deleteCustomerComponentResources()];
                })
            );
        })
    );

type UpdateUserInfoEpicAction = { type: EpicActionType.UPDATE_USER_INFO };

export const updateUserInfoEpic = (action$: ActionsObservable<UpdateUserInfoEpicAction>) =>
    action$.pipe(
        ofType(EpicActionType.UPDATE_USER_INFO),
        switchMap(() => {
            return from(fetchComponentResource(endpoints.profilemodule.userinfo)).pipe(
                map((response) => setComponentResources({ userInfo: response.data.details })),
                catchError(() => {
                    Toast.openGenericErrorToast();
                    return [signOut(false), deleteCustomerComponentResources()];
                })
            );
        })
    );

type GetIconsEpicAction = {
    type: EpicActionType.GET_ICONS;
    payload: SideMenuLink[];
};

const ICONS_PATH = '/assets/menuIcons/';

const fetchIconAndCreateImgSrc = (path: string) => {
    const base64SrcPrefix = 'data:image/png;base64, ';
    return instance
        .get(`${window.location.origin}${ICONS_PATH}${path}`, {
            responseType: 'arraybuffer',
        })
        .then(({ data }) => Buffer.from(data, 'binary').toString('base64'))
        .then((base64String) => base64SrcPrefix + base64String);
};

const getIconsEpic: Epic = (action$: ActionsObservable<GetIconsEpicAction>) =>
    action$.pipe(
        ofType(EpicActionType.GET_ICONS),
        // Filter as some iconName values are null.
        map(({ payload }) => payload.filter(({ iconName }) => !!iconName)),
        switchMap((iconNames) => {
            return forkJoin(
                iconNames.map(({ iconName }) => {
                    return forkJoin([
                        fetchIconAndCreateImgSrc(`${iconName}_Active.png`),
                        fetchIconAndCreateImgSrc(`${iconName}_Inactive.png`),
                        fetchIconAndCreateImgSrc(`${iconName}_Mobile_Inactive.png`),
                    ]);
                })
            ).pipe(
                map((icons) =>
                    icons.map(([active, inactive, mobileInactive]) => ({
                        active,
                        inactive,
                        mobileInactive,
                    }))
                ),
                map((customerMenuIcons) => setComponentResources({ customerMenuIcons }))
            );
        })
    );

export const componentResourcesEpic = combineEpics(
    getPublicComponentResourcesEpic,
    getCustomerComponentResourcesEpic,
    updateUserInfoEpic,
    getIconsEpic
);
/* ACTION CREATORS */
const setComponentResources = (resources: Partial<State>) => {
    return {
        type: SET_COMPONENT_RESOURCES,
        payload: resources,
    };
};

export const getPublicComponentResources = (): GetPublicComponentResourcesActionType => ({
    type: EpicActionType.GET_PUBLIC_COMPONENT_RESOURCES,
});

export const getCustomerComponentResources = (): GetCustomerComponentResourcesActionType => {
    return {
        type: EpicActionType.GET_CUSTOMER_COMPONENT_RESOURCES,
    };
};

export const updateUserInfo = (): UpdateUserInfoEpicAction => {
    return {
        type: EpicActionType.UPDATE_USER_INFO,
    };
};

const getIcons = (payload: SideMenuLink[]): GetIconsEpicAction => {
    return {
        type: EpicActionType.GET_ICONS,
        payload,
    };
};

export const deleteCustomerComponentResources = (): Action => {
    return {
        type: DELETE_CUSTOMER_COMPONENT_RESOURCES,
    };
};

/* SELECTORS */
export const selectComponentResources = (store: Store) => store.componentResources;
