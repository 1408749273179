import { ColumnDetail } from '@avamae/table/dist/hooks/useTable';
import { TableInfo } from 'api';
import React from 'react';
import { CellOptions } from './Cell';
import { RowConfig } from './FlexTableRow';
import { LeftTable } from './LeftTable';
import { MiddleTable } from './MiddleTable';
import { NoResults } from './NoResults';
import { RightTable } from './RightTable';

export type Checkbox = {
    selectedIds: (string | number)[];
    setSelectedIds: React.Dispatch<React.SetStateAction<(string | number)[]>>;
    idColumn: string | number;
};

export type PrimitiveFormatters = Partial<{
    boolean(b: boolean): React.ReactNode;
    string(s: string): React.ReactNode;
    number(n: number): React.ReactNode;
    date(d: Date): React.ReactNode;
}>;

export type TableProps<T = any> = {
    idColumn: string;
    checkbox?: Checkbox;
    table?: TableInfo;
    rowHeight?: 'default' | 'large';
    rowButton?(data: any): React.ReactNode;
    rowOptionComponent?(data: any): React.ReactNode;
    onRowClick?(id: number | string): void;
    primitiveFormatters?: PrimitiveFormatters;
    hiddenColumns?: string[];
    rowFormatter?(r: T): { [K in keyof T]: React.ReactNode };
    noRowHover?: boolean;
    splitFromLeft?: number;
    splitFromRight?: number;
    buttonInMiddleTable?: boolean;
    rowCellOptions?: { [K in keyof T]: CellOptions };
    rowConfig?: RowConfig;
    showIdColumn?: boolean;
    noResultsMessage?: string;
    type?: string;
    queryParams?: { [k: string]: any };
    emptyListComponent?: React.FC;
    reload?: any;
    isTallRow?: (r: T) => boolean;
};

// Defaults
export const defaultPrimitiveFormatters: PrimitiveFormatters = {
    boolean: (b: boolean) => (b ? <span>Yes</span> : <span>No</span>),
    date: (d: Date) => {
        return new Date(d).toDateString();
    },
    string: (s: string) => <span>{s}</span>,
    number: (n: number) => <span>{n}</span>,
};

const FlexTable: React.FC<TableProps> = ({
    idColumn,
    checkbox,
    table,
    onRowClick,
    primitiveFormatters,
    rowHeight = 'default',
    type = '',
    rowButton,
    rowOptionComponent,
    hiddenColumns: h,
    rowFormatter,
    noRowHover,
    splitFromLeft = 0,
    splitFromRight = 0,
    buttonInMiddleTable = false,
    rowCellOptions,
    rowConfig,
    showIdColumn,
    noResultsMessage,
    queryParams,
    emptyListComponent: EmptyListComponent,
    isTallRow,
}) => {
    const hiddenColumns = React.useMemo(() => h ?? [], [h]);
    const [rowHovered, setRowHovered] = React.useState<number | null>(null);

    const loading = table && table.loading;
    const actions = table && table.data?.actions;
    const columns = React.useMemo(
        () => (table && table.data ? table.data.details.columns : []),
        [table]
    );
    const listData = table && table.data ? table.data.details.listData : [];
    const sortBy = table?.data?.details.sortBy;

    const rawColumns = React.useMemo(
        () =>
            columns
                ? columns
                      .filter((c) => c.bVisible)
                      .sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1))
                : [],
        [columns]
    );

    const processedColumns = React.useMemo(() => {
        return rawColumns.filter((c) => {
            if (!showIdColumn && (c.columnKey as string).toLowerCase() === idColumn.toLowerCase()) {
                return false;
            }
            return !hiddenColumns
                .map((x) => x.toLowerCase())
                .includes(c.columnKey.toString().toLowerCase());
        });
    }, [hiddenColumns, idColumn, showIdColumn, rawColumns]);

    const formatPrimitives = React.useCallback(
        (value: unknown) => {
            const formatter = {
                ...defaultPrimitiveFormatters,
                ...primitiveFormatters,
            };
            if (typeof value === 'string' && formatter.string) return formatter.string(value);
            if (typeof value === 'number' && formatter.number) return formatter.number(value);
            if (typeof value === 'boolean' && formatter.boolean) return formatter.boolean(value);

            return value;
        },
        [primitiveFormatters]
    );

    const handleRowHover = React.useCallback(
        (_e: React.MouseEvent<HTMLDivElement>, rowIndex: number) => {
            setRowHovered(rowIndex);
        },
        []
    );

    const handleRowClick = React.useCallback(
        (id: number) => {
            if (onRowClick) {
                onRowClick(id);
            } else if (checkbox && !noRowHover) {
                checkbox.setSelectedIds((p) => {
                    return p.includes(id) ? p.filter((i) => i !== id) : p.concat([id]);
                });
            }
        },
        [onRowClick, checkbox, noRowHover]
    );

    const getTableWrapperClassName = () => {
        let className = 'FlexTableWrapper';

        if (rowHeight === 'large') {
            className += ' LargeRow';
        }
        return className;
    };

    const middleTableColumns = React.useMemo(
        () =>
            processedColumns.filter((_, i) =>
                isBetween(i, splitFromLeft - 1, processedColumns.length - splitFromRight)
            ),
        [processedColumns, splitFromLeft, splitFromRight]
    );

    const isListEmpty = !loading && listData && listData.length === 0;

    return (
        <>
            <div className={getTableWrapperClassName()}>
                <div className="Inner">
                    <div
                        className={`TableContainer ${loading ? 'Loading ' : ''}${
                            isListEmpty ? 'Empty ' : ''
                        }`}
                        onMouseLeave={() => setRowHovered(null)}
                    >
                        <LeftTable
                            idColumn={idColumn}
                            columns={processedColumns}
                            toggleColumnSort={actions?.toggleColumnSort as (s: any) => void}
                            queryParams={queryParams}
                            sortBy={sortBy as any}
                            checkbox={checkbox}
                            listData={listData}
                            rowHovered={rowHovered}
                            handleMouseEnterRow={handleRowHover}
                            onRowClick={handleRowClick}
                            formatPrimitives={formatPrimitives}
                            rowFormatter={rowFormatter}
                            noRowHover={noRowHover}
                            columnCount={splitFromLeft}
                            rowCellOptions={rowCellOptions}
                            rowConfig={rowConfig}
                            isTallRow={isTallRow}
                        />
                        <MiddleTable
                            columns={middleTableColumns}
                            listData={listData}
                            toggleColumnSort={actions?.toggleColumnSort as (s: any) => void}
                            queryParams={queryParams}
                            sortBy={sortBy as any}
                            rowHovered={rowHovered}
                            rowButton={buttonInMiddleTable ? rowButton : undefined}
                            handleMouseEnterRow={handleRowHover}
                            onRowClick={handleRowClick}
                            idColumn={idColumn}
                            hiddenColumns={hiddenColumns}
                            formatPrimitives={formatPrimitives}
                            rowFormatter={rowFormatter}
                            noRowHover={noRowHover}
                            selectedIds={checkbox?.selectedIds}
                            rowCellOptions={rowCellOptions}
                            type={type}
                            rowConfig={rowConfig}
                            isTallRow={isTallRow}
                        />
                        <div id="RightBoxShadow" className="RightBoxShadowElement" />
                        <RightTable
                            idColumn={idColumn}
                            columns={processedColumns}
                            listData={listData}
                            sortBy={sortBy as any}
                            selectedIds={checkbox?.selectedIds}
                            toggleColumnSort={actions?.toggleColumnSort}
                            queryParams={queryParams}
                            rowHovered={rowHovered}
                            handleMouseEnterRow={handleRowHover}
                            onRowClick={handleRowClick}
                            formatPrimitives={formatPrimitives}
                            rowFormatter={rowFormatter}
                            noRowHover={noRowHover}
                            rowButton={rowButton}
                            rowOptionComponent={rowOptionComponent}
                            columnCount={splitFromRight}
                            buttonInMiddleTable={buttonInMiddleTable}
                            rowCellOptions={rowCellOptions}
                            rowConfig={rowConfig}
                            isTallRow={isTallRow}
                        />
                    </div>
                    {!loading &&
                        listData &&
                        listData.length === 0 &&
                        (EmptyListComponent ? (
                            <EmptyListComponent />
                        ) : (
                            <NoResults message={noResultsMessage} />
                        ))}
                </div>
            </div>
        </>
    );
};
export { FlexTable };

// helpers
const isBetween = (numOfInterest: number, lowerBound: number, higherBound: number) =>
    numOfInterest > lowerBound && numOfInterest < higherBound;
