import React, { useState } from 'react';
import { TableInfo } from 'api';
import { NoResults } from '../flexTable/NoResults';
import classNames from 'classnames';
import { defaultPrimitiveFormatters, PrimitiveFormatters } from 'components/flexTable/FlexTable';
import { BitLineTableRow } from './BitLineTableRow';
import { ColumnDetail } from 'helpers/createUseTable';

export type TableProps<T = any> = {
    idColumn: string;
    headerColumns: Array<keyof T>;
    table?: TableInfo;
    rowButton?(data: T): React.ReactNode;
    primitiveFormatters?: PrimitiveFormatters;
    rowFormatter?(r: T): { [K in keyof T]: React.ReactNode };
    emptyListComponent?: React.FC;
    noResultsMessage?: string;
    isNotCollapsible?: boolean;
};

const BitLineTable = <T,>({
    idColumn,
    headerColumns,
    table,
    rowButton,
    primitiveFormatters,
    rowFormatter,
    emptyListComponent: EmptyListComponent,
    noResultsMessage,
    isNotCollapsible = false,
}: TableProps<T>) => {
    const [expandedRowId, setExpandedRowId] = useState<string | null>(null);

    const loading = table && table.loading;
    const columns: ColumnDetail<T>[] = React.useMemo(
        () => (table && table.data ? table.data.details.columns : []),
        [table]
    );
    const listData = table && table.data ? table.data.details.listData : [];

    const rawColumns = React.useMemo(
        () =>
            columns
                ? columns
                      .filter((c) => c.bVisible)
                      .sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1))
                : [],
        [columns]
    );

    const processedColumns = React.useMemo(() => {
        return rawColumns.filter((c) => {
            if ((c.columnKey as string).toLowerCase() === idColumn.toLowerCase()) {
                return false;
            }
            return true;
            // return !hiddenColumns
            //     .map((x) => x.toLowerCase())
            //     .includes(c.columnKey.toString().toLowerCase());
        });
    }, [idColumn, rawColumns]);

    const formatPrimitives = React.useCallback(
        (value: unknown) => {
            const formatter = {
                ...defaultPrimitiveFormatters,
                ...primitiveFormatters,
            };
            if (typeof value === 'string' && formatter.string) return formatter.string(value);
            if (typeof value === 'number' && formatter.number) return formatter.number(value);
            if (typeof value === 'boolean' && formatter.boolean) return formatter.boolean(value);

            return value;
        },
        [primitiveFormatters]
    );

    const handleToggleRowExpand = React.useCallback((rowId: string) => {
        setExpandedRowId((prevRowId) => {
            if (rowId === prevRowId) {
                return null;
            }
            return rowId;
        });
    }, []);

    const headerColumnsDetails = React.useMemo(
        () =>
            processedColumns.filter((col) =>
                headerColumns
                    .map((x) => x.toString().toLowerCase())
                    .includes(col.columnKey.toString().toLowerCase())
            ),
        [processedColumns, headerColumns]
    );

    const bodyColumnsDetails = React.useMemo(
        () =>
            processedColumns.filter(
                (col) =>
                    !headerColumns
                        .map((x) => x.toString().toLowerCase())
                        .includes(col.columnKey.toString().toLowerCase())
            ),
        [processedColumns, headerColumns]
    );

    const isListEmpty = !loading && listData && listData.length === 0;

    if (loading) return null;

    return (
        <>
            <div
                className={classNames('BitLineTableContainer', {
                    Loading: loading,
                    Empty: isListEmpty,
                })}
            >
                <div className="TableHead">
                    {headerColumnsDetails.map((col) => {
                        return (
                            <div className="HeadItemCell">
                                <span className={'HeadItemContent'} title={col.labelValue}>
                                    {col.labelValue}
                                </span>
                            </div>
                        );
                    })}

                    {isNotCollapsible ? null : <span className="ChevronColumn" />}
                </div>

                {listData && listData.length > 0 && (
                    <div className="TableBody">
                        {listData.map((row) => {
                            const rowId = row[idColumn].toString() as string;
                            const isExpanded = expandedRowId ? expandedRowId === rowId : false;

                            return (
                                <BitLineTableRow
                                    key={rowId}
                                    id={rowId}
                                    row={row}
                                    idColumn={idColumn}
                                    columns={processedColumns}
                                    headerColumnsDetails={headerColumnsDetails}
                                    bodyColumnsDetails={bodyColumnsDetails}
                                    handleToggleRowExpand={handleToggleRowExpand}
                                    rowButton={rowButton}
                                    formatPrimitives={formatPrimitives}
                                    rowFormatter={rowFormatter}
                                    isExpanded={isExpanded}
                                    isNotCollapsible={isNotCollapsible}
                                />
                            );
                        })}
                    </div>
                )}
                {isListEmpty &&
                    (EmptyListComponent ? (
                        <EmptyListComponent />
                    ) : (
                        <NoResults message={noResultsMessage} />
                    ))}
            </div>
        </>
    );
};
export { BitLineTable };
