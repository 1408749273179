import { stringOnlyContainsNumbers } from 'helpers/stringOnlyContainsNumbers';
import { SignInTranslation } from 'pages/signIn/signInTypes';
import { SetStateAction } from 'react';
import { TFAType } from 'types/shared';
import * as Yup from 'yup';
import { OptionalObjectSchema } from 'yup/lib/object';

export enum RegisterStep {
    INPUT_PERSONAL_DETAILS,
    EMAIL_VERIFICATION,
    SELECT_AUTH_TYPE,
    TFA_SETUP,
    TFA_VERIFICATION,
    COMPLETE,
}

export enum AccountType {
    PERSONAL = 'Personal',
    BUSINESS = 'Business',
}

export enum AuthType {
    APP = 'App',
    SMS = 'SMS',
}

export interface RegistrationStepProps {
    translations: SignInTranslation | null;
    onComplete: () => void;
}

export interface SelectAccountTypeProps extends RegistrationStepProps {
    accountType: AccountType;
    setAccountType: React.Dispatch<SetStateAction<AccountType>>;
}
export interface SelectAuthTypeProps extends RegistrationStepProps {
    authType: AuthType;
    setAuthType: React.Dispatch<SetStateAction<AuthType>>;
}

export interface InputDetailsProps extends RegistrationStepProps {
    setAccountDetails: React.Dispatch<SetStateAction<AccountDetails>>;
}

export interface RegisterFormSubForm {
    translations: SignInTranslation | null;
    errorMessage: string;
    setErrorMessage: (msg: string) => void;
}

export interface EmailVerificationProps extends RegistrationStepProps {
    accountDetails: AccountDetails;
    formikProps?: any;
}

export type EmailVerificationCodePayload = {
    username: string;
    password: string;
    emailVerificationCode: string;
    bResendCode: boolean;
};
export type TFAVerificationPayload = {
    username: string;
    password: string;
    tfaType: TFAType;
    tfaCode: string;
    phoneNumber?: string;
    totpSharedSecret?: string;
};

export type AccountDetails = {
    emailAddress: string;
    password: string;
    phoneNumber?: string;
};

export type RegisterCustomerUserPayload = {
    firstName: string;
    lastName: string;
    emailAddress: string;
    password: string;
    confirmPassword: string;
    phoneNumber: string;
};

export type RegisterValidateStepOne = {
    companyName: string;
    companyEmailAddress: string;
    firstName: string;
    lastName: string;
    password: string;
    confirmPassword: string;
};

export interface TFASetupProps extends RegistrationStepProps {
    authType: string;
    setAuthType: React.Dispatch<SetStateAction<AuthType>>;
    setSharedSecret: React.Dispatch<SetStateAction<string>>;
    setPhoneNumber: React.Dispatch<SetStateAction<string>>;
    accountDetails: AccountDetails;
}

export type TFASetupState = {
    tfaStep: string;
};
export type AppSetupDetails = {
    qrCode: any;
    sharedSecret: string;
};

export interface SMSTFASetup {
    username: string;
    password: string;
    phoneNumber?: string;
    bNewPhoneNumber: boolean;
}
export interface TFAVerificationProps extends RegistrationStepProps {
    authType: string;
    accountDetails: AccountDetails;
    sharedSecret?: string;
    phoneNumber?: string;
    setAuthType: React.Dispatch<React.SetStateAction<AuthType>>;
    setRegistrationStep: React.Dispatch<React.SetStateAction<RegisterStep>>;
}

export const stepIdentifiers = {
    personalDetails: 'PersonalDetails',
    accountDetails: 'AccountDetails',
    emailVerification: 'EmailVerification',
} as const;

export type StepIdentifier = (typeof stepIdentifiers)[keyof typeof stepIdentifiers];

export const generateValidationSchema = (
    translations: SignInTranslation | null
): Record<StepIdentifier, OptionalObjectSchema<any>> => {
    const validationSchemaPersonalDetails = Yup.object({
        firstName: Yup.string().required('Please provide a first name'),
        lastName: Yup.string().required('Please provide a last name'),
        nameConfirmed: Yup.bool().oneOf([true], 'Please confirm this name matches your ID'),
    });

    const validationSchemaAccountDetails = Yup.object({
        phoneNumber: Yup.string()
            .required('Please provide a valid phone number')
            .min(5, 'Please provide a valid phone number'),
        emailAddress: Yup.string()
            .email(translations?.email_address_required)
            .required(translations?.email_address_required),
        password: Yup.string().required(translations?.password_required),
        confirmPassword: Yup.string().required('Please confirm the given password'),
    });
    const validationSchemaEmailVerification = Yup.object({
        emailVerificationCode: Yup.string()
            .required('Please enter your verification code')
            .test(
                'is valid tfa',
                'Please enter your verification code',
                (value: string | undefined) => {
                    if (value && value.length === 6 && stringOnlyContainsNumbers(value)) {
                        return true;
                    }
                    return false;
                }
            ),
    });
    return {
        [stepIdentifiers.personalDetails]: validationSchemaPersonalDetails,
        [stepIdentifiers.accountDetails]: validationSchemaAccountDetails,
        [stepIdentifiers.emailVerification]: validationSchemaEmailVerification,
    };
};
