import { navigate } from '@reach/router';
import Lottie from 'react-lottie';
import { AppPath } from 'appConstants';
import Button from 'components/button/Button';
import animationJson from '../../lotties/authenticator app animation.json';

export const RegistrationSuccessMessage = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationJson,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <div className="SuccessMessage">
            <Lottie height={250} width={250} options={defaultOptions} />
            <h1>Congratulations!</h1>
            <p>
                Your Registration Was Sucessful.
                <br />
                Return To The Login Screen To Sign In.
            </p>
            <Button
                minWidth
                priority="primary"
                onClick={() => navigate(AppPath.SIGN_IN)}
                color="second"
            >
                Go to Sign In
            </Button>
        </div>
    );
};
