import Button from 'components/button/Button';
import { TFAField } from 'components/form/TFAField';
import { Form, Formik } from 'formik';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { useTFAField } from 'helpers/useTFAField';
import { TFAValidation } from 'helpers/validationSnippets';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, DisableTFATypeModalData, selectModalState } from 'reducers/modal';
import * as Yup from 'yup';
import { ProfileApi } from './ProfileApi';
import { SecurityContext } from './Security';

type FormikState = {
    tfaCode: string;
};

const initialValues: FormikState = {
    tfaCode: '',
};

const validationSchema = Yup.object({
    tfaCode: TFAValidation,
});

export const DisableTFAModal: React.FC = () => {
    const dispatch = useDispatch();
    const { data } = useSelector(selectModalState);
    const [tfaType, toggleTFAType] = useTFAField();
    const { reloadSecurityData } = useContext(SecurityContext);
    const [errors, setErrors] = useState<string[] | null>(null);

    if (!data) {
        Toast.openGenericErrorToast();
    }

    const typeToDisable = data as DisableTFATypeModalData;

    const handleSubmit = async (values: FormikState) => {
        if (typeToDisable) {
            const response = await ProfileApi.DisableTFA({
                tfaCode: values.tfaCode,
                tfaType: tfaType,
                changeTfaType: typeToDisable,
            });

            if (ProfileApi.isSuccessData(response)) {
                dispatch(closeModal());
                Toast.openToastMessage('TFA type disabled successfully', ToastMessageReason.VALID);
                reloadSecurityData();
            } else if (ProfileApi.isErrorData(response)) {
                setErrors(response.errors);
                reloadSecurityData();
            } else {
                dispatch(closeModal());
                Toast.openGenericErrorToast();
            }
        }
    };

    return (
        <div className="UpdateAppTFAModal">
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                enableReinitialize
            >
                {(formikProps) => (
                    <Form>
                        <TFAField
                            field={'tfaCode'}
                            label={'TFA Code'}
                            autoComplete={'one-time-code'}
                            value={formikProps.values.tfaCode}
                            toggleTFAType={toggleTFAType}
                            tfaType={tfaType}
                            required
                            autoFocus
                            holdFocus
                        />
                        {errors && (
                            <>
                                {errors.map((error) => (
                                    <p className="ErrorText NoMargin">{error}</p>
                                ))}
                            </>
                        )}
                        <div className="ModalNavigation">
                            <Button
                                priority="secondary"
                                type="button"
                                onClick={() => {
                                    dispatch(closeModal());
                                }}
                                color="grey"
                            >
                                Cancel
                            </Button>
                            <Button
                                priority="primary"
                                type="submit"
                                disabled={!formikProps.isValid || formikProps.isSubmitting}
                            >
                                Update
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
