/** @jsxImportSource @emotion/react */
import { css } from '@emotion/core';
import { useTheme } from '@emotion/react';
import { Spinner } from 'components/spinner/Spinner';
import { TFAInputMask } from 'components/tfaInputMask/TFAInputMask';
import { ERROR_CODES, getErrorMessage } from 'errors';
import { Form, useFormikContext } from 'formik';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { useFocus } from 'helpers/useFocus';
import { postSmsTFASetup } from 'pages/register/RegisterApi';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../components/button/Button';
import FormTextField from '../../components/form/FormTextField';
import {
    selectAuthMessage,
    selectAuthStatus,
    selectTFAType,
    TFASignInAttempt,
} from '../../reducers/auth';
import { SignInTranslation, TFAFormDetails } from './signInTypes';
import WarningIcon from 'assets/bitline-ui-redesign/error icon.svg';

type Props = {
    translations: SignInTranslation | null;
};

const TFAForm: React.FC<Props> = ({ translations }) => {
    const theme = useTheme();
    const TFAType = useSelector(selectTFAType);
    const TFAError = useSelector(selectAuthMessage);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { values, setFieldValue, setFieldTouched, setFieldError } =
        useFormikContext<TFAFormDetails>();
    const authStatus = useSelector(selectAuthStatus);
    const [inputRef, setFocus] = useFocus<HTMLInputElement>();

    const TFAFormCSS = css`
        .UseSMS {
            color: ${theme.colors.second};
        }
    `;

    React.useEffect(() => {
        const tfa = TFAType === 'both' || TFAType === 'app' ? 'AuthenticatorApp' : 'SMS';
        setFieldTouched('tfaCode', false);
        setFieldValue('tfaType', tfa);
        if (tfa === 'SMS') {
            setFieldValue('useSMS', true);
        } else {
            setFieldValue('useSMS', false);
        }
    }, [TFAType, setFieldValue, setFieldTouched]);

    React.useEffect(() => {
        if (TFAError && TFAError.length > 0) {
            setErrorMessage('Code is invalid, please try again');
            setFieldValue('tfaCode', '');
        }
    }, [TFAError, setFieldError, setFieldValue]);

    const handleChangeAuthType = () => {
        setFocus();
        setErrorMessage(null);
        if (values.useSMS === false) {
            //Send SMS TFA Code
            if (values.username && values.password) {
                (async () => {
                    const result = await postSmsTFASetup({
                        username: values.username,
                        password: values.password,
                        bNewPhoneNumber: false,
                    });
                    if (result.response) {
                    }
                    if (result.errors && result.errors.length > 0) {
                        const translatableErrors = result.errors.filter(
                            (err) => err.messageCode in ERROR_CODES
                        );
                        if (translatableErrors.length > 0) {
                            Toast.openToastMessage(
                                getErrorMessage(translatableErrors[0].messageCode),
                                ToastMessageReason.ERROR
                            );
                        } else {
                            Toast.openGenericErrorToast();
                        }
                    }
                })();
            }
        }
        setFieldValue('useSMS', !values.useSMS);
    };

    return (
        <Form className="AuthFormContainer">
            <div className="AuthForm TFAForm" css={TFAFormCSS}>
                <h1>{translations?.heading2 ?? ''}</h1>
                <p>
                    {' '}
                    {values.useSMS
                        ? translations?.sub_heading4 ?? ''
                        : translations?.sub_heading2 ?? ''}
                </p>
                <div className="FieldsContainer">
                    <div className="TFAInputContainer">
                        <FormTextField
                            field={'tfaCode'}
                            label={'Your verification code'}
                            required={true}
                            maxLength={6}
                            autoFocus
                            autoComplete={'one-time-code'}
                            inputMode="numeric"
                            // touched={() => { setError(false) }}
                            onBlur={setFocus}
                            ref={inputRef}
                            className="TFAHiddenField"
                        />
                        <TFAInputMask
                            value={values.tfaCode}
                            className={errorMessage ? 'ErrorInput' : ''}
                        />
                    </div>
                    {TFAType === 'both' && (
                        <div className="UseSMS" onClick={handleChangeAuthType}>
                            {values.useSMS
                                ? translations?.use_authy ?? ''
                                : translations?.use_sms ?? ''}
                        </div>
                    )}
                    {errorMessage && (
                        <div className="ErrorLabel TFAErrorMessage">
                            <img src={WarningIcon} alt="Warning icon" className="WarningIcon" />
                            <span>{errorMessage}</span>
                        </div>
                    )}
                    <Button
                        variety="full"
                        type="submit"
                        disabled={authStatus === 'tfa_pending'}
                        color="second"
                    >
                        {authStatus === 'tfa_pending' ? (
                            <Spinner color={'#fff'} />
                        ) : values.useSMS ? (
                            translations?.verify_button ?? ''
                        ) : (
                            translations?.continue_button ?? ''
                        )}
                    </Button>
                </div>
            </div>
        </Form>
    );
};

export const parseTfaForm = (payload: TFAFormDetails): TFASignInAttempt => ({
    username: payload.username,
    password: payload.password,
    rememberMe: payload.rememberMe,
    tfaCode: payload.tfaCode,
    tfaType: payload.tfaType,
});

export default TFAForm;
