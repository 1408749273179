export const parseQueryParamsToObject = <T = any>(queryString: string): T | null => {
    // https://stackoverflow.com/a/8649003
    try {
        return JSON.parse(
            '{"' + decodeURI(queryString.replace(/&/g, '","').replace(/=/g, '":"')) + '"}'
        );
    } catch {
        return null;
    }
};
