import instance, { ApiResponse } from 'api';
import { Spinner } from 'components/spinner/Spinner';
import { endpoints } from 'endpoints.config';
import { update } from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setVerificationStage } from 'reducers/jumio';
import { JumioStage, VerificationStatusResponse } from './jumioModels';

export const JumioIframe = ({ url, stage }: { url: string; stage: JumioStage }) => {
    const dispatch = useDispatch();
    const iframeOrigin = 'https://netverify.com';

    const handleComplete = useCallback(() => {
        const url =
            stage === JumioStage.IdFrame
                ? endpoints.jumiomodule.idIframeComplete
                : endpoints.jumiomodule.documentIframeComplete;
        instance.post<ApiResponse<VerificationStatusResponse>>(url).then((res) => {
            dispatch(
                setVerificationStage({
                    currentStage: res.data.details.status,
                    additionalData: res.data.details.additionalData,
                })
            );
        });
    }, [stage, dispatch]);

    const handleMessage = useCallback(
        (e: MessageEvent<any>) => {
            if (
                e.origin.toLowerCase() === iframeOrigin ||
                e.origin.toLowerCase() === 'https://bitline.netverify.com'
            ) {
                // temporarily allow both so that this can be deployed separately from change to url
                if (typeof e.data === 'string') {
                    const data = JSON.parse(e.data);
                    if (data.payload.value === 'success') handleComplete();
                } else if (e.data.payload.value === 'success') handleComplete();
            }
        },
        [handleComplete, iframeOrigin]
    );

    useEffect(() => {
        window.addEventListener('message', handleMessage);
        return () => window.removeEventListener('message', handleMessage);
    }, [handleMessage]);

    return (
        <iframe
            title="Jumio ID Iframe"
            src={url}
            className="JumioIframe"
            allow="camera;fullscreen;accelerometer;gyroscope;magnetometer"
            allowFullScreen
        ></iframe>
    );
};
