import instance from 'api';
import { endpoints } from 'endpoints.config';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveTFAType } from 'reducers/auth';
import { TFAType } from 'types/shared';
import { Toast, ToastMessageReason } from './toast';

export const useTFAField = (enabled = true) => {
    const activeTFAType = useSelector(selectActiveTFAType);

    const [canSwitch, _] = useState(() => activeTFAType === 'both');
    const [currentTFAType, setCurrentTFAType] = useState<TFAType>(() =>
        activeTFAType === 'both'
            ? 'AuthenticatorApp'
            : activeTFAType === 'app'
            ? 'AuthenticatorApp'
            : activeTFAType === 'sms'
            ? 'SMS'
            : 'AuthenticatorApp'
    );
    // Can toggle if toggleTFAType function is non-null.
    const toggleTFAType = canSwitch
        ? () => {
              setCurrentTFAType((prevValue) =>
                  prevValue === 'AuthenticatorApp' ? 'SMS' : 'AuthenticatorApp'
              );
          }
        : null;

    useEffect(() => {
        if (currentTFAType === 'SMS' && enabled) {
            instance.post(endpoints.profilemodule.requestSmsTfaCode);
            Toast.openToastMessage('SMS TFA code sent', ToastMessageReason.PENDING);
        }
    }, [currentTFAType, enabled]);

    return [currentTFAType, toggleTFAType] as const;
};
