import AAVE from 'assets/currencyIcons/AAVE.svg';
import ADA from 'assets/currencyIcons/ADA.svg';
import ALGO from 'assets/currencyIcons/ALGO.svg';
import ANT from 'assets/currencyIcons/ANT.svg';
import ATOM from 'assets/currencyIcons/ATOM.svg';
import BAL from 'assets/currencyIcons/BAL.svg';
import BAT from 'assets/currencyIcons/BAT.svg';
import BCH from 'assets/currencyIcons/BCH.svg';
import BNB from 'assets/currencyIcons/BNB.svg';
import BSV from 'assets/currencyIcons/BSV.svg';
import COMP from 'assets/currencyIcons/COMP.svg';
import CRV from 'assets/currencyIcons/CRV.svg';
import DAI from 'assets/currencyIcons/DAI.svg';
import DASH from 'assets/currencyIcons/DASH.svg';
import DOT from 'assets/currencyIcons/DOT.svg';
import EOS from 'assets/currencyIcons/EOS.svg';
import ETC from 'assets/currencyIcons/ETC.svg';
import ETH from 'assets/currencyIcons/ETH.svg';
import EWT from 'assets/currencyIcons/EWT.svg';
import FIL from 'assets/currencyIcons/FIL.svg';
import FLOW from 'assets/currencyIcons/FLOW.svg';
import GNO from 'assets/currencyIcons/GNO.svg';
import GRT from 'assets/currencyIcons/GRT.svg';
import ICX from 'assets/currencyIcons/ICX.svg';
import KAVA from 'assets/currencyIcons/KAVA.svg';
import KEEP from 'assets/currencyIcons/KEEP.svg';
import KNC from 'assets/currencyIcons/KNC.svg';
import KSM from 'assets/currencyIcons/KSM.svg';
import LINK from 'assets/currencyIcons/LINK.svg';
import LSK from 'assets/currencyIcons/LSK.svg';
import LTC from 'assets/currencyIcons/LTC.svg';
import MANA from 'assets/currencyIcons/MANA.svg';
import MLN from 'assets/currencyIcons/MLN.svg';
import NANO from 'assets/currencyIcons/NANO.svg';
import OCEAN from 'assets/currencyIcons/OCEAN.svg';
import OMG from 'assets/currencyIcons/OMG.svg';
import OXT from 'assets/currencyIcons/OXT.svg';
import PAX from 'assets/currencyIcons/PAX.svg';
import PAXG from 'assets/currencyIcons/PAXG.svg';
import QTUM from 'assets/currencyIcons/QTUM.svg';
import REP from 'assets/currencyIcons/REP.svg';
import SC from 'assets/currencyIcons/SC.svg';
import SNX from 'assets/currencyIcons/SNX.svg';
import STORJ from 'assets/currencyIcons/STORJ.svg';
import TRX from 'assets/currencyIcons/TRX.svg';
import UNI from 'assets/currencyIcons/UNI.svg';
import USDC from 'assets/currencyIcons/USDC.svg';
import USDT from 'assets/currencyIcons/USDT.svg';
import WAVES from 'assets/currencyIcons/WAVES.svg';
import XBT from 'assets/currencyIcons/XBT.svg';
import XDG from 'assets/currencyIcons/XDG.svg';
import XLM from 'assets/currencyIcons/XLM.svg';
import XMR from 'assets/currencyIcons/XMR.svg';
import XRP from 'assets/currencyIcons/XRP.svg';
import XTZ from 'assets/currencyIcons/XTZ.svg';
import YFI from 'assets/currencyIcons/YFI.svg';
import ZEC from 'assets/currencyIcons/ZEC.svg';

import AUD from 'assets/currencyIcons/AUD.png';
import CAD from 'assets/currencyIcons/CAD.png';
import EUR from 'assets/currencyIcons/EUR.png';
import GBP from 'assets/currencyIcons/GBP.png';
import HKD from 'assets/currencyIcons/HKD.png';
import JPY from 'assets/currencyIcons/JPY.png';
import USD from 'assets/currencyIcons/USD.png';

import Default from 'assets/currencyIcons/default.svg';

const ICON_MAP = {
    AAVE,
    ADA,
    ALGO,
    ANT,
    ATOM,
    BAL,
    BAT,
    BCH,
    BNB,
    BSV,
    COMP,
    CRV,
    DAI,
    DASH,
    DOT,
    EOS,
    ETC,
    ETH,
    EWT,
    FIL,
    FLOW,
    GNO,
    GRT,
    ICX,
    KAVA,
    KEEP,
    KNC,
    KSM,
    LINK,
    LSK,
    LTC,
    MANA,
    MLN,
    NANO,
    OCEAN,
    OMG,
    OXT,
    PAX,
    PAXG,
    QTUM,
    REP,
    SC,
    SNX,
    STORJ,
    TRX,
    UNI,
    USDC,
    USDT,
    WAVES,
    XBT,
    XDG,
    XLM,
    XMR,
    XRP,
    XTZ,
    YFI,
    ZEC,
    // Fireblocks Id -> icon
    BTC: XBT,
    BTC_TEST: XBT,
    ETH_TEST: ETH,
    DASH_TEST: DASH,
    XRP_TEST: XRP,
    USDT_T: USDT,
    USDC_TEST3: USDC,
    USDT_BSC_TEST: USDT,
    ETH_TEST5: ETH,
    // Fiats
    AUD,
    CAD,
    GBP,
    HKD,
    JPY,
    USD,
    EUR,
};

type CurrencyIconProps = {
    currency: string;
    className?: string;
};

export const CurrencyIcon: React.FC<CurrencyIconProps> = ({ currency, className }) => {
    return currency in ICON_MAP ? (
        <img
            src={ICON_MAP[currency as keyof typeof ICON_MAP]}
            alt={currency}
            style={{ borderRadius: '50%' }}
            className={className ?? ''}
        />
    ) : (
        <img src={Default} alt="Fallback" className={className ?? ''} />
    );
};
