import { css } from '@emotion/core';
import { useTheme } from '@emotion/react';
import { Link, useLocation } from '@reach/router';
import { SideMenuLink } from 'components/sideMenu/SideMenu';
import React from 'react';
/** @jsxImportSource @emotion/react */

type SubNavigationMenuProps = {
    items: SideMenuLink[];
    basePath: string;
};

export const SubNavigationMenu: React.FC<SubNavigationMenuProps> = ({ items, basePath }) => {
    const theme = useTheme();
    const { pathname } = useLocation();

    const isLinkActive = (path: string) => pathname === path;

    const activeLinkStyle = css`
        border-bottom: 3px solid ${theme.colors.second};
        // To prevent text from moving when active
        padding-top: 3px;
    `;

    return (
        <div className="SubNavigationMenu">
            {items.map((item) => {
                const path = `${basePath}/${item.path}`;
                const isActive = isLinkActive(path);
                const style = isActive ? activeLinkStyle : null;
                return (
                    <Link to={path} className={`Link`} css={style} key={item.id}>
                        <span className="LinkText">{item.displayName}</span>
                    </Link>
                );
            })}
        </div>
    );
};
