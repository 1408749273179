import { useTheme } from '@emotion/react';
import { fiatCurrencyFormatter } from 'appConstants';
import Button from 'components/button/Button';
import { CheckboxInput } from 'components/form/CheckboxInput';
import { GuaranteeTableRow, isGuaranteeTableRow } from 'components/guaranteesTable/GuaranteesTable';
import { Spinner } from 'components/spinner/Spinner';
import { Formik } from 'formik';
import { Toast, ToastMessageReason } from 'helpers/toast';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentHoldings } from 'reducers/currentHoldings';
import { closeModal, ModalType, openModal, selectModalState } from 'reducers/modal';
import * as Yup from 'yup';
import { TopUpAmountResponse, TopUpModalApi } from './TopUpModalApi';

type TopUpModalFormikState = {
    disclaimer: boolean;
};

const initialValues: TopUpModalFormikState = {
    disclaimer: false,
};

const validationSchema = Yup.object({
    disclaimer: Yup.boolean().oneOf([true]),
});

const generateDisclaimer = (tltv: number, liquidationPct: number) =>
    `As the market value of your Deposited Assets changes, so does the Actual Value of your transaction. If the Actual Value significantly increases an Early Settlement Threshold call may occur. You will then be asked to deposit Additional Assets to the transaction to bring the Actual Value more closely inline with what we call the Total Value. The Total Value of your transaction is ${tltv}%. If the Actual Value exceeds ${liquidationPct}%, a portion of your Digital Assets will automatically be sold to recover the transaction amount and fees, and any remaining Digital Assets returned to your wallet. If the Actual Value significantly decreases, you may be invited to release some of your Digital Assets back to your wallet to bring the Actual Value back to target.`;

export const TopUpModal: React.FC = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { data } = useSelector(selectModalState);
    const currentHoldings = useSelector(selectCurrentHoldings);
    const [topUpResponse, setTopUpResponse] = useState<TopUpAmountResponse | null>(null);

    if (!isGuaranteeTableRow(data)) {
        dispatch(closeModal());
    }
    const topUpModalData = data as GuaranteeTableRow;

    useEffect(() => {
        TopUpModalApi.getTopUpAmount(topUpModalData.guarantees__Id)
            .then((response) => {
                setTopUpResponse(response.data.details);
            })
            .catch(() => {
                dispatch(closeModal());
                Toast.openGenericErrorToast();
            });
    }, [topUpModalData.guarantees__Id, dispatch]);

    const handleSubmit = (values: TopUpModalFormikState) => {
        if (topUpResponse) {
            TopUpModalApi.postTopUpRequest(topUpModalData.guarantees__Id, topUpResponse.amount)
                .then((response) => {
                    dispatch(closeModal());
                    Toast.openToastMessage('Top up sucessful', ToastMessageReason.VALID);
                })
                .catch(() => {
                    Toast.openGenericErrorToast();
                });
        }
    };

    const handleClose = () => {
        dispatch(closeModal());
    };

    const handleOpenDepositModal = () => {
        dispatch(
            openModal({
                modalType: ModalType.DEPOSIT_CRYPTO,
                data: { fireblocksAssetId: topUpModalData.assets__FireblocksAssetId },
            })
        );
    };

    const selectedCurrentHolding = currentHoldings?.find(
        (holding) => holding.fireblocksAssetId === topUpModalData.assets__FireblocksAssetId
    );
    const userCanTopUp =
        selectedCurrentHolding &&
        topUpResponse &&
        selectedCurrentHolding.unlockedBalance &&
        selectedCurrentHolding.unlockedBalance > topUpResponse.amount;

    return (
        <div className="TopUpModal">
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                validateOnMount
            >
                {({ submitForm, isValid, isSubmitting }) =>
                    topUpResponse && selectedCurrentHolding ? (
                        userCanTopUp ? (
                            <div>
                                <p>
                                    <b>
                                        Your Actual Value has dropped below our safe threshold to
                                        fully cover your transaction for{' '}
                                        {fiatCurrencyFormatter(
                                            topUpModalData.guarantees__GuaranteeValue,
                                            topUpModalData.fiatAssets__Code
                                        )}{' '}
                                        USD.
                                    </b>
                                </p>
                                <p>
                                    In order to avoid your transaction being automatically liquidated
                                    to USD we need you to Top Up by adding more{' '}
                                    {topUpModalData.assets__Code}.
                                </p>
                                <p>
                                    To bring your Actual Value back to {topUpResponse.tltv}% we need to
                                    increase your transaction hold by a further{' '}
                                    <b>
                                        {topUpResponse.amount} {topUpModalData.assets__Code}
                                    </b>
                                    .
                                </p>
                                <div className="Disclaimer">
                                    <CheckboxInput label={''} name="disclaimer" />
                                    {generateDisclaimer(
                                        topUpResponse.tltv,
                                        topUpResponse.ltvLiquidationPercent
                                    )}
                                </div>
                                <div className="ModalFooter">
                                    <Button
                                        priority="secondary"
                                        className="Secondary"
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={submitForm}
                                        priority="primary"
                                        style={{
                                            backgroundColor: theme.colors.second,
                                            borderColor: theme.colors.second,
                                        }}
                                        type="submit"
                                        disabled={!isValid || isSubmitting}
                                    >
                                        Confirm
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <p>
                                    <b>
                                        Your Actual Value has dropped below our safe threshold to
                                        fully cover your transaction for{' '}
                                        {fiatCurrencyFormatter(
                                            topUpModalData.guarantees__GuaranteeValue,
                                            topUpModalData.fiatAssets__Code
                                        )}{' '}
                                        USD.
                                    </b>
                                </p>
                                <p>
                                    In order to avoid your transaction being automatically liquidated
                                    to USD we need you to Top Up by locking{' '}
                                    <b>
                                        {topUpResponse.amount} {topUpModalData.assets__Code}
                                    </b>
                                    .
                                </p>
                                {selectedCurrentHolding.unlockedBalance && (
                                    <p>
                                        You currently have {selectedCurrentHolding.unlockedBalance}{' '}
                                        {selectedCurrentHolding.displayCode} unlocked. Please
                                        deposit at least{' '}
                                        <b>
                                            {topUpResponse.amount -
                                                selectedCurrentHolding.unlockedBalance}{' '}
                                            {selectedCurrentHolding.displayCode}
                                        </b>{' '}
                                        and top up this transaction.
                                    </p>
                                )}
                                <div className="ModalFooter">
                                    <Button
                                        priority="secondary"
                                        className="Secondary"
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={handleOpenDepositModal}
                                        priority="primary"
                                        style={{
                                            backgroundColor: theme.colors.second,
                                            borderColor: theme.colors.second,
                                        }}
                                        type="button"
                                    >
                                        Deposit {selectedCurrentHolding.displayCode}
                                    </Button>
                                </div>
                            </div>
                        )
                    ) : (
                        <div className="LoaderContainer">
                            <Spinner />
                        </div>
                    )
                }
            </Formik>
        </div>
    );
};
