import instance, { ApiResponse, BaseApi } from 'api';
import { endpoints } from 'endpoints.config';
import {
    DisableTFAType,
    EditableUserProfileDetails,
    GetUserProfileResponse,
    IDeactivateAccount,
    ITfa,
    IUpdateEmail,
    IUpdatePassword,
    IUpdatePhoneNumber,
    IUpdateTFA,
    IVerifyPhoneNumber,
    ProfileParser,
    StateProvince,
    UpdateAppTFAState,
    UserProfileDetails,
} from './models';

export class ProfileApi extends BaseApi {
    public static RequestSMSCode = async () => {
        return ProfileApi.makeRequest<string>({ url: endpoints.profilemodule.requestSmsTfaCode });
    };

    public static UpdatePassword = (payload: IUpdatePassword) => {
        return ProfileApi.makeRequest({
            url: endpoints.profilemodule.updatePassword,
            method: 'post',
            params: payload,
        });
    };

    public static UpdatePhoneNumber = async (payload: IUpdatePhoneNumber) => {
        return instance.post(endpoints.profilemodule.updatePhoneNumber, null, {
            params: ProfileParser.parseUpdatePhoneNumber(payload),
        });
    };

    public static VerifyPhoneNumber = async (payload: IVerifyPhoneNumber) => {
        return instance.post(endpoints.profilemodule.verifyPhoneNumber, null, {
            params: payload,
        });
    };

    public static UpdateEmailAddress = async (payload: IUpdateEmail) => {
        return ProfileApi.makeRequest({
            url: endpoints.profilemodule.updateEmailAddress,
            method: 'POST',
            params: ProfileParser.parseUpdateEmailAddress(payload),
        });
    };

    public static UpdateEmailAddressConfirm = async (payload: IUpdateEmail) => {
        return ProfileApi.makeRequest({
            url: endpoints.profilemodule.updateEmailAddressConfirm,
            method: 'POST',
            params: ProfileParser.parseUpdateEmailAddressConfirm(payload),
        });
    };

    public static ResendEmailVerificationCode = async (payload: IUpdateEmail) => {
        return ProfileApi.makeRequest({
            url: endpoints.profilemodule.updateEmailAddressConfirm,
            method: 'POST',
            params: ProfileParser.parseResendEmailAddressVerification(payload),
        });
    };

    public static DeactivateAccount = async (payload: IDeactivateAccount) => {
        return ProfileApi.makeRequest({
            url: endpoints.profilemodule.disableAccount,
            method: 'POST',
            data: ProfileParser.parseDeactivateAccount(payload),
        });
    };

    public static EnableSmsTFA = async (payload: IUpdateTFA) => {
        return ProfileApi.makeRequest({
            url: endpoints.profilemodule.enableSmsTfa,
            method: 'POST',
            data: ProfileParser.parseEnableSmsTFA(payload),
        });
    };

    public static UpdateAppTFA = async (payload: ITfa) => {
        return ProfileApi.makeRequest<UpdateAppTFAState>({
            url: endpoints.profilemodule.updateAppTfa,
            method: 'POST',
            data: payload,
        });
    };

    public static UpdateTFAConfirm = async (payload: ITfa) => {
        return ProfileApi.makeRequest({
            url: endpoints.profilemodule.updateTfaConfirm,
            method: 'POST',
            data: payload,
        });
    };

    public static DisableTFA = async (payload: DisableTFAType) => {
        return ProfileApi.makeRequest({
            url: endpoints.profilemodule.disableTfa,
            method: 'POST',
            data: payload,
        });
    };

    public static GetUserDetails = async () => {
        return ProfileApi.makeRequest<GetUserProfileResponse>({
            url: endpoints.profilemodule.userProfileDetails,
            method: 'GET',
        });
    };

    public static UpdateUserDetails = async (details: EditableUserProfileDetails) => {
        return ProfileApi.makeRequest<UserProfileDetails>({
            url: endpoints.profilemodule.userProfileDetails,
            data: details,
            method: 'POST',
        });
    };

    public static GetStateProvinceOptions = async (isoCode: string) => {
        return ProfileApi.makeRequest<{ stateProvinceOptions: StateProvince[] }>({
            url: endpoints.profilemodule.getStateProvinceOptions,
            method: 'GET',
            params: { countryISOCode: isoCode },
        });
    };
}
