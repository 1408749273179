import UpDown from 'assets/bitline-ui-redesign/chevron down.svg';
import { InitialsIcon } from 'components/initialsIcon/InitialsIcon';
import React, { MutableRefObject, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import { AvailableVenue, selectAvailableAssets } from 'reducers/availableAssets';

type VenueDropdownSelectProps = {
    selectedVenueId: number | null;
    setSelectedVenueId: (venueId: number) => void;
    availableVenues?: AvailableVenue[];
    portalTarget?: HTMLElement;
    disabled?: boolean;
};

export const VenueDropdownSelect: React.FC<VenueDropdownSelectProps> = ({
    selectedVenueId,
    setSelectedVenueId,
    availableVenues,
    portalTarget = null,
    disabled = false,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const containerRef = useRef<null | HTMLDivElement>(null);
    const { availableVenues: storedVenues } = useSelector(selectAvailableAssets);

    const displayedVenues = availableVenues ?? storedVenues;

    const handleSelect = (id: number) => {
        setSelectedVenueId(id);
        setIsOpen(false);
    };

    const toggleOpen = () => {
        if (disabled) return;
        setIsOpen((prev) => !prev);
    };

    const dropdownOptions = displayedVenues
        ? displayedVenues
              .filter((option) => option.id !== selectedVenueId)
              .map<IOption>((option) => ({ name: option.name, value: option.id }))
        : [];

    const selectedVenue = displayedVenues
        ? displayedVenues.find((venue) => venue.id === selectedVenueId)
        : null;

    return (
        <div
            className={`VenueDropdownSelectContainer${disabled ? ' DropdownDisabled' : ''}`}
            ref={containerRef}
        >
            <div className="CurrentValue" onClick={toggleOpen}>
                {selectedVenue && <InitialsIcon name={selectedVenue.name} className="Icon" />}
                <div className="Text">
                    {selectedVenue ? (
                        <h4 className="NoMargin Small">{selectedVenue.name}</h4>
                    ) : (
                        <h4 className="NoMargin Small Placeholder">Select</h4>
                    )}
                </div>
                <img
                    src={UpDown}
                    className={isOpen ? 'UpDown Open' : 'UpDown Closed'}
                    alt="toggle select"
                />
            </div>
            {isOpen &&
                (portalTarget && containerRef.current ? (
                    <VenueOptions
                        containerRef={containerRef as MutableRefObject<HTMLDivElement>}
                        portalTarget={portalTarget}
                        onClickGenerator={(id: number) => () => {
                            handleSelect(id);
                        }}
                        options={dropdownOptions}
                    />
                ) : (
                    <VenueOptions
                        onClickGenerator={(id: number) => () => {
                            handleSelect(id);
                        }}
                        options={dropdownOptions}
                    />
                ))}
        </div>
    );
};

interface IOption {
    name: string;
    value: number;
}

type VenueOptionsProps = {
    options: IOption[];
    onClickGenerator: (id: number) => () => void;
    containerRef?: MutableRefObject<HTMLDivElement>;
    portalTarget?: HTMLElement;
};

const VenueOptions: React.FC<VenueOptionsProps> = ({
    onClickGenerator,
    options,
    containerRef,
    portalTarget,
}) => {
    if (portalTarget && containerRef && containerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect();

        const styles: React.CSSProperties = {
            width: containerRect.width,
            top: containerRect.top + (containerRect.height + 10),
            left: containerRect.left,
        };

        return createPortal(
            <div className="SelectMenu VenueOptions" style={styles}>
                {options.map((option) => (
                    <VenueOption
                        key={option.value}
                        name={option.name}
                        value={option.value}
                        onClick={onClickGenerator(option.value)}
                    />
                ))}
            </div>,
            portalTarget
        );
    }

    return (
        <div className="SelectMenu">
            {options.map((option) => (
                <VenueOption
                    name={option.name}
                    value={option.value}
                    onClick={onClickGenerator(option.value)}
                />
            ))}
        </div>
    );
};

type VenueOptionProps = {
    name: string;
    value: number;
    onClick: (value: number) => void;
};

const VenueOption: React.FC<VenueOptionProps> = ({ name, value, onClick }) => {
    return (
        <div className="VenueOption" onClick={() => onClick(value)}>
            <InitialsIcon name={name} className="Icon" />
            <div className="Text">
                <h4 className="NoMargin">{name}</h4>
            </div>
        </div>
    );
};
