/** @jsxImportSource @emotion/react */
import { css } from '@emotion/core';
import { useTheme } from '@emotion/react';
import { Link, useLocation } from '@reach/router';
import { DASHBOARD_MENU_PAGE_SUB_PAGE_PLACEHOLDER } from 'App';
import { CreateGuaranteeModal } from 'components/createGuaranteeModal/CreateGuaranteeModal';
import { DepositCryptoModal } from 'components/depositCryptoModal/DepositCryptoModal';
import { Modal } from 'components/modal/Modal';
import { NavMenuButtons } from 'components/navMenuButtons/NavMenuButtons';
import React from 'react';
import { useSelector } from 'react-redux';
import { Icon, selectComponentResources } from 'reducers/componentResources';
import { ModalType, selectModalState } from 'reducers/modal';
import { AppPath, DashboardPath } from '../../appConstants';
import { selectCultureCode } from '../../reducers/language';

export interface SideMenuLink {
    id: number;
    displayName: string;
    path: string;
    apiPath: string;
    resourceKey: string;
    position: string;
    iconName: string;
    childElements: SideMenuLink[];
}

type SideMenuProps = {
    links: SideMenuLink[] | null;
    icons: Icon[] | null;
};

const SideMenu: React.FC<SideMenuProps> = ({ links, icons }) => {
    const theme = useTheme();
    const modal = useSelector(selectModalState);
    const cultureCode = useSelector(selectCultureCode);
    const { pathname } = useLocation();
    const SideMenuCSS = css`
        .DashboardLink {
            background: ${theme.colors.first};
            border-left: 5px solid ${theme.colors.third};
        }

        a.Active {
            border-left: 5px solid ${theme.colors.second};
        }
    `;
    const isLinkActive = (link: string) => {
        return pathname.startsWith(link);
    };

    const { userInfo } = useSelector(selectComponentResources);

    return (
        <>
            <div className="SideMenu" css={SideMenuCSS}>
                <ul>
                    <li className="DashboardLinkContainer">
                        <Link
                            className="DashboardLink"
                            to={`/${cultureCode}${AppPath.PERSONAL}${DashboardPath.HOME}/${DASHBOARD_MENU_PAGE_SUB_PAGE_PLACEHOLDER}`}
                        >
                            <h3>
                                {userInfo?.firstName} {userInfo?.lastName}
                            </h3>
                        </Link>
                    </li>
                    <li>
                        {links &&
                            links.map((link, index) => {
                                // Route to the first subpage if they exist.
                                // See App.tsx Dashboard routing for explanation on the need for
                                // this placeholder, if no subpages.
                                const linkHasChildren = !!link.childElements;
                                const finalUrlSegment = `/${
                                    linkHasChildren
                                        ? `${link.childElements[0].path}`
                                        : DASHBOARD_MENU_PAGE_SUB_PAGE_PLACEHOLDER
                                }`;
                                const basePath = `/${cultureCode}${AppPath.PERSONAL}/${link.path}`;
                                const linkClass = `SideMenuLink ${
                                    isLinkActive(basePath) && 'Active'
                                }`;
                                return (
                                    <Link
                                        className={linkClass}
                                        to={`/${cultureCode}${AppPath.PERSONAL}/${link.path}${finalUrlSegment}`}
                                        key={link.displayName}
                                    >
                                        {icons && (
                                            <img
                                                src={icons[index].active}
                                                alt={link.displayName}
                                                className={
                                                    isLinkActive(basePath) ? '' : 'IconHidden'
                                                }
                                            />
                                        )}
                                        {icons && (
                                            <img
                                                src={icons[index].inactive}
                                                alt={link.displayName}
                                                className={
                                                    !isLinkActive(basePath) ? '' : 'IconHidden'
                                                }
                                            />
                                        )}
                                        {link.displayName}
                                    </Link>
                                );
                            })}
                    </li>
                </ul>
                <NavMenuButtons />
            </div>
            {modal.modalType === ModalType.DEPOSIT_CRYPTO ? (
                <Modal removeCloseButton={true} filledHeader={true}>
                    <DepositCryptoModal />
                </Modal>
            ) : null}
            {modal.modalType === ModalType.CREATE_GUARANTEE ? (
                <Modal removeCloseButton={true} filledHeader={true}>
                    <CreateGuaranteeModal />
                </Modal>
            ) : null}
        </>
    );
};

export default SideMenu;
