import React from 'react';
import { useField } from 'formik';
import zxcvbn from 'zxcvbn';

export type PasswordStrengthProps = {
    fieldName: string;
    onInfoClick(): void;
};
export type PasswordStrengthDetails = {
    className: string;
    text: string;
};
const PasswordStrengthMeter: React.FC<PasswordStrengthProps> = ({ fieldName, onInfoClick }) => {
    const [field] = useField(fieldName);
    const result = zxcvbn(field.value);

    const passwordStrengthDetails = (): PasswordStrengthDetails => {
        switch (result.score) {
            case 1:
                return {
                    className: 'Strength_Weak',
                    text: 'Weak',
                };
            case 2:
                return {
                    className: 'Strength_SoSo',
                    text: 'So-so',
                };
            case 3:
                return {
                    className: 'Strength_Good',
                    text: 'Good',
                };
            case 4:
                return {
                    className: 'Strength_Great',
                    text: 'Great!',
                };
            default:
                return {
                    className: 'Strength_VeryWeak',
                    text: 'Very weak',
                };
        }
    };
    return (
        <div className="PasswordStrengthHelper">
            <div className={`PasswordStrengthInfo ${passwordStrengthDetails().className}`}>
                {passwordStrengthDetails().text}
                <div className="MoreInfo" onClick={() => onInfoClick()}>
                    i
                </div>
            </div>
            <div
                className={`PasswordStrengthMeterContainer ${passwordStrengthDetails().className}`}
            >
                <div className="PasswordStrengthMeter" />
                <div className="PasswordStrengthMeter" />
                <div className="PasswordStrengthMeter" />
                <div className="PasswordStrengthMeter" />
            </div>
        </div>
    );
};
export { PasswordStrengthMeter };
