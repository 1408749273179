import { AxiosError, AxiosRequestConfig } from 'axios';
import { produce } from 'immer';
import { ActionsObservable, combineEpics, ofType } from 'redux-observable';
import { createSelector } from 'reselect';
import { from, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import api, { ApiResponse } from '../api';
import { endpoints } from '../endpoints.config';
import {
    deleteTokenResource,
    LoadedTokenResource,
    loadTokenResource,
    saveTokenResource,
    Scopes,
} from '../helpers/auth';
import { Errors } from '../helpers/forms';
import {
    deleteCustomerComponentResources,
    getCustomerComponentResources,
} from './componentResources';
import { deleteCurrentHoldings } from './currentHoldings';
import { deleteVerificationStatus } from './jumio';
import { closeModal } from './modal';
import { Store } from './rootReducer';

export type SignInAttempt = {
    username: string;
    password: string;
    rememberMe: boolean;
};

export type TFASignInAttempt = {
    username: string;
    password: string;
    rememberMe: boolean;
    tfaCode: string;
    tfaType: string;
};

type SignInError = {
    error: null | string;
    error_description: Errors;
    error_uri: null;
};

type SignInSuccess = {
    details: {
        accessToken: string;
        bEmailVerified: true;
        bTwoFactorAppAuthEnabled: false;
        bTwoFactorSMSAuthEnabled: false;
        expiresIn: string;
        refreshToken: string;
        refreshTokenExpiresIn: number;
        scopes?: Scopes;
    };
    status: string;
};

type SignInNeedsTFA = {
    details: {
        accessToken: null;
        bEmailVerified: boolean;
        bTwoFactorAppAuthEnabled: boolean;
        bTwoFactorSMSAuthEnabled: boolean;
        expiresIn: 0;
        refreshToken: null;
        scopes?: Scopes;
    };
    status: string;
};
type SignInNeedsEmailVerified = {
    details: {
        accessToken: null;
        bEmailVerified: false;
        bTwoFactorAppAuthEnabled: boolean;
        bTwoFactorSMSAuthEnabled: boolean;
        expiresIn: 0;
        refreshToken: null;
        refreshTokenExpiresIn: 0;
        scopes?: Scopes;
    };
    status: string;
};
type SignInNeedsToAddTFAToAccount = {
    details: {
        accessToken: null;
        bEmailVerified: true;
        bTwoFactorAppAuthEnabled: false;
        bTwoFactorSMSAuthEnabled: false;
        expiresIn: 0;
        refreshToken: null;
        refreshTokenExpiresIn: 0;
        scopes?: Scopes;
    };
    status: string;
};

type SignInResponse = SignInSuccess | SignInError | SignInNeedsTFA | SignInNeedsEmailVerified;

type TFATypesResponse = { bTwoFactorAppAuthEnabled: boolean; bTwoFactorSMSAuthEnabled: boolean };

// Type guards to check response type.
const isSignInResponseSuccess = (r: SignInResponse): r is SignInSuccess =>
    (r as SignInSuccess).status === '1' && !!(r as SignInSuccess).details.accessToken;

const isResponseNeedsTFA = (r: SignInResponse): r is SignInNeedsTFA => {
    const response = r as SignInNeedsTFA;
    return (
        (response.details.bTwoFactorAppAuthEnabled || response.details.bTwoFactorSMSAuthEnabled) &&
        !response.details.accessToken
    );
};
const isResponseEmailUnverified = (r: SignInResponse): r is SignInNeedsEmailVerified => {
    return !(r as SignInNeedsEmailVerified).details.bEmailVerified;
};
const isResponseNeedsToAddTFAToAccount = (
    response: SignInResponse
): response is SignInNeedsToAddTFAToAccount => {
    const r = response as SignInNeedsToAddTFAToAccount;
    return (
        r.details.bEmailVerified &&
        !r.details.bTwoFactorAppAuthEnabled &&
        !r.details.bTwoFactorSMSAuthEnabled
    );
};

/* STATE */
type AuthStatus =
    | 'signed_out'
    | 'signed_in'
    | 'need_tfa'
    | 'need_email_verified'
    | 'needs_to_add_account_tfa'
    | 'pending'
    | 'tfa_pending';
type TFA = 'sms' | 'app' | 'both';

type State = {
    appVisible: boolean;
    authStatus: AuthStatus;
    TFAType: TFA | null;
    accessTokenNeedsRefresh: boolean;
    message: Errors;
    accessTokenRefreshing: boolean;
};

const initialState: State = {
    authStatus: 'signed_out',
    appVisible: false,
    TFAType: null,
    accessTokenNeedsRefresh: false,
    message: [],
    accessTokenRefreshing: false,
};

const getTFAType = (payload: SignInNeedsTFA): TFA | null => {
    if (payload.details.bTwoFactorAppAuthEnabled && payload.details.bTwoFactorSMSAuthEnabled) {
        return 'both';
    }
    if (payload.details.bTwoFactorAppAuthEnabled) {
        return 'app';
    }
    if (payload.details.bTwoFactorSMSAuthEnabled) {
        return 'sms';
    }
    return null;
};

/* ACTIONS */

const SIGN_OUT = 'app/auth/SIGN_OUT';
const SIGN_OUT_WITH_CALL_EPIC_ACTION = 'app/auth/SIGN_OUT_EPIC_ACTION';
const SIGN_OUT_WITHOUT_CALL_EPIC_ACTION = 'app/auth/SIGN_OUT_WITHOUT_CALL_EPIC_ACTION';
const SIGN_IN = 'app/auth/SIGN_IN';
const TFA_SIGN_IN = 'app/auth/TFA_SIGN_IN';
export const SIGN_IN_SUCCESS = 'app/auth/SIGN_IN_OKAY';
const SIGN_IN_NEEDS_TFA = 'app/auth/SIGN_IN_NEED_TFA';
const SIGN_IN_NEEDS_EMAIL_VERIFIED = 'app/auth/SIGN_IN_NEED_EMAIL_VERIFIED';
const SIGN_IN_NEEDS_TO_ADD_TFA_TO_ACCOUNT = 'SIGN_IN_NEEDS_TO_ADD_TFA_TO_ACCOUNT';
const SIGN_IN_ERROR = 'app/auth/SIGN_IN_ERROR';
const TFA_SIGN_IN_ERROR = 'app/auth/TFA_SIGN_IN_ERROR';
const SHOW_APP = 'app/auth/SHOW_APP';
const CHECK_SIGNED_IN = 'app/auth/CHECK_SIGNED_IN';
const REFRESH_ACCESS_TOKEN = 'app/auth/REFRESH_ACCESS_TOKEN';
const SET_ACCESS_TOKEN_REFRESHED = 'app/auth/SET_ACCESS_TOKEN_REFRESHED';
const GET_USER_TFA_SETTING = 'app/auth/GET_USER_TFA_SETTINGS';
const SET_USER_TFA_SETTING = 'app/auth/SET_USER_TFA_SETTINGS';
const SET_AUTH_STATUS = 'app/auth/SET_AUTH_STATE';
const SET_ACCESS_TOKEN_REFRESHING = 'app/auth/SET_ACCESS_TOKEN_REFRESHING';

type Action =
    | { type: typeof SIGN_IN; payload: SignInAttempt }
    | { type: typeof TFA_SIGN_IN; payload: TFASignInAttempt }
    | { type: typeof SIGN_OUT; message: []; signOutMarker: boolean }
    | { type: typeof SIGN_OUT_WITHOUT_CALL_EPIC_ACTION }
    | { type: typeof SIGN_OUT_WITH_CALL_EPIC_ACTION }
    | { type: typeof CHECK_SIGNED_IN }
    | { type: typeof SHOW_APP; payload: boolean }
    | { type: typeof SIGN_IN_SUCCESS; payload: SignInSuccess }
    | { type: typeof SIGN_IN_NEEDS_TFA; payload: SignInNeedsTFA }
    | { type: typeof SIGN_IN_NEEDS_EMAIL_VERIFIED; payload: SignInNeedsEmailVerified }
    | { type: typeof SIGN_IN_NEEDS_TO_ADD_TFA_TO_ACCOUNT }
    | { type: typeof SIGN_IN_ERROR; payload: SignInError }
    | { type: typeof TFA_SIGN_IN_ERROR; payload: SignInError }
    | {
          type: typeof REFRESH_ACCESS_TOKEN;
          meta: { onComplete: { type: string; payload?: any; meta?: any }[] };
      }
    | { type: typeof SET_ACCESS_TOKEN_REFRESHED }
    | { type: typeof GET_USER_TFA_SETTING }
    | { type: typeof SET_USER_TFA_SETTING; payload: TFATypesResponse }
    | { type: typeof SET_AUTH_STATUS; payload: AuthStatus }
    | { type: typeof SET_ACCESS_TOKEN_REFRESHING; payload: boolean };

/* REDUCER */

export default function reducer(state = initialState, action: Action): State {
    return produce(state, (draft) => {
        switch (action.type) {
            case SHOW_APP:
                draft.appVisible = true;
                // Is the user already signed in?
                if (action.payload === true) {
                    draft.authStatus = 'signed_in';
                }
                break;
            case SIGN_OUT:
                draft.authStatus = 'signed_out';
                draft.message = action.message;
                break;
            case SIGN_IN:
                draft.authStatus = 'pending';
                draft.message = [];
                break;
            case TFA_SIGN_IN:
                draft.authStatus = 'tfa_pending';
                draft.message = [];
                break;
            case SIGN_IN_SUCCESS:
                draft.authStatus = 'signed_in';
                break;
            case SIGN_IN_NEEDS_TFA:
                draft.authStatus = 'need_tfa';
                draft.TFAType = getTFAType(action.payload);
                break;
            case SIGN_IN_NEEDS_EMAIL_VERIFIED:
                draft.authStatus = 'need_email_verified';
                break;
            case SIGN_IN_NEEDS_TO_ADD_TFA_TO_ACCOUNT:
                draft.authStatus = 'needs_to_add_account_tfa';
                break;
            case SIGN_IN_ERROR:
                draft.authStatus = 'signed_out';
                draft.message = action.payload.error_description;
                break;
            case TFA_SIGN_IN_ERROR:
                draft.authStatus = 'need_tfa';
                draft.message = action.payload.error_description;
                break;
            case REFRESH_ACCESS_TOKEN:
                draft.accessTokenNeedsRefresh = true;
                break;
            case SET_ACCESS_TOKEN_REFRESHED:
                draft.accessTokenNeedsRefresh = false;
                break;
            case SET_USER_TFA_SETTING:
                const { bTwoFactorAppAuthEnabled, bTwoFactorSMSAuthEnabled } = action.payload;
                if (bTwoFactorAppAuthEnabled && bTwoFactorSMSAuthEnabled) {
                    draft.TFAType = 'both';
                } else if (bTwoFactorAppAuthEnabled) {
                    draft.TFAType = 'app';
                } else if (bTwoFactorSMSAuthEnabled) {
                    draft.TFAType = 'sms';
                }
                break;
            case SET_AUTH_STATUS:
                draft.authStatus = action.payload;
                break;
            case SET_ACCESS_TOKEN_REFRESHING:
                draft.accessTokenRefreshing = action.payload;
                break;
        }
    });
}

/* EPICS */

function makeRequest<T>(config: AxiosRequestConfig) {
    return from(api.request<T>(config));
}

export function saveResponse(r: SignInResponse) {
    if (isSignInResponseSuccess(r)) {
        const { accessToken, refreshToken, refreshTokenExpiresIn, scopes } = r.details;
        saveTokenResource({
            accessToken,
            refreshToken,
            refreshTokenExpiresIn,
            scopes: scopes ?? [],
        });
    }
}

export const checkResponse = (r: SignInResponse) => {
    if (isResponseEmailUnverified(r)) {
        return signInNeedsEmailVerified(r);
    }
    if (isResponseNeedsToAddTFAToAccount(r)) {
        return signInNeedsToAddTFAToAccount(r);
    }
    if (isResponseNeedsTFA(r)) {
        return signInNeedsTFA(r);
    }
    if (isSignInResponseSuccess(r)) {
        return signInSuccess(r);
    }
    return signInError(r);
};

// const parseApiErrorMessageCode = (code: string): string => {
//   switch (code) {
//     case "Invalid_User":
//       return "Invalid";
//     default:
//       return "Oops, something went wrong. Please try again later.";
//   }
// };

/** Attempt to sign in and handle response, cancel if we sign out while a request pending. */
const signInEpic = (action$: ActionsObservable<any>): Observable<Action> =>
    action$.pipe(
        ofType(SIGN_IN),
        switchMap((attempt) =>
            makeRequest<SignInResponse>({
                url: endpoints.auth.login,
                data: attempt.payload,
                method: 'post',
            }).pipe(
                tap((res) => saveResponse(res.data)),
                map((res) => checkResponse(res.data)),
                catchError((err: AxiosError) => {
                    const errorMessage = err?.response?.data.errors;

                    return of(
                        signInError({
                            error: null,
                            error_description: errorMessage,
                            error_uri: null,
                        })
                    );
                })
                //takeUntil(action$.pipe(ofType(SIGN_OUT, SIGN_IN_ERROR)))
            )
        )
    );
/** Attempt to tfa sign in and handle response, cancel if we sign out while a request pending. */
const tfaSignInEpic = (action$: ActionsObservable<any>): Observable<Action> =>
    action$.pipe(
        ofType(TFA_SIGN_IN),
        switchMap((attempt) =>
            makeRequest<SignInResponse>({
                url: endpoints.auth.tfaLogin,
                data: attempt.payload,
                method: 'post',
            }).pipe(
                tap((res) => saveResponse(res.data)),
                map((res) => checkResponse(res.data)),
                catchError((err: AxiosError) => {
                    const errorMessage = err?.response?.data.errors;

                    return of(
                        tfaSignInError({
                            error: null,
                            error_description: errorMessage,
                            error_uri: null,
                        })
                    );
                })
                //takeUntil(action$.pipe(ofType(SIGN_OUT, SIGN_IN_ERROR)))
            )
        )
    );

/** Clear the session storage on sign out, so we don't appear logged in on refresh. */
const signOutWithBackendCallEpic = (action$: ActionsObservable<any>) =>
    action$.pipe(
        ofType(SIGN_OUT_WITH_CALL_EPIC_ACTION),
        tap(() => {
            const tokenResource = loadTokenResource();
            if (tokenResource) {
                makeRequest({
                    url: endpoints.profilemodule.signout,
                    method: 'POST',
                    // Manually include token in sign-out request as deleteing TokenResource
                    // from session storage causes request to fail.
                    headers: { Authorization: `Bearer ${tokenResource.accessToken}` },
                });
                deleteTokenResource();
            }
        }),
        mergeMap(() => [
            closeModal(),
            {
                type: SIGN_OUT,
                message: [],
                signOutMarker: true,
            },
            deleteCustomerComponentResources(),
            deleteCurrentHoldings(),
            deleteVerificationStatus(),
        ])
    );

const signOutWithoutBackendCallEpic = (action$: ActionsObservable<any>) =>
    action$.pipe(
        ofType(SIGN_OUT_WITHOUT_CALL_EPIC_ACTION),
        tap(() => deleteTokenResource()),
        mergeMap(() => [
            closeModal(),
            {
                type: SIGN_OUT,
                message: [],
                signOutMarker: true,
            },
            deleteCustomerComponentResources(),
            deleteVerificationStatus(),
            deleteCurrentHoldings(),
        ])
    );

const checkSignedInEpic = (action$: ActionsObservable<any>) =>
    action$.pipe(
        ofType(CHECK_SIGNED_IN),
        map(loadTokenResource),
        map((r) => r !== null),
        map(showApp)
    );

const saveFetchedTokenResource = (r: any) => {
    const { accessToken, refreshToken, scopes, refreshTokenExpiresIn } = r;
    saveTokenResource({
        accessToken,
        refreshToken,
        refreshTokenExpiresIn,
        scopes: scopes ?? [],
    });
};

const refreshAccessTokenEpic = (action$: ActionsObservable<Action>) =>
    action$.pipe(
        ofType(REFRESH_ACCESS_TOKEN),
        switchMap((action) => {
            const resource = loadTokenResource() as LoadedTokenResource;
            return makeRequest<ApiResponse<SignInResponse>>({
                url: endpoints.auth.refreshAccessToken,
                method: 'POST',
                data: {
                    accessToken: resource.accessToken,
                    refreshToken: resource.refreshToken,
                },
            }).pipe(
                filter((response) => response.data.status === '1'),
                map((response) => response.data.details),
                mergeMap((response) => {
                    saveFetchedTokenResource(response);
                    const outputActions = [
                        { type: SET_ACCESS_TOKEN_REFRESHED },
                        getCustomerComponentResources(),
                    ];
                    return outputActions.concat(
                        (action as ReturnType<typeof refreshAccessToken>).meta.onComplete
                    );
                })
            );
        })
    );

const getUserTFASettingsEpic = (action$: ActionsObservable<Action>) =>
    action$.pipe(
        ofType(GET_USER_TFA_SETTING),
        switchMap(() => {
            return makeRequest<ApiResponse<TFATypesResponse>>({
                url: endpoints.profilemodule.usersecurityinfo,
            }).pipe(
                filter((response) => {
                    return response.data.status === '1';
                }),
                map((response) => {
                    return setUserTFASetting(response.data.details);
                })
            );
        })
    );

export const authEpic = combineEpics(
    signInEpic,
    tfaSignInEpic,
    checkSignedInEpic,
    refreshAccessTokenEpic,
    signOutWithBackendCallEpic,
    signOutWithoutBackendCallEpic,
    getUserTFASettingsEpic
);

/* ACTION CREATORS */

export const signIn = (payload: SignInAttempt): Action => {
    return {
        type: SIGN_IN,
        payload,
    };
};

export const tfaSignIn = (payload: TFASignInAttempt): Action => ({
    type: TFA_SIGN_IN,
    payload,
});

// We have two separate sign out actions. signOut is just used to kick the user out
// if trying to access parts of the app without the proper tokens, signOutWithBackendCall
// is used when the user manually signs out.
export const signOut = (withBackendCall: boolean = true): Action => {
    if (withBackendCall) {
        return { type: SIGN_OUT_WITH_CALL_EPIC_ACTION };
    } else {
        return { type: SIGN_OUT_WITHOUT_CALL_EPIC_ACTION };
    }
};

export const signOutWithBackendCall = () => {
    return {
        type: SIGN_OUT_WITH_CALL_EPIC_ACTION,
    };
};

export const checkSignedIn = (): Action => ({
    type: CHECK_SIGNED_IN,
});

const signInSuccess = (payload: SignInSuccess): Action => ({
    type: SIGN_IN_SUCCESS,
    payload,
});

export const signInNeedsTFA = (payload: SignInNeedsTFA): Action => ({
    type: SIGN_IN_NEEDS_TFA,
    payload,
});
export const signInNeedsEmailVerified = (payload: SignInNeedsEmailVerified): Action => ({
    type: SIGN_IN_NEEDS_EMAIL_VERIFIED,
    payload,
});
export const signInNeedsToAddTFAToAccount = (payload: SignInNeedsToAddTFAToAccount): Action => ({
    type: SIGN_IN_NEEDS_TO_ADD_TFA_TO_ACCOUNT,
});
export const refreshAccessToken = (
    onComplete: { type: string; payload?: any; meta?: any }[] = []
) => ({
    type: REFRESH_ACCESS_TOKEN,
    meta: { onComplete },
});
export const getUserTFASettings = () => ({ type: GET_USER_TFA_SETTING });
export const setUserTFASetting = (payload: TFATypesResponse) => ({
    type: SET_USER_TFA_SETTING,
    payload,
});

export const setAuthStatus = (payload: AuthStatus) => ({ type: SET_AUTH_STATUS, payload });

const signInError = (payload: SignInError): Action => ({
    type: SIGN_IN_ERROR,
    payload,
});
const tfaSignInError = (payload: SignInError): Action => ({
    type: TFA_SIGN_IN_ERROR,
    payload,
});

const showApp = (payload: boolean): Action => ({
    type: SHOW_APP,
    payload,
});

export const setAccessTokenRefreshing = (payload: boolean): Action => ({
    type: SET_ACCESS_TOKEN_REFRESHING,
    payload,
});

/* SELECTORS */

export const selectAuthStatus = (app: Store) => app.auth.authStatus;
export const selectSignedIn = createSelector(selectAuthStatus, (status) => status === 'signed_in');
export const selectAuthMessage = (app: Store) => app.auth.message;
export const selectTFAType = (app: Store) => app.auth.TFAType;
export const selectAppVisible = (app: Store) => app.auth.appVisible;
export const selectAccessTokenNeedsRefresh = (app: Store) => app.auth.accessTokenNeedsRefresh;
export const selectAccessTokenRefreshing = (app: Store) => app.auth.accessTokenRefreshing;

// Selects the TFAType from the store, which is 'sms' | 'app' | 'both'.
// These values aren't used anywhere outside this reducer so unlikely you'll need.
export const selectActiveTFAType = (app: Store) => app.auth.TFAType;
