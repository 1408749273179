import { Spinner } from 'components/spinner/Spinner';
import { useCMS } from 'helpers/useCMS';

interface CMSContentProps {
    page: string;
}

export const CMSContent: React.FC<CMSContentProps> = ({ page }) => {
    const CMSResponse = useCMS(`/${page}`);

    return CMSResponse && CMSResponse.content ? (
        <div dangerouslySetInnerHTML={{ __html: CMSResponse.content }}></div>
    ) : (
        <Spinner className="CMSContentSpinner" />
    );
};
