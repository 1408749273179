import Button from 'components/button/Button';
import React, { ReactNode, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modal';
import Icon_Close from '../../assets/ibanera/Icon_Close.png';
import classNames from 'classnames';

export const MODAL_PORTAL_ID = 'modal-portal';

type ModalProps = {
    title?: string;
    className?: string;
    backgroundClassName?: string;
    removeCloseButton?: boolean;
    customCloseButton?: ReactNode;
    primaryButton?: string;
    secondaryButton?: string;
    handlePrimary?: () => void;
    handleSecondary?: () => void;
    titleIcon?: React.ReactChild;
    filledHeader?: boolean;
};

const Modal: React.FC<ModalProps> = ({
    children,
    title,
    className,
    backgroundClassName,
    primaryButton,
    secondaryButton,
    handlePrimary,
    handleSecondary,
    removeCloseButton = false,
    customCloseButton,
    titleIcon,
    filledHeader,
}) => {
    const dispatch = useDispatch();
    const modalBackgroundRef = useRef<HTMLDivElement | null>(null);

    const handleCloseModal = (event: React.MouseEvent<HTMLDivElement>) => {
        // Should ideally avoid accessing nativeEvent for performance purposes.
        if (event.nativeEvent.target === modalBackgroundRef.current) {
            dispatch(closeModal());
        }
    };

    return (
        <div
            className={`ModalBackground ${backgroundClassName ? backgroundClassName : ''}`}
            // Want to avoid using useOnClickOutside hook as toast messages can pop up whilst
            // modals are open and clicking the close button on the toast message shouldn't
            // cause the modal to close.
            onMouseDown={handleCloseModal}
            ref={modalBackgroundRef}
        >
            <div className={`Modal ${className ? className : ''}`}>
                <div id={MODAL_PORTAL_ID} />
                <div className={classNames('ModalHeader', { FilledHeader: filledHeader })}>
                    {titleIcon && <div className="ModalTitleIconContainer">{titleIcon}</div>}
                    {title && <p className="ModalHeading">{title}</p>}
                    {customCloseButton ? customCloseButton : !removeCloseButton && (
                        <div className="ExitImg">
                            <img
                                src={Icon_Close}
                                alt="CloseIcon"
                                onClick={() => {
                                    dispatch(closeModal());
                                }}
                            />
                        </div>
                    )}
                </div>
                <div className="ModalContent">{children}</div>
                {primaryButton && secondaryButton && handlePrimary && handleSecondary && (
                    <div className="ModalNavigation">
                        <Button priority="secondary" onClick={handleSecondary} color="grey">
                            {secondaryButton}
                        </Button>
                        <Button priority="primary" onClick={handlePrimary}>
                            {primaryButton}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export { Modal };
