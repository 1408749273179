import React, { useEffect, useRef, useState } from 'react';
import IconClose from '../../assets/ibanera/Icon_Close.png';
import SuccessIcon from '../../assets/notifications/success.svg';
import ErrorIcon from '../../assets/notifications/error.svg';
import InfoIcon from '../../assets/notifications/info.svg';
import WarningIcon from '../../assets/notifications/warning.svg';
import { NOTIFICATION_ANIMATION_TIME } from './Notifications';
import { NotificationType } from './models';

type NotificationTileProps = {
    type: NotificationType;
    heading: string;
    message: string;
    handleClose: () => void;
};

const NotificationClassMap: { [key in NotificationType]: string } = {
    [NotificationType.SUCCESS]: 'Good',
    [NotificationType.INFO]: 'Neutral',
    [NotificationType.WARNING]: 'Warning',
    [NotificationType.ERROR]: 'Bad',
};

export const NotificationTile: React.FC<NotificationTileProps> = ({
    heading,
    message,
    type,
    handleClose,
}) => {
    const [isClosing, setIsClosing] = useState(false);
    const [notificationHeight, setNotificationHeight] = useState<number | null>(null);
    const [isOpening, setIsOpening] = useState(true);

    const handleCloseWithAnimation = () => {
        setIsClosing(true);
        setTimeout(() => {
            handleClose();
        }, NOTIFICATION_ANIMATION_TIME);
    };

    const notificationRef = useRef<null | HTMLDivElement>(null);

    const notificationIcon = (type: NotificationType) => {
        switch (type) {
            case NotificationType.SUCCESS:
                return SuccessIcon;
            case NotificationType.ERROR:
                return ErrorIcon;
            case NotificationType.INFO:
                return InfoIcon;
            case NotificationType.WARNING:
                return WarningIcon;
            default:
                return undefined;
        }
    };

    useEffect(() => {
        if (notificationRef.current && !notificationHeight) {
            const height = notificationRef.current.getBoundingClientRect().height;
            setNotificationHeight(height);
        }
    }, [notificationHeight]);

    useEffect(() => {
        if (notificationRef.current && isOpening) {
            setTimeout(() => {
                // Need to set notification height again as we have a max-height
                // set to handle the notification animating in.
                const height = notificationRef.current?.getBoundingClientRect().height;
                if (height) {
                    setNotificationHeight(height);
                }
                setIsOpening(false);
            }, NOTIFICATION_ANIMATION_TIME);
        }
    }, [isOpening]);

    return (
        <div
            className={`NotificationTile ${NotificationClassMap[type]} ${
                isClosing ? 'Closing' : ''
            } ${isOpening ? 'Opening' : ''}`}
            ref={notificationRef}
            // Typescript definition for css doesn't include custom attributes,
            // hence asserting as any.
            style={{ ['--height' as any]: `${notificationHeight}px` }}
        >
            <button
                aria-label="Close notification"
                onClick={handleCloseWithAnimation}
                className="CloseButton"
            >
                <img alt="Close icon" src={IconClose} />
            </button>

            <img
                alt="Notification icon"
                src={notificationIcon(type)}
                className="NotificationIcon"
            />
            <div>
                <h1 className="Title">{heading}</h1>
                <div
                    className={`Body ${NotificationClassMap[type]}`}
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            </div>
        </div>
    );
};
