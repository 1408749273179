import { BaseApi } from 'api';
import { endpoints } from 'endpoints.config';

type DeleteRequest = {
    tfaCode: string;
    tfaType: string;
    guaranteesId: number;
};

export class DeleteModalApi extends BaseApi {
    public static requestDeleteGuarantee = (payload: DeleteRequest) =>
        DeleteModalApi.makeRequest({
            url: endpoints.guaranteemodule.deleteGuarantee,
            data: payload,
            method: 'POST',
        });
}
