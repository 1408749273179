import React from 'react';
import { ColumnDetail } from '@avamae/table';
import { Cell, CellOptions } from './Cell';
import { toCamelCase } from 'helpers/formatFormFieldNames';
import { RowConfig, RowProps, TableRow } from './FlexTableRow';
import { Checkbox } from './FlexTable';
import { composeP } from 'ramda';

type LeftTableProps<T = any> = {
    idColumn: string;
    checkbox?: Checkbox;
    columns: ColumnDetail<any>[];
    listData: T[] | undefined;
    rowHovered: number | null;
    handleMouseEnterRow(e: any, i: number): void;
    onRowClick(id: string | number): void;
    hiddenColumns?: string[];
    formatPrimitives(value: unknown): any;
    rowFormatter?(r: T): { [K in keyof T]: React.ReactNode };
    noRowHover?: boolean;
    columnCount: number;
    rowCellOptions?: { [K in keyof T]: CellOptions };
    sortBy: { [k: string]: 'ASC' | 'DESC' | undefined };
    toggleColumnSort?: (key: keyof T, queryParams?: { [k: string]: any }) => void;
    queryParams?: { [k: string]: any };
    rowConfig?: RowConfig;
    isTallRow?: (r: T) => boolean;
};

const LeftTable: React.FC<LeftTableProps> = ({
    idColumn,
    checkbox,
    columns,
    listData,
    rowHovered,
    handleMouseEnterRow,
    onRowClick,
    hiddenColumns: h,
    formatPrimitives,
    rowFormatter,
    noRowHover,
    columnCount = 0,
    rowCellOptions = {},
    sortBy,
    toggleColumnSort,
    queryParams,
    rowConfig,
    isTallRow,
}) => {
    const hiddenColumns = React.useMemo(() => h ?? [], [h]);

    const onClickCell = React.useCallback(
        (bSortable: boolean, columnKey: string | number | symbol) => () =>
            bSortable && toggleColumnSort && toggleColumnSort(String(columnKey), queryParams),
        [toggleColumnSort, queryParams]
    );

    const sliceBy = checkbox ? (columnCount <= 1 ? 1 : columnCount) : columnCount;

    //we filter out hiddencolumns so that we can get the first n non-hidden columns
    const filteredColumns = React.useMemo(
        () =>
            columns.filter(
                (col) => !hiddenColumns.includes(col.columnKey.toString().toLowerCase())
            ),
        [columns, hiddenColumns]
    );
    const selectedColumns = filteredColumns.slice(0, sliceBy);
    // we add all the non-selected columns to hiddencolumns, so that we can pass the full row data on (to allow conditional formatting) while still only showing the selected columns
    const newHiddenColumns = React.useMemo(
        () =>
            hiddenColumns.concat(
                ...filteredColumns
                    .slice(sliceBy)
                    .map((col) =>
                        typeof col.columnKey === 'string'
                            ? col.columnKey.toLowerCase()
                            : col.columnKey.toString()
                    )
            ),
        [filteredColumns, hiddenColumns, sliceBy]
    );

    const allSelected =
        checkbox &&
        listData &&
        checkbox?.selectedIds.length > 0 &&
        checkbox?.selectedIds.length === listData?.length;

    const toggleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        if (checkbox) {
            if (allSelected) {
                checkbox.setSelectedIds([]);
            } else {
                if (listData) {
                    checkbox.setSelectedIds(listData.map((data) => data[idColumn].toString()));
                }
            }
        }
    };
    const inputId = `toggleSelectAll-${idColumn.toLowerCase()}`;

    return (
        <div id={'FlexTable_LeftTable'} className="LeftSideTable">
            {listData && (
                <div className="Head">
                    {selectedColumns && checkbox && (
                        <Cell
                            options={{
                                cellType: 'checkboxCell',
                            }}
                        >
                            <div>
                                <input
                                    id={inputId}
                                    type="checkbox"
                                    onChange={toggleSelectAll}
                                    checked={allSelected}
                                />
                                <label className="CustomInput" htmlFor={inputId} />
                            </div>
                        </Cell>
                    )}
                    {selectedColumns.map((c, i) => {
                        const sortByValue = sortBy[String(c.columnKey)] ?? 'NONE';
                        const isSorting = sortByValue === 'ASC' || sortByValue === 'DESC';
                        const { bSortable } = c;
                        return (
                            <Cell
                                key={i}
                                sortable={bSortable}
                                onClick={onClickCell(bSortable, c.columnKey)}
                                options={
                                    rowCellOptions[toCamelCase(c.columnKey as string) as string]
                                }
                            >
                                <span
                                    className={bSortable && isSorting ? 'Sorting' : ''}
                                    title={c.labelValue}
                                >
                                    {c.labelValue}
                                </span>
                                {bSortable && (
                                    <i
                                        className={
                                            sortByValue === 'ASC'
                                                ? 'SortIcon ASC'
                                                : sortByValue === 'DESC'
                                                ? 'SortIcon DESC'
                                                : 'SortIcon'
                                        }
                                    />
                                )}
                            </Cell>
                        );
                    })}
                </div>
            )}
            {listData && listData.length > 0 && (
                <div className="TableBody">
                    {listData.map((d, i) => {
                        //TableRow
                        const rowProps: RowProps<any> = {
                            id: d.id,
                            r: d,
                            index: i,
                            idColumn,
                            columns,
                            formatPrimitives,
                            hiddenColumns: newHiddenColumns,
                            rowHovered,
                            handleMouseEnterRow,
                            onRowClick,
                            formatRow: rowFormatter,
                            noRowHover,
                            checkbox,
                            isSelected: checkbox?.selectedIds
                                ?.map((i) => i.toString())
                                .includes(d.id?.toString()),
                            rowCellOptions,
                            rowConfig,
                            isTallRow,
                        };
                        return <TableRow key={i} {...rowProps} />;
                    })}
                </div>
            )}
        </div>
    );
};

export { LeftTable };
