import Button from 'components/button/Button';
import FormTextField from 'components/form/FormTextField';
import { TFAField } from 'components/form/TFAField';
import { PasswordHelper } from 'components/passwordHelper/PasswordHelper';
import { PasswordStrengthMeter } from 'components/passwordStrengthMeter/PasswordStrengthMeter';
import { Form, Formik, FormikHelpers } from 'formik';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { useTFAField } from 'helpers/useTFAField';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modal';
import * as Yup from 'yup';
import { IUpdatePassword } from './models';
import { ProfileApi } from './ProfileApi';
import { SecurityContext } from './Security';
import passwordIcon from 'assets/bitline-ui-redesign/password blue icon.svg';
import { useTheme } from '@emotion/react';
import WarningIcon from 'assets/bitline-ui-redesign/error icon.svg';
import EyeIcon from 'assets/bitline-ui-redesign/eye icon.svg';

type UpdatePasswordModalProps = {
    isSMSAuth: boolean | null;
    isAppAuth: boolean | null;
};

const validationSchema = Yup.object({
    showTfa: Yup.boolean(),
    oldPassword: Yup.string().required('Please enter your current password'),
    newPassword: Yup.string()
        .required('Please enter your new password')
        .min(8, 'Password must be at least 8 characters long')
        .matches(/[0-9]/, 'Password must contain a number')
        .matches(/[a-zA-Z]/, 'Password must contain a letter'),
    confirmNewPassword: Yup.string()
        .required('Please confirm your new password')
        .test('passwords-match', 'Passwords must match', (value: any, testContext?: any) => {
            return testContext.parent.newPassword === value;
        }),
    tfaCode: Yup.string().when('showTfa', {
        is: true,
        then: Yup.string().required('Please enter your two-factor authentication code'),
    }),
    tfaType: Yup.string(),
});

const UpdatePasswordModal: React.FC<UpdatePasswordModalProps> = ({}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [errors, setErrors] = useState<string[] | null>(null);
    const [showTfa, setShowTfa] = useState(false);
    const [tfaType, toggleTFAType] = useTFAField(showTfa);
    const { reloadSecurityData } = useContext(SecurityContext);
    const [showPasswordHelper, setShowPasswordHelper] = React.useState(false);
    const [passwordVisibility, setPasswordVisibility] = React.useState(false);

    const handlePasswordVisibility = () => {
        setPasswordVisibility(!passwordVisibility);
    };
    const initialValues: IUpdatePassword = {
        showTfa: false,
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        tfaCode: '',
        tfaType: tfaType,
    };

    const handleClose = () => {
        dispatch(closeModal());
    };

    const handleSubmit = async (
        values: IUpdatePassword,
        helpers: FormikHelpers<IUpdatePassword>
    ) => {
        if (!values.showTfa) {
            helpers.setFieldValue('showTfa', true);
            setShowTfa(true);
            helpers.setSubmitting(false);
            helpers.setFieldTouched('tfaCode', false);
            return;
        }
        const response = await ProfileApi.UpdatePassword({ ...values, tfaType });
        if (ProfileApi.isSuccessData(response)) {
            reloadSecurityData();
            dispatch(closeModal());
            Toast.openToastMessage('Password changed successfully', ToastMessageReason.VALID);
        } else if (ProfileApi.isErrorData(response)) {
            setErrors(response.errors);
        } else {
            dispatch(closeModal());
            Toast.openGenericErrorToast();
        }
        helpers.setSubmitting(false);
    };

    return (
        <div className="UpdatePasswordModal">
            <h1 className="Title">Edit Password</h1>

            <img src={passwordIcon} alt="blue password icon" className="PasswordIcon" />

            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {(formikProps) => (
                    <Form className="Form">
                        <>
                            <FormTextField
                                field="oldPassword"
                                label="Old password"
                                type="password"
                                autoFocus
                                required
                            />
                            <div className="PasswordField">
                                <FormTextField
                                    field="newPassword"
                                    label="New password"
                                    type={passwordVisibility ? 'text' : 'password'}
                                    required
                                    showIcon={true}
                                    icon={EyeIcon}
                                    onClickIcon={handlePasswordVisibility}
                                >
                                    <PasswordStrengthMeter
                                        fieldName="newPassword"
                                        onInfoClick={() => setShowPasswordHelper((s) => !s)}
                                    />
                                    {showPasswordHelper && (
                                        <PasswordHelper fieldName="newPassword" />
                                    )}
                                </FormTextField>
                            </div>
                            <FormTextField
                                field="confirmNewPassword"
                                label="Confirm new password"
                                type="password"
                                required
                            />
                            {formikProps.values.showTfa && (
                                <TFAField
                                    field={'tfaCode'}
                                    label={'Your verification code'}
                                    required
                                    tfaType={tfaType}
                                    toggleTFAType={toggleTFAType}
                                    value={formikProps.values.tfaCode}
                                    autoFocus
                                />
                            )}
                            {errors && (
                                <>
                                    {errors.map((error) => (
                                        <div className="ErrorLabel PasswordErrorMessages">
                                            <img
                                                src={WarningIcon}
                                                alt="Warning icon"
                                                className="WarningIcon"
                                            />
                                            <span className="ErrorText">{error}</span>
                                        </div>
                                    ))}
                                </>
                            )}

                            <Button
                                priority="primary"
                                type="submit"
                                style={{
                                    backgroundColor: theme.colors.second,
                                    borderColor: theme.colors.second,
                                }}
                                disabled={formikProps.isSubmitting || !formikProps.isValid}
                            >
                                Save Changes
                            </Button>
                            <Button
                                priority="secondary"
                                type="button"
                                className="Secondary"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export { UpdatePasswordModal };
