import React from 'react';
import Button from 'components/button/Button';
import { useDispatch } from 'react-redux';

import Img_KYC from 'assets/Img_KYC.png';
import { fetchVerificationStatus } from 'reducers/jumio';
import { refreshAccessToken } from 'reducers/auth';

export const JumioVerificationComplete = () => {
    const dispatch = useDispatch();
    return (
        <div className="VerificationComplete">
            <h3>Verification complete!</h3>
            <img className="SuccessImage" src={Img_KYC} alt="" />
            <Button
                variety="full"
                onClick={() => dispatch(refreshAccessToken([fetchVerificationStatus()]))}
            >
                Click here to continue
            </Button>
        </div>
    );
};
