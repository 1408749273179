import { NotificationsConfig } from 'components/notifications/NotificationsConfig';

export const Notifications = () => {
    return (
        <div className="NotificationSettings">
            <h2>Notification settings</h2>
            <NotificationsConfig />
        </div>
    );
};
