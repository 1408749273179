import React from 'react';
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { getCultureCodeFromLocalStorage } from '../../helpers/cache';
import { Link } from '@reach/router';
import { useTheme } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/core';
import { useDispatch, useSelector } from 'react-redux';
import { selectImagePath } from '../../reducers/ui';
import RememberMeInput from '../../components/form/RememberMeInput';
import FormTextField from '../../components/form/FormTextField';
import Button from '../../components/button/Button';
import { selectCultureCode } from '../../reducers/language';
import { selectAuthMessage, selectAuthStatus, signIn, SignInAttempt } from '../../reducers/auth';
import { AppPath } from '../../appConstants';
import { SignInTranslation } from './signInTypes';
import { Spinner } from 'components/spinner/Spinner';
import { ThemedLink } from 'components/themedLink/ThemedLink';
import { ERROR_CODES } from 'errors';
import WarningIcon from 'assets/bitline-ui-redesign/error icon.svg';

type Props = {
    translations: SignInTranslation | null;
};

const SignInForm: React.FC<Props> = ({ translations }) => {
    const authMessages = useSelector(selectAuthMessage);
    const authMessageToShow =
        (authMessages &&
            authMessages.length > 0 &&
            ERROR_CODES[authMessages[0].messageCode as keyof typeof ERROR_CODES]) ??
        authMessages[0].messageCode;
    const authStatus = useSelector(selectAuthStatus);

    const { values } = useFormikContext<SignInAttempt>();

    const theme = useTheme();
    const imagesPath = useSelector(selectImagePath);

    const cultureCode = useSelector(selectCultureCode);

    const SignInFormCSS = css`
        input[type='checkbox']:checked + .CustomInput:after {
            background-image: url('../..${imagesPath}/Icon_Tick.png');
        }
    `;

    return (
        <Form className="AuthFormContainer">
            <div className="AuthForm" css={SignInFormCSS}>
                <h1>{translations?.heading1 ?? ''}</h1>
                <p>{translations?.sub_heading1 ?? ''}</p>
                <div className="FieldsContainer">
                    <FormTextField
                        field={'username'}
                        label={translations?.email_address ?? ''}
                        required={true}
                        maxLength={200}
                        inputMode="email"
                        errorMessageWarningIcon={true}
                    />
                    <FormTextField
                        field={'password'}
                        label={translations?.password ?? ''}
                        type={'password'}
                        required={true}
                        maxLength={20}
                        errorMessageWarningIcon={true}
                    />
                    {authMessageToShow && (
                        <div className="ErrorLabel SignInErrorMessage">
                            <img src={WarningIcon} alt="Warning icon" className="WarningIcon" />
                            <span>{authMessageToShow}</span>
                        </div>
                    )}
                    <div className="RememberMe">
                        <RememberMeInput
                            type="checkbox"
                            name="rememberMe"
                            id="RememberMe"
                            checked={values.rememberMe}
                        />
                        <label className="CustomInput" htmlFor="RememberMe">
                            {translations?.remember_me ?? ''}
                        </label>
                        <ThemedLink to={`/${cultureCode}${AppPath.FORGOTTEN_PASSWORD}`}>
                            <span className="ForgottenPassword">
                                {translations?.forgotten_password ?? ''}
                            </span>
                        </ThemedLink>
                    </div>
                    <Button
                        variety="full"
                        type="submit"
                        disabled={authStatus === 'pending'}
                        color="second"
                    >
                        {authStatus === 'pending' ? (
                            <Spinner color={'#fff'} />
                        ) : (
                            translations?.sign_in_button ?? ''
                        )}
                    </Button>

                    <div className="Redirect">
                        <span>{translations?.no_account}&nbsp;</span>
                        <ThemedLink to={`/${cultureCode}${AppPath.REGISTER}`}>
                            {' '}
                            <span className="Register">{translations?.register}</span>
                        </ThemedLink>
                    </div>
                </div>
            </div>
        </Form>
    );
};

export default SignInForm;
