import React from 'react';
import { ThreeBounce } from 'better-react-spinkit';
import { DEFAULT_COLOR } from 'appConstants';
import classNames from 'classnames';

interface SpinnerProps {
    size?: number;
    color?: string;
    scaleStart?: number;
    scaleEnd?: number;
    positionAbsolute?: boolean;
    className?: string;
}

const Spinner: React.FC<SpinnerProps> = ({
    size = 15,
    color = DEFAULT_COLOR,
    className,
    positionAbsolute,
}) => {
    return (
        <div
            className={classNames('Spinner', className, {
                PositionAbsolute: positionAbsolute,
            })}
        >
            <ThreeBounce size={size} color={color} />
        </div>
    );
};

export { Spinner };
