import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import React from 'react';
import CalendarIcon from 'assets/bitline-ui-redesign/calendar icon.svg';

export const CustomDatePicker: React.FC<ReactDatePickerProps> = ({ className, ...props }) => {
    return (
        <div className="FormField CustomDatePickerContainer">
            <DatePicker
                {...props}
                className={`CustomDatePicker EditBox ${className}`}
                showPopperArrow={false}
                withPortal={true}
            />
            <img src={CalendarIcon} className="CalendarIcon" alt="Calendar icon" />
        </div>
    );
};
