import { useTheme } from '@emotion/react';
import { fiatCurrencyFormatter } from 'appConstants';
import CountdownIcon from 'assets/ibanera/Icon_Countdown.png';
import PendingIcon from 'assets/ibanera/Icon_Pending.png';
import Button from 'components/button/Button';
import { TFAField } from 'components/form/TFAField';
import { GuaranteeTableRow, isGuaranteeTableRow } from 'components/guaranteesTable/GuaranteesTable';
import {
    NotificationIdentifier,
    selectUIUpdate,
} from 'components/notifications/notificationUIUpdateReducer';
import { Form, Formik, FormikHelpers } from 'formik';
import { generateBase64Src } from 'helpers/generateBase64Src';
import { stringOnlyContainsNumbers } from 'helpers/stringOnlyContainsNumbers';
import { Toast } from 'helpers/toast';
import { useTFAField } from 'helpers/useTFAField';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveTFAType } from 'reducers/auth';
import { selectAvailableAssets } from 'reducers/availableAssets';
import { closeModal, selectModalState } from 'reducers/modal';
import { QRCode } from 'types/shared';
import * as Yup from 'yup';
import { ActivateGuaranteeModalApi } from './ActivateGuaranteeModalApi';

type ActivateGuaranteeFormikState = {
    tfaCode: string;
};

const validationSchema: Yup.SchemaOf<ActivateGuaranteeFormikState> = Yup.object({
    tfaCode: Yup.string()
        .required('Please enter your TFA code')
        .test('is valid tfa', 'Please enter your TFA code', (value: string | undefined) => {
            if (value && value.length === 6 && stringOnlyContainsNumbers(value)) {
                return true;
            }
            return false;
        }),
});

const initialValues: ActivateGuaranteeFormikState = { tfaCode: '' };

enum ActivateStep {
    ENTER_TFA = 1,
    QR_CODE,
}

const TIMEOUT_LENGTH = 60;

export const ActivateGuaranteeModal: React.FC = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { data } = useSelector(selectModalState);
    const [currentStep, setCurrentStep] = useState<ActivateStep>(ActivateStep.ENTER_TFA);
    const activeTFAType = useSelector(selectActiveTFAType);

    const [qrCode, setQRCode] = useState<QRCode | null>(null);
    const [timeRemaining, setTimeRemaining] = useState<number | null>(null);
    const [countdownInterval, setCountdownInterval] = useState<number | null>(null);
    const [errorMessages, setErrorMessages] = useState<string[] | null>(null);
    // TODO(HC): Add UI element to switch TFA type. Make initial value depend on
    // type of TFA user has activated.
    const [TFAType, toggleTFAType] = useTFAField();
    const update = useSelector(selectUIUpdate);

    useEffect(() => {
        if (
            update?.pushType === NotificationIdentifier.ACTIVATION_CODE_SCANNED &&
            update.data.activationCode === qrCode?.code
        ) {
            dispatch(closeModal());
        }
    }, [update, qrCode]);

    if (!isGuaranteeTableRow(data)) {
        dispatch(closeModal());
    }

    const activateGuaranteeModalData = data as GuaranteeTableRow;

    const handleSubmit = async (
        values: ActivateGuaranteeFormikState,
        helpers: FormikHelpers<ActivateGuaranteeFormikState>
    ) => {
        const result = await ActivateGuaranteeModalApi.generateQRCode(
            values.tfaCode,
            TFAType,
            activateGuaranteeModalData.guarantees__Id
        );
        if (ActivateGuaranteeModalApi.isSuccessData(result)) {
            setQRCode({
                code: result.data.activationCode,
                QRBase64: result.data.activationQRCode,
            });
            setCurrentStep(ActivateStep.QR_CODE);
            setTimeRemaining(result.data.activationCodeExpiryTimeSeconds);
        } else if (ActivateGuaranteeModalApi.isErrorData(result)) {
            setErrorMessages(result.errors);
        } else {
            dispatch(closeModal());
            Toast.openGenericErrorToast();
        }
        helpers.setSubmitting(false);
    };

    useEffect(() => {
        if (timeRemaining && !countdownInterval) {
            const interval = window.setInterval(() => {
                setTimeRemaining((oldVal) => (oldVal === null ? null : Math.max(oldVal - 1, 0)));
            }, 1000);
            setCountdownInterval(interval);
        }

        if (timeRemaining === 0 && countdownInterval) {
            window.clearInterval(countdownInterval);
            setCountdownInterval(null);
            setCurrentStep(ActivateStep.ENTER_TFA);
            setErrorMessages(null);
        }
    }, [timeRemaining, countdownInterval]);

    return (
        <div className="ActivateGuaranteeModal">
            <h1 style={{ color: theme.colors.first }} className="Price">
                {fiatCurrencyFormatter(
                    activateGuaranteeModalData.guarantees__GuaranteeValue,
                    activateGuaranteeModalData.fiatAssets__Code
                )}{' '}
                <span className="Ticker">{activateGuaranteeModalData.fiatAssets__Code}</span>
            </h1>
            {currentStep === ActivateStep.ENTER_TFA ? (
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {({ values, isSubmitting, isValid, submitForm }) => (
                        <Form>
                            <div className="TFASection">
                                <h3 className="Title">Two-Factor Authentication</h3>
                                <p className="SubTitle">{`Enter the code from ${
                                    TFAType === 'AuthenticatorApp'
                                        ? 'your authentication app'
                                        : 'the sms we sent you'
                                }`}</p>
                                <div>
                                    <TFAField
                                        field={'tfaCode'}
                                        label={'Your verification code'}
                                        required
                                        autoFocus
                                        holdFocus
                                        toggleTFAType={toggleTFAType}
                                        tfaType={TFAType}
                                        value={values.tfaCode}
                                        backgroundColor="white"
                                    />
                                    {errorMessages && (
                                        <>
                                            {errorMessages.map((error) => (
                                                <p className="ErrorText NoMargin">{error}</p>
                                            ))}
                                        </>
                                    )}
                                    <Button
                                        type="submit"
                                        className="SubmitButton"
                                        style={{
                                            backgroundColor: theme.colors.second,
                                            borderColor: theme.colors.second,
                                        }}
                                        disabled={!isValid || isSubmitting}
                                        onClick={submitForm}
                                    >
                                        Apply
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            ) : (
                qrCode && (
                    <>
                        <div className="CountdownTimer">
                            <img src={CountdownIcon} className="CountdownIcon" />
                            <span>{timeRemaining} Sec</span>
                        </div>
                        <div className="QRContainer">
                            <img src={generateBase64Src(qrCode.QRBase64)} className="QRCode" />
                            <p className="UnlockCode">
                                <span>{qrCode.code}</span>
                            </p>
                        </div>
                    </>
                )
            )}
            <div className="PendingContainer">
                <img src={PendingIcon} alt="pending" className="PendingIcon" />
                <h1 className="PendingTitle NoMargin">Pending</h1>
                <div className="FlexPlaceholder" />
            </div>
        </div>
    );
};
