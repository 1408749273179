import { RouteComponentProps, useNavigate } from '@reach/router';
import { AppPath, PageResourceKey } from 'appConstants';
import { usePageTranslations } from 'helpers/usePageTranslations';
import { SignInTranslation } from 'pages/signIn/signInTypes';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAuthStatus, setAuthStatus } from 'reducers/auth';
import { selectCultureCode } from 'reducers/language';
import Page from '../../components/page/Page';
import { AccountDetails, AuthType, RegisterStep } from './models';
import { postEmailVerificationCode } from './RegisterApi';
import { RegistrationSuccessMessage } from './RegistrationSuccessMessage';
import { SelectAuthType } from './SelectAuthType';
import { TFASetup } from './TFASetup';
import { TFAVerification } from './TFAVerification';
import { RegistrationForm } from './RegistrationForm';

export type RegisterInitialState = {
    stage: 'emailVerification' | 'tfa';
    email: string;
    password: string;
};

// TODO(Hari): Move individual form steps into single shared Formik state one submission schema has
// been determined.
export const Register: React.FC<RouteComponentProps> = ({ location }) => {
    const authStatus = useSelector(selectAuthStatus);
    const cultureCode = useSelector(selectCultureCode);
    const navigate = useNavigate();

    const [registrationStep, setRegistrationStep] = useState<RegisterStep>(
        RegisterStep.INPUT_PERSONAL_DETAILS
    );
    const [authType, setAuthType] = useState<AuthType>(AuthType.APP);
    const [accountDetails, setAccountDetails] = useState<AccountDetails>({
        emailAddress: '',
        password: '',
    });
    const [sharedSecret, setSharedSecret] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const translations = usePageTranslations<SignInTranslation>(PageResourceKey.SIGN_IN);

    const handleOnComplete = (nextStep: RegisterStep) => () => {
        setRegistrationStep(nextStep);
    };

    const resendEmailVerificationCode = (email: string, password: string) => {
        postEmailVerificationCode({
            username: email,
            password: password,
            bResendCode: true,
            emailVerificationCode: '',
        });
    };

    if (authStatus === 'signed_in') {
        navigate(`/${cultureCode}${AppPath.CHECKING_DETAILS}`);
    }

    useEffect(() => {
        if (location?.state) {
            const initialState = location?.state as RegisterInitialState;
            if (initialState.stage === 'emailVerification') {
                setAccountDetails({
                    emailAddress: initialState.email,
                    password: initialState.password,
                });
                resendEmailVerificationCode(initialState.email, initialState.password);
                setRegistrationStep(RegisterStep.EMAIL_VERIFICATION);
            } else if (initialState.stage === 'tfa') {
                setAccountDetails({
                    emailAddress: initialState.email,
                    password: initialState.password,
                });
                setRegistrationStep(RegisterStep.SELECT_AUTH_TYPE);
            }
            // Resetting auth status back to signed-out so that user sees the
            // sign-in form once they have completed registration.
            setAuthStatus('signed_out');
        }
    }, [location?.state]);

    return (
        <Page isPublic={true}>
            {(registrationStep === RegisterStep.INPUT_PERSONAL_DETAILS ||
                registrationStep === RegisterStep.EMAIL_VERIFICATION) && (
                <RegistrationForm
                    translations={translations}
                    onComplete={handleOnComplete(RegisterStep.SELECT_AUTH_TYPE)}
                    setAccountDetails={setAccountDetails}
                    accountDetails={accountDetails}
                    registrationStep={registrationStep}
                    setRegistrationStep={handleOnComplete}
                />
            )}
            <div className="RegistrationForm">
                {registrationStep === RegisterStep.SELECT_AUTH_TYPE && (
                    <SelectAuthType
                        translations={translations}
                        onComplete={handleOnComplete(RegisterStep.TFA_SETUP)}
                        authType={authType}
                        setAuthType={setAuthType}
                    />
                )}
                {registrationStep === RegisterStep.TFA_SETUP && (
                    <TFASetup
                        translations={translations}
                        onComplete={handleOnComplete(RegisterStep.TFA_VERIFICATION)}
                        authType={authType}
                        setAuthType={setAuthType}
                        accountDetails={accountDetails}
                        setSharedSecret={setSharedSecret}
                        setPhoneNumber={setPhoneNumber}
                    />
                )}
                {registrationStep === RegisterStep.TFA_VERIFICATION && (
                    <TFAVerification
                        translations={translations}
                        onComplete={handleOnComplete(RegisterStep.COMPLETE)}
                        authType={authType}
                        setAuthType={setAuthType}
                        accountDetails={accountDetails}
                        sharedSecret={sharedSecret}
                        phoneNumber={phoneNumber}
                        setRegistrationStep={setRegistrationStep}
                    />
                )}
                {registrationStep === RegisterStep.COMPLETE && <RegistrationSuccessMessage />}
            </div>
        </Page>
    );
};
