// https://stackoverflow.com/a/2901298
export const formatPriceWithCommas = (price: number | string, precision: number = 6) => {
    const typedPrice = typeof price === 'string' ? parseFloat(price) : price;
    if (Math.abs(typedPrice) < 10 ** -precision) return '0.00';
    const priceToPrecision = typedPrice.toPrecision(precision);
    if (!priceToPrecision.includes('.')) {
        return priceToPrecision;
    }
    const priceWithPaddingZero = priceToPrecision.concat(
        `${priceToPrecision.split('.')[1].length === 1 ? '0' : ''}`
    );
    const preceedingDecimalPointWithCommas = priceWithPaddingZero
        .split('.')[0]
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return preceedingDecimalPointWithCommas.concat('.').concat(priceWithPaddingZero.split('.')[1]);
};

export const formatPriceWithLocale = (
    price: number | string,
    locale: string,
    sigFig?: number,
    showExtraZeroes: boolean = false
) => {
    const typedPrice = typeof price === 'string' ? parseFloat(price) : price;
    if (sigFig && Math.abs(typedPrice) < 10 ** -sigFig)
        // if number is smaller than we can show, show 0 instead
        return (0).toLocaleString(locale, {
            minimumFractionDigits: showExtraZeroes ? sigFig || 2 : 2,
            maximumFractionDigits: sigFig,
        });
    if (sigFig && Math.abs(typedPrice) < 1)
        // if we ask for 0.003211 to 3 s.f., we'd get 0.00321 instead of 0.003, so we have to use d.p. for numbers that start with 0.xxx
        return typedPrice.toLocaleString(locale, {
            minimumFractionDigits: showExtraZeroes ? sigFig || 2 : 2,
            maximumFractionDigits: sigFig,
        });
    if (sigFig && Math.abs(typedPrice) > 10 ** sigFig)
        // if number is larger than the number of sigfig, use compact notation (billions, millions etc)
        return typedPrice.toLocaleString(locale, {
            notation: 'compact',
            compactDisplay: 'long',
        });
    return typedPrice.toLocaleString(locale, {
        minimumFractionDigits: showExtraZeroes ? sigFig || 2 : 2,
        maximumSignificantDigits: sigFig,
    });
};
