import { useTheme } from '@emotion/react';
import { fiatCurrencyFormatter } from 'appConstants';
import PendingIcon from 'assets/ibanera/Icon_Pending.png';
import Button from 'components/button/Button';
import { TFAField } from 'components/form/TFAField';
import { GuaranteeTableRow, isGuaranteeTableRow } from 'components/guaranteesTable/GuaranteesTable';
import { Form, Formik, FormikHelpers } from 'formik';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { useTFAField } from 'helpers/useTFAField';
import { TFAValidation } from 'helpers/validationSnippets';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAvailableAssets } from 'reducers/availableAssets';
import { closeModal, selectModalState } from 'reducers/modal';
import * as Yup from 'yup';
import { DeleteModalApi } from './DeleteModalApi';

type DeleteGuaranteeFormikState = {
    tfaCode: string;
};

const validationSchema = Yup.object({
    tfaCode: TFAValidation,
});

const initialValues: DeleteGuaranteeFormikState = { tfaCode: '' };

export const DeleteGuaranteeModal: React.FC = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const modalState = useSelector(selectModalState);
    const [errorMessages, setErrorMessages] = useState<string[] | null>(null);
    const [TFAType, toggleTFAType] = useTFAField();

    if (!isGuaranteeTableRow(modalState.data)) {
        dispatch(closeModal());
    }
    const modalData = modalState.data as GuaranteeTableRow;

    const handleSubmit = async (
        values: DeleteGuaranteeFormikState,
        helpers: FormikHelpers<DeleteGuaranteeFormikState>
    ) => {
        const response = await DeleteModalApi.requestDeleteGuarantee({
            guaranteesId: modalData.guarantees__Id,
            tfaCode: values.tfaCode,
            tfaType: TFAType,
        });
        if (DeleteModalApi.isSuccessData(response)) {
            dispatch(closeModal());
            Toast.openToastMessage('Transaction sent for deletion', ToastMessageReason.VALID);
        } else if (DeleteModalApi.isErrorData(response)) {
            setErrorMessages(response.errors);
        } else {
            dispatch(closeModal());
            Toast.openGenericErrorToast();
        }
        helpers.setSubmitting(false);
    };

    return (
        <div className="ActivateGuaranteeModal">
            <h1 style={{ color: theme.colors.first }} className="Price">
                {fiatCurrencyFormatter(
                    modalData.guarantees__GuaranteeValue,
                    modalData.fiatAssets__Code
                )}{' '}
                <span className="Ticker">{modalData.fiatAssets__Code}</span>
            </h1>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {({ values, isSubmitting, isValid, submitForm }) => (
                    <Form>
                        <div className="TFASection">
                            <h3 className="Title">Two-Factor Authentication</h3>
                            <p className="SubTitle">{`Enter the code from ${
                                TFAType === 'AuthenticatorApp'
                                    ? 'your authentication app'
                                    : 'the sms we sent you'
                            }`}</p>
                            <div>
                                <TFAField
                                    field={'tfaCode'}
                                    label={'Your verification code'}
                                    required={true}
                                    autoFocus
                                    holdFocus
                                    tfaType={TFAType}
                                    toggleTFAType={toggleTFAType}
                                    value={values.tfaCode}
                                    backgroundColor="white"
                                />
                                {errorMessages && (
                                    <>
                                        {errorMessages.map((error) => (
                                            <p className="ErrorText NoMargin">{error}</p>
                                        ))}
                                    </>
                                )}
                                <Button
                                    type="submit"
                                    className="SubmitButton"
                                    style={{
                                        backgroundColor: theme.colors.second,
                                        borderColor: theme.colors.second,
                                    }}
                                    disabled={!isValid || isSubmitting}
                                    onClick={submitForm}
                                >
                                    Delete
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            <div className="PendingContainer">
                <img src={PendingIcon} alt="pending" className="PendingIcon" />
                <h1 className="PendingTitle NoMargin">Pending</h1>
                <div className="FlexPlaceholder" />
            </div>
        </div>
    );
};
