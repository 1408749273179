import { useTheme } from '@emotion/react';
import { isErrorHandled, retrieveErrorMessages } from 'api';
import { fiatCurrencyFormatter } from 'appConstants';
import Icon_One from 'assets/Icon_Collateral_01.png';
import Icon_Two from 'assets/Icon_Collateral_02.png';
import Icon_Lock from 'assets/Icon_Lock_01.png';
import Button from 'components/button/Button';
import { CountdownTimer } from 'components/countdownTimer/CountdownTimer';
import { TFAField } from 'components/form/TFAField';
import { GuaranteeTableRow, isGuaranteeTableRow } from 'components/guaranteesTable/GuaranteesTable';
import {
    NotificationIdentifier,
    selectUIUpdate,
} from 'components/notifications/notificationUIUpdateReducer';
import { Form, Formik, FormikHelpers } from 'formik';
import { formatDateToShortDate } from 'helpers/formatDateToShortDate';
import { generateBase64Src } from 'helpers/generateBase64Src';
import { stringOnlyContainsNumbers } from 'helpers/stringOnlyContainsNumbers';
import { Toast } from 'helpers/toast';
import { useNotificationUIUpdates } from 'helpers/useNotificationUIUpdates';
import { useTFAField } from 'helpers/useTFAField';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAvailableAssets } from 'reducers/availableAssets';
import { closeModal, selectModalState } from 'reducers/modal';
import * as Yup from 'yup';
import { UnlockGuaranteeModalApi } from './UnlockGuaranteeModalApi';

type UnlockGuaranteeFormikState = {
    tfaCode: string;
};

type UnlockInfo = {
    QRCode: string;
    unlockCode: string;
};

const validationSchema: Yup.SchemaOf<UnlockGuaranteeFormikState> = Yup.object({
    tfaCode: Yup.string()
        .required('Please enter your TFA code')
        .test('is valid tfa', 'Please enter your TFA code', (value: string | undefined) => {
            if (value && value.length === 6 && stringOnlyContainsNumbers(value)) {
                return true;
            }
            return false;
        }),
});

export enum UnlockOption {
    DIRECT = 'Direct',
    COLLATERAL = 'Collateral',
}

const initialValues: UnlockGuaranteeFormikState = { tfaCode: '' };

const UnlockGuaranteeModal: React.FC = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [selectedUnlockOption, setSelectedUnlockOption] = useState<UnlockOption | null>(null);
    const [unlockInfo, setUnlockInfo] = useState<UnlockInfo | null>(null);
    const [currentTFAType, toggleTFAType] = useTFAField();
    const [errors, setErrors] = useState<string[] | null>(null);
    const update = useSelector(selectUIUpdate);

    const { data } = useSelector(selectModalState);

    if (!isGuaranteeTableRow(data)) {
        dispatch(closeModal());
    }

    useEffect(() => {
        if (
            update?.pushType === NotificationIdentifier.UNLOCK_CODE_SCANNED &&
            update.data.unlockCode === unlockInfo?.unlockCode
        ) {
            dispatch(closeModal());
        }
    }, [update, unlockInfo]);

    const unlockGuaranteeModalData = data as GuaranteeTableRow;

    const handleSubmit = (
        values: UnlockGuaranteeFormikState,
        { setSubmitting, setFieldValue }: FormikHelpers<UnlockGuaranteeFormikState>
    ) => {
        // TODO(HC): Add SMS TFA option.
        if (selectedUnlockOption) {
            UnlockGuaranteeModalApi.unlockGuarantee(
                unlockGuaranteeModalData.guarantees__Id,
                values.tfaCode,
                currentTFAType,
                selectedUnlockOption
            )
                .then((response) => {
                    if (selectedUnlockOption === UnlockOption.COLLATERAL) {
                        return dispatch(closeModal());
                    }
                    setUnlockInfo({
                        QRCode: response.data.details.unlockQRCode,
                        unlockCode: response.data.details.unlockCode,
                    });
                    setSelectedUnlockOption(null);
                    setFieldValue('tfaCode', '');
                })
                .catch((error) => {
                    if (isErrorHandled(error)) {
                        const errors = retrieveErrorMessages(error.response.data.errors);
                        setErrors(errors);
                        setSubmitting(false);
                    } else {
                        dispatch(closeModal());
                        Toast.openGenericErrorToast();
                    }
                });
        }
    };

    return (
        <div className="UnlockGuaranteeModal">
            {unlockInfo ? (
                <div>
                    <p className="IssueDate">
                        <span>Issue date: </span>{' '}
                        {formatDateToShortDate(
                            new Date(unlockGuaranteeModalData.guarantees__LockDate!)
                        )}
                    </p>
                    <CountdownTimer
                        countdownTime={60}
                        className="UnlockCountdownTimer"
                        onTimerEnd={() => {
                            setUnlockInfo(null);
                        }}
                    />
                    <h1 className="Amount">
                        {fiatCurrencyFormatter(
                            unlockGuaranteeModalData.guarantees__GuaranteeValue,
                            unlockGuaranteeModalData.fiatAssets__Code
                        )}{' '}
                        {unlockGuaranteeModalData.fiatAssets__Code}
                    </h1>
                    <div className="QRCodeContainer">
                        <img src={generateBase64Src(unlockInfo.QRCode)} className="QRCode" />
                        <p className="UnlockCode">
                            <span>{unlockInfo.unlockCode}</span>
                        </p>
                    </div>
                </div>
            ) : selectedUnlockOption ? (
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {({ values, isSubmitting, isValid, submitForm }) => (
                        <Form>
                            <div>
                                <p className="IssueDate">
                                    <span>Issue date: </span>{' '}
                                    {formatDateToShortDate(
                                        new Date(unlockGuaranteeModalData.guarantees__LockDate!)
                                    )}
                                </p>
                                <h1 className="Amount">
                                    {fiatCurrencyFormatter(
                                        unlockGuaranteeModalData.guarantees__GuaranteeValue,
                                        unlockGuaranteeModalData.fiatAssets__Code
                                    )}{' '}
                                    {unlockGuaranteeModalData.fiatAssets__Code}
                                </h1>
                                <div className="TFASection">
                                    {selectedUnlockOption === UnlockOption.DIRECT ? (
                                        <>
                                            <h3 className="Title">Two-Factor Authentication</h3>
                                            <p className="SubTitle">
                                                {`Enter the code from ${
                                                    currentTFAType === 'AuthenticatorApp'
                                                        ? 'your authentication app'
                                                        : 'the sms we sent you'
                                                }`}
                                            </p>
                                        </>
                                    ) : (
                                        <p>
                                            <b>
                                                Please confirm you would like us to liquidate your
                                                crypto assets in order to cover your transaction for{' '}
                                                {fiatCurrencyFormatter(
                                                    unlockGuaranteeModalData.guarantees__GuaranteeValue,
                                                    unlockGuaranteeModalData.fiatAssets__Code
                                                )}
                                            </b>
                                        </p>
                                    )}
                                    <div>
                                        <TFAField
                                            field={'tfaCode'}
                                            label={'Your verification code'}
                                            required={true}
                                            value={values.tfaCode}
                                            tfaType={currentTFAType}
                                            toggleTFAType={toggleTFAType}
                                            backgroundColor="white"
                                            autoFocus
                                            holdFocus
                                        />
                                        {errors &&
                                            errors.map((error) => (
                                                <p className="ErrorText NoMargin">{error}</p>
                                            ))}
                                        <Button
                                            type="submit"
                                            style={{
                                                backgroundColor: theme.colors.second,
                                                borderColor: theme.colors.second,
                                            }}
                                            disabled={!isValid || isSubmitting}
                                            onClick={submitForm}
                                            className="SubmitButton"
                                        >
                                            Apply
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            ) : (
                <>
                    <p>{`Would you like to repay ${unlockGuaranteeModalData.venues__Name} directly or would you like us to unlock your transaction using your locked collateral?`}</p>

                    <div className="EitherOrContainer">
                        <div className="EitherOr">
                            <img src={Icon_One} alt="icon" />
                            <Button
                                onClick={() => setSelectedUnlockOption(UnlockOption.DIRECT)}
                                className="OptionButton"
                            >
                                Directly
                            </Button>
                        </div>
                        <div className="EitherOr">
                            <img src={Icon_Two} alt="icon" />
                            <Button
                                onClick={() => setSelectedUnlockOption(UnlockOption.COLLATERAL)}
                                className="OptionButton"
                            >
                                Use My Collateral
                            </Button>
                        </div>
                    </div>
                </>
            )}
            <div className="BottomBanner">
                <div className="ImgContainer">
                    <img className="BannerImg" src={Icon_Lock} alt="lock" />
                </div>
                <p className="BannerText">Locked</p>
            </div>
        </div>
    );
};

export { UnlockGuaranteeModal };
